import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isInputNumberInRange, isInputNumberInteger } from 'utils';
import {
    ButtonsDA,
    ButtonsNY,
    ContractHireExtensionViewFormValues,
    ExtensionMonths,
    ChangeInCircumstances,
    NewAgreementFinanceBrands,
} from './types';

const proposalNumberRegex = /^44[0-9]{10}$/;

const isProposalNumberValid = (value: string | null | undefined): boolean => {
    return !value || proposalNumberRegex.test(value);
};

const noAndYesValidValues: string[] = [ButtonsNY.no, ButtonsNY.yes];

const disagreeAndAgreeValidValues: string[] = [ButtonsDA.disagree, ButtonsDA.agree];

const newAgreementFinanceBrandsValidValues: string[] = [
    NewAgreementFinanceBrands.audi,
    NewAgreementFinanceBrands.bentley,
    NewAgreementFinanceBrands.lamborghini,
    NewAgreementFinanceBrands.porsche,
    NewAgreementFinanceBrands.seat,
    NewAgreementFinanceBrands.skoda,
    NewAgreementFinanceBrands.vw,
    NewAgreementFinanceBrands.vwcv,
];

const extensionMonths12ValidValues: string[] = [
    ExtensionMonths.one,
    ExtensionMonths.two,
    ExtensionMonths.three,
    ExtensionMonths.four,
    ExtensionMonths.five,
    ExtensionMonths.six,
    ExtensionMonths.seven,
    ExtensionMonths.eight,
    ExtensionMonths.nine,
    ExtensionMonths.ten,
    ExtensionMonths.eleven,
    ExtensionMonths.twelve,
];

const changeInCircumstanceValidValues: string[] = [
    ChangeInCircumstances.adverseHealth,
    ChangeInCircumstances.recentLossOfEmployment,
    ChangeInCircumstances.relationshipBreakdownDivorce,
    ChangeInCircumstances.changeInLivingArrangementNoFixedAbode,
    ChangeInCircumstances.noLongerWorking,
    ChangeInCircumstances.risingCostOfLiving,
    ChangeInCircumstances.other,
];

export const validationSchema = (
    t: TFunction,
    minValidCurrentMileage: number,
    maxValidCurrentMileage: number,
    contractNumber: string,
): Yup.ObjectSchema<ContractHireExtensionViewFormValues> => {
    return Yup.object()
        .shape<ContractHireExtensionViewFormValues>({
            alreadyHaveNewAgreement: Yup.string()
                .required(t('noOrYesRadioButtons.validation.required'))
                .oneOf(noAndYesValidValues, t('noOrYesRadioButtons.validation.required')),
            newAgreementFinanceBrand: Yup.string()
                .default('')
                .defined()
                .when(
                    'alreadyHaveNewAgreement',
                    (alreadyHaveNewAgreement: string, schema: Yup.StringSchema<string>) => {
                        if (alreadyHaveNewAgreement === ButtonsNY.yes) {
                            return schema
                                .required(t('newAgreementFinanceBrand.validation.required'))
                                .oneOf(
                                    newAgreementFinanceBrandsValidValues,
                                    t('newAgreementFinanceBrand.validation.required'),
                                );
                        } else {
                            return schema.length(0, t('newAgreementFinanceBrand.validation.mustBeBlank'));
                        }
                    },
                ),
            newAgreementProposalNumber: Yup.string()
                .default('')
                .defined()
                .when(
                    'alreadyHaveNewAgreement',
                    (alreadyHaveNewAgreement: string, schema: Yup.StringSchema<string>) => {
                        if (alreadyHaveNewAgreement === ButtonsNY.yes) {
                            return schema
                                .optional()
                                .test('format', t('newAgreementProposalNumber.validation.format'), (value) =>
                                    isProposalNumberValid(value),
                                )
                                .test(
                                    'notCurrent',
                                    t('newAgreementProposalNumber.validation.notSame'),
                                    (value) => !value || value.localeCompare(contractNumber) !== 0,
                                );
                        } else {
                            return schema.length(0, t('newAgreementProposalNumber.validation.mustBeBlank'));
                        }
                    },
                ),
            extensionMonths: Yup.string()
                .required(t('extensionMonths.validation.required'))
                .oneOf(extensionMonths12ValidValues, t('extensionMonths.validation.required')),
            currentMileage: Yup.string()
                .required(t('currentMileage.validation.required'))
                .test(
                    'integer',
                    t('currentMileage.validation.integer', { minValidCurrentMileage, maxValidCurrentMileage }),
                    (value) => isInputNumberInteger(value),
                )
                .test(
                    'range',
                    t('currentMileage.validation.range', { minValidCurrentMileage, maxValidCurrentMileage }),
                    (value) => isInputNumberInRange(value, minValidCurrentMileage, maxValidCurrentMileage),
                ),
            canAffordWithCurrentLevelsOfIncomeAndExpenditure: Yup.string()
                .required(t('disagreeOrAgreeRadioButtons.validation.required'))
                .oneOf(disagreeAndAgreeValidValues, t('disagreeOrAgreeRadioButtons.validation.required')),
            incomeNotExpectedToChange: Yup.string()
                .required(t('disagreeOrAgreeRadioButtons.validation.required'))
                .oneOf(disagreeAndAgreeValidValues, t('disagreeOrAgreeRadioButtons.validation.required')),
            expenditureNotExpectedToChange: Yup.string()
                .required(t('disagreeOrAgreeRadioButtons.validation.required'))
                .oneOf(disagreeAndAgreeValidValues, t('disagreeOrAgreeRadioButtons.validation.required')),
            changeInCircumstance: Yup.string()
                .default('')
                .defined()
                .when(
                    [
                        'canAffordWithCurrentLevelsOfIncomeAndExpenditure',
                        'incomeNotExpectedToChange',
                        'expenditureNotExpectedToChange',
                    ],
                    (
                        canAffordWithCurrentLevelsOfIncomeAndExpenditure: string,
                        incomeNotExpectedToChange: string,
                        expenditureNotExpectedToChange: string,
                        schema: Yup.StringSchema<string>,
                    ) => {
                        if (
                            canAffordWithCurrentLevelsOfIncomeAndExpenditure === ButtonsDA.disagree ||
                            incomeNotExpectedToChange === ButtonsDA.disagree ||
                            expenditureNotExpectedToChange === ButtonsDA.disagree
                        ) {
                            return schema
                                .required(t('changeInCircumstance.validation.required'))
                                .oneOf(changeInCircumstanceValidValues, t('changeInCircumstance.validation.required'));
                        } else {
                            return schema.length(0, t('changeInCircumstance.validation.mustBeBlank'));
                        }
                    },
                ),
        })
        .required();
};
