import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, Spinner } from '@cp-shared-8/frontend-ui';
import { ChangeContactDetailsBO, ChangeContactDetailsRequest, getChangeContactDetailsEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { ChangeData } from 'components/change-data/ChangeData';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { stripPhoneNumber, validationSchema } from './ChangeContactDetailsValidation';

export const ChangeContactDetails: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: () => void;
    onChangeSuccess: (changeContactDetailsRequest: ChangeContactDetailsRequest) => void;
    changeContactDetails: ChangeContactDetailsBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changeContactDetails }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showFailureNotification, setShowFailureNotification] = useState<boolean>(false);
    const { t } = useTranslation('change-contact-details');

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    if (changeContactDetails === undefined) {
        return null;
    }

    const onClose = (): void => {
        onChangeCancel();
    };

    const onCancel = (): void => {
        onChangeCancel();
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-contact-details'}
                heading={t('heading')}
                isSubmitting={isSubmitting}
                onClose={onClose}
                testId={'changeContactDetails'}
            >
                <Notification
                    className={'u-mb'}
                    status={NotificationStatus.warning}
                    headline={t('notYourLoginNotification.headline')}
                    testId={'notYourLoginNotification'}
                >
                    {textWithComponents(t, 'notYourLoginNotification.text')}
                </Notification>
                <Formik
                    initialValues={{
                        emailAddress: changeContactDetails.initialEmailAddress,
                        mobileNumber: changeContactDetails.initialMobileNumber,
                        homePhoneNumber: changeContactDetails.initialHomePhoneNumber,
                        workPhoneNumber: changeContactDetails.initialWorkPhoneNumber,
                    }}
                    validationSchema={validationSchema(t)}
                    onSubmit={({ emailAddress, mobileNumber, homePhoneNumber, workPhoneNumber }): Promise<void> => {
                        const changeContactDetailsRequest: ChangeContactDetailsRequest = {
                            emailAddress,
                            mobileNumber: stripPhoneNumber(mobileNumber),
                            homePhoneNumber: stripPhoneNumber(homePhoneNumber),
                            workPhoneNumber: stripPhoneNumber(workPhoneNumber),
                        };
                        setShowFailureNotification(false);
                        setIsSubmitting(true);
                        return CpDataApi.put<void>(getChangeContactDetailsEndpoint(), changeContactDetailsRequest)
                            .then(() => {
                                setIsSubmitting(false);
                                onChangeSuccess(changeContactDetailsRequest);
                            })
                            .catch(() => {
                                setIsSubmitting(false);
                                setShowFailureNotification(true);
                                onChangeFailure();
                            });
                    }}
                >
                    {(formik): React.ReactNode => (
                        <Form className={'uk-change-contact-details-form'} onSubmit={preventSubmit}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            blocks: [200],
                                            delimiter: '',
                                        }}
                                        className={'input-email-address'}
                                        label={t('emailAddress.label')}
                                        tooltip={t('emailAddress.tooltip')}
                                        name={'emailAddress'}
                                        testId={'emailAddress'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default={'1/3'} m={'1/1'}>
                                            <ValidatedInputWithCleave
                                                cleaveOptions={{
                                                    blocks: [20],
                                                    delimiter: '',
                                                }}
                                                className={'input-phone-number'}
                                                label={t('mobileNumber.label')}
                                                tooltip={t('mobileNumber.tooltip')}
                                                addonText={t('mobileNumber.addonText')}
                                                reversed
                                                name={'mobileNumber'}
                                                testId={'mobileNumber'}
                                                isMandatory={true}
                                            />
                                        </Layout.Item>
                                        <Layout.Item default={'1/3'} m={'1/1'}>
                                            <ValidatedInputWithCleave
                                                cleaveOptions={{
                                                    blocks: [20],
                                                    delimiter: '',
                                                }}
                                                className={'input-phone-number'}
                                                label={t('homePhoneNumber.label')}
                                                tooltip={t('homePhoneNumber.tooltip')}
                                                addonText={t('homePhoneNumber.addonText')}
                                                reversed
                                                name={'homePhoneNumber'}
                                                testId={'homePhoneNumber'}
                                                isMandatory={false}
                                            />
                                        </Layout.Item>
                                        <Layout.Item default={'1/3'} m={'1/1'}>
                                            <ValidatedInputWithCleave
                                                cleaveOptions={{
                                                    blocks: [20],
                                                    delimiter: '',
                                                }}
                                                className={'input-phone-number'}
                                                label={t('workPhoneNumber.label')}
                                                tooltip={t('workPhoneNumber.tooltip')}
                                                addonText={t('workPhoneNumber.addonText')}
                                                reversed
                                                name={'workPhoneNumber'}
                                                testId={'workPhoneNumber'}
                                                isMandatory={false}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    {showFailureNotification && (
                                        <Notification
                                            status={NotificationStatus.error}
                                            testId={'failureNotification'}
                                        >
                                            {textWithComponents(t, 'failureNotification.text', {
                                                linkToAvartoPhoneNumber,
                                            })}
                                        </Notification>
                                    )}
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer nav>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={onCancel}
                                            testId={'cancelButton'}
                                            secondary
                                        >
                                            {t('translation:editableSectionNav.cancel')}
                                        </Button>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={formik.submitForm}
                                            testId={'confirmChangeButton'}
                                        >
                                            {t('translation:editableSectionNav.confirmChange')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </ChangeData>
        </Suspense>
    );
};
