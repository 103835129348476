import React from 'react';
import { RealexFailedModalProps } from './types';
import { useTranslation } from 'react-i18next';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';

export const PaymentFailedModal: React.FC<RealexFailedModalProps> = ({
    shown,
    confirmationDisabled,
    status,
    errorKey,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation('realex-payments');
    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    return (
        <Modal
            shown={shown}
            testId={'paymentFailureModal'}
            status={status}
            title={t(`paymentFailedModal.${errorKey}.title`)}
            closeAny={false}
            hideCloseButton={true}
            buttonConfirmText={t('translation:editableSectionNav.ok')}
            buttonConfirmType={'button'}
            confirmationDisabled={confirmationDisabled}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>
                {textWithComponents(t, `paymentFailedModal.${errorKey}.contents`, { linkToAvartoPhoneNumber })}
            </Paragraph>
        </Modal>
    );
};
