import { SimplePageHeroTeaserDto, getFrequentlyAskedQuestionsPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'frequentlyAskedQuestionsPageHeroTeaser',
    contentEndpoint: getFrequentlyAskedQuestionsPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchFrequentlyAskedQuestionsPageHeroTeaser = fetchContent;
