import { RequestContactBO } from '@cp-uk/common';

export const RequestContactTypical: RequestContactBO = {
    encryptedContractIds: ['encrypted0000440123456789', 'encrypted0000440123456790'],
    emailAddress: 'sample@domain.com',
    firstName: 'John',
    lastName: 'Smith',
    mobileNumber: '07890123456',
    homePhoneNumber: '01296123456',
    workPhoneNumber: undefined,
};

export const RequestContactWithMissingContactDetails: RequestContactBO = {
    ...RequestContactTypical,
    emailAddress: undefined,
    homePhoneNumber: undefined,
    mobileNumber: undefined,
};

export const RequestContactWithMissingEmailAddress: RequestContactBO = {
    ...RequestContactTypical,
    emailAddress: undefined,
};

export const RequestContactWithMissingPhoneNumbers: RequestContactBO = {
    ...RequestContactTypical,
    homePhoneNumber: undefined,
    mobileNumber: undefined,
};
