import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleSummarySectionBO } from '@cp-uk/common';
import { vehicleInformation } from 'utils';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { valueOrErrorTag } from 'components/helpers';

export const SummarySection: React.FC<{ summarySection: VehicleSummarySectionBO | undefined }> = ({
    summarySection,
}) => {
    const { t } = useTranslation('vehicle-summary-section');

    if (summarySection === undefined) {
        return null;
    }

    const { vehicleBrand, vehicleDescription, vehicleIdentificationNumber } = summarySection;

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('vehicleInformation.label'),
            value: valueOrErrorTag(t, vehicleInformation(vehicleBrand, vehicleDescription)),
            uppercaseValue: true,
            testId: 'vehicleInformation',
        },
        {
            label: t('vehicleIdentificationNumber.label'),
            value: valueOrErrorTag(t, vehicleIdentificationNumber),
            uppercaseValue: true,
            testId: 'vehicleIdentificationNumber',
        },
    ];

    return (
        <DataOverview noBorder testId={'summarySection'}>
            <DescriptionListHorizontal items={descriptionListItems} />
        </DataOverview>
    );
};
