import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { KeycloakTokenParsed } from 'keycloak-js';
import { AuthenticationHookResult, useAuthentication } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from './axiosInstances';
import { currentBrand, apiGatewayConsumerName, integrationApiBaseUrlLocal } from 'config';

declare interface IdentityBrokerTokenParsed extends KeycloakTokenParsed {
    idpSub?: string;
}

function getAuthenticationHeaders(auth: AuthenticationHookResult): Record<string, string | undefined> {
    if (!auth.isAuthenticated) {
        return {};
    }

    if (process.env.NODE_ENV === 'development') {
        return {
            'x-request-sub': '00000000-0000-0000-0000-000000000000',
            'x-request-idpsub': (auth.tokenParsed as IdentityBrokerTokenParsed).idpSub,
        };
    } else {
        return {
            'x-consumer-name': apiGatewayConsumerName,
            'x-authorization-token': `Bearer ${auth.token}`,
        };
    }
}

export function useAxiosWithAuthentication(): void {
    const auth = useAuthentication();

    if (auth.isAuthenticated) {
        Object.assign(CpDataApi.defaults.headers, { 'x-cp-brand': currentBrand }, getAuthenticationHeaders(auth));
        if (process.env.NODE_ENV === 'development') {
            CpDataApi.defaults.baseURL = integrationApiBaseUrlLocal;
        }
    }
}

export function useAxiosWithCurrentLanguage(): void {
    const { i18n } = useTranslation(undefined, { useSuspense: false });
    const { language } = i18n;
    useEffect(() => {
        if (language) {
            Object.assign(CpDataApi.defaults.headers, {
                'Accept-Language': language,
            });
        }
    }, [language]);
}
