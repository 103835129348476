import { ThirdPartyLicencesDto, getThirdPartyLicencesEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ThirdPartyLicencesDto>({
    contentName: 'thirdPartyLicences',
    contentEndpoint: getThirdPartyLicencesEndpoint,
});

export default reducer;
export const fetchThirdPartyLicences = fetchContent;
