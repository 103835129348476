import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { RequestAdditionalHelp } from 'components/request-additional-help';

export const RequestAdditionalHelpPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.RequestAdditionalHelp);
    const { t } = useTranslation('page-titles');
    const { encryptedContractId } = useParams<{ encryptedContractId: string }>();

    return (
        <ContentSection className={'o-content-section--compact uk-request-additional-help-page'} pageWrapSize={'small'}>
            <PageHeader title={t('request-additional-help')} />
            <RequestAdditionalHelp encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
