import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Paragraph } from '@vwfs-bronson/bronson-react';
import { View } from 'components/view/View';

export type RegisteredKeeperViewProps = {
    onYes: () => void;
    onNo: () => void;
};

export const RegisteredKeeperView: React.FC<RegisteredKeeperViewProps> = ({ onYes, onNo }) => {
    const { t } = useTranslation('change-registration-number-registered-keeper-view');

    const onNoClick = (): void => {
        onNo();
    };
    const onYesClick = (): void => {
        onYes();
    };

    return (
        <View testId={'registeredKeeperView'}>
            <Paragraph className={'u-mb'} testId={'questionParagraph'}>
                {t('paragraphs.question')}
            </Paragraph>
            <ButtonContainer center>
                <Button type={'button'} testId={'noButton'} onClick={onNoClick} secondary>
                    {t('buttons.no.label')}
                </Button>
                <Button type={'button'} testId={'yesButton'} onClick={onYesClick} secondary>
                    {t('buttons.yes.label')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
