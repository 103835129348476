import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Paragraph, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { FinanceBrandsDrive } from '@cp-uk/common';
import { appendBuildTimestamp, openInNewWindow, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { carOrVehicleText } from '../helpers';
import { ContractMarketingCard } from './ContractMarketingCard';

export const DriveMarketingCard: React.FC<{ financeBrand: FinanceBrandsDrive }> = ({ financeBrand }) => {
    const { t } = useTranslation('drive-marketing-card');

    const { onAction: onFindOffersAction } = useAnalyticsActionTracker('driveMarketingCardForward');
    useAnalyticsPageViewTracker('driveMarketingCard', true, financeBrand);

    const title = t('title');
    const buttonUrl = t(`buttonUrls.${financeBrand}`);
    const buttonTestId = 'findOffersButton';
    const carOrVehicle = carOrVehicleText(financeBrand);

    const onFindOffersClick = (): void => {
        onFindOffersAction(buttonTestId, buttonUrl);
        openInNewWindow(buttonUrl);
    };

    return (
        <ContractMarketingCard
            title={title}
            buttonLabel={t('buttons.findOffers')}
            buttonTestId={buttonTestId}
            onButtonClick={onFindOffersClick}
            testId={'driveMarketingCard'}
        >
            <Layout>
                <Layout.Item default={'2/3'} m={'1/2'} s={'1/1'}>
                    <Paragraph className={'u-mb-none'}>{t('text', { carOrVehicle })}</Paragraph>
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/2'} s={'1/1'}>
                    <ResponsiveImage
                        src={appendBuildTimestamp(t('imageUrl', { brand: financeBrand }))}
                        alt={title}
                        testId={'driveImage'}
                    />
                </Layout.Item>
            </Layout>
        </ContractMarketingCard>
    );
};
