import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { currentBrandName } from 'config';
import { useTranslation } from 'react-i18next';

export enum ExternalWebsitesNotificationMode {
    Hidden = '',
    Singular = 'singular',
    Plural = 'plural',
}

export type ExternalWebsitesNotificationProps = {
    className?: string;
    mode: ExternalWebsitesNotificationMode;
};

export const ExternalWebsitesNotification: React.FC<ExternalWebsitesNotificationProps> = ({ className, mode }) => {
    const { t } = useTranslation('external-websites-notification');

    if (mode === ExternalWebsitesNotificationMode.Hidden) {
        return null;
    }

    return (
        <Notification
            className={className}
            status={NotificationStatus.warning}
            text={t(`notifications.${mode}.text`, {
                brandName: currentBrandName,
            })}
            testId={'externalWebsitesNotification'}
        />
    );
};
