import React, { AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

const LinkComponent: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, children, ...otherProps }) => {
    return (
        <Link to={href ?? ''} {...otherProps}>
            {children}
        </Link>
    );
};

export const LinkAdapter = (LinkComponent as unknown) as React.ReactElement;
