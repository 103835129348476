import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textWithComponents, useLocalStorage, buildLinkToPhoneNumber } from 'utils';
import sha1 from 'sha1';

export enum RemediationTypes {
    BorrowersInFinancialDifficulty = 'borrowersInFinancialDifficulty',
    ContractHireTermination = 'contractHireTermination',
    JulySetOfFour = 'julySetOfFour',
}

export type RemediationNotificationProps = {
    className?: string;
    showNotification: boolean;
    remediationType: RemediationTypes;
    contractNumber: string;
};

export const RemediationNotification: React.FC<RemediationNotificationProps> = ({
    className,
    showNotification,
    remediationType,
    contractNumber,
}) => {
    const { t } = useTranslation('remediation-notification');
    const { getLocalValue, setLocalValue } = useLocalStorage();
    const [visible, setVisible] = useState(true);

    if (!showNotification) {
        return null;
    }

    const message = `${remediationType}${contractNumber}`;
    const hash = sha1(message);
    const dismissedKey = `${hash}Dismissed`;
    const dismissedValue = true.toString();
    const notificationDismissed = getLocalValue(dismissedKey);

    const isNotificationAlreadyDismissed = !!notificationDismissed && notificationDismissed === dismissedValue;

    if (isNotificationAlreadyDismissed) {
        return null;
    }

    const linkToPhoneNumber = buildLinkToPhoneNumber(t, remediationType);

    const onButtonClick = (): void => {
        setLocalValue(dismissedKey, dismissedValue);
        setVisible(false);
    };

    return (
        <Notification
            className={className}
            visible={visible}
            status={NotificationStatus.info}
            showCloseButton
            onCloseClick={onButtonClick}
            headline={t(`${remediationType}.headline`)}
            testId={`${remediationType}Notification`}
        >
            {textWithComponents(t, `${remediationType}.text`, { linkToPhoneNumber })}
        </Notification>
    );
};
