import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { ErrorOrEarlyTerminationBO, getEarlyTerminationEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { isSuccessOrBadGateway } from 'utils';

const { reducer, fetchData, updateData } = createGetContractBasedDataFetchSlice<
    ErrorOrEarlyTerminationBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'earlyTermination',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<ErrorOrEarlyTerminationBO>(getEarlyTerminationEndpoint(encryptedContractId), {
            validateStatus: isSuccessOrBadGateway,
        }).then(({ data }) => data);
    },
});

export default reducer;
export const fetchEarlyTermination = fetchData;
export const updateEarlyTermination = updateData;
