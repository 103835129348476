import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { currentBrand } from 'config';

export type NoContractsNotificationProps = {
    className?: string;
    hasOtherBrandContracts: boolean;
    testId?: string;
};

export const NoContractsNotification: React.FC<NoContractsNotificationProps> = ({
    className,
    hasOtherBrandContracts,
    testId,
}) => {
    const { t } = useTranslation('no-contracts-notification');

    const notificationKey = hasOtherBrandContracts ? 'hasOtherBrandContracts' : 'hasNoContracts';
    const brandName = t(`brandNames.${currentBrand}`);

    return (
        <Notification
            className={className}
            status={NotificationStatus.info}
            text={t(`notifications.${notificationKey}.text`, { brandName })}
            testId={testId}
        />
    );
};
