import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import {
    formatAsCurrency,
    formatAsDate,
    formatAsDistance,
    formatAsEmission,
    formatAsNumber,
    formatAsTime,
} from '@cp-uk/common';
import { currentBuild } from 'config';

// This is an "async IIFE" (immediately invoked function expression)
(async (): Promise<void> => {
    await i18next
        .use(LanguageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            load: 'languageOnly',
            debug: process.env.NODE_ENV === 'development',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
                format(value, format): string {
                    if (format === 'date') {
                        return formatAsDate(value);
                    }
                    if (format === 'time') {
                        return formatAsTime(value);
                    }
                    if (format === 'number') {
                        return formatAsNumber(value);
                    }
                    if (format === 'distance') {
                        return formatAsDistance(value);
                    }
                    if (format === 'emission') {
                        return formatAsEmission(value);
                    }
                    if (format === 'currency') {
                        return formatAsCurrency(value);
                    }
                    return value;
                },
            },
            backend: {
                loadPath: `/locales/{{lng}}/{{ns}}.json?v=${currentBuild.timestamp}`,
            },
            whitelist: ['en', 'en'],
        });
})();

export default i18next;
