import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { ArrearsStatuses } from '@cp-uk/common';
import { outstandingPaymentsPagePath, requestAdditionalHelpPagePath } from 'components/navigation/paths';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';

export type ArrearsNotificationProps = {
    className?: string;
    arrearsStatus: ArrearsStatuses;
    totalArrears: number;
    encryptedContractId: string;
};

export const ArrearsNotification: React.FC<ArrearsNotificationProps> = ({
    className,
    arrearsStatus,
    totalArrears,
    encryptedContractId,
}) => {
    const history = useHistory();
    const { t } = useTranslation('arrears-notification');

    if (arrearsStatus === 'none') {
        return null;
    }

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    type buttonType = 'payment' | 'help';

    const makeButtons = (types: buttonType[]): ReactNode => {
        return (
            <ButtonContainer nav className="u-pt">
                {types.map((type) => {
                    const getPath = type === 'payment' ? outstandingPaymentsPagePath : requestAdditionalHelpPagePath;
                    return (
                        <Button
                            key={type}
                            type={'button'}
                            onClick={(): void => history.push(getPath(encryptedContractId))}
                            secondary
                            testId={`${type}Button`}
                        >
                            {t(`buttons.${type}.label`)}
                        </Button>
                    );
                })}
            </ButtonContainer>
        );
    };

    let status: NotificationStatus;
    let keyFragment: string;
    let buttons: ReactNode = null;

    switch (arrearsStatus) {
        case 'missing':
        case 'negative':
            status = NotificationStatus.error;
            keyFragment = 'missing';
            break;
        case 'paymentAndHelp':
            status = NotificationStatus.warning;
            keyFragment = 'paymentAndHelp';
            buttons = makeButtons(['payment', 'help']);
            break;
        case 'payment':
            status = NotificationStatus.warning;
            keyFragment = 'payment';
            buttons = makeButtons(['payment']);
            break;
        case 'info':
            status = NotificationStatus.warning;
            keyFragment = 'info';
            break;
    }

    return (
        <Notification
            className={className}
            status={status}
            headline={t(`notifications.${keyFragment}.headline`)}
            buttons={buttons}
            testId={'arrearsNotification'}
        >
            {textWithComponents(
                t,
                `notifications.${keyFragment}.text`,
                { linkToCollectionsPhoneNumber },
                { totalArrears },
            )}
        </Notification>
    );
};
