import React, { Fragment } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ContentSection, Heading, PageWrap, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { AbstractContentState, FetchContentType, useCmsContent } from '@cp-shared-8/frontend-integration';
import { TrackingId, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { SimpleContentDto } from '@cp-uk/common';
import { RootState } from 'store/rootReducer';
import {
    appendBuildTimestamp,
    buildLinkToEmailAddress,
    buildLinkToPhoneNumber,
    expandSimpleContent,
    textWithComponents,
} from 'utils';
import { linkToCookiePolicyPage, linkToLandingPage, linkToPrivacyPolicyPage } from 'components/link-to-pages';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { fetchLegalNotice } from './LegalNoticeSlice';
import { selectLegalNotice } from './LegalNoticeSelector';
import { fetchPrivacyPolicy } from './PrivacyPolicySlice';
import { selectPrivacyPolicy } from './PrivacyPolicySelector';
import { fetchModernSlaveryStatement } from './ModernSlaveryStatementSlice';
import { selectModernSlaveryStatement } from './ModernSlaveryStatementSelector';

const Ampersand: React.FC = () => {
    return <Fragment>&amp;</Fragment>;
};

const linkToFinancialServicesRegister = (
    <SimpleLink
        href={'https://www.fca.org.uk/firms/financial-services-register'}
        target={'_BLANK'}
        testId={'financialServicesRegisterLink'}
    />
);
const linkToJointPrivacyStatement = (
    <SimpleLink
        href="https://customer.vwfs.co.uk/privacy-policy/Joint-privacy-notice.html"
        target="_BLANK"
        testId={'jointPrivacyStatementLink'}
    />
);
const linkToVwgPrivacyStatement = (
    <SimpleLink href="http://www.volkswagen.co.uk/privacy" target="_BLANK" testId={'vwgPrivacyStatementLink'} />
);
const linkToIcoMakeAComplaint = (
    <SimpleLink href="https://ico.org.uk/make-a-complaint/" target="_BLANK" testId={'icoMakeAComplaintLink'} />
);
const linkToCallCreditCrain = (
    <SimpleLink href={'http://www.callcredit.co.uk/crain'} target="_BLANK" testId={'callCreditCrainLink'} />
);
const linkToEquifaxCrain = (
    <SimpleLink href={'http://www.equifax.co.uk/crain'} target="_BLANK" testId={'equifaxCrainLink'} />
);
const linkToExperianCrain = (
    <SimpleLink href={'http://www.experian.co.uk/crain'} target="_BLANK" testId={'experianCrainLink'} />
);
const linkToCIFAS = <SimpleLink href={'http://www.cifas.org.uk'} target="_BLANK" testId={'ccifasLink'} />;
const linkToHunter = <SimpleLink href={'http://www.nhunter.co.uk'} target="_BLANK" testId={'hunterLink'} />;
const linkToHCYMTIWHAU = <SimpleLink href={'#hcymtiwhau'} testId={'hcymtiwhauLink'} />;
const linkToHDWUYI = <SimpleLink href={'#hdwuyi'} testId={'hdwuyiLink'} />;
const linkToVwgTheCodeOfConductOfTheVolkswagenGroup = (
    <SimpleLink
        href={'https://www.volkswagen-group.com/en/publications/more/the-code-of-conduct-of-the-volkswagen-group-1882'}
        target={'_BLANK'}
        testId={'vwgTheCodeOfConductOfTheVolkswagenGroupLink'}
    />
);
const linkToVwgEthicsRiskManagementAndCompliance = (
    <SimpleLink
        href={'https://www.volkswagen-group.com/en/ethics-risk-management-and-compliance-15952'}
        target={'_BLANK'}
        testId={'vwgEthicsRiskManagementAndComplianceLink'}
    />
);
const linkToVwgIntegrityAndCompliance = (
    <SimpleLink
        href={'https://www.volkswagen-group.com/en/integrity-and-compliance-15705'}
        target={'_BLANK'}
        testId={'vwgIntegrityAndComplianceLink'}
    />
);
const pHCYMTIWHAU = <p id={'hcymtiwhau'} />;
const pHDWUYI = <p id={'hdwuyi'} />;
const h3 = <Heading level={3} />;
const ampersand = <Ampersand />;
const pWithoutMargin = <p className={'u-mb-none'} />;
const imgSignature = (
    <ResponsiveImage
        className={'u-mv-small'}
        src={appendBuildTimestamp('/contents/common/modern-slavery-statement-signature.jpg')}
        alt={'Signature of Jean Smith'}
        testId={'signatureImage'}
    />
);

export const getLegalNoticeComponents = (): Record<string, React.ReactNode> => {
    return {
        linkToWebsite: linkToLandingPage,
        linkToFinancialServicesRegister,
        linkToPrivacyPolicyPage,
        linkToCookiePolicyPage,
    };
};
export const getPrivacyPolicyComponents = (t: TFunction): Record<string, React.ReactNode> => {
    return {
        linkToCookiePolicyPage,
        linkToJointPrivacyStatement,
        linkToVwgPrivacyStatement,
        linkToIcoMakeAComplaint,
        linkToCallCreditCrain,
        linkToEquifaxCrain,
        linkToExperianCrain,
        linkToCIFAS,
        linkToHunter,
        linkToHCYMTIWHAU,
        linkToHDWUYI,
        pHCYMTIWHAU,
        pHDWUYI,
        linkToDataProtectionOfficerEmailAddress: buildLinkToEmailAddress(t, 'dataProtectionOfficer'),
        linkToInformationCommissionersOfficePhoneNumber: buildLinkToPhoneNumber(t, 'informationCommissionersOffice'),
    };
};
export const getModernSlaveryStatementComponents = (): Record<string, React.ReactNode> => {
    return {
        linkToVwgTheCodeOfConductOfTheVolkswagenGroup,
        linkToVwgEthicsRiskManagementAndCompliance,
        linkToVwgIntegrityAndCompliance,
        h3,
        ampersand,
        pWithoutMargin,
        imgSignature,
    };
};

const SimplePageContentUi: React.FC<{
    simpleContent?: SimpleContentDto;
    trackingId: Extract<TrackingId, 'legalNotice' | 'privacyPolicy' | 'modernSlaveryStatement'>;
    components: Record<string, React.ReactNode>;
    className: string;
}> = ({ simpleContent, trackingId, components, className }) => {
    useAnalyticsPageViewTracker(trackingId, !!simpleContent);

    if (simpleContent === undefined) {
        return null;
    }

    return (
        <ContentSection className={className} testId={'simplePageContent'}>
            <PageWrap size={'medium'}>
                {textWithComponents(undefined, expandSimpleContent(simpleContent).content, components)}
            </PageWrap>
        </ContentSection>
    );
};

const SimpleContentWithWrappers = withLoadingAndNoConnectionHandler(SimplePageContentUi);

export enum SimplePageContentPage {
    LegalNotice = 'legal-notice',
    PrivacyPolicy = 'privacy-policy',
    ModernSlaveryStatement = 'modern-slavery-statement',
}

export type SimpleContentProps = {
    page: SimplePageContentPage;
};

export const SimplePageContent: React.FC<SimpleContentProps> = ({ page }) => {
    const { t } = useTranslation();

    let fetchContent: FetchContentType,
        selectContent: (state: RootState) => AbstractContentState<SimpleContentDto>,
        trackingId: Extract<TrackingId, 'legalNotice' | 'privacyPolicy' | 'modernSlaveryStatement'>,
        components: Record<string, React.ReactNode>;
    switch (page) {
        case SimplePageContentPage.LegalNotice:
            fetchContent = fetchLegalNotice;
            selectContent = selectLegalNotice;
            trackingId = 'legalNotice';
            components = getLegalNoticeComponents();
            break;

        case SimplePageContentPage.PrivacyPolicy:
            fetchContent = fetchPrivacyPolicy;
            selectContent = selectPrivacyPolicy;
            trackingId = 'privacyPolicy';
            components = getPrivacyPolicyComponents(t);
            break;

        case SimplePageContentPage.ModernSlaveryStatement:
            fetchContent = fetchModernSlaveryStatement;
            selectContent = selectModernSlaveryStatement;
            trackingId = 'modernSlaveryStatement';
            components = getModernSlaveryStatementComponents();
            break;
    }

    const { cmsContent: simpleContent, isLoading, loadingError } = useCmsContent(fetchContent, selectContent);

    return (
        <SimpleContentWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            simpleContent={simpleContent}
            trackingId={trackingId}
            components={components}
            className={`uk-${page}`}
        />
    );
};
