import * as Yup from 'yup';
import { TFunction } from 'i18next';

const isNotSetWhenDetailsMissing = (value: boolean | null | undefined, detailsMissing: boolean): boolean => {
    return !value || !detailsMissing;
};

const isNotSetWhenChangeInProgress = (value: boolean | null | undefined, changeInProgress: boolean): boolean => {
    return !value || !changeInProgress;
};

export const validationSchema = (
    t: TFunction,
    emailAddressMissing: boolean,
    phoneNumbersMissing: boolean,
    postalAddressMissing: boolean,
    otherChangeInProgress: boolean,
    addressChangeInProgress: boolean,
    addressChangeByPhone: boolean,
) => {
    const phoneSuffix = addressChangeByPhone ? 'Phone' : '';

    const phoneNumber = t('translation:phoneNumbers.avarto').replace(/<nbsp\/>/g, '&nbsp;');

    return Yup.object().shape({
        email: Yup.boolean()
            .required(t('email.validation.required'))
            .test('emailAddressMissing', t('email.validation.emailAddressMissing'), (value) =>
                isNotSetWhenDetailsMissing(value, emailAddressMissing),
            )
            .test('otherChangeInProgress', t('email.validation.otherChangeInProgress'), (value) =>
                isNotSetWhenChangeInProgress(value, otherChangeInProgress),
            ),
        telephone: Yup.boolean()
            .required(t('telephone.validation.required'))
            .test('phoneNumbersMissing', t('telephone.validation.phoneNumbersMissing'), (value) =>
                isNotSetWhenDetailsMissing(value, phoneNumbersMissing),
            )
            .test('otherChangeInProgress', t('telephone.validation.otherChangeInProgress'), (value) =>
                isNotSetWhenChangeInProgress(value, otherChangeInProgress),
            ),
        simpleMessageService: Yup.boolean()
            .required(t('simpleMessageService.validation.required'))
            .test('phoneNumbersMissing', t('simpleMessageService.validation.phoneNumbersMissing'), (value) =>
                isNotSetWhenDetailsMissing(value, phoneNumbersMissing),
            )
            .test('otherChangeInProgress', t('simpleMessageService.validation.otherChangeInProgress'), (value) =>
                isNotSetWhenChangeInProgress(value, otherChangeInProgress),
            ),
        post: Yup.boolean()
            .required(t('post.validation.required'))
            .test(
                'postalAddressMissing',
                t(`post.validation.postalAddressMissing${phoneSuffix}`, { phoneNumber }),
                (value) => isNotSetWhenDetailsMissing(value, postalAddressMissing),
            )
            .test('addressChangeInProgress', t('post.validation.addressChangeInProgress'), (value) =>
                isNotSetWhenChangeInProgress(value, addressChangeInProgress),
            ),
    });
};
