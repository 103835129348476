import React from 'react';
import { MaximumPaymentExceededModalProps } from './types';
import { useTranslation } from 'react-i18next';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';

export const MaximumPaymentExceededModal: React.FC<MaximumPaymentExceededModalProps> = ({
    shown,
    maximumPayment,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation('realex-payments');

    return (
        <Modal
            shown={shown}
            testId={'maximumPaymentExceededModal'}
            status={'info'}
            title={t('maximumPaymentExceededModal.title')}
            closeAny={false}
            hideCloseButton={true}
            buttonConfirmText={t('translation:editableSectionNav.ok')}
            buttonConfirmType={'button'}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>{t('maximumPaymentExceededModal.contents', { maximumPayment })}</Paragraph>
        </Modal>
    );
};
