import { RequestPayInOneGo } from '@cp-uk/common';
import { OptionsAvailableSelections } from '../options-available-view/types';
import { PayInOneGoSelections } from '../pay-in-one-go-view/types';
import { AdditionalInformationSelections } from '../additional-information-view/types';
import { ContactDetailsSelections } from '../contact-details-view/types';

export const buildRequestPayInOneGo = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
    optionsAvailableSelections: OptionsAvailableSelections,
    payInOneGoSelections: PayInOneGoSelections,
    additionalInformationSelections: AdditionalInformationSelections,
    contactDetailsSelections: ContactDetailsSelections,
): RequestPayInOneGo => {
    return {
        firstName,
        lastName,
        emailAddress,
        option: optionsAvailableSelections.optionAsString,
        consent: optionsAvailableSelections.consent,
        methodOfPayment: payInOneGoSelections.methodOfPaymentAsString,
        dateOfPayment: payInOneGoSelections.dateOfPaymentEffective,
        financialCircumstances: additionalInformationSelections.financialCircumstancesAsString,
        financialCircumstancesMoreDetail: additionalInformationSelections.financialCircumstancesMoreDetail,
        additionalSupport: additionalInformationSelections.additionalSupport,
        additionalSupportDetail: additionalInformationSelections.additionalSupportDetail,
        upToDateWithPriorityBills: additionalInformationSelections.upToDateWithPriorityBills,
        phoneNumber: contactDetailsSelections.phoneNumber,
        earliestAvailableTime: contactDetailsSelections.earliestAvailableTimeAsString,
        latestAvailableTime: contactDetailsSelections.latestAvailableTimeAsString,
        availableDays: contactDetailsSelections.availableDaysAsString,
    };
};
