import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hr } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { BankAccountChangeables, BankAccountSectionBO, ChangeBankAccountRequest } from '@cp-uk/common';
import { maskAccountNumber, maskSortCode } from 'utils';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ContractDetailsContext } from '../../ContractDetailsContext';
import { ChangeBankAccount } from './change-bank-account/ChangeBankAccount';
import { NotificationContentProps } from '../types';

export const BankAccountSection: React.FC<{ bankAccountSection: BankAccountSectionBO | undefined }> = ({
    bankAccountSection: propsBankAccountSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [failureNotificationContentProps, setFailureNotificationContentProps] = useState<NotificationContentProps | undefined>(undefined);
    const { newBankAccountSection, setNewBankAccountSection } = useContext(ContractDetailsContext);
    const { t } = useTranslation('bank-account-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('financialDetailsEditIbanSectionCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker(
        'financialDetailsEditIbanSectionSubmitFailed',
    );
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSuccess');

    if (propsBankAccountSection === undefined) {
        return null;
    }

    const title = t('title');

    const bankAccountSection = newBankAccountSection ?? propsBankAccountSection;

    const { bankAccountChangeable, sortCode, accountNumber } = bankAccountSection;
    // NOTE: We are always retrieving the changeBankAccount from the "props" BankAccountSectionBO as we need to show the
    // confirmation view after the data-entry view is successful (we normally return to the read-only table then)
    const changeBankAccount = propsBankAccountSection.changeBankAccount;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        setFailureNotificationContentProps(undefined);
        onChangeStartAction(title);
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction(title);
    };
    const onChangeFailure = (failureNotificationContentProps: NotificationContentProps): void => {
        setFailureNotificationContentProps(failureNotificationContentProps);
        setIsChanging(false);
        onChangeFailureAction(title);
    };
    const onChangeSuccess = (): void => {
        setIsChanging(false);
    };
    const onChangeData = (changeBankAccountRequest: ChangeBankAccountRequest): void => {
        const newBankAccountSection: BankAccountSectionBO = {
            bankAccountChangeable: 'inProgress' as Exclude<BankAccountChangeables, 'ok'>,
            sortCode: maskSortCode(changeBankAccountRequest.sortCode),
            accountNumber: maskAccountNumber(changeBankAccountRequest.accountNumber),
            changeBankAccount: undefined,
        };
        setNewBankAccountSection(newBankAccountSection);
        onChangeSuccessAction(title);
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (sortCode) {
        descriptionListItems.push({
            label: t('sortCode.label'),
            tooltip: t('sortCode.tooltip'),
            value: sortCode,
            testId: 'sortCode',
        });
    }
    if (accountNumber) {
        descriptionListItems.push({
            label: t('accountNumber.label'),
            tooltip: t('accountNumber.tooltip'),
            value: accountNumber,
            testId: 'accountNumber',
        });
    }

    return (
        <Fragment>
            <Hr className={'u-mb-none'} />
            {isChanging ? (
                <ChangeBankAccount
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    onChangeData={onChangeData}
                    changeBankAccount={changeBankAccount}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={title}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${bankAccountChangeable}`)}
                    buttonDisabled={bankAccountChangeable !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'bankAccountSection'}
                >
                    {bankAccountChangeable === 'inProgress' && (
                        <Notification
                            className={'u-mb'}
                            status={NotificationStatus.warning}
                            text={t('inProgressNotification.text')}
                            testId={'inProgressNotification'}
                        />
                    )}
                    <DescriptionListHorizontal items={descriptionListItems} />
                    {failureNotificationContentProps && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.warning}
                            headline={failureNotificationContentProps.headline}
                            text={failureNotificationContentProps.text}
                            testId={'failureNotification'}
                        >
                            {failureNotificationContentProps.children}
                        </Notification>
                    )}
                </DataOverview>
            )}
        </Fragment>
    );
};
