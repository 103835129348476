import React, { FocusEventHandler, Fragment } from 'react';
import { useField } from 'formik';
import { Checkbox as CheckboxBronson, FormField, Paragraph } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { hasError, ValidationErrorMessage } from 'components/errors';
import { HelpText } from 'components/help-text/HelpText';

export type ValidatedCheckboxGroupProps = {
    children: React.ReactNode;
    name: string;
    label: string | React.ReactNode;
    secondaryLabel?: string | React.ReactNode;
    helpText?: string | React.ReactNode;
    className?: string;
    testId?: string;
    tooltip?: string;
    id?: string;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const ValidatedCheckboxGroup = ({
    children,
    name,
    label,
    secondaryLabel,
    helpText,
    className,
    testId,
    tooltip,
    id,
    isMandatory = false,
    withHtmlValidationWarning = false,
}: ValidatedCheckboxGroupProps) => {
    const [, meta] = useField({ name });

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    return (
        <FormField
            type="other"
            testId={`${testId}FormField`}
            id={id}
            labelText={label as string | undefined}
            notion={isMandatory}
            infoIcon={infoIcon}
            staticLabel
        >
            {secondaryLabel && <Paragraph className={'u-text-muted u-font-size-fs-1 u-mb-small'}>{secondaryLabel}</Paragraph>}
            <div className={className}>
                {children}
                {errorMessageElseHelpText}
            </div>
        </FormField>
    );
};

type CheckboxProps = {
    name: string;
    groupName: string;
    label: string | React.ReactNode;
    helpText?: string | React.ReactNode;
    className?: string;
    testId?: string;
    id?: string;
    tooltip?: string;
    checked?: boolean;
    disabled?: boolean;
    isMandatory?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

const Checkbox = ({
    name,
    groupName,
    label,
    helpText,
    className,
    testId,
    id,
    tooltip,
    checked,
    disabled,
    isMandatory = false,
    onFocus,
}: CheckboxProps) => {
    const [field, meta] = useField({ name, type: 'checkbox' });
    const [, groupMeta] = useField({ name: groupName });

    const notion = isMandatory ? <span className="c-form-field__notion">* </span> : null;
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const alwaysHelpText = (
        <HelpText meta={meta} testId={testId}>
            {helpText}
        </HelpText>
    );

    return (
        <FormField className={className} type="checkbox" testId={`${testId}FormField`} id={id}>
            <CheckboxBronson
                checked={checked}
                disabled={disabled}
                error={hasError(groupMeta)}
                testId={testId}
                onFocus={onFocus}
                {...field}
            >
                {label}
                {notion}
                {infoIcon}
                {alwaysHelpText}
            </CheckboxBronson>
        </FormField>
    );
};
Checkbox.displayName = 'ValidatedCheckboxGroup.Checkbox';
ValidatedCheckboxGroup.Checkbox = Checkbox;
