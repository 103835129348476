import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { AxiosResponse } from 'axios';
import { Button, ButtonContainer, Card, Fieldset, Form, Layout, Paragraph, Spinner } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import {
    AddressDetailsBO,
    AddressSuggestionsBO,
    AddressSuggestionBO,
    ChangeAddressBO,
    ChangeAddressRequest,
    getAddressDetailsEndpoint,
    getAddressSuggestionsEndpoint,
    getChangeAddressEndpoint,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { textAsHtml } from 'utils';
import { ChangeData } from 'components/change-data/ChangeData';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { ValidatedSelect } from 'components/validated-select/ValidatedSelect';
import { Well } from 'components/well/Well';
import { validationSchema as findValidationSchema } from './FindAddressValidation';
import { countryFixedValue, validationSchema as changeValidationSchema } from './ChangeAddressValidation';

export const ChangeAddress: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: () => void;
    onChangeSuccess: (changeAddressRequest: ChangeAddressRequest) => void;
    changeAddress: ChangeAddressBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changeAddress }) => {
    const [isFindSubmitting, setIsFindSubmitting] = useState<boolean>(false);
    const [isDetailsSubmitting, setIsDetailsSubmitting] = useState<boolean>(false);
    const [isChangeSubmitting, setIsChangeSubmitting] = useState<boolean>(false);
    const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
    const [showFindFailureNotification, setShowFindFailureNotification] = useState<boolean>(false);
    const [showDetailsFailureNotification, setShowDetailsFailureNotification] = useState<boolean>(false);
    const [showChangeFailureNotification, setShowChangeFailureNotification] = useState<boolean>(false);
    const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestionBO[]>([]);
    const [addressSuggestionIdForDetails, setAddressSuggestionIdForDetails] = useState<string | undefined>(undefined);
    const [addressDetails, setAddressDetails] = useState<AddressDetailsBO | undefined>(undefined);
    const { t } = useTranslation('change-address');

    useEffect(() => {
        if (addressSuggestionIdForDetails) {
            setAddressSuggestionIdForDetails(undefined);
            setIsDetailsSubmitting(true);
            CpDataApi.get<AddressDetailsBO>(getAddressDetailsEndpoint(addressSuggestionIdForDetails))
                .then(({ data }: AxiosResponse<AddressDetailsBO>) => {
                    setAddressDetails(data);
                    setShowAddressFields(true);
                    setIsDetailsSubmitting(false);
                })
                .catch(() => {
                    setShowDetailsFailureNotification(true);
                    setIsDetailsSubmitting(false);
                });
        }
    }, [addressSuggestionIdForDetails]);

    if (changeAddress === undefined) {
        return null;
    }

    const isAnySubmitting = isFindSubmitting || isDetailsSubmitting || isChangeSubmitting;

    const getSelectAddressSelectItems = (): ValidatedSelectItem[] => {
        return addressSuggestions.map(
            (addressSuggestion: AddressSuggestionBO): ValidatedSelectItem => {
                return {
                    label: addressSuggestion.summary,
                    value: addressSuggestion.id,
                };
            },
        );
    };

    const onSelectAddressChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
        setAddressSuggestionIdForDetails(value);
    };

    const changeInitialValues = () => {
        if (addressDetails) {
            return {
                careOf: '',
                flatPoBox: addressDetails.flatPoBox ?? '',
                propertyName: addressDetails.propertyName ?? '',
                houseNumber: addressDetails.houseNumber ?? '',
                street: addressDetails.street ?? '',
                district: addressDetails.district ?? '',
                city: addressDetails.city ?? '',
                postCode: addressDetails.postCode ?? '',
                country: countryFixedValue,
            };
        }

        return {
            careOf: changeAddress.initialCareOf,
            flatPoBox: changeAddress.initialFlatPoBox,
            propertyName: changeAddress.initialPropertyName,
            houseNumber: changeAddress.initialHouseNumber,
            street: changeAddress.initialStreet,
            district: changeAddress.initialDistrict,
            city: changeAddress.initialCity,
            postCode: changeAddress.initialPostCode,
            country: countryFixedValue,
        };
    };

    const onClose = (): void => {
        onChangeCancel();
    };

    const onCancel = (): void => {
        onChangeCancel();
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-address'}
                heading={t('heading')}
                isSubmitting={isAnySubmitting}
                onClose={onClose}
                testId={'changeAddress'}
            >
                <Well className={'u-mb'}>
                    <Formik
                        initialValues={{
                            postCodeToFind: '',
                            selectAddress: '',
                        }}
                        validationSchema={findValidationSchema(t)}
                        onSubmit={({ postCodeToFind }, { setFieldError, setFieldValue }): Promise<void> => {
                            setAddressSuggestionIdForDetails(undefined);
                            setShowFindFailureNotification(false);
                            setIsFindSubmitting(true);
                            return CpDataApi.get<AddressSuggestionsBO>(getAddressSuggestionsEndpoint(postCodeToFind))
                                .then(({ data }: AxiosResponse<AddressSuggestionsBO>) => {
                                    setIsFindSubmitting(false);
                                    setAddressSuggestions(data.suggestions);
                                    if (data.suggestions.length === 0) {
                                        setFieldError('postCodeToFind', t('postCode.validation.noResultsFound'));
                                    }
                                    setFieldValue('selectAddress', '', false);
                                })
                                .catch(() => {
                                    setIsFindSubmitting(false);
                                    setAddressSuggestions([]);
                                    setShowFindFailureNotification(true);
                                });
                        }}
                    >
                        {(formik): React.ReactNode => (
                            <Form className={'uk-find-address-form'} onSubmit={preventSubmit}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInputWithCleave
                                            cleaveOptions={{
                                                blocks: [10],
                                                delimiter: '',
                                            }}
                                            className={'u-inline input-post-code'}
                                            label={t('postCode.label')}
                                            tooltip={t('postCode.tooltip')}
                                            name={'postCodeToFind'}
                                            testId={'postCodeToFind'}
                                            isMandatory={true}
                                        >
                                            <Button
                                                className={'u-ml u-ml-none@xs u-mt-xsmall@xs'}
                                                type={'button'}
                                                disabled={isAnySubmitting}
                                                onClick={formik.submitForm}
                                                testId={'findAddressButton'}
                                            >
                                                {t('buttons.findAddress.label')}
                                            </Button>
                                            {(isFindSubmitting || isDetailsSubmitting) && (
                                                <Spinner
                                                    className={
                                                        'u-inline-flex u-ml u-ml-none@xs u-mt-xsmall@xs c-spinner--compact'
                                                    }
                                                />
                                            )}
                                        </ValidatedInputWithCleave>
                                    </Fieldset.Row>
                                    {showFindFailureNotification && (
                                        <Fieldset.Row>
                                            <Notification
                                                className={'u-mb'}
                                                status={NotificationStatus.error}
                                                testId={'findFailureNotification'}
                                            >
                                                {textAsHtml(t('findFailureNotification.text'))}
                                            </Notification>
                                        </Fieldset.Row>
                                    )}
                                    {addressSuggestions.length > 0 && (
                                        <Fieldset.Row>
                                            <ValidatedSelect
                                                label={t('selectAddress.label')}
                                                placeholder={t('selectAddress.placeholder')}
                                                selectItems={getSelectAddressSelectItems()}
                                                name={'selectAddress'}
                                                testId={'selectAddress'}
                                                disabled={isAnySubmitting}
                                                isMandatory={false}
                                                handleChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                                                    onSelectAddressChange(e)
                                                }
                                            />
                                        </Fieldset.Row>
                                    )}
                                    {showDetailsFailureNotification && (
                                        <Fieldset.Row>
                                            <Notification
                                                className={'u-mb'}
                                                status={NotificationStatus.error}
                                                testId={'detailsFailureNotification'}
                                            >
                                                {textAsHtml(t('detailsFailureNotification.text'))}
                                            </Notification>
                                        </Fieldset.Row>
                                    )}
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Well>
                <Formik
                    initialValues={changeInitialValues()}
                    enableReinitialize={true}
                    validationSchema={changeValidationSchema(t)}
                    onSubmit={({
                        careOf,
                        flatPoBox,
                        propertyName,
                        houseNumber,
                        street,
                        district,
                        city,
                        postCode,
                        country,
                    }): Promise<void> => {
                        const changeAddressRequest: ChangeAddressRequest = {
                            careOf,
                            flatPoBox,
                            propertyName,
                            houseNumber,
                            street,
                            district,
                            city,
                            postCode,
                            country,
                        };
                        setShowChangeFailureNotification(false);
                        setIsChangeSubmitting(true);
                        return CpDataApi.put<void>(getChangeAddressEndpoint(), changeAddressRequest)
                            .then(() => {
                                setIsChangeSubmitting(false);
                                onChangeSuccess(changeAddressRequest);
                            })
                            .catch(() => {
                                setIsChangeSubmitting(false);
                                setShowChangeFailureNotification(true);
                                onChangeFailure();
                            });
                    }}
                >
                    {(formik): React.ReactNode => (
                        <Form className={'uk-change-address-form'} onSubmit={preventSubmit}>
                            <Fieldset>
                                <Card className={'u-mb'} element={'article'}>
                                    {!showAddressFields ? (
                                        <Fieldset.Row>
                                            <Button
                                                className={'u-float-left'}
                                                type={'button'}
                                                link
                                                small
                                                icon={'semantic-forward'}
                                                iconReversed
                                                onClick={(): void => setShowAddressFields(true)}
                                                testId={'changeAddressManuallyButton'}
                                            >
                                                {t('buttons.changeAddressManually.label')}
                                            </Button>
                                        </Fieldset.Row>
                                    ) : (
                                        <Fragment>
                                            <Fieldset.Row>
                                                <ValidatedInputWithCleave
                                                    cleaveOptions={{
                                                        blocks: [50],
                                                        delimiter: '',
                                                    }}
                                                    label={t('careOf.label')}
                                                    name={'careOf'}
                                                    testId={'careOf'}
                                                    isMandatory={false}
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <ValidatedInputWithCleave
                                                    cleaveOptions={{
                                                        blocks: [50],
                                                        delimiter: '',
                                                    }}
                                                    label={t('propertyName.label')}
                                                    name={'propertyName'}
                                                    testId={'propertyName'}
                                                    isMandatory={false}
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default={'2/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [50],
                                                                delimiter: '',
                                                            }}
                                                            label={t('flatPoBox.label')}
                                                            name={'flatPoBox'}
                                                            testId={'flatPoBox'}
                                                            isMandatory={false}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default={'2/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [50],
                                                                delimiter: '',
                                                            }}
                                                            label={t('houseNumber.label')}
                                                            name={'houseNumber'}
                                                            testId={'houseNumber'}
                                                            isMandatory={false}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default={'8/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [50],
                                                                delimiter: '',
                                                            }}
                                                            label={t('street.label')}
                                                            name={'street'}
                                                            testId={'street'}
                                                            isMandatory={false}
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default={'4/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [50],
                                                                delimiter: '',
                                                            }}
                                                            label={t('district.label')}
                                                            name={'district'}
                                                            testId={'district'}
                                                            isMandatory={false}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default={'4/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [50],
                                                                delimiter: '',
                                                            }}
                                                            label={t('city.label')}
                                                            name={'city'}
                                                            testId={'city'}
                                                            isMandatory={true}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default={'2/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [10],
                                                                delimiter: '',
                                                            }}
                                                            label={t('postCode.label')}
                                                            name={'postCode'}
                                                            testId={'postCode'}
                                                            isMandatory={true}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default={'2/12'} m={'1/1'}>
                                                        <ValidatedInputWithCleave
                                                            cleaveOptions={{
                                                                blocks: [10],
                                                                delimiter: '',
                                                            }}
                                                            label={t('country.label')}
                                                            name={'country'}
                                                            testId={'country'}
                                                            disabled={true}
                                                            isMandatory={false}
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                        </Fragment>
                                    )}
                                </Card>
                                <Fieldset.Row>
                                    {showChangeFailureNotification && (
                                        <Notification
                                            className={'u-mb'}
                                            status={NotificationStatus.error}
                                            testId={'failureNotification'}
                                        >
                                            {textAsHtml(t('failureNotification.text'))}
                                        </Notification>
                                    )}
                                    <Paragraph testId={'hmrcRegsChange2021'}>
                                        {t('hmrcRegsChange2021.content')}
                                    </Paragraph>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer nav>
                                        <Button
                                            type={'button'}
                                            disabled={isAnySubmitting}
                                            onClick={onCancel}
                                            secondary
                                            testId={'cancelButton'}
                                        >
                                            {t('translation:editableSectionNav.cancel')}
                                        </Button>
                                        <Button
                                            type={'button'}
                                            disabled={isAnySubmitting}
                                            onClick={formik.submitForm}
                                            testId={'confirmChangeButton'}
                                        >
                                            {t('translation:editableSectionNav.confirmChange')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </ChangeData>
        </Suspense>
    );
};
