import React from 'react';

export type SimpleLinkProps = {
    className?: string;
    title?: string;
    href?: string;
    target?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    testId?: string;
    download?: boolean;
};

export const SimpleLink: React.FC<SimpleLinkProps> = ({
    className,
    title,
    href,
    target,
    onClick,
    testId,
    download,
    children,
}) => {
    const otherProps = download ? { download } : undefined;

    return (
        <a
            className={className}
            title={title}
            href={href}
            target={target}
            onClick={onClick}
            data-testid={testId}
            {...otherProps}
        >
            {children}
        </a>
    );
};
