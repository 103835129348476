import React, { Dispatch, SetStateAction } from 'react';
import { BankAccountSectionBO, DayOfPaymentSectionBO, MileageSectionBO, RegistrationSectionBO } from '@cp-uk/common';

export interface ContractDetailsContextValues {
    contractNumber: string | undefined;
    newBankAccountSection: BankAccountSectionBO | undefined;
    setNewBankAccountSection: Dispatch<SetStateAction<BankAccountSectionBO | undefined>>;
    newDayOfPaymentSection: DayOfPaymentSectionBO | undefined;
    setNewDayOfPaymentSection: Dispatch<SetStateAction<DayOfPaymentSectionBO | undefined>>;
    newMileageSection: MileageSectionBO | undefined;
    setNewMileageSection: Dispatch<SetStateAction<MileageSectionBO | undefined>>;
    newRegistrationSection: RegistrationSectionBO | undefined;
    setNewRegistrationSection: Dispatch<SetStateAction<RegistrationSectionBO | undefined>>;
}

const defaultDispatch = <S>(): Dispatch<SetStateAction<S>> => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (_state): void => {};
};

export const ContractDetailsContext = React.createContext<ContractDetailsContextValues>({
    contractNumber: undefined,
    newBankAccountSection: undefined,
    setNewBankAccountSection: defaultDispatch<BankAccountSectionBO | undefined>(),
    newDayOfPaymentSection: undefined,
    setNewDayOfPaymentSection: defaultDispatch<DayOfPaymentSectionBO | undefined>(),
    newMileageSection: undefined,
    setNewMileageSection: defaultDispatch<MileageSectionBO | undefined>(),
    newRegistrationSection: undefined,
    setNewRegistrationSection: defaultDispatch<RegistrationSectionBO | undefined>(),
});
