import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { LandingPageHeroTeaser } from 'components/landing-page-hero-teaser/LandingPageHeroTeaser';
import { LandingPageMarketingCards } from 'components/landing-page-marketing-cards/LandingPageMarketingCards';
import { Chatbot, ChatbotPage } from 'components/chatbot/Chatbot';

export const LandingPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.Landing);

    return (
        <Fragment>
            <LandingPageHeroTeaser />
            <LandingPageMarketingCards />
            <Chatbot page={ChatbotPage.Landing} />
        </Fragment>
    );
};
