import { nowCPDate } from '@cp-uk/common';
import { ValidatedSelectItem } from '../validated-select/ValidatedSelect';

export const buildRenewalMonthAndYearSelectItems = (): ValidatedSelectItem[] => {
    const renewalMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const nowDate = nowCPDate().toMoment().startOf('day').utc();

    return renewalMonths.map((value) => {
        const renewalMonth = nowDate.clone().add(value, 'months');

        return {
            label: renewalMonth.format('MMMM YYYY'),
            value: renewalMonth.format('YYYY-MM'),
        };
    });
};
