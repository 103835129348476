import React, { Suspense } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { Spinner, useAnalyticsDashboardPageTracker } from '@cp-shared-8/frontend-ui';
import { ContractsOverviewBO, ContractBO } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { authPaths } from 'components/navigation/paths';
import { NoContractsNotification } from 'components/notifications/no-contracts/NoContractsNotification';
import { fetchContractsOverview } from './ContractsOverviewSlice';
import { selectContractsOverview } from './ContractsOverviewSelector';
import { ContractOverview } from './ContractOverview';
import { FinancialDifficultyNotification } from 'components/notifications/financial-difficulty/FinancialDifficultyNotification';

const ContractsOverviewUi: React.FC<{ contractsOverview: ContractsOverviewBO | undefined }> = ({
    contractsOverview,
}) => {
    const anyArrearsStatusNotNone = !!contractsOverview?.contracts?.some(
        (contract: ContractBO): boolean => contract.arrearsStatus !== 'none',
    );
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? authPaths.loginCallback() : lastLocation?.pathname;
    useAnalyticsDashboardPageTracker(!!contractsOverview, anyArrearsStatusNotNone, lastPathname);

    if (contractsOverview === undefined) {
        return null;
    }

    const { hasOtherBrandContracts, contracts } = contractsOverview;


    return (
        <Suspense fallback={<Spinner center />}>
            {contracts.length === 0 ? (
                <NoContractsNotification
                    className={'u-mb'}
                    hasOtherBrandContracts={hasOtherBrandContracts}
                    testId={'contractsNotification'}
                />
            ) : (
                <FinancialDifficultyNotification className={'u-mb'} testId={'contractsNotification'} />
            )}
            {contracts.map((contract: ContractBO, index: number) => {
                return <ContractOverview key={index} contract={contract} isFirst={index === 0} />;
            })}
        </Suspense>
    );
};

const ContractsOverviewWithHandlers = withLoadingAndNoConnectionHandler(ContractsOverviewUi);

export const ContractsOverview: React.FC = () => {
    const { data: contractsOverview, isLoading, loadingError } = useGetSimpleApiData(
        fetchContractsOverview,
        getSimpleDataSelector(selectContractsOverview),
    );

    return (
        <ContractsOverviewWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            contractsOverview={contractsOverview}
        />
    );
};
