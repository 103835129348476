import { CPDate } from '@cp-shared-8/common-utilities';
import { isTodayInDateRange } from './date-utils';

export const selectAlternativeOrNormal = <T>(
    normal: T,
    alternative: T | undefined,
    startDate: CPDate | undefined,
    endDate: CPDate | undefined,
): T => {
    return !!alternative && isTodayInDateRange(startDate, endDate) ? alternative : normal;
};
