// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/cleave-input/CleaveInput.tsx
// (there weren't enough/working properties on the original)

import React, { useLayoutEffect } from 'react';

import { useFormikContext } from 'formik';
import Cleave from 'cleave.js';
// TODO: Check why the below workaround is necessary https://digitalunit-fs.atlassian.net/browse/CO-6147
// eslint-disable-next-line import/no-unresolved
import { CleaveOptions } from 'cleave.js/options';

import { getDisplayName } from '@cp-shared-8/frontend-ui';

export type withCleaveProps = {
    name: string;
    cleaveOptions: CleaveOptions;
    withHtmlValidationWarning?: boolean;
};

interface CanHandleChange {
    handleChange?: Function;
}

export const withCleave = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withCleaveProps> => {
    const Wrapper: React.FC<TProps & withCleaveProps> = ({ name, cleaveOptions, ...props }: withCleaveProps) => {
        const { setFieldValue, initialValues } = useFormikContext();
        const stringifiedOptions = JSON.stringify(cleaveOptions);

        const inputSelector = `input[name="${name}"]`;
        const isInputElementMounted = document.querySelectorAll(inputSelector).length > 0;

        const { handleChange } = props as CanHandleChange;

        useLayoutEffect(() => {
            if (isInputElementMounted) {
                new Cleave(inputSelector, {
                    ...cleaveOptions,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    initValue: (initialValues as any)[name],
                    onValueChanged: (e) => {
                        setFieldValue(`${name}`, e.target.value, true);
                        if (handleChange) {
                            handleChange(e);
                        }
                    },
                });
            }
            // TODO do proper cleanup
            return undefined;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setFieldValue, name, stringifiedOptions, isInputElementMounted]);

        return <WrappedComponent {...(props as TProps)} name={name} />;
    };

    Wrapper.displayName = `withCleave(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
