import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isInputNumberInRange, isInputNumberInteger } from 'utils';
import {
    DisposableIncomeFormValues,
    ExpenditureFormValues,
    IncomeAndExpenditureFormValues,
    IncomeFormValues,
} from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<IncomeAndExpenditureFormValues> => {
    const minValidMoney = 0;
    const maxValidMoney = 999999;

    return Yup.object()
        .shape<IncomeAndExpenditureFormValues>({
            income: Yup.object()
                .shape<IncomeFormValues>({
                    salaryWages: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    benefitsOther: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    total: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
                })
                .required(),
            expenditure: Yup.object()
                .shape<ExpenditureFormValues>({
                    volkswagenFinance: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    rentMortgage: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    waterGasElectric: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    councilTax: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    childCareMaintenance: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    landlineInternetTv: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    mobiles: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    carInsuranceTax: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    fuelTravelCosts: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    housekeeping: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    other: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    otherCreditors: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                        .test('range', t('money.validation.range'), (value) =>
                            isInputNumberInRange(value, minValidMoney, maxValidMoney),
                        ),
                    total: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
                })
                .required(),
            disposableIncome: Yup.object()
                .shape<DisposableIncomeFormValues>({
                    total: Yup.string()
                        .required(t('money.validation.required'))
                        .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
                })
                .required(),
        })
        .required();
};
