import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { FinancialDetailsBO } from '@cp-uk/common';
import { SummarySection } from './summary-section/SummarySection';
import { BankAccountSection } from '../common-details/bank-account-section/BankAccountSection';
import { DayOfPaymentSection } from '../common-details/day-of-payment-section/DayOfPaymentSection';
import { FinalPaymentAmountSection } from './final-payment-amount-section/FinalPaymentAmountSection';
import { MileageSection } from '../common-details/mileage-section/MileageSection';

export const FinancialDetails: React.FC<{ financialDetails: FinancialDetailsBO | undefined }> = ({
    financialDetails,
}) => {
    const { t } = useTranslation('financial-details');

    if (financialDetails === undefined) {
        return null;
    }

    const {
        summarySection,
        bankAccountSection,
        dayOfPaymentSection,
        finalPaymentAmountSection,
        mileageSection,
    } = financialDetails;

    return (
        <Accordion.Item title={t('title')} testId={'financialDetails'}>
            <Suspense fallback={<Spinner center />}>
                <SummarySection summarySection={summarySection} />
                <BankAccountSection bankAccountSection={bankAccountSection} />
                <DayOfPaymentSection dayOfPaymentSection={dayOfPaymentSection} />
                <FinalPaymentAmountSection finalPaymentAmountSection={finalPaymentAmountSection} />
                <MileageSection mileageSection={mileageSection} />
            </Suspense>
        </Accordion.Item>
    );
};
