import { FinancialSupportAndWellbeingContractBO, isProductType } from '@cp-uk/common';
import { currentBrandAsFinanceBrands } from '../helpers';
import { financeHireProducts, financePurchaseProducts } from './types';
import { toCamelCase, upperCaseFirstLetter } from '../../utils';

export const getTrackingId = (pageId: string, topicOrContentId = ''): string => {
    return `${pageId}${upperCaseFirstLetter(toCamelCase(topicOrContentId.replace(/-/g, '_')))}`;
};

export type ServiceData = {
    servicingPlanType: 'multiple' | 'singleFps' | 'singleFcm' | 'none';
    vehicleBrand?: string;
    financeBrand: string;
    vehicleRegistration?: string;
    remainingServiceEntitlement?: number;
};

export const getServiceData = (contracts: FinancialSupportAndWellbeingContractBO[]): ServiceData => {
    const allServiceContracts = contracts.filter((contracts) =>
        isProductType(contracts.productType, ['fixedCostMaintenance', 'fixedPriceServicing']),
    );

    if (allServiceContracts.length === 0) {
        return { servicingPlanType: 'none', financeBrand: currentBrandAsFinanceBrands() };
    }

    if (allServiceContracts.length > 1) {
        return {
            servicingPlanType: 'multiple',
            financeBrand: currentBrandAsFinanceBrands(),
        };
    }

    const singleServiceContract = allServiceContracts[0];

    const servicingPlanType = isProductType(singleServiceContract.productType, 'fixedCostMaintenance')
        ? 'singleFcm'
        : 'singleFps';

    return {
        servicingPlanType,
        financeBrand: singleServiceContract.financeBrand,
        vehicleBrand: singleServiceContract.vehicleBrand,
        vehicleRegistration: singleServiceContract.vehicleRegistrationNumber,
        remainingServiceEntitlement: singleServiceContract.remainingServiceEntitlement,
    };
};

export const getContractTypeKeyFragment = (contracts: FinancialSupportAndWellbeingContractBO[]): string => {
    const hasPurchaseContract = contracts.some((contract) => isProductType(contract.productType, financePurchaseProducts));
    const hasHireContract = contracts.some((contract) => isProductType(contract.productType, financeHireProducts));

    let contractTypeKeyFragment: string;
    if (hasPurchaseContract && hasHireContract) {
        contractTypeKeyFragment = 'both';
    } else if (hasPurchaseContract) {
        contractTypeKeyFragment = 'purchase';
    } else if (hasHireContract) {
        contractTypeKeyFragment = 'hire';
    } else {
        contractTypeKeyFragment = 'none';
    }

    return contractTypeKeyFragment;
};
