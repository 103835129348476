import { FrequentlyAskedQuestionsDto, getFrequentlyAskedQuestionsEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<FrequentlyAskedQuestionsDto>({
    contentName: 'frequentlyAskedQuestions',
    contentEndpoint: getFrequentlyAskedQuestionsEndpoint,
});

export default reducer;
export const fetchFrequentlyAskedQuestions = fetchContent;
