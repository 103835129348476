import React, { Fragment, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, InfoIcon, Paragraph } from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-8/common-utilities';
import {
    ContractDescriptionBO,
    FinanceBrandsEOC,
    isoDateTimeFormat,
    nowCPDate,
    parseCPDate,
    ProductTypesEOC,
} from '@cp-uk/common';
import {
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    buildLinkToPhoneNumber,
    contractCategory,
    textAsHtml,
    textWithComponents,
    useFinanceCompanyDetails
} from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath } from 'components/navigation/paths';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { retailerDescriptionText } from '../../helpers';

export type SolutionsKeepViewProps = {
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    finalPaymentAmount: number;
    contractEndDate: CPDate;
    inArrears: boolean;
    onBack: () => void;
    onRefinance: () => void;
};

export const SolutionsKeepView: React.FC<SolutionsKeepViewProps> = ({
    contractDescription,
    financeBrand,
    vehicleBrand,
    finalPaymentAmount,
    contractEndDate,
    inArrears,
    onBack,
    onRefinance,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const { shortCompanyName, phoneNumber, openingHours } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation(['end-of-contract-solutions-keep-view', 'end-of-contract']);
    const history = useHistory();

    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractPcpKeepBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractPcpKeepDashboard');
    useAnalyticsPageViewTracker('endOfContractPcpKeep');

    // TODO: Analytics...
    //const { onAction: onProductInformationGuideAction } = useAnalyticsActionTracker('onEndOfContractPcpProductInformationGuide');
    const onProductInformationGuideAction = (productInformationGuideForBrandUrl: string): void => {
        console.log(`[SolutionsKeepView] onProductInformationGuideAction(${productInformationGuideForBrandUrl})`);
    };

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const retailerDescription = retailerDescriptionText(financeBrand);
    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);

    const productInformationGuideForBrandUrl = t('paragraphs.common.productInformationGuideForBrandUrl', {
        financeBrand,
    });

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const contractEndDateMoment = parseCPDate(contractEndDate).toMoment();
    const oneMonthBeforeContractEndDateMoment = contractEndDateMoment.clone().utc().subtract(1, 'month');
    const threeMonthsBeforeContractEndDateMoment = contractEndDateMoment.clone().utc().subtract(3, 'month');

    const isTodayBeforeThreeMonthsFromContractEndDate = todayMoment.isBefore(
        threeMonthsBeforeContractEndDateMoment,
        'day',
    );
    const isTodayAfterOneMonthFromContractEndDate = todayMoment.isAfter(oneMonthBeforeContractEndDateMoment, 'day');
    const isTodayWithinOneToThreeMonthsOfContractEndDate = !(
        isTodayBeforeThreeMonthsFromContractEndDate || isTodayAfterOneMonthFromContractEndDate
    );

    const oneMonthBeforeContractEndDate = oneMonthBeforeContractEndDateMoment.format(isoDateTimeFormat);
    const threeMonthsBeforeContractEndDate = threeMonthsBeforeContractEndDateMoment.format(isoDateTimeFormat);

    const refinanceButtonDisabled = inArrears || !isTodayWithinOneToThreeMonthsOfContractEndDate;

    const renderRefinanceButtonInfoIcon = (): ReactNode | null => {
        let infoIconText = '';
        let components: Record<string, React.ReactNode> | undefined = undefined;
        let tOptions = {};

        if (isTodayBeforeThreeMonthsFromContractEndDate) {
            infoIconText = 'buttons.refinance.tooltip.beforeDateRange';
            components = undefined;
            tOptions = {
                threeMonthsBeforeContractEndDate,
                oneMonthBeforeContractEndDate,
            };
        }

        if (isTodayWithinOneToThreeMonthsOfContractEndDate && inArrears) {
            infoIconText = 'buttons.refinance.tooltip.inArrears';
            components = { linkToPhoneNumber };
            tOptions = {
                phoneNumber,
                openingHours,
            };
        }

        if (!!infoIconText) {
            return (
                <InfoIcon className={'u-ml-xsmall'} icon={'semantic-info'}>
                    {textWithComponents(t, infoIconText, components, tOptions)}
                </InfoIcon>
            );
        }

        return null;
    };

    const onProductInformationGuideClick = (): void => {
        onProductInformationGuideAction(productInformationGuideForBrandUrl);
    };

    const linkToATRProductInformationGuide = (
        <SimpleLink
            href={productInformationGuideForBrandUrl}
            target={'_BLANK'}
            onClick={onProductInformationGuideClick}
            testId={'applyToRefinanceProductInformationGuideLink'}
            download
        />
    );
    const linkToRTRProductInformationGuide = (
        <SimpleLink
            href={productInformationGuideForBrandUrl}
            target={'_BLANK'}
            onClick={onProductInformationGuideClick}
            testId={'refinanceThroughRetailerProductInformationGuideLink'}
            download
        />
    );

    const onRefinanceClick = (): void => {
        onRefinance();
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    return (
        <View testId={'solutionsKeepView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-text-bold'} testId={'settlingTitleParagraph'}>
                {t('paragraphs.settling.title')}
            </Paragraph>
            <Paragraph testId={'settlingDetailParagraph1'}>{t('paragraphs.settling.detail1')}</Paragraph>
            <Paragraph testId={'settlingDetailParagraph2'}>
                {t('paragraphs.settling.detail2', { finalPaymentAmount })}
            </Paragraph>
            <Paragraph testId={'settlingDetailParagraph3'}>{t('paragraphs.settling.detail3')}</Paragraph>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                testId={'semiConductorDelaysNotification'}
            >
                {textAsHtml(
                    t('notifications.semiConductorDelays.text', {
                        vehicleBrand,
                        shortCompanyName,
                        retailerDescription,
                        contactUrl,
                    }),
                )}
            </Notification>
            <Paragraph className={'u-text-bold'} testId={'applyToRefinanceTitleParagraph'}>
                {t('paragraphs.applyToRefinance.title')}
            </Paragraph>
            {isTodayAfterOneMonthFromContractEndDate ? (
                <Paragraph testId={'afterDateRangeParagraph'}>
                    {textWithComponents(
                        t,
                        'paragraphs.applyToRefinance.afterDateRange',
                        { linkToPhoneNumber },
                        { phoneNumber, openingHours },
                    )}
                </Paragraph>
            ) : (
                <Fragment>
                    <Paragraph testId={'applyToRefinanceDetailParagraph'}>
                        {textWithComponents(
                            t,
                            'paragraphs.applyToRefinance.detail',
                            { linkToATRProductInformationGuide },
                            { vehicleBrand },
                        )}
                    </Paragraph>
                    <Paragraph className={'u-text-bold'} testId={'refinanceThroughRetailerTitleParagraph'}>
                        {t('paragraphs.refinanceThroughRetailer.title', { shortCompanyName, retailerDescription })}
                    </Paragraph>
                    <Paragraph testId={'refinanceThroughRetailerDetailParagraph'}>
                        {textWithComponents(
                            t,
                            'paragraphs.refinanceThroughRetailer.detail',
                            { linkToRTRProductInformationGuide },
                            { shortCompanyName, retailerDescription },
                        )}
                    </Paragraph>
                    <Paragraph className={'u-text-bold'} testId={'refinanceOnlineTitleParagraph'}>
                        {t('paragraphs.refinanceOnline.title')}
                    </Paragraph>
                    <Paragraph testId={'refinanceOnlineDetailParagraph'}>
                        {t('paragraphs.refinanceOnline.detail')}
                    </Paragraph>
                    <Paragraph className={'u-text-bold'} testId={'requestQuoteTitleParagraph'}>
                        {t('paragraphs.requestQuote.title')}
                    </Paragraph>
                    <Paragraph testId={'requestQuoteDetailParagraph1'}>
                        {textWithComponents(t, 'paragraphs.requestQuote.detail1', undefined, {
                            threeMonthsBeforeContractEndDate,
                            oneMonthBeforeContractEndDate,
                        })}
                    </Paragraph>
                    <Paragraph testId={'requestQuoteDetailParagraph2'}>
                        {textWithComponents(t, 'paragraphs.requestQuote.detail2')}
                    </Paragraph>
                </Fragment>
            )}
            <Paragraph>
                <Button
                    type={'button'}
                    testId={'refinanceButton'}
                    disabled={refinanceButtonDisabled}
                    onClick={onRefinanceClick}
                    secondary
                >
                    {t('buttons.refinance.label')}
                </Button>
                {renderRefinanceButtonInfoIcon()}
            </Paragraph>
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
