import * as Yup from 'yup';
import { TFunction } from 'i18next';
import {
    showOtherFields,
    showFinancialDifficultyFields,
    showConsiderEndingProductPurchaseFields,
    showConsiderEndingProductFields,
    showConsiderEndingProductHireFields,
    showConsiderEndingProductLeaseFields,
} from './helpers';
import {
    ConsiderEndingProductHireFormValues,
    ConsiderEndingProductLeaseFormValues,
    ConsiderEndingProductPurchaseFormValues,
    ReceivingIncomeFormValues,
} from './types';
import { isInputNumberInRange, isInputNumberInteger } from '../../utils';

export const validationSchema = (t: TFunction) => {
    const minValidMoney = 0;
    const maxValidMoney = 999999;

    return Yup.object().shape({
        vulnerableCustomer: Yup.boolean().required(),
        phoneNumber: Yup.string().required(t('phoneNumber.validation.required')),
        reason: Yup.string().required(t('reason.validation.required')),
        message: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showOtherFields(reason),
                then: Yup.string()
                    .required(t('message.validation.required'))
                    .max(500, t('message.validation.maxLength')),
            }),
        causeFinancialDifficulty: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('causeFinancialDifficulty.validation.required'))
                    .max(500, t('causeFinancialDifficulty.validation.maxLength')),
            }),
        receivingIncome: Yup.object()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.object()
                    .required()
                    .test('atLeastOneSelected', t('receivingIncome.validation.atLeastOneSelected'), (value) => {
                        const receivingIncome = value as ReceivingIncomeFormValues;
                        return (
                            receivingIncome.none ||
                            receivingIncome.wage ||
                            receivingIncome.benefits ||
                            receivingIncome.govtContrib
                        );
                    }),
            }),
        behindWithOther: Yup.boolean()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.boolean().required(),
            }),
        behindWithOtherDetail: Yup.string()
            .optional()
            .when(['reason', 'behindWithOther'], {
                is: (reason, behindWithOther) => showFinancialDifficultyFields(reason) && behindWithOther,
                then: Yup.string()
                    .required(t('behindWithOtherDetail.validation.required'))
                    .max(500, t('behindWithOtherDetail.validation.maxLength')),
            }),
        knowWhenReturnNormal: Yup.boolean()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.boolean().required(),
            }),
        knowWhenReturnNormalDuration: Yup.string()
            .optional()
            .when(['reason', 'knowWhenReturnNormal'], {
                is: (reason, knowWhenReturnNormal) => showFinancialDifficultyFields(reason) && knowWhenReturnNormal,
                then: Yup.string().required(t('durations.validation.required')),
            }),
        notMaintainMonthlyPayments: Yup.boolean()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.boolean().required(),
            }),
        notMaintainMonthlyPaymentsDetail: Yup.boolean()
            .optional()
            .when(['reason', 'notMaintainMonthlyPayments'], {
                is: (reason, notMaintainMonthlyPayments) =>
                    showFinancialDifficultyFields(reason) && notMaintainMonthlyPayments,
                then: Yup.boolean().required(),
            }),
        notMaintainMonthlyPaymentsDuration: Yup.string()
            .optional()
            .when(['reason', 'notMaintainMonthlyPayments', 'notMaintainMonthlyPaymentsDetail'], {
                is: (reason, notMaintainMonthlyPayments, notMaintainMonthlyPaymentsDetail) =>
                    showFinancialDifficultyFields(reason) &&
                    notMaintainMonthlyPayments &&
                    notMaintainMonthlyPaymentsDetail,
                then: Yup.string().required(t('durations.validation.required')),
            }),
        continueOption: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string().required(t('continueOption.validation.required')),
            }),
        additionalSupport: Yup.boolean()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.boolean().required(),
            }),
        additionalSupportDetail: Yup.string().when(['reason', 'additionalSupport'], {
            is: (reason, additionalSupport) => showFinancialDifficultyFields(reason) && additionalSupport,
            then: Yup.string()
                .required(t('additionalSupportDetail.validation.required'))
                .max(500, t('additionalSupportDetail.validation.maxLength')),
        }),
        consentInformationProvided: Yup.boolean()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.boolean().required().oneOf([true], t('consentInformationProvided.validation.required')),
            }),
        incomeSalaryWages: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        incomeBenefitsOther: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        incomeTotal: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
            }),
        expenditureVolkswagenFinance: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureRentMortgage: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureWaterGasElectric: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureCouncilTax: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureChildCareMaintenance: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureLandlineInternetTv: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureMobiles: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureCarInsuranceTax: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureFuelTravelCosts: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureHousekeeping: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureOther: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureOtherCreditors: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value))
                    .test('range', t('money.validation.range'), (value) =>
                        isInputNumberInRange(value, minValidMoney, maxValidMoney),
                    ),
            }),
        expenditureTotal: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
            }),
        disposableIncomeTotal: Yup.string()
            .optional()
            .when('reason', {
                is: (reason) => showFinancialDifficultyFields(reason),
                then: Yup.string()
                    .required(t('money.validation.required'))
                    .test('integer', t('money.validation.integer'), (value) => isInputNumberInteger(value)),
            }),
        considerEndingProduct: Yup.string()
            .optional()
            .when(['reason', 'continueOption'], {
                is: (reason, continueOption) =>
                    showFinancialDifficultyFields(reason) && showConsiderEndingProductFields(continueOption),
                then: Yup.string().required(t('considerEndingProduct.validation.required')),
            }),
        considerEndingProductPurchase: Yup.object()
            .optional()
            .when(['reason', 'continueOption', 'considerEndingProduct'], {
                is: (reason, continueOption, considerEndingProduct) =>
                    showFinancialDifficultyFields(reason) &&
                    showConsiderEndingProductFields(continueOption) &&
                    showConsiderEndingProductPurchaseFields(considerEndingProduct),
                then: Yup.object()
                    .required()
                    .test(
                        'atLeastOneSelected',
                        t('considerEndingProductOptions.validation.atLeastOneSelected'),
                        (value) => {
                            const considerEndingProductPurchase = value as ConsiderEndingProductPurchaseFormValues;
                            return (
                                considerEndingProductPurchase.sell ||
                                considerEndingProductPurchase.partExchange ||
                                considerEndingProductPurchase.voluntaryTermination
                            );
                        },
                    ),
            }),
        considerEndingProductHire: Yup.object()
            .optional()
            .when(['reason', 'continueOption', 'considerEndingProduct'], {
                is: (reason, continueOption, considerEndingProduct) =>
                    showFinancialDifficultyFields(reason) &&
                    showConsiderEndingProductFields(continueOption) &&
                    showConsiderEndingProductHireFields(considerEndingProduct),
                then: Yup.object()
                    .required()
                    .test(
                        'atLeastOneSelected',
                        t('considerEndingProductOptions.validation.atLeastOneSelected'),
                        (value) => {
                            const considerEndingProductHire = value as ConsiderEndingProductHireFormValues;
                            return considerEndingProductHire.earlyTermination;
                        },
                    ),
            }),
        considerEndingProductLease: Yup.object()
            .optional()
            .when(['reason', 'continueOption', 'considerEndingProduct'], {
                is: (reason, continueOption, considerEndingProduct) =>
                    showFinancialDifficultyFields(reason) &&
                    showConsiderEndingProductFields(continueOption) &&
                    showConsiderEndingProductLeaseFields(considerEndingProduct),
                then: Yup.object()
                    .required()
                    .test(
                        'atLeastOneSelected',
                        t('considerEndingProductOptions.validation.atLeastOneSelected'),
                        (value) => {
                            const considerEndingProductLease = value as ConsiderEndingProductLeaseFormValues;
                            return considerEndingProductLease.leaseSettlement;
                        },
                    ),
            }),
    });
};
