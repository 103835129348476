import { combineReducers, createAction, AnyAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import landingPageHeroTeaserReducer from 'components/landing-page-hero-teaser/LandingPageHeroTeaserSlice';
import landingPageMarketingCardsReducer from 'components/landing-page-marketing-cards/LandingPageMarketingCardsSlice';
import useOurMatchmakerPageHeroTeaserReducer from 'components/simple-page-hero-teaser/UseOurMatchmakerPageHeroTeaserSlice';
import legalFooterReducer from 'components/legal-footer/LegalFooterSlice';
import iconFooterReducer from 'components/icon-footer/IconFooterSlice';
import frequentlyAskedQuestionsPageHeroTeaserReducer from 'components/simple-page-hero-teaser/FrequentlyAskedQuestionsPageHeroTeaserSlice';
import legalNoticePageHeroTeaserReducer from 'components/simple-page-hero-teaser/LegalNoticePageHeroTeaserSlice';
import privacyPolicyPageHeroTeaserReducer from 'components/simple-page-hero-teaser/PrivacyPolicyPageHeroTeaserSlice';
import cookiePolicyPageHeroTeaserReducer from 'components/simple-page-hero-teaser/CookiePolicyPageHeroTeaserSlice';
import modernSlaveryStatementPageHeroTeaserReducer from 'components/simple-page-hero-teaser/ModernSlaveryStatementPageHeroTeaserSlice';
import thirdPartyLicencesPageHeroTeaserReducer from 'components/simple-page-hero-teaser/ThirdPartyLicencesPageHeroTeaserSlice';
import registerPageHeroTeaserReducer from 'components/simple-page-hero-teaser/RegisterPageHeroTeaserSlice';
import frequentlyAskedQuestionsReducer from 'components/frequently-asked-questions/FrequentlyAskedQuestionsSlice';
import privacyPolicyReducer from 'components/simple-page-content/PrivacyPolicySlice';
import legalNoticeReducer from 'components/simple-page-content/LegalNoticeSlice';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import modernSlaveryStatementReducer from 'components/simple-page-content/ModernSlaveryStatementSlice';
import thirdPartyLicencesReducer from 'components/third-party-licences/ThirdPartyLicencesSlice';
import dashboardMarketingCardReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import otherFinanceBrandsReducer from 'components/other-finance-brands-card/OtherFinanceBrandsCardSlice';
import contractsOverviewReducer from 'components/contracts-overview/ContractsOverviewSlice';
import contractDetailsReducer from 'components/contract-details/ContractDetailsSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import requestContactReducer from 'components/request-contact/RequestContactSlice';
import startMatchmakerReducer from 'components/start-matchmaker/StartMatchmakerSlice';
import outstandingPaymentsReducer from 'components/outstanding-payments/OutstandingPaymentsSlice';
import requestAdditionalHelpReducer from 'components/request-additional-help/RequestAdditionalHelpSlice';
import earlySettlementReducer from 'components/early-settlement/EarlySettlementSlice';
import earlyTerminationReducer from 'components/early-termination/EarlyTerminationSlice';
import endOfContractReducer from 'components/end-of-contract/EndOfContractSlice';
import documentCentreReducer from 'components/document-centre/DocumentCentreSlice';
import documentListReducer from 'components/document-list/DocumentListSlice';
import financialSupportAndWellbeingReducer from 'components/financial-support-and-wellbeing/FinancialSupportAndWellbeingSlice';
import insuranceReducer from '../components/insurance/InsuranceSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    greeting: greetingReducer,
    otherFinanceBrands: otherFinanceBrandsReducer,
    contractsOverview: contractsOverviewReducer,
    contractDetails: contractDetailsReducer,
    myProfile: myProfileReducer,
    requestContact: requestContactReducer,
    startMatchmaker: startMatchmakerReducer,
    insurance: insuranceReducer,
    outstandingPayments: outstandingPaymentsReducer,
    requestAdditionalHelp: requestAdditionalHelpReducer,
    earlySettlement: earlySettlementReducer,
    earlyTermination: earlyTerminationReducer,
    endOfContract: endOfContractReducer,
    documentCentre: documentCentreReducer,
    documentList: documentListReducer,
    financialSupportAndWellbeing: financialSupportAndWellbeingReducer,
    content: combineReducers({
        landingPageHeroTeaser: landingPageHeroTeaserReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        useOurMatchmakerPageHeroTeaser: useOurMatchmakerPageHeroTeaserReducer,
        legalFooter: legalFooterReducer,
        iconFooter: iconFooterReducer,
        frequentlyAskedQuestionsPageHeroTeaser: frequentlyAskedQuestionsPageHeroTeaserReducer,
        legalNoticePageHeroTeaser: legalNoticePageHeroTeaserReducer,
        privacyPolicyPageHeroTeaser: privacyPolicyPageHeroTeaserReducer,
        cookiePolicyPageHeroTeaser: cookiePolicyPageHeroTeaserReducer,
        modernSlaveryStatementPageHeroTeaser: modernSlaveryStatementPageHeroTeaserReducer,
        thirdPartyLicencesPageHeroTeaser: thirdPartyLicencesPageHeroTeaserReducer,
        registerPageHeroTeaser: registerPageHeroTeaserReducer,
        frequentlyAskedQuestions: frequentlyAskedQuestionsReducer,
        privacyPolicy: privacyPolicyReducer,
        legalNotice: legalNoticeReducer,
        cookiePolicy: cookiePolicyReducer,
        modernSlaveryStatement: modernSlaveryStatementReducer,
        thirdPartyLicences: thirdPartyLicencesReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
