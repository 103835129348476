import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, FinanceBrandsEOC, ProductTypesEOC } from '@cp-uk/common';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath } from 'components/navigation/paths';
import {
    TileSelectProps,
    ValidatedTileSelectGroup,
} from 'components/validated-tile-select-group/ValidatedTileSelectGroup';
import {
    buildLinkToPhoneNumber,
    contractCategory,
    textAsHtml,
    textWithComponents,
    useFinanceCompanyDetails
} from 'utils';
import { Views } from '../types';
import { carOrVehicleText, retailerDescriptionText } from '../../helpers';
import { validationSchema } from './SolutionsStartValidation';
import { SolutionsStartOptions, SolutionsStartViewFormValues } from './types';

export type SolutionsStartViewProps = {
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    initialOption: SolutionsStartOptions;
    showSellOption: boolean;
    onContinue: (
        nextStep: Extract<
            Views,
            Views.solutionsExchange | Views.solutionsKeep | Views.solutionsHandBack | Views.solutionsSell
        >,
        option: SolutionsStartOptions,
    ) => void;
};

export const SolutionsStartView: React.FC<SolutionsStartViewProps> = ({
    contractDescription,
    financeBrand,
    vehicleBrand,
    initialOption,
    showSellOption,
    onContinue,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const { shortCompanyName, phoneNumber, openingHours } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation(['end-of-contract-solutions-start-view', 'end-of-contract']);
    const history = useHistory();

    useAnalyticsPageViewTracker('endOfContractPcpStart');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const initialValues: SolutionsStartViewFormValues = {
        option: initialOption,
    };

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);
    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);

    const optionTileSelects: TileSelectProps[] = [
        {
            icon: 'semantic-safety-check',
            title: t('options.exchange.title', { vehicleBrand }),
            text: t('options.exchange.text', { carOrVehicle, shortCompanyName }),
            value: 'exchange',
        },
        {
            icon: 'semantic-car',
            title: t('options.keep.title', { vehicleBrand }),
            text: t('options.keep.text', { vehicleBrand }),
            value: 'keep',
        },
        {
            icon: 'semantic-car-handover',
            title: t('options.handBack.title', { vehicleBrand }),
            text: t('options.handBack.text', { vehicleBrand }),
            value: 'handBack',
        },
    ];
    if (showSellOption) {
        optionTileSelects.push({
            icon: 'semantic-car-value',
            title: t('options.sell.title', { vehicleBrand }),
            text: t('options.sell.text', { vehicleBrand }),
            value: 'sell',
        });
    }

    const numberOfTiles: number = optionTileSelects.length;

    return (
        <View testId={'solutionsStartView'}>
            <Heading level={2} testId={'viewHeader'}>
                {contractCategory(productType)}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Notification
                className={'u-mb'}
                status={NotificationStatus.warning}
                testId={'semiConductorDelaysNotification'}
            >
                {textAsHtml(
                    t('notifications.semiConductorDelays.text', { shortCompanyName, retailerDescription, contactUrl }),
                )}
            </Notification>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                headline={t('notifications.bePrepared.headline')}
                testId={'bePreparedNotification'}
            >
                {textWithComponents(
                    t,
                    'notifications.bePrepared.text',
                    { linkToPhoneNumber },
                    { phoneNumber, openingHours, carOrVehicle },
                )}
            </Notification>
            <Paragraph testId={'introductionParagraph'}>
                {textAsHtml(t('paragraphs.introduction', { vehicleBrand }))}
            </Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, showSellOption)}
                onSubmit={({ option }): void => {
                    if (option === 'exchange') {
                        onContinue(Views.solutionsExchange, option);
                    } else if (option === 'keep') {
                        onContinue(Views.solutionsKeep, option);
                    } else if (option === 'handBack') {
                        onContinue(Views.solutionsHandBack, option);
                    } else if (option === 'sell') {
                        onContinue(Views.solutionsSell, option);
                    } else {
                        // NOTE: Leave this in as testing is done for each of the three other "action" views:
                        // eslint-disable-next-line no-alert
                        alert('Unexpected state for onSubmit() handler!');
                    }
                }}
            >
                {(formik: FormikProps<SolutionsStartViewFormValues>): React.ReactNode => (
                    <Form className={'uk-end-of-contract-solutions-start-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedTileSelectGroup
                                    layoutItemClassName={`u-1/${numberOfTiles} u-1/1@m`}
                                    inputType={'radio'}
                                    tileSelects={optionTileSelects}
                                    name={'option'}
                                    testId={'optionTileSelects'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'returnButton'}
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
