import React from 'react';
import { FinancialSummarySectionBO } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';

export const SummarySection: React.FC<{ summarySection: FinancialSummarySectionBO | undefined }> = ({
    summarySection,
}) => {
    const { t, f } = useTranslationWithFormatting('financial-summary-section');

    if (summarySection === undefined) {
        return null;
    }

    const { contractStartDate, contractEndDate, term } = summarySection;

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (contractStartDate !== undefined) {
        descriptionListItems.push({
            label: t('contractStartDate.label'),
            value: f(contractStartDate, TranslationFormat.DATE),
            testId: 'contractStartDate',
        });
    }
    if (contractEndDate !== undefined) {
        descriptionListItems.push({
            label: t('contractEndDate.label'),
            value: f(contractEndDate, TranslationFormat.DATE),
            testId: 'contractEndDate',
        });
    }
    if (term !== undefined) {
        descriptionListItems.push({
            label: t('term.label'),
            value: f(term, TranslationFormat.MONTHS),
            testId: 'term',
        });
    }

    if (descriptionListItems.length === 0) {
        return null;
    }

    return (
        <DataOverview noBorder testId={'summarySection'}>
            <DescriptionListHorizontal items={descriptionListItems} />
        </DataOverview>
    );
};
