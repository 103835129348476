import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { MyProfileBO, getMyProfileEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<MyProfileBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myProfile',
    fetchCallback() {
        return CpDataApi.get<MyProfileBO>(getMyProfileEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchMyProfile = fetchData;
export const updateMyProfile = updateData;
