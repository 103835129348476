import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph, Layout } from '@vwfs-bronson/bronson-react';
import { featureToggles } from 'config';
import { appendBuildTimestamp, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { NavigationTile } from './NavigationTile';
import { getTrackingId } from './helpers';

export type FinancialSupportAndWellbeingTile = {
    key: string;
    imageUrl: string;
    title: string;
    text: string;
    buttonText: string;
    buttonAriaLabel: string;
    buttonUrl: string;
};

export const FinancialSupportAndWellbeing: React.FC = () => {
    const { t } = useTranslation('financial-support-and-wellbeing');

    const trackingId = getTrackingId('financialSupportAndWellbeing');
    useAnalyticsPageViewTracker(trackingId);
    const { onAction: onForwardAction } = useAnalyticsActionTracker(`${trackingId}Forward`);

    const tiles = t<FinancialSupportAndWellbeingTile[]>('tiles', { returnObjects: true }).filter(
        ({ key }) => (featureToggles.financialSupportAndWellbeing as Record<string, boolean>)[key],
    );

    return (
        <section>
            <Paragraph testId={'introductionParagraph'}>{t('paragraphs.introduction')}</Paragraph>
            <Layout equalHeight>
                {tiles.map(({ key, imageUrl, title, text, buttonText, buttonAriaLabel, buttonUrl }) => (
                    <Layout.Item key={key} default={'1/3'} m={'1/2'} s={'1/1'}>
                        <NavigationTile
                            imageUrl={appendBuildTimestamp(imageUrl)}
                            title={title}
                            text={text}
                            buttonText={buttonText}
                            buttonAriaLabel={buttonAriaLabel}
                            buttonUrl={buttonUrl}
                            testId={`${key}Topic`}
                            onAction={onForwardAction}
                        />
                    </Layout.Item>
                ))}
            </Layout>
        </section>
    );
};
