import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ChangeMarketingPreferencesRequest, MarketingPreferencesSectionBO } from '@cp-uk/common';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ChangeMarketingPreferences } from './change-marketing-preferences/ChangeMarketingPreferences';
import { MyProfileContext } from '../MyProfileContext';

const CheckmarkIcon: React.FC<{ ariaLabel: string }> = ({ ariaLabel }) => {
    return <i className={`c-icon c-icon--[semantic-checkmark] c-icon--small u-text-bold`} aria-label={ariaLabel} />;
};

export const MarketingPreferencesSection: React.FC<{ marketingPreferencesSection: MarketingPreferencesSectionBO }> = ({
    marketingPreferencesSection: propsMarketingPreferencesSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const { newMarketingPreferencesSection, setNewMarketingPreferencesSection } = useContext(MyProfileContext);
    const { t } = useTranslation('marketing-preferences-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('onEditProfileConsentSettings');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker('onEditProfileConsentSettingsSubmitFailed');
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('onEditProfileConsentSettingsSuccess');

    const marketingPreferencesSection = newMarketingPreferencesSection ?? propsMarketingPreferencesSection;

    const { changeState, email, telephone, simpleMessageService, post } = marketingPreferencesSection;

    const buildIcon = (value: boolean): React.ReactNode | null => {
        if (!value) {
            return null;
        }

        return <CheckmarkIcon ariaLabel={t(`ariaLabels.preferenceSelected`)} />;
    };

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        onChangeStartAction();
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction('Consent settings');
    };
    const onChangeFailure = (): void => {
        // NOTE: Failure notification is displayed within the change control and should NOT close here
        onChangeFailureAction();
    };
    const onChangeSuccess = (changeMarketingPreferencesRequest: ChangeMarketingPreferencesRequest): void => {
        const newMarketingPreferencesSection: MarketingPreferencesSectionBO = {
            changeState: 'inProgress',
            email: changeMarketingPreferencesRequest.email,
            telephone: changeMarketingPreferencesRequest.telephone,
            simpleMessageService: changeMarketingPreferencesRequest.simpleMessageService,
            post: changeMarketingPreferencesRequest.post,
            changeMarketingPreferences: undefined,
        };
        setNewMarketingPreferencesSection(newMarketingPreferencesSection);
        setIsChanging(false);
        onChangeSuccessAction();
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('email.label'),
            value: buildIcon(email),
            testId: 'email',
        },
        {
            label: t('telephone.label'),
            value: buildIcon(telephone),
            testId: 'telephone',
        },
        {
            label: t('simpleMessageService.label'),
            value: buildIcon(simpleMessageService),
            testId: 'simpleMessageService',
        },
        {
            label: t('post.label'),
            value: buildIcon(post),
            testId: 'post',
        },
    ];

    return (
        <Fragment>
            {isChanging ? (
                <ChangeMarketingPreferences
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changeMarketingPreferences={marketingPreferencesSection.changeMarketingPreferences}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={t('title')}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${changeState}`)}
                    buttonDisabled={changeState !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'marketingPreferencesSection'}
                >
                    {changeState === 'inProgress' && (
                        <Notification
                            className={'u-mb'}
                            status={NotificationStatus.warning}
                            headline={t('inProgressNotification.headline')}
                            text={t('inProgressNotification.text')}
                            testId={'inProgressNotification'}
                        />
                    )}
                    <Paragraph testId={'informationParagraph'}>{t('informationParagraph.content')}</Paragraph>
                    <DescriptionListHorizontal items={descriptionListItems} />
                </DataOverview>
            )}
        </Fragment>
    );
};
