import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from '../../utils';
import { UseOurMatchmaker } from '../../components/use-our-matchmaker/UseOurMatchmaker';
import { landingPagePath } from '../../components/navigation/paths';
import { Redirect } from 'react-router-dom';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import { currentBrand } from 'config';
import { FinanceBrandsMM, isFinanceBrand } from '@cp-uk/common';

export const UseOurMatchmakerPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.UseOurMatchmaker);

    const isSupportedMatchmakerBrand = isFinanceBrand(currentBrand, ['audi', 'seat', 'skoda', 'vw']);
    if (!isSupportedMatchmakerBrand) {
        return <Redirect to={landingPagePath()} />;
    }

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.UseOurMatchmaker} />
            <UseOurMatchmaker financeBrand={currentBrand as FinanceBrandsMM} />
        </Fragment>
    );
};
