import { TFunction } from 'i18next';
import { ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';

const buildCommonAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
    mode: 'earliest' | 'latest',
): ValidatedSelectItem[] => {
    const args: number[] = mode === 'earliest' ? [0, -2] : [2];
    return entries.slice(...args).map(([, value]) => {
        return {
            label: t(`availableTimes.${value}`),
            value,
        };
    });
};

export const buildEarliestAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
): ValidatedSelectItem[] => {
    return buildCommonAvailableTimeSelectItems(t, entries, 'earliest');
};

export const buildLatestAvailableTimeSelectItems = (
    t: TFunction,
    entries: [string, string][],
): ValidatedSelectItem[] => {
    return buildCommonAvailableTimeSelectItems(t, entries, 'latest');
};

export const formatTimeForEmail = (time: string): string => {
    return time.replace(':00', '');
};

export const buildCallbackAvailabilityString = (
    t: TFunction,
    earliestTime: string,
    latestTime: string,
    availableDayEntries: [string, boolean][],
): string => {
    const availableDaysAsString =
        availableDayEntries
            .filter(([, value]) => !!value)
            .map(([key]) => t(`availableDays.${key}.label`))
            .join(', ') || t('availableDays.anyDay');

    const earliestPreference = earliestTime !== 'noPreferredEarliest';
    const latestPreference = latestTime !== 'noPreferredLatest';

    const displayEarliestTime = earliestPreference ? formatTimeForEmail(t(`availableTimes.${earliestTime}`)) : '';
    const displayLatestTime = latestPreference ? formatTimeForEmail(t(`availableTimes.${latestTime}`)) : '';

    if (earliestPreference && latestPreference) {
        const between = t('availableTimes.between');
        const and = t('availableTimes.and');
        return `${availableDaysAsString}, ${between} ${displayEarliestTime} ${and} ${displayLatestTime}`;
    } else if (earliestPreference) {
        const after = t('availableTimes.after');
        return `${availableDaysAsString}, ${after} ${displayEarliestTime}`;
    } else if (latestPreference) {
        const before = t('availableTimes.before');
        return `${availableDaysAsString}, ${before} ${displayLatestTime}`;
    } else {
        return availableDaysAsString;
    }
};
