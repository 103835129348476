import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import {
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, ProductCategories } from '@cp-uk/common';
import { textAsHtml, textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath } from 'components/navigation/paths';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';

export type RequestAdditionalHelpEndAgreementProps = {
    contractDescription: ContractDescriptionBO;
    productCategory: ProductCategories;
    totalArrears: number;
    onBack: () => void;
};

export const EndAgreementView: React.FC<RequestAdditionalHelpEndAgreementProps> = ({
    contractDescription,
    productCategory,
    totalArrears,
    onBack,
}) => {
    const { t } = useTranslation('request-additional-help-end-agreement-view');
    const history = useHistory();
    const { onAction: onRequestAdditionalHelpBackAction } = useAnalyticsActionTracker('onRequestAdditionalHelpBack');
    useAnalyticsPageViewTracker('requestAdditionalHelpEndAgreement');

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    const paragraphInformationContent = (): string => {
        let key: string;
        if (productCategory === 'purchase') {
            key = 'paragraphs.information.purchase';
        } else if (productCategory === 'hire') {
            key = 'paragraphs.information.hire';
        } else if (productCategory === 'lease') {
            key = 'paragraphs.information.lease';
        } else {
            key = 'unsupported.productCategory';
        }
        return t(key, { productCategory });
    };

    const notificationActionText = (): string => {
        let key: string;
        if (productCategory === 'purchase') {
            key = 'notifications.action.plural';
        } else if (productCategory === 'hire' || productCategory === 'lease') {
            key = 'notifications.action.singular';
        } else {
            key = 'unsupported.productCategory';
        }
        return t(key, { productCategory });
    };

    const onBackClick = (): void => {
        onRequestAdditionalHelpBackAction();
        onBack();
    };

    return (
        <View testId={'endAgreementView'}>
            <SectionHeading level={2} testId={'viewHeader'}>{t('subHeading')}</SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph>{textAsHtml(paragraphInformationContent())}</Paragraph>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
            >
                {textWithComponents(undefined, notificationActionText(), { linkToCollectionsPhoneNumber })}
            </Notification>
            <ButtonContainer nav>
                <Button type={'button'} testId={'buttonBack'} secondary onClick={onBackClick}>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} className={'buttonDashboard'} onClick={() => history.push(dashboardPagePath())}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
