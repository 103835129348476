import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { DocumentCentreBO, getDocumentCentreEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<DocumentCentreBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'documentCentre',
    fetchCallback() {
        return CpDataApi.get<DocumentCentreBO>(getDocumentCentreEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchDocumentCentre = fetchData;
