import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { AvailableDays, AvailableTimes, ContactDetailsFormValues } from './types';

const earliestAvailableTimesValidValues: AvailableTimes[] = [
    AvailableTimes.noPreferredEarliest,
    AvailableTimes.nineAm,
    AvailableTimes.tenAm,
    AvailableTimes.elevenAm,
    AvailableTimes.twelvePm,
    AvailableTimes.onePm,
    AvailableTimes.twoPm,
    AvailableTimes.threePm,
    AvailableTimes.fourPm,
    AvailableTimes.fivePm,
    AvailableTimes.sixPm,
];

const latestAvailableTimesValidValues: AvailableTimes[] = [
    AvailableTimes.tenAm,
    AvailableTimes.elevenAm,
    AvailableTimes.twelvePm,
    AvailableTimes.onePm,
    AvailableTimes.twoPm,
    AvailableTimes.threePm,
    AvailableTimes.fourPm,
    AvailableTimes.fivePm,
    AvailableTimes.sixPm,
    AvailableTimes.sevenPm,
    AvailableTimes.noPreferredLatest,
];

export const availableTimesToNumber = (availableTime: AvailableTimes): number => {
    return Number(availableTime?.replace('H', ''));
};

export const validationSchema = (
    t: TFunction,
    saturdayClosingTime: AvailableTimes,
): Yup.ObjectSchema<ContactDetailsFormValues> => {
    return Yup.object()
        .shape<ContactDetailsFormValues>({
            phoneNumber: Yup.string().required(t('phoneNumber.validation.required')),
            earliestAvailableTime: Yup.string()
                .required(t('earliestAvailableTime.validation.required'))
                .oneOf(earliestAvailableTimesValidValues, t('earliestAvailableTime.validation.required')),
            latestAvailableTime: Yup.string()
                .required(t('latestAvailableTime.validation.required'))
                .oneOf(latestAvailableTimesValidValues, t('latestAvailableTime.validation.required'))
                .when('earliestAvailableTime', (earliestAvailableTime: string, schema: Yup.StringSchema<string>) => {
                    if (!earliestAvailableTime || earliestAvailableTime === AvailableTimes.noPreferredEarliest) {
                        return schema;
                    }
                    return schema
                        .test('matchesEarliest', t('latestAvailableTime.validation.matchesEarliest'), (value) => {
                            return (
                                !value ||
                                value === 'noPreferredLatest' ||
                                availableTimesToNumber(value as AvailableTimes) !==
                                    availableTimesToNumber(earliestAvailableTime as AvailableTimes)
                            );
                        })
                        .test('beforeEarliest', t('latestAvailableTime.validation.beforeEarliest'), (value) => {
                            return (
                                !value ||
                                value === 'noPreferredLatest' ||
                                availableTimesToNumber(value as AvailableTimes) >
                                    availableTimesToNumber(earliestAvailableTime as AvailableTimes)
                            );
                        });
                }),
            availableDays: Yup.object()
                .shape<AvailableDays>({
                    monday: Yup.boolean().required(),
                    tuesday: Yup.boolean().required(),
                    wednesday: Yup.boolean().required(),
                    thursday: Yup.boolean().required(),
                    friday: Yup.boolean().required(),
                    saturday: Yup.boolean().required(),
                })
                .required()
                .when('earliestAvailableTime', (earliestAvailableTime: string, schema: Yup.StringSchema<string>) => {
                    if (earliestAvailableTime === AvailableTimes.noPreferredEarliest) {
                        return schema;
                    }
                    return schema.test(
                        'outsideSaturdayOpening',
                        t('availableDays.validation.outsideSaturdayOpening'),
                        (value) => {
                            const availableDays = (value as unknown) as AvailableDays;
                            const onlySaturday =
                                availableDays.saturday &&
                                !(
                                    availableDays.monday ||
                                    availableDays.tuesday ||
                                    availableDays.wednesday ||
                                    availableDays.thursday ||
                                    availableDays.friday
                                );
                            const earliestAvailableTimeAfterSaturdayClosingTime =
                                availableTimesToNumber(earliestAvailableTime as AvailableTimes) >=
                                availableTimesToNumber(saturdayClosingTime);
                            return !(onlySaturday && earliestAvailableTimeAfterSaturdayClosingTime);
                        },
                    );
                }),
        })
        .required();
};
