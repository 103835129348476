import React from 'react';
import { TFunction } from 'i18next';
import { SimpleLink } from 'components/simple-link/SimpleLink';

export const getMailtoHref = (emailAddress: string): string => {
    return `mailto:${emailAddress}`;
};

export const buildLinkToEmailAddress = (tOrEmailAddress: TFunction | string, key: string): JSX.Element => {
    if (typeof tOrEmailAddress === 'function') {
        const t = tOrEmailAddress as TFunction;
        const emailAddress = t(`translation:emailAddresses.${key}`);
        return buildLinkToEmailAddress(emailAddress, key);
    }

    const emailAddress = tOrEmailAddress as string;
    return <SimpleLink href={getMailtoHref(emailAddress)} testId={`${key}EmailAddressLink`} />;
};
