import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textAsHtml } from '../../../utils';

export type DocumentSafetyNotificationProps = {
    className?: string;
    testId?: string;
};

export const DocumentSafetyNotification: React.FC<DocumentSafetyNotificationProps> = ({ className, testId }) => {
    const { t } = useTranslation('document-safety-notification');

    return (
        <Notification
            className={className}
            status={NotificationStatus.info}
            testId={testId}
        >
            {textAsHtml(t('notifications.document-safety.text'))}
        </Notification>
    );
};
