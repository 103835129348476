const maskAtPositions = (value: string, positions: number[]): string => {
    const chars = value.split('');
    for (const position of positions) {
        chars[position] = '*';
    }
    return chars.join('');
};

export const maskSortCode = (value: string): string => {
    return maskAtPositions(value, [0, 3, 6]);
};

export const maskAccountNumber = (value: string): string => {
    return maskAtPositions(value, [0, 1, 2, 3, 4]);
};
