import React, { Fragment, useContext, useState } from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ChangeMileageRequest, MileageSectionBO } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ChangeMileage } from './change-mileage/ChangeMileage';
import { ContractDetailsContext } from '../../ContractDetailsContext';

export const MileageSection: React.FC<{ mileageSection: MileageSectionBO | undefined }> = ({
    mileageSection: propsMileageSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [showFailureNotification, setShowFailureNotification] = useState<boolean>(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState<boolean>(false);
    const { newMileageSection, setNewMileageSection } = useContext(ContractDetailsContext);
    const { t, f } = useTranslationWithFormatting('mileage-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('financialDetailsEditMileageSection');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('financialDetailsEditMileageSectionCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker(
        'financialDetailsEditMileageSectionSubmitFailed',
    );
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('financialDetailsEditMileageSectionSuccess');

    if (propsMileageSection === undefined) {
        return null;
    }

    const title = t('title');

    const mileageSection = newMileageSection ?? propsMileageSection;

    const {
        mileageChangeable,
        startingMileage,
        latestRecordedMileage,
        expectedMileageToDate,
        contractedMileage,
        changeMileage,
    } = mileageSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        setShowFailureNotification(false);
        setShowSuccessNotification(false);
        onChangeStartAction(title);
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction(title);
    };
    const onChangeFailure = (): void => {
        setShowFailureNotification(true);
        setIsChanging(false);
        onChangeFailureAction(title);
    };
    const onChangeSuccess = (changeMileageRequest: ChangeMileageRequest): void => {
        if (changeMileage) {
            const newMileageSection: MileageSectionBO = {
                mileageChangeable: 'ok',
                startingMileage,
                latestRecordedMileage: changeMileageRequest.currentMileage,
                expectedMileageToDate,
                contractedMileage,
                changeMileage: {
                    ...changeMileage,
                    latestRecordedMileage: changeMileageRequest.currentMileage,
                },
            };
            setNewMileageSection(newMileageSection);
        }
        setShowSuccessNotification(true);
        setIsChanging(false);
        onChangeSuccessAction(title);
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (startingMileage !== undefined) {
        descriptionListItems.push({
            label: t('startingMileage.label'),
            tooltip: t('startingMileage.tooltip'),
            value: f(startingMileage, TranslationFormat.NUMBER),
            testId: 'startingMileage',
        });
    }
    if (latestRecordedMileage !== undefined) {
        descriptionListItems.push({
            label: t('latestRecordedMileage.label'),
            tooltip: t('latestRecordedMileage.tooltip'),
            value: f(latestRecordedMileage, TranslationFormat.NUMBER),
            testId: 'latestRecordedMileage',
        });
    }
    if (expectedMileageToDate !== undefined) {
        descriptionListItems.push({
            label: t('expectedMileageToDate.label'),
            tooltip: t('expectedMileageToDate.tooltip'),
            value: f(expectedMileageToDate, TranslationFormat.NUMBER),
            testId: 'expectedMileageToDate',
        });
    }
    if (contractedMileage !== undefined) {
        descriptionListItems.push({
            label: t('contractedMileage.label'),
            tooltip: t('contractedMileage.tooltip'),
            value: f(contractedMileage, TranslationFormat.NUMBER),
            testId: 'contractedMileage',
        });
    }

    return (
        <Fragment>
            <Hr className={'u-mb-none'} />
            {isChanging ? (
                <ChangeMileage
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changeMileage={changeMileage}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={title}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${mileageChangeable}`)}
                    buttonDisabled={mileageChangeable !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'mileageSection'}
                >
                    <DescriptionListHorizontal items={descriptionListItems} />
                    {showFailureNotification && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.error}
                            text={t('notifications.failure.text')}
                            testId={'failureNotification'}
                        />
                    )}
                    {showSuccessNotification && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.success}
                            text={t('notifications.success.text')}
                            testId={'successNotification'}
                        />
                    )}
                </DataOverview>
            )}
        </Fragment>
    );
};
