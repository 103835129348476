import React from 'react';
import classNames from 'classnames';

export type WellProps = {
    className?: string;
    testId?: string;
};

export const Well: React.FC<WellProps> = ({ className, testId, children }) => {
    const classNameList = classNames('c-well', 'u-p', className).trim();

    return (
        <article data-testid={testId} className={classNameList} data-theme={'alternative'}>
            {children}
        </article>
    );
};
