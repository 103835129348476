import React, { Fragment, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import {
    InfoIcon,
    Notification,
    NotificationStatus,
    TrackingId,
    useAnalyticsPageViewTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import {
    SectionHeading,
    Paragraph,
    ButtonContainer,
    Button,
    RadioButton,
    FormField,
    Card,
    Layout,
    DescriptionList,
} from '@vwfs-bronson/bronson-react';
import {
    CompleteViewSettlementBO,
    ContractDescriptionBO,
    formatAsDate,
    formatAsCurrency,
    getProposeEarlySettlementEndpoint,
    ProposeEarlySettlementResponse,
    ProposeEarlySettlementError,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { dashboardPagePath } from 'components/navigation/paths';
import { View } from 'components/view/View';
import { parseErrorResponse, textWithComponents, useBankDetails, useRealex } from 'utils';
import { analyticsDiscriminator } from '../constants';
import {
    BrowserAwarenessModal,
    PaymentStaleModal,
    PaymentFailedModal,
    PaymentGatewayErrorModal,
    PaymentSuccessfulModal,
    RealexFailedModalStatus,
} from 'components/realex-payments';

enum PaymentMethods {
    card = 'card',
    bankTransfer = 'bankTransfer',
}

export const CompleteView: React.FC<{
    contractDescription: ContractDescriptionBO;
    completeView: CompleteViewSettlementBO | undefined;
    onReturn: () => void;
}> = ({ contractDescription, completeView, onReturn }) => {
    const [trackingId, setTrackingId] = useState<TrackingId>('earlySettlementComplete');
    const [showPaymentStaleModalModal, setShowPaymentStaleModal] = useState<boolean>(false);
    const [showBrowserAwarenessModal, setShowBrowserAwarenessModal] = useState<boolean>(false);
    const [showPaymentGatewayErrorModal, setShowPaymentGatewayErrorModal] = useState<boolean>(false);
    const [showPaymentSuccessfulModal, setShowPaymentSuccessfulModal] = useState<boolean>(false);
    const [showPaymentFailedModal, setShowPaymentFailedModal] = useState<boolean>(false);
    const [paymentFailedModalStatus, setPaymentFailedModalStatus] = useState<RealexFailedModalStatus>('warning');
    const [paymentFailedModalKey, setPaymentFailedModalKey] = useState<string>('defaultError');
    const [isProposeSubmitting, setIsProposeSubmitting] = useState<boolean>(false);
    const [isProcessSubmitting, setIsProcessSubmitting] = useState<boolean>(false);
    const [isCancelSubmitting, setIsCancelSubmitting] = useState<boolean>(false);
    const [authCode, setAuthCode] = useState<string | undefined>(undefined);
    const paymentIdentifierRef = useRef<string | undefined>(undefined);
    const { t } = useTranslation('early-settlement-complete-view');
    const history = useHistory();
    const { bank, accountName, accountNumber, sortCode } = useBankDetails();
    const { setupRealex, cancelPayment } = useRealex(
        'early-settlement',
        'earlySettlementPaymentSuccess',
        'earlySettlementPaymentError',
        setTrackingId,
        setShowPaymentSuccessfulModal,
        setShowPaymentFailedModal,
        setPaymentFailedModalStatus,
        setPaymentFailedModalKey,
        setIsProcessSubmitting,
        setIsCancelSubmitting,
        setAuthCode,
    );

    const titleWithTooltip = (title: string, tooltip: string): React.ReactNode => {
        return (
            <Fragment>
                {title}
                <InfoIcon text={tooltip} />
            </Fragment>
        );
    };

    const isPayByCardAvailableOk = completeView?.payByCardAvailable === 'ok';
    const initialPaymentMethod = isPayByCardAvailableOk ? PaymentMethods.card : PaymentMethods.bankTransfer;
    const [paymentMethod, setPaymentMethod] = useState<string>(initialPaymentMethod);

    const { onAction: onProceedToPayByCardAction } = useAnalyticsActionTracker(
        'onEarlySettlementPaymentProceedToPayByCard',
    );
    const { onAction: onSelectBankTransferAction } = useAnalyticsActionTracker(
        'onEarlySettlementPaymentSelectBankTransfer',
    );
    const { onAction: onEarlySettlementCompletePrintAction } = useAnalyticsActionTracker(
        'onEarlySettlementCompletePrint',
    );
    useAnalyticsPageViewTracker(trackingId, !!completeView, analyticsDiscriminator, isPayByCardAvailableOk);

    if (completeView === undefined) {
        return null;
    }

    const { encryptedContractId, contractNumber, settlementAmount, quoteExpiryDate } = completeView;

    const isAnySubmitting = isProposeSubmitting || isProcessSubmitting || isCancelSubmitting;

    const proposePayment = (): Promise<void> => {
        if (isAnySubmitting || !!paymentIdentifierRef.current) {
            return Promise.resolve();
        }

        setIsProposeSubmitting(true);
        return CpDataApi.post<ProposeEarlySettlementResponse>(getProposeEarlySettlementEndpoint(encryptedContractId))
            .then(({ data }: AxiosResponse<ProposeEarlySettlementResponse>): void => {
                setIsProposeSubmitting(false);
                setupRealex(data);
            })
            .catch((error: AxiosError<ProposeEarlySettlementResponse>): void => {
                const supportedCodes: string[] = ['QUOTE_STALE'];
                const parsedError = parseErrorResponse<ProposeEarlySettlementError>(error);
                if (parsedError?.status === 502 && supportedCodes.includes(parsedError?.code)) {
                    setShowPaymentStaleModal(true);
                } else {
                    setShowPaymentGatewayErrorModal(true);
                }
                setIsProposeSubmitting(false);
            });
    };

    const onReturnClick = async (): Promise<void> => {
        if (isAnySubmitting || !!paymentIdentifierRef.current) {
            return;
        }

        if (isAnySubmitting && paymentMethod === PaymentMethods.card) {
            await cancelPayment();
        }

        onReturn();
        return Promise.resolve();
    };

    const onProceedToPaymentClick = (): void => {
        setShowBrowserAwarenessModal(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onIgnoreClose = (): void => {};

    const onBrowserAwarenessClose = (): void => {
        setShowBrowserAwarenessModal(false);
    };
    const onBrowserAwarenessConfirm = (): Promise<void> => {
        setShowBrowserAwarenessModal(false);
        onProceedToPayByCardAction(analyticsDiscriminator, isPayByCardAvailableOk);

        return proposePayment();
    };

    const onPaymentGatewayErrorModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onPaymentSuccessfulModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onPaymentItemsStaleConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onPaymentFailedModalConfirm = (): void => {
        setShowPaymentFailedModal(false);
        setTrackingId('earlySettlementComplete');
    };

    const onPrintClick: () => void = () => {
        onEarlySettlementCompletePrintAction(analyticsDiscriminator, isPayByCardAvailableOk);
        window.print();
    };

    const cardDetailsPanel = (): React.ReactNode => {
        return <span data-testid={'cardDetails'}>{t('cardDetails.contents')}</span>;
    };

    const bankDetailsPanel = (tooltipKey: string): React.ReactNode => {
        return (
            <DescriptionList testId={'bankDetails'} horizontal>
                <DescriptionTermAndDetail
                    testId={'bank'}
                    term={t('bankDetails.bank.title')}
                    detail={bank}
                />
                <DescriptionTermAndDetail
                    testId={'accountName'}
                    term={t('bankDetails.accountName.title')}
                    detail={accountName}
                />
                <DescriptionTermAndDetail
                    testId={'sortCode'}
                    term={t('bankDetails.sortCode.title')}
                    detail={sortCode}
                />
                <DescriptionTermAndDetail
                    testId={'accountNumber'}
                    term={t('bankDetails.accountNumber.title')}
                    detail={accountNumber}
                />
                <DescriptionTermAndDetail
                    testId={'referenceNumber'}
                    term={titleWithTooltip(
                        t('bankDetails.referenceNumber.title'),
                        t(`bankDetails.referenceNumber.tooltip.${tooltipKey}`),
                    )}
                    detail={contractNumber}
                />
            </DescriptionList>
        );
    };

    const settlementDetailsPanel = (tooltipKey: string): React.ReactNode => {
        return (
            <span>
                <DescriptionList className={'u-mb-none'} testId={'settlementDetails'} horizontal>
                    <DescriptionTermAndDetail
                        testId={'settlementAmount'}
                        term={t('settlementDetails.settlementAmount.title')}
                        detail={formatAsCurrency(settlementAmount)}
                    />
                    <DescriptionTermAndDetail
                        testId={'quoteExpiryDate'}
                        term={titleWithTooltip(
                            t('settlementDetails.quoteExpiryDate.title'),
                            t(`settlementDetails.quoteExpiryDate.tooltip.${tooltipKey}`),
                        )}
                        detail={formatAsDate(quoteExpiryDate)}
                    />
                </DescriptionList>
            </span>
        );
    };

    const notificationPanel = (): React.ReactNode => {
        return (
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                text={t('notifications.information.text')}
                testId={'informationNotification'}
            />
        );
    };

    const onPaymentMethodChanged = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        setPaymentMethod(value);
        if (value === PaymentMethods.bankTransfer) {
            onSelectBankTransferAction(analyticsDiscriminator, isPayByCardAvailableOk);
        }
    };

    return (
        <View testId={'completeView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            {completeView.payByCardAvailable === 'payByCardDisabled' ? (
                <Fragment>
                    <Paragraph className={'u-mb'} testId={'informationParagraph1'}>
                        {t('paragraphs.information1.old')}
                    </Paragraph>
                    <Card element="article" className={`u-mb u-text-left`} title={t('bankDetails.title')}>
                        <Layout equalHeight divider>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                {bankDetailsPanel('old')}
                            </Layout.Item>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                {settlementDetailsPanel('old')}
                            </Layout.Item>
                        </Layout>
                    </Card>
                    {notificationPanel()}
                </Fragment>
            ) : (
                <Fragment>
                    <Paragraph className={'u-mb'} testId={'informationParagraph1'}>
                        {t('paragraphs.information1.new')}
                    </Paragraph>
                    <FormField
                        type={'select'}
                        className={'u-mb'}
                        labelText={t('radioButtons.prompt')}
                        testId={'paymentMethod'}
                        onChange={onPaymentMethodChanged}
                        staticLabel
                    >
                        <span className={'u-mb-xsmall u-block'}>
                            <RadioButton
                                value={PaymentMethods.card}
                                testId={'payByCardRadioButton'}
                                disabled={!isPayByCardAvailableOk}
                                defaultChecked={initialPaymentMethod === PaymentMethods.card}
                            >
                                <span data-testid={'payByCardRadioButtonLabel'}>
                                    {t('radioButtons.cardPayment.text')}
                                </span>
                                <InfoIcon
                                    text={t(`radioButtons.cardPayment.tooltip.${completeView.payByCardAvailable}`)}
                                />
                            </RadioButton>
                        </span>
                        <span className={'u-block'}>
                            <RadioButton
                                value={PaymentMethods.bankTransfer}
                                testId={'payByBankTransferRadioButton'}
                                defaultChecked={initialPaymentMethod === PaymentMethods.bankTransfer}
                            >
                                <span data-testid={'payByBankTransferRadioButtonLabel'}>
                                    {t('radioButtons.bankTransfer.text')}
                                </span>
                                <InfoIcon text={t('radioButtons.bankTransfer.tooltip')} />
                            </RadioButton>
                        </span>
                    </FormField>
                    <Card
                        element="article"
                        className={'u-mb u-text-left'}
                        title={
                            (paymentMethod === PaymentMethods.card && t('cardDetails.title')) ||
                            (paymentMethod === PaymentMethods.bankTransfer && t('bankDetails.title'))
                        }
                    >
                        <Layout equalHeight divider>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                {paymentMethod === PaymentMethods.card && cardDetailsPanel()}
                                {paymentMethod === PaymentMethods.bankTransfer && bankDetailsPanel('new')}
                            </Layout.Item>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                {settlementDetailsPanel('new')}
                            </Layout.Item>
                        </Layout>
                    </Card>
                    {notificationPanel()}
                    <Paragraph className={'u-text-bold'} testId={'settlingYourAgreementParagraphTitle'}>
                        {t('paragraphs.settlingYourAgreement.title')}
                    </Paragraph>
                    {paymentMethod === PaymentMethods.bankTransfer && (
                        <Paragraph className={'u-mb'} testId={'settlingYourAgreementParagraphDetail1'}>
                            {t('paragraphs.settlingYourAgreement.detail1')}
                        </Paragraph>
                    )}
                    <Paragraph className={'u-mb'} testId={'settlingYourAgreementParagraphDetail2'}>
                        {t('paragraphs.settlingYourAgreement.detail2')}
                    </Paragraph>
                    {paymentMethod === PaymentMethods.card && (
                        <Fragment>
                            <Paragraph className={'u-text-bold'} testId={'termsAndConditionsOfPaymentParagraphTitle'}>
                                {t('paragraphs.termsAndConditionsOfPayment.title')}
                            </Paragraph>
                            <Paragraph className={'u-mb'} testId={'termsAndConditionsOfPaymentParagraphDetail'}>
                                {t('paragraphs.termsAndConditionsOfPayment.detail')}
                            </Paragraph>
                            <Paragraph className={'u-text-bold'} testId={'payingByCreditCardParagraphTitle'}>
                                {textWithComponents(t, 'paragraphs.payingByCreditCard.title')}
                            </Paragraph>
                            <Paragraph testId={'payingByCreditCardParagraphDetail1'}>
                                {t('paragraphs.payingByCreditCard.detail1')}
                            </Paragraph>
                            <Paragraph className={'u-mb'} testId={'payingByCreditCardParagraphDetail2'}>
                                {t('paragraphs.payingByCreditCard.detail2')}
                            </Paragraph>
                        </Fragment>
                    )}
                </Fragment>
            )}
            <ButtonContainer nav>
                <Button
                    type={'button'}
                    testId={'printButton'}
                    icon={'semantic-print'}
                    iconReversed
                    simple
                    small
                    link
                    onClick={onPrintClick}
                >
                    {t('translation:editableSectionNav.print')}
                </Button>
                <Button
                    type={'button'}
                    disabled={isAnySubmitting}
                    testId={'returnButton'}
                    onClick={onReturnClick}
                    secondary
                >
                    {t('translation:editableSectionNav.return')}
                </Button>
                {paymentMethod === PaymentMethods.card ? (
                    <Button
                        type="button"
                        disabled={isAnySubmitting}
                        testId="proceedToPaymentButton"
                        onClick={onProceedToPaymentClick}
                    >
                        {t('buttons.proceedToPayment.label')}
                    </Button>
                ) : (
                    <Button
                        type={'button'}
                        testId={'dashboardButton'}
                        onClick={(): void => history.push(dashboardPagePath())}
                    >
                        {t('translation:editableSectionNav.dashboard')}
                    </Button>
                )}
            </ButtonContainer>
            {paymentMethod === PaymentMethods.card && (
                <Fragment>
                    <PaymentStaleModal
                        shown={showPaymentStaleModalModal}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentItemsStaleConfirm}
                        context={'earlySettlement'}
                    />
                    <BrowserAwarenessModal
                        shown={showBrowserAwarenessModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onBrowserAwarenessClose}
                        onConfirm={onBrowserAwarenessConfirm}
                    />
                    <PaymentGatewayErrorModal
                        shown={showPaymentGatewayErrorModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentGatewayErrorModalConfirm}
                    />
                    <PaymentSuccessfulModal
                        shown={showPaymentSuccessfulModal}
                        confirmationDisabled={isAnySubmitting}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentSuccessfulModalConfirm}
                        context={'earlySettlement'}
                        authCode={authCode}
                    />
                    <PaymentFailedModal
                        shown={showPaymentFailedModal}
                        confirmationDisabled={isAnySubmitting}
                        errorKey={paymentFailedModalKey}
                        status={paymentFailedModalStatus}
                        onClose={onIgnoreClose}
                        onConfirm={onPaymentFailedModalConfirm}
                    />
                </Fragment>
            )}
        </View>
    );
};
