import { FinanceBrands } from '../apis';

export const isFinanceBrand = (
    financeBrand: FinanceBrands | string | undefined,
    requiredFinanceBrands: FinanceBrands | FinanceBrands[],
): boolean => {
    if (Array.isArray(requiredFinanceBrands)) {
        return requiredFinanceBrands.some((requiredFinanceBrand: FinanceBrands) => financeBrand === requiredFinanceBrand);
    }

    return financeBrand === requiredFinanceBrands;
};
