import { LandingPageHeroTeaserDto, getLandingPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LandingPageHeroTeaserDto>({
    contentName: 'landingPageHeroTeaser',
    contentEndpoint: getLandingPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchLandingPageHeroTeaser = fetchContent;
