import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ProductCategories } from '@cp-uk/common';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { View } from 'components/view/View';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { validationSchema } from './ConfirmationOrInformationValidation';
import { ConfirmationOrInformationFormValues } from './types';

export type ConfirmationOrInformationViewProps = {
    productCategory: ProductCategories;
    isRegisteredKeeperOfVehicle: boolean;
    onBack: () => void;
    onNext: () => void;
};

export const ConfirmationOrInformationView: React.FC<ConfirmationOrInformationViewProps> = ({
    productCategory,
    isRegisteredKeeperOfVehicle,
    onBack,
    onNext,
}) => {
    const { t } = useTranslation('change-registration-number-confirmation-or-information-view');

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    const initialValues: ConfirmationOrInformationFormValues = { confirmAlreadyUpdated: false };

    const onBackClick = (): void => {
        onBack();
    };

    return (
        <View testId={'confirmationOrInformationView'}>
            {isRegisteredKeeperOfVehicle ? (
                <Fragment>
                    {(productCategory === 'purchase' || productCategory === 'service') && (
                        <Fragment>
                            <Paragraph testId={'registeredKeeperPurchaseParagraph'}>
                                {textWithComponents(t, 'paragraphs.registeredKeeper.purchaseOrService.information')}
                            </Paragraph>
                            <Button
                                element={'a'}
                                className={'u-mb'}
                                testId={'registeredKeeperPurchaseDownloadLink'}
                                href={t('links.registeredKeeper.purchaseOrService.url')}
                                target={'_BLANK'}
                                icon={'semantic-arrow-right'}
                                link
                                simple
                            >
                                {t('links.registeredKeeper.purchaseOrService.label')}
                            </Button>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema(t)}
                                onSubmit={(_props): void => {
                                    onNext();
                                }}
                            >
                                {(formik: FormikProps<ConfirmationOrInformationFormValues>): React.ReactNode => (
                                    <Form
                                        className={'uk-change-registration-number-confirmation-or-information-view'}
                                        onSubmit={preventSubmit}
                                    >
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <ValidatedCheckbox
                                                    label={textWithComponents(t, 'confirmAlreadyUpdated.label')}
                                                    name={'confirmAlreadyUpdated'}
                                                    testId={'confirmAlreadyUpdated'}
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <ButtonContainer nav>
                                                    <Button
                                                        type={'button'}
                                                        testId={'backButton'}
                                                        onClick={onBackClick}
                                                        secondary
                                                    >
                                                        {t('translation:editableSectionNav.back')}
                                                    </Button>
                                                    <Button
                                                        type={'button'}
                                                        testId={'nextButton'}
                                                        onClick={formik.submitForm}
                                                    >
                                                        {t('translation:editableSectionNav.next')}
                                                    </Button>
                                                </ButtonContainer>
                                            </Fieldset.Row>
                                        </Fieldset>
                                    </Form>
                                )}
                            </Formik>
                        </Fragment>
                    )}
                    {productCategory === 'hire' && (
                        <Fragment>
                            <Paragraph testId={'registeredKeeperHireParagraph1'}>
                                {textWithComponents(t, 'paragraphs.registeredKeeper.hire.information1')}
                            </Paragraph>
                            <Paragraph testId={'registeredKeeperHireParagraph2'}>
                                {textWithComponents(t, 'paragraphs.registeredKeeper.hire.information2', {
                                    linkToAvartoPhoneNumber,
                                })}
                            </Paragraph>
                            <ButtonContainer nav>
                                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                    {t('translation:editableSectionNav.back')}
                                </Button>
                            </ButtonContainer>
                        </Fragment>
                    )}
                    {productCategory === 'lease' && (
                        <Fragment>
                            <Paragraph testId={'registeredKeeperLeaseParagraph'} className={'u-text-alert'}>
                                {t('unsupported.productCategory', { productCategory })}
                            </Paragraph>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    {(productCategory === 'purchase' || productCategory === 'service') && (
                        <Fragment>
                            <Paragraph testId={'notRegisteredKeeperPurchaseParagraph'}>
                                {textWithComponents(t, 'paragraphs.notRegisteredKeeper.purchaseOrService.information', {
                                    linkToAvartoPhoneNumber,
                                })}
                            </Paragraph>
                            <ButtonContainer nav>
                                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                    {t('translation:editableSectionNav.back')}
                                </Button>
                            </ButtonContainer>
                        </Fragment>
                    )}
                    {productCategory === 'hire' && (
                        <Fragment>
                            <Paragraph testId={'notRegisteredKeeperHireParagraph1'}>
                                {t('paragraphs.notRegisteredKeeper.hire.information1')}
                            </Paragraph>
                            <Paragraph testId={'notRegisteredKeeperHireParagraph2'}>
                                {textWithComponents(t, 'paragraphs.notRegisteredKeeper.hire.information2')}
                            </Paragraph>
                            <Button
                                element={'a'}
                                className={'u-mb'}
                                testId={'notRegisteredKeeperHireDownloadLink'}
                                href={'/contents/common/customer-guide-for-personalised-plates.pdf'}
                                target={'_BLANK'}
                                icon={'semantic-download'}
                                link
                                simple
                                download
                            >
                                {t('links.notRegisteredKeeper.hire.label')}
                            </Button>
                            <ButtonContainer nav>
                                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                    {t('translation:editableSectionNav.back')}
                                </Button>
                            </ButtonContainer>
                        </Fragment>
                    )}
                    {productCategory === 'lease' && (
                        <Fragment>
                            <Paragraph testId={'notRegisteredKeeperLeaseParagraph'} className={'u-text-alert'}>
                                {t('unsupported.productCategory', { productCategory })}
                            </Paragraph>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </View>
    );
};
