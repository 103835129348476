import { IncomeAndExpenditure, Situation } from '@cp-uk/common';

export const payFullArrearsWithinNMonths = (situation: Situation): boolean | undefined => {
    const { relatedToCovid19, payFullArrearsWithin3Months, payFullArrearsWithin12Months } = situation;

    return relatedToCovid19 ? payFullArrearsWithin12Months : payFullArrearsWithin3Months;
};

export const defaultSituation: Partial<Situation> = {
    causeOfArrears: {
        reducedIncome: false,
        unemployment: false,
        healthCondition: false,
        oversightOrMismanagementOfFinances: false,
        behindWithOtherCreditors: false,
        inAnIvaOrFilingForBankruptcy: false,
        other: false,
        moreDetail: undefined,
    },
    consentInformationProvided: false,
    relatedToCovid19: undefined,
    vulnerableCustomer: undefined,
    vulnerableCustomerDetail: undefined,
    continueToPayRentals: undefined,
    endAgreement: undefined,
    payFullArrearsWithin30Days: undefined,
    contributeTowardsArrears: undefined,
    payFullArrearsWithin3Months: undefined,
    payFullArrearsWithin12Months: undefined,
};

export const defaultIncomeAndExpenditure: IncomeAndExpenditure = {
    income: {
        salaryWages: 0,
        benefitsOther: 0,
    },
    expenditure: {
        volkswagenFinance: 0,
        rentMortgage: 0,
        waterGasElectric: 0,
        councilTax: 0,
        childCareMaintenance: 0,
        landlineInternetTv: 0,
        mobiles: 0,
        carInsuranceTax: 0,
        fuelTravelCosts: 0,
        housekeeping: 0,
        other: 0,
        otherCreditors: 0,
    },
};
