export enum Views {
    optionsAvailable = 'optionsAvailable',
    payInOneGo = 'payInOneGo',
    payInInstalments = 'payInInstalments',
    breathingSpace = 'breathingSpace',
    otherOptions = 'otherOptions',
    additionalInformation = 'additionalInformation',
    contactDetails = 'contactDetails',
    summaryAndRequest = 'summaryAndRequest',
}

export interface SummaryItem {
    view: Views;
    heading: string;
    content: string;
}
