import React from 'react';
import { LicensePlate as LicensePlateBronson } from '@vwfs-bronson/bronson-react';

export type LicensePlateProps = {
    vehicleRegistrationNumber: string | undefined;
    isContentShort?: boolean;
};

export const LicensePlate: React.FC<LicensePlateProps> = ({ isContentShort, vehicleRegistrationNumber }) => {
    return (
        <LicensePlateBronson
            className={`u-text-uppercase c-license-plate--compact${isContentShort ? ' u-ml-small u-ml-none@s' : ''}`}
            size={'large'}
            registrationNumber={vehicleRegistrationNumber}
            nl={true}
            testId={'licensePlate'}
        />
    );
};
