import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hr } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ChangeRegistrationNumberRequest, RegistrationSectionBO } from '@cp-uk/common';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ContractDetailsContext } from '../../ContractDetailsContext';
import { ChangeRegistrationNumber } from './change-registration-number/ChangeRegistrationNumber';
import { valueOrErrorTag } from 'components/helpers';

export const RegistrationSection: React.FC<{ registrationSection: RegistrationSectionBO | undefined }> = ({
    registrationSection: propsRegistrationSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [showFailureNotification, setShowFailureNotification] = useState<boolean>(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState<boolean>(false);
    const { newRegistrationSection, setNewRegistrationSection } = useContext(ContractDetailsContext);
    const { t } = useTranslation('registration-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('vehicleDetailsEditLicensePlate');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('vehicleDetailsEditLicensePlateCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker('vehicleDetailsEditLicensePlateSubmitFailed');
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('vehicleDetailsEditLicensePlateSuccess');

    if (propsRegistrationSection === undefined) {
        return null;
    }

    const title = t('title');

    const registrationSection = newRegistrationSection ?? propsRegistrationSection;

    const { registrationChangeable, vehicleRegistrationNumber, changeRegistrationNumber } = registrationSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        setShowFailureNotification(false);
        setShowSuccessNotification(false);
        onChangeStartAction(title);
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction(title);
    };
    const onChangeFailure = (): void => {
        setShowFailureNotification(true);
        setIsChanging(false);
        onChangeFailureAction(title);
    };
    const onChangeSuccess = (changeRegistrationNumberRequest: ChangeRegistrationNumberRequest): void => {
        if (changeRegistrationNumber) {
            const newRegistrationSection: RegistrationSectionBO = {
                registrationChangeable: 'ok',
                vehicleRegistrationNumber: changeRegistrationNumberRequest.newRegistrationNumber,
                changeRegistrationNumber: {
                    ...changeRegistrationNumber,
                    registrationNumber: changeRegistrationNumberRequest.newRegistrationNumber,
                },
            };
            setNewRegistrationSection(newRegistrationSection);
        }
        setShowSuccessNotification(true);
        setIsChanging(false);
        onChangeSuccessAction(title);
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('registrationNumber.label'),
            value: valueOrErrorTag(t, vehicleRegistrationNumber),
            uppercaseValue: true,
            testId: 'vehicleRegistrationNumber',
        },
    ];

    return (
        <Fragment>
            <Hr className={'u-mb-none'} />
            {isChanging ? (
                <ChangeRegistrationNumber
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changeRegistrationNumber={changeRegistrationNumber}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={title}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonDisabled={registrationChangeable !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'registrationSection'}
                >
                    <DescriptionListHorizontal items={descriptionListItems} />
                    {showFailureNotification && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.error}
                            text={t('notifications.failure.text')}
                            testId={'failureNotification'}
                        />
                    )}
                    {showSuccessNotification && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.success}
                            text={t('notifications.success.text')}
                            testId={'successNotification'}
                        />
                    )}
                </DataOverview>
            )}
        </Fragment>
    );
};
