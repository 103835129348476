import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions } from '@cp-shared-8/frontend-storybook-extensions';
import * as config from '../config/config';

const mockValue = (name: string, value: string): void => Object.defineProperty(config, name, { value });
export const mockCurrentBrand = (brand: string): void => mockValue('currentBrand', brand);
export const mockCurrentBrandName = (brandName: string): void => mockValue('currentBrandName', brandName);
export const mockChatbotApiKey = (apiKey: string): void => mockValue('chatbotApiKey', apiKey);

export const buildMockOptions = <T extends string = DefaultBusinessMarketApiErrorCode>(
    successDescription: string,
    successStatus: number,
    successResponseBody?: object,
): MockOptions<T> => {
    const mockOptions = {} as MockOptions<T>;
    mockOptions[successDescription] = {
        status: successStatus,
        responseBody: successResponseBody,
    };
    mockOptions['Bad Request'] = {
        status: 400,
        responseBody: {},
    };
    mockOptions['Internal Server Error'] = {
        status: 500,
    };
    return mockOptions;
};

export const buildEndpointMockDescription = <T extends string = DefaultBusinessMarketApiErrorCode>(
    name: string,
    url: string,
    method: 'get' | 'put' | 'post',
    successDescription: string,
    successStatus: number,
    successResponseBody?: object,
): EndpointMockDescription<T> => {
    return {
        name,
        url,
        method,
        defaultMockOption: successDescription,
        mockOptions: buildMockOptions(successDescription, successStatus, successResponseBody),
    };
};
