import React, { Suspense, useState } from 'react';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { EndOfContractBO } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { toBrandCase, useScrollTo } from 'utils';
import { selectEndOfContractForSpecific } from './EndOfContractSelector';
import { fetchEndOfContract } from './EndOfContractSlice';
import { SolutionsStartOptions } from './solutions-start-view/types';
import { SolutionsStartView } from './solutions-start-view/SolutionsStartView';
import { SolutionsExchangeView } from './solutions-exchange-view/SolutionsExchangeView';
import { SolutionsKeepView } from './solutions-keep-view/SolutionsKeepView';
import { SolutionsRefinanceView } from './solutions-refinance-view/SolutionsRefinanceView';
import { SolutionsHandBackView } from './solutions-hand-back-view/SolutionsHandBackView';
import { SolutionsSellView } from './solutions-sell-view/SolutionsSellView';
import { ContractHireStartOptions } from './contract-hire-start-view/types';
import { ContractHireStartView } from './contract-hire-start-view/ContractHireStartView';
import { ContractHireNewView } from './contract-hire-new-view/ContractHireNewView';
import { ContractHireExtendView } from './contract-hire-extend-view/ContractHireExtendView';
import { ContractHireExtensionView } from './contract-hire-extension-view/ContractHireExtensionView';
import { ContractHireReturnView } from './contract-hire-return-view/ContractHireReturnView';
import { ContractHireCallBackView } from './contract-hire-call-back-view/ContractHireCallBackView';
import { CallingViews, Views } from './types';

export const EndOfContractUi: React.FC<{ encryptedContractId: string; endOfContract: EndOfContractBO | undefined }> = ({
    encryptedContractId,
    endOfContract,
}) => {
    const initialView = endOfContract?.productType === 'solutions' ? Views.solutionsStart : Views.contractHireStart;
    const initialCaller = ('' as unknown) as CallingViews;
    const [currentView, setCurrentView] = useState<Views>(initialView);
    const [callingView, setCallingView] = useState<CallingViews>(initialCaller);
    const [solutionsStartOption, setSolutionsStartOption] = useState<SolutionsStartOptions>('');
    const [contractHireStartOption, setContractHireStartOption] = useState<ContractHireStartOptions>('');
    const scrollToRef = useScrollTo(currentView, initialView);

    if (endOfContract === undefined) {
        return null;
    }

    const {
        contractDescription,
        contractNumber,
        financeBrand,
        vehicleBrand: propsVehicleBrand,
        registrationNumber,
        finalPaymentAmount,
        contractEndDate,
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
        showSellOption,
        inArrears,
        minValidCurrentMileage,
        maxValidCurrentMileage,
        contractedMileage,
        showMatchmaker,
    } = endOfContract;
    const vehicleBrand = toBrandCase(propsVehicleBrand);

    const onSolutionsStartContinue = (
        nextView: Extract<
            Views,
            Views.solutionsExchange | Views.solutionsKeep | Views.solutionsHandBack | Views.solutionsSell
        >,
        option: SolutionsStartOptions,
    ): void => {
        setCurrentView(nextView);
        setSolutionsStartOption(option);
    };

    const onSolutionsExchangeBack = (): void => {
        setCurrentView(Views.solutionsStart);
    };

    const onSolutionsKeepBack = (): void => {
        setCurrentView(Views.solutionsStart);
    };
    const onSolutionsKeepRefinance = (): void => {
        setCurrentView(Views.solutionsRefinance);
    };

    const onSolutionsRefinanceBack = (): void => {
        setCurrentView(Views.solutionsKeep);
    };

    const onSolutionsHandBackBack = (): void => {
        setCurrentView(Views.solutionsStart);
    };

    const onSolutionsSellBack = (): void => {
        setCurrentView(Views.solutionsStart);
    };

    const onContractHireStartContinue = (
        nextStep: Extract<Views, Views.contractHireNew | Views.contractHireExtend | Views.contractHireReturn>,
        option: ContractHireStartOptions,
    ): void => {
        setCurrentView(nextStep);
        setContractHireStartOption(option);
    };

    const onContractHireNewBack = (): void => {
        setCurrentView(Views.contractHireStart);
    };
    const onContractHireNewReturning = (): void => {
        setCurrentView(Views.contractHireReturn);
    };
    const onContractHireNewCallBack = (): void => {
        setCurrentView(Views.contractHireCallBack);
        setCallingView(Views.contractHireNew);
    };

    const onContractHireExtendBack = (): void => {
        setCurrentView(Views.contractHireStart);
    };
    const onContractHireExtendExtension = (): void => {
        setCurrentView(Views.contractHireExtension);
    };
    const onContractHireExtendCallBack = (): void => {
        setCurrentView(Views.contractHireCallBack);
        setCallingView(Views.contractHireExtend);
    };

    const onContractHireExtensionStart = (): void => {
        setCurrentView(Views.contractHireStart);
    };
    const onContractHireExtensionBack = (): void => {
        setCurrentView(Views.contractHireExtend);
    };
    const onContractHireExtensionCallBack = (): void => {
        setCurrentView(Views.contractHireCallBack);
        setCallingView(Views.contractHireExtension);
    };

    const onContractHireReturnBack = (): void => {
        setCurrentView(Views.contractHireStart);
    };

    const onContractHireReturnCallBack = (): void => {
        setCurrentView(Views.contractHireCallBack);
        setCallingView(Views.contractHireReturn);
    };

    const onContractHireCallBackBack = (previousStep: CallingViews): void => {
        setCurrentView(previousStep);
        setCallingView(initialCaller);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.solutionsStart && (
                    <SolutionsStartView
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        initialOption={solutionsStartOption}
                        showSellOption={showSellOption}
                        onContinue={onSolutionsStartContinue}
                    />
                )}
                {currentView === Views.solutionsExchange && (
                    <SolutionsExchangeView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        showMatchmaker={showMatchmaker}
                        onBack={onSolutionsExchangeBack}
                    />
                )}
                {currentView === Views.solutionsKeep && (
                    <SolutionsKeepView
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        finalPaymentAmount={finalPaymentAmount}
                        contractEndDate={contractEndDate}
                        inArrears={inArrears}
                        onBack={onSolutionsKeepBack}
                        onRefinance={onSolutionsKeepRefinance}
                    />
                )}
                {currentView === Views.solutionsRefinance && (
                    <SolutionsRefinanceView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress}
                        mobileNumber={mobileNumber}
                        homePhoneNumber={homePhoneNumber}
                        workPhoneNumber={workPhoneNumber}
                        onBack={onSolutionsRefinanceBack}
                    />
                )}
                {currentView === Views.solutionsHandBack && (
                    <SolutionsHandBackView
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        onBack={onSolutionsHandBackBack}
                    />
                )}
                {currentView === Views.solutionsSell && (
                    <SolutionsSellView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        vehicleBrand={vehicleBrand}
                        onBack={onSolutionsSellBack}
                    />
                )}
                {currentView === Views.contractHireStart && (
                    <ContractHireStartView
                        contractDescription={contractDescription}
                        contractEndDate={contractEndDate}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        initialOption={contractHireStartOption}
                        onContinue={onContractHireStartContinue}
                    />
                )}
                {currentView === Views.contractHireNew && (
                    <ContractHireNewView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        showMatchmaker={showMatchmaker}
                        onBack={onContractHireNewBack}
                        onReturning={onContractHireNewReturning}
                        onCallBack={onContractHireNewCallBack}
                    />
                )}
                {currentView === Views.contractHireExtend && (
                    <ContractHireExtendView
                        contractDescription={contractDescription}
                        onBack={onContractHireExtendBack}
                        onExtension={onContractHireExtendExtension}
                        onCallBack={onContractHireExtendCallBack}
                    />
                )}
                {currentView === Views.contractHireExtension && (
                    <ContractHireExtensionView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        contractNumber={contractNumber}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        registrationNumber={registrationNumber}
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress}
                        inArrears={inArrears}
                        minValidCurrentMileage={minValidCurrentMileage}
                        maxValidCurrentMileage={maxValidCurrentMileage}
                        contractedMileage={contractedMileage}
                        onStart={onContractHireExtensionStart}
                        onBack={onContractHireExtensionBack}
                        onCallBack={onContractHireExtensionCallBack}
                    />
                )}
                {currentView === Views.contractHireReturn && (
                    <ContractHireReturnView
                        contractDescription={contractDescription}
                        financeBrand={financeBrand}
                        vehicleBrand={vehicleBrand}
                        onBack={onContractHireReturnBack}
                        onCallBack={onContractHireReturnCallBack}
                    />
                )}
                {currentView === Views.contractHireCallBack && (
                    <ContractHireCallBackView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        vehicleBrand={vehicleBrand}
                        registrationNumber={registrationNumber}
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress}
                        mobileNumber={mobileNumber}
                        homePhoneNumber={homePhoneNumber}
                        workPhoneNumber={workPhoneNumber}
                        callingView={callingView}
                        onBack={onContractHireCallBackBack}
                    />
                )}
            </section>
        </Suspense>
    );
};

const EndOfContractWithHandlers = withLoadingAndNoConnectionHandler(EndOfContractUi);

export const EndOfContract: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: endOfContract, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchEndOfContract,
        selectEndOfContractForSpecific,
        encryptedContractId,
    );

    return (
        <EndOfContractWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            endOfContract={endOfContract}
        />
    );
};
