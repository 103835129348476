import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Card } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO } from '@cp-uk/common';
import { contractSubtitle, contractTitle } from 'components/contracts-overview/ContractOverview';
import { LicensePlate } from 'components/contracts-overview/LicensePlate';

export type ContractDescriptionProps = {
    className?: string;
    testId?: string;
    contractDescription: ContractDescriptionBO;
};

export const ContractDescription: React.FC<ContractDescriptionProps> = ({
    className,
    testId = 'contractDescription',
    contractDescription,
    children,
}) => {
    const classNameList = classNames('c-card--hide-body', className).trim();

    const { contractNumber, productType, vehicleBrand, vehicleDescription, registrationNumber } = contractDescription;
    const contractSubtitleText = contractSubtitle(productType, contractNumber);

    return (
        <Card
            element={'article'}
            className={classNameList}
            title={contractTitle(vehicleBrand, vehicleDescription)}
            subtitle={
                children ? (
                    <Fragment>
                        {contractSubtitleText}
                        {children}
                    </Fragment>
                ) : (
                    contractSubtitleText
                )
            }
            contentShort={<LicensePlate vehicleRegistrationNumber={registrationNumber} isContentShort />}
            testId={testId}
            expandable
        />
    );
};
