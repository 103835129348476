import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { EndOfContractBO, getEndOfContractEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = createGetContractBasedDataFetchSlice<
    EndOfContractBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'endOfContract',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<EndOfContractBO>(getEndOfContractEndpoint(encryptedContractId)).then(({ data }) => data);
    },
});

export default reducer;
export const fetchEndOfContract = fetchData;
export const updateEndOfContract = updateData;
