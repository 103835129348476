import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { CPDate } from '@cp-shared-8/common-utilities';
import { ContractDescriptionBO, FinanceBrandsEOC, nowCPDate, parseCPDate, ProductTypesEOC } from '@cp-uk/common';
import { contractCategory, textAsHtml, useFinanceCompanyDetails } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { retailerDescriptionText } from 'components/helpers';
import {
    TileSelectProps,
    ValidatedTileSelectGroup,
} from 'components/validated-tile-select-group/ValidatedTileSelectGroup';
import { dashboardPagePath } from 'components/navigation/paths';
import {
    ExternalWebsitesNotification,
    ExternalWebsitesNotificationMode,
} from 'components/notifications/external-websites/ExternalWebsitesNotification';
import { Views } from '../types';
import { validationSchema } from './ContractHireStartValidation';
import { ContractHireStartOptions, ContractHireStartViewFormValues } from './types';

export type ContractHireStartViewProps = {
    contractDescription: ContractDescriptionBO;
    contractEndDate: CPDate;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    initialOption: ContractHireStartOptions;
    onContinue: (
        nextStep: Extract<Views, Views.contractHireNew | Views.contractHireExtend | Views.contractHireReturn>,
        option: ContractHireStartOptions,
    ) => void;
};

export const ContractHireStartView: React.FC<ContractHireStartViewProps> = ({
    contractDescription,
    contractEndDate,
    financeBrand,
    vehicleBrand,
    initialOption,
    onContinue,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation(['end-of-contract-contract-hire-start-view', 'end-of-contract']);
    const history = useHistory();

    useAnalyticsPageViewTracker('endOfContractRchStart');

    const initialValues: ContractHireStartViewFormValues = {
        option: initialOption,
    };

    const retailerDescription = retailerDescriptionText(financeBrand);
    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const contractEndDateMoment = parseCPDate(contractEndDate).toMoment();
    const buyOptionMoment = contractEndDateMoment.subtract(90, 'days');
    const showBuyingNotification = todayMoment.isSameOrAfter(buyOptionMoment);

    const optionTileSelects: TileSelectProps[] = [
        {
            icon: 'semantic-safety-check',
            title: t('options.new.title', { shortCompanyName }),
            text: t('options.new.text', { shortCompanyName }),
            value: 'new',
        },
        {
            icon: 'semantic-car',
            title: t('options.extend.title', { vehicleBrand }),
            text: t('options.extend.text'),
            value: 'extend',
        },
        {
            icon: 'semantic-car-handover',
            title: t('options.return.title', { vehicleBrand }),
            text: t('options.return.text', { shortCompanyName }),
            value: 'return',
        },
    ];

    return (
        <View testId={'contractHireStartView'}>
            <Heading level={2} testId={'viewHeader'}>
                {contractCategory(productType)}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Notification
                className={'u-mb'}
                status={NotificationStatus.warning}
                testId={'semiConductorDelaysNotification'}
            >
                {textAsHtml(t('notifications.semiConductorDelays.text', { shortCompanyName, retailerDescription, contactUrl }))}
            </Notification>
            <Paragraph testId={'introductionParagraph'}>{textAsHtml(t('paragraphs.introduction'))}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={({ option }): void => {
                    if (option === 'new') {
                        onContinue(Views.contractHireNew, option);
                    } else if (option === 'extend') {
                        onContinue(Views.contractHireExtend, option);
                    } else if (option === 'return') {
                        onContinue(Views.contractHireReturn, option);
                    } else {
                        // NOTE: Leave this in as testing is done for each of the three other "action" views:
                        // eslint-disable-next-line no-alert
                        alert('Unexpected state for onSubmit() handler!');
                    }
                }}
            >
                {(formik: FormikProps<ContractHireStartViewFormValues>): React.ReactNode => (
                    <Form className={'uk-end-of-contract-Hire-start-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedTileSelectGroup
                                    layoutItemClassName={'u-1/3 u-1/1@m'}
                                    inputType={'radio'}
                                    tileSelects={optionTileSelects}
                                    name={'option'}
                                    testId={'optionTileSelects'}
                                />
                            </Fieldset.Row>
                            {showBuyingNotification && (
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        headline={t('notifications.buying.headline', { vehicleBrand })}
                                        testId={'buyingNotification'}
                                    >
                                        {textAsHtml(t('notifications.buying.text'))}
                                    </Notification>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'returnButton'}
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            {showBuyingNotification && (
                                <Fieldset.Row>
                                    <ExternalWebsitesNotification mode={ExternalWebsitesNotificationMode.Singular} />
                                </Fieldset.Row>
                            )}
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
