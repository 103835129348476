import { getValueFromEnumKey } from 'utils';
import { Options } from './options-available-view/types';

export const asType = <T>(value: unknown): T => {
    return value as T;
};

export const getInitialOptionFromProp = (propInKebabCase: string | undefined): Options => {
    const value = getValueFromEnumKey(Options, propInKebabCase);

    return asType<Options>(value ?? '');
};
