import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { RequestContactBO, getRequestContactEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<RequestContactBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'requestContact',
    fetchCallback() {
        return CpDataApi.get<RequestContactBO>(getRequestContactEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchRequestContact = fetchData;
