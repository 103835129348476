import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import { ThirdPartyLicences } from 'components/third-party-licences/ThirdPartyLicences';

export const ThirdPartyLicencesPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.ThirdPartyLicences);

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.ThirdPartyLicences} />
            <ThirdPartyLicences />
        </Fragment>
    );
};
