import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { FinancialSupportAndWellbeing } from 'components/financial-support-and-wellbeing/FinancialSupportAndWellbeing';

export const FinancialSupportAndWellbeingPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.FinancialSupportAndWellbeing);
    const { t } = useTranslation('page-titles');

    return (
        <ContentSection
            className={'o-content-section--compact uk-financial-support-and-wellbeing-page'}
            pageWrapSize={'small'}
        >
            <PageHeader title={t('financial-support-and-wellbeing')} />
            <FinancialSupportAndWellbeing />
        </ContentSection>
    );
};
