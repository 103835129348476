import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ConfirmationOrInformationFormValues } from './types';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape<ConfirmationOrInformationFormValues>({
        confirmAlreadyUpdated: Yup.boolean()
            .required(t('confirmAlreadyUpdated.validation.required'))
            .oneOf([true], t('confirmAlreadyUpdated.validation.mustConfirm')),
    });
};
