import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        accountHolderName: Yup.string()
            .required(t('accountHolderName.validation.required'))
            .max(60, t('accountHolderName.validation.maxLength')),
        sortCode: Yup.string()
            .required(t('sortCode.validation.required'))
            .matches(RegExp('^[0-9]{2}-[0-9]{2}-[0-9]{2}$'), t('sortCode.validation.format')),
        accountNumber: Yup.string()
            .required(t('accountNumber.validation.required'))
            .matches(RegExp('^[0-9]{8}$'), t('accountNumber.validation.format')),
        accountHolderConfirmation: Yup.bool()
            .required(t('accountHolderConfirmation.validation.required'))
            .oneOf([true], t('accountHolderConfirmation.validation.mustConfirm')),
    });
};
