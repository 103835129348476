export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

let contentEndpointQueryString = '';
const brandCommon = 'common';

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json${contentEndpointQueryString}`;
}

export const setContentEndpointBuildTimestamp = (buildTimestamp: string): void => {
    contentEndpointQueryString = `?v=${buildTimestamp}`;
};

export const getLandingPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-hero-teaser');
};

export const getLandingPageMarketingCardsEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-marketing-cards');
};

export const getIconFooterEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'icon-footer');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'legal-footer');
};

export const getFrequentlyAskedQuestionsEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'frequently-asked-questions');
};

export const getLegalNoticeEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'legal-notice');
};

export const getPrivacyPolicyEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'privacy-policy');
};

export const getCookiePolicyEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'cookie-policy');
};

export const getModernSlaveryStatementEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'modern-slavery-statement');
};

export const getThirdPartyLicencesEndpoint: ContentEndpoint = (locale, _brand) => {
    return getContentEndpoint(locale, brandCommon, 'third-party-licences');
};

export const getFrequentlyAskedQuestionsPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'frequently-asked-questions-page-hero-teaser');
};

export const getLegalNoticePageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice-page-hero-teaser');
};

export const getPrivacyPolicyPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'privacy-policy-page-hero-teaser');
};

export const getCookiePolicyPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-policy-page-hero-teaser');
};

export const getModernSlaveryStatementPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'modern-slavery-statement-page-hero-teaser');
};

export const getThirdPartyLicencesPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'third-party-licences-page-hero-teaser');
};

export const getRegisterPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'register-page-hero-teaser');
};

export const getUseOurMatchmakerPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'use-our-matchmaker-page-hero-teaser');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};
