import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { DocumentListBO, getDocumentListEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<DocumentListBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'documentList',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<DocumentListBO>(getDocumentListEndpoint(encryptedContractId)).then(({ data }) => data);
    },
});

export default reducer;
export const fetchDocumentList = fetchData;
