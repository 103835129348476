import React from 'react';
import classNames from 'classnames';
import { DataOverview } from '../data-overview/DataOverview';

export type ContractMarketingCardProps = {
    className?: string;
    title: string;
    buttonLabel: string;
    buttonTestId?: string;
    onButtonClick?: () => void;
    testId?: string;
};

export const ContractMarketingCard: React.FC<ContractMarketingCardProps> = ({
    className,
    title,
    buttonLabel,
    buttonTestId,
    onButtonClick,
    testId,
    children,
}) => {
    const classNameList = classNames('c-contract-marketing-card', className).trim();

    return (
        <DataOverview
            noBorder
            separator
            className={classNameList}
            title={title}
            buttonLabel={buttonLabel}
            buttonTestId={buttonTestId}
            onButtonClick={onButtonClick}
            testId={testId}
        >
            {children}
        </DataOverview>
    );
};
