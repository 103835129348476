import React from 'react';
import { useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { SimplePageError } from 'components/simple-page-error/SimplePageError';

export const LoginErrorPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.LoginError);
    useAnalyticsErrorPageTracker('connectionProblemError');

    return <SimplePageError namespace={'login-error'} />;
};
