import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-8/common-utilities';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    useAnalyticsPageViewTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    getRequestPromiseToPayEndpoint,
    MethodsOfPayment,
    nowCPDate,
    parseCPDate,
    parseCPDateFromInput,
    RequestPromiseToPay,
    Situation,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import 'extensions';
import { textAsHtml, textWithComponents, useBankDetails, buildLinkToPhoneNumber } from 'utils';
import { View } from 'components/view/View';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { RadioButtonProps, ValidatedRadioButtons } from 'components/validated-radio-buttons/ValidatedRadioButtons';
import { BankDetailsForDirectDebit } from 'components/bank-details-for-direct-debit/BankDetailsForDirectDebit';
import { BankDetailsForBankTransfer } from 'components/bank-details-for-bank-transfer/BankDetailsForBankTransfer';
import { AlternativeNotification } from '../alternative-notification';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';
import { PromiseToPayFormValues } from './types';
import { dateOfPaymentDisabled, dateOfPaymentIsMandatory, initialValues, maxValidDate, minValidDate } from './helpers';
import { validationSchema } from './PromiseToPayValidation';

type PromiseToPayViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    contractNumber: string;
    registrationNumber: string;
    changeBankAccountInProgress: boolean;
    changePaymentDateInProgress: boolean;
    sortCode: string;
    accountNumber: string;
    lastBilledPaymentDate: CPDate | undefined;
    nextScheduledPaymentDate: CPDate | undefined;
    totalArrears: number;
    situation: Situation;
    onBack: () => void;
};

export const PromiseToPayView: React.FC<PromiseToPayViewProps> = ({
    encryptedContractId,
    contractDescription,
    contractNumber,
    registrationNumber,
    changeBankAccountInProgress,
    changePaymentDateInProgress,
    sortCode,
    accountNumber,
    lastBilledPaymentDate,
    nextScheduledPaymentDate,
    totalArrears,
    situation,
    onBack,
}) => {
    const { t } = useTranslation('request-additional-help-promise-to-pay-view');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const history = useHistory();
    const bankDetails = useBankDetails();
    const { onAction: onRequestAdditionalHelpSubmitSuccessAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitSuccess');
    const { onAction: onRequestAdditionalHelpSubmitFailedAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitFailed');
    const { onAction: onRequestAdditionalHelpBackAction } = useAnalyticsActionTracker('onRequestAdditionalHelpBack');
    useAnalyticsPageViewTracker('requestAdditionalHelpPromiseToPay');

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const inBillingPeriod = parseCPDate(lastBilledPaymentDate).toMoment().isSameOrAfter(todayMoment, 'day');
    const noRegularPayments = !nextScheduledPaymentDate;

    const directDebitDisabled =
        changeBankAccountInProgress || changePaymentDateInProgress || inBillingPeriod || noRegularPayments;

    const notAvailableSuffix = directDebitDisabled ? t('methodOfPayment.directDebit.notAvailable') : '';

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    const methodOfPaymentRadioButtons: RadioButtonProps[] = [
        {
            label: t('methodOfPayment.directDebit.label'),
            tooltip: textAsHtml(`${t('methodOfPayment.directDebit.tooltip')}${notAvailableSuffix}`),
            value: 'directDebit',
            disabled: directDebitDisabled,
        },
        {
            label: t('methodOfPayment.cardPayment.label'),
            tooltip: t('methodOfPayment.cardPayment.tooltip'),
            value: 'cardPayment',
        },
        {
            label: t('methodOfPayment.bankTransfer.label'),
            tooltip: t('methodOfPayment.bankTransfer.tooltip'),
            value: 'bankTransfer',
        },
    ];

    const resetDateOfPayment = ({ setFieldValue, setFieldTouched }: FormikProps<PromiseToPayFormValues>): void => {
        setFieldValue('dateOfPayment', '', false);
        setFieldTouched('dateOfPayment', false, false);
    };

    const onMethodOfPaymentChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<PromiseToPayFormValues>,
    ): void => {
        if (newValue === 'directDebit') {
            resetDateOfPayment(formik);
        }
    };

    const commonFragment = (): string => {
        return t('paragraphs.informationCommon', { totalArrears });
    };

    const specificFragment = (): string => {
        let key: string;
        let options: {} | undefined = undefined;
        if (changeBankAccountInProgress && changePaymentDateInProgress) {
            key = 'ChangeBankAccountAndPaymentDateInProgress';
        } else if (changeBankAccountInProgress) {
            key = 'ChangeBankAccountOnlyInProgress';
        } else if (changePaymentDateInProgress) {
            key = 'ChangePaymentDateOnlyInProgress';
        } else if (inBillingPeriod) {
            key = 'InBillingPeriod';
        } else if (noRegularPayments) {
            key = 'NoRegularPayments';
        } else {
            key = 'Normal';
            options = { nextScheduledPaymentDate };
        }

        return t(`paragraphs.information${key}`, options);
    };

    const paragraphInformationContent = `${commonFragment()} ${specificFragment()}`;

    const notificationActionText = (methodOfPayment: MethodsOfPayment): React.ReactNode => {
        let text: React.ReactNode;
        if (methodOfPayment === 'directDebit') {
            text = (
                <Fragment>
                    {textWithComponents(t, 'notifications.actionDirectDebit')}
                    <BankDetailsForDirectDebit
                        className={'c-description-list--notification u-mb-none'}
                        sortCode={sortCode}
                        accountNumber={accountNumber}
                    />
                </Fragment>
            );
        } else if (methodOfPayment === 'cardPayment') {
            text = textWithComponents(t, 'notifications.actionCardPayment', { linkToCollectionsPhoneNumber });
        } else if (methodOfPayment === 'bankTransfer') {
            text = (
                <Fragment>
                    {textWithComponents(t, 'notifications.actionBankTransfer.header')}
                    <BankDetailsForBankTransfer
                        className={'c-description-list--notification u-mb-small'}
                        {...bankDetails}
                        referenceNumber={contractNumber}
                    />
                    {textWithComponents(t, 'notifications.actionBankTransfer.footer')}
                </Fragment>
            );
        } else {
            text = t('unsupported.methodOfPayment', { methodOfPayment });
        }

        return text;
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
    };

    const onBackClick = (): void => {
        onRequestAdditionalHelpBackAction();
        onBack();
    }

    return (
        <View testId={'promiseToPayView'}>
            <SectionHeading level={2} testId={'viewHeader'}>{t('subHeading')}</SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph>{textAsHtml(paragraphInformationContent)}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={({ methodOfPayment, dateOfPayment }: PromiseToPayFormValues): Promise<void> => {
                    const dateOfPaymentAsCPDate: CPDate =
                        methodOfPayment === 'directDebit'
                            ? nextScheduledPaymentDate ?? ''
                            : parseCPDateFromInput(dateOfPayment).toMoment().toUTC().toCPDate();
                    const requestPromiseToPay: RequestPromiseToPay = {
                        registrationNumber,
                        totalArrears,
                        methodOfPayment: methodOfPayment as MethodsOfPayment,
                        dateOfPayment: dateOfPaymentAsCPDate,
                        situation,
                    };
                    setIsSubmitting(true);
                    return CpDataApi.put<void>(getRequestPromiseToPayEndpoint(encryptedContractId), requestPromiseToPay)
                        .then(() => {
                            setShowSuccessModal(true);
                            setIsSubmitting(false);
                            onRequestAdditionalHelpSubmitSuccessAction();
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setIsSubmitting(false);
                            onRequestAdditionalHelpSubmitFailedAction();
                        });
                }}
            >
                {(formik: FormikProps<PromiseToPayFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-promise-to-pay-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedRadioButtons
                                    className={'u-indent u-mb-xsmall'}
                                    label={t('methodOfPayment.label')}
                                    radioButtons={methodOfPaymentRadioButtons}
                                    name={'methodOfPayment'}
                                    testId={'methodOfPayment'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onMethodOfPaymentChange(e, formik);
                                    }}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [2, 2, 4],
                                        delimiter: '/',
                                    }}
                                    className={'input-date'}
                                    label={t('dateOfPayment.label')}
                                    tooltip={t('dateOfPayment.tooltip', { minValidDate, maxValidDate })}
                                    placeholder={t('dateOfPayment.placeholder')}
                                    name="dateOfPayment"
                                    testId="dateOfPayment"
                                    isMandatory={dateOfPaymentIsMandatory(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                    disabled={dateOfPaymentDisabled(formik.values.methodOfPayment as MethodsOfPayment)}
                                />
                            </Fieldset.Row>
                            {!!formik.values.methodOfPayment && (
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        testId={'notificationAction'}
                                        text={' '}
                                    >
                                        {notificationActionText(formik.values.methodOfPayment as MethodsOfPayment)}
                                    </Notification>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type="button"
                                        testId="backButton"
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type="button"
                                        testId="requestButton"
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.dashboard')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'successModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'errorModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
        </View>
    );
};
