import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Paragraph, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker as useOldAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { FinanceBrandsMM } from '@cp-uk/common';
import {
    appendBuildTimestamp,
    useAnalyticsPageViewTracker as useNewAnalyticsPageViewTracker,
    useAnalyticsActionTracker as useNewAnalyticsActionTracker,
    useFinanceCompanyDetails,
} from 'utils';
import { startMatchmakerPagePath } from 'components/navigation/paths';
import { ContractMarketingCard } from './ContractMarketingCard';

export const MatchmakerMarketingCard: React.FC<{ encryptedContractId: string; financeBrand: FinanceBrandsMM }> = ({
    encryptedContractId,
    financeBrand,
}) => {
    const { t } = useTranslation('matchmaker-marketing-card');
    const { shortCompanyName } = useFinanceCompanyDetails('solutions', financeBrand);
    const history = useHistory();

    const { onAction: onOldStartMatchmakerAction } = useOldAnalyticsActionTracker('onDashboardStartMatchmaker');

    const { onAction: onNewStartMatchmakerAction } = useNewAnalyticsActionTracker('matchmakerMarketingCardForward');
    useNewAnalyticsPageViewTracker('matchmakerMarketingCard', true, financeBrand);

    const title = t('title');

    const onStartMatchmakerClick = (): void => {
        onOldStartMatchmakerAction();
        onNewStartMatchmakerAction('startMatchmakerButton', financeBrand);
        history.push(startMatchmakerPagePath(encryptedContractId));
    };

    return (
        <ContractMarketingCard
            title={title}
            buttonLabel={t('buttons.startMatchmaker')}
            buttonTestId={'startMatchmakerButton'}
            onButtonClick={onStartMatchmakerClick}
            testId={'matchmakerMarketingCard'}
        >
            <Layout>
                <Layout.Item default={'2/3'} m={'1/2'} s={'1/1'}>
                    <Paragraph className={'u-mb-none'}>{t('text', { shortCompanyName })}</Paragraph>
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/2'} s={'1/1'}>
                    <ResponsiveImage
                        src={appendBuildTimestamp(t('imageUrl', { brand: financeBrand }))}
                        alt={title}
                        testId={'matchmakerImage'}
                    />
                </Layout.Item>
            </Layout>
        </ContractMarketingCard>
    );
};
