import React, { FormEventHandler, Fragment } from 'react';
import { useField } from 'formik';
import { FormField, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { hasError, ValidationErrorMessage } from '../errors';
import { HelpText } from '../help-text/HelpText';

export type TileSelectProps = {
    icon: string;
    id?: string;
    title: string;
    text: string;
    value?: string;
};

type ValidatedTileSelectGroupProps = {
    tileSelects: TileSelectProps[];
    inputType: 'radio' | 'checkbox';
    name: string;
    label?: string;
    tooltip?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    testId?: string;
    helpText?: string | React.ReactNode;
    handleChange?: FormEventHandler<HTMLInputElement>;
    className?: string;
    layoutItemClassName?: string;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
};

export const ValidatedTileSelectGroup: React.FC<ValidatedTileSelectGroupProps> = ({
    tileSelects,
    inputType,
    name,
    tooltip,
    defaultChecked,
    label,
    disabled,
    testId,
    helpText,
    handleChange,
    className,
    layoutItemClassName,
    isMandatory = false,
    withHtmlValidationWarning = false,
}) => {
    const [field, meta] = useField(name);

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    return (
        <FormField
            type="other"
            testId={`${testId}FormField`}
            errorMessage={errorMessageElseHelpText}
            labelText={label}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            <TileSelectGroup className={className} layoutItemClassName={layoutItemClassName} testId={testId} {...field}>
                {tileSelects.map(({ id, icon, title, text, value }, index: number) => {
                    const innerTestId = testId ? `${testId}.${value}` : undefined;

                    return (
                        <TileSelect
                            key={index}
                            error={hasError(meta)}
                            radioGroupName={inputType === 'radio' ? name : undefined}
                            inputType={inputType}
                            defaultChecked={defaultChecked || value === field.value}
                            icon={icon}
                            id={inputType === 'radio' ? undefined : id}
                            value={value}
                            title={title}
                            disabled={disabled}
                            testId={innerTestId}
                        >
                            <span data-testid={'tileSelectText'}>{text}</span>
                        </TileSelect>
                    );
                })}
            </TileSelectGroup>
        </FormField>
    );
};
