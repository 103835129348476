import { EndOfContractBO, ProductTypesEOC } from '@cp-uk/common';

const buildEndOfContract = (
    productType: ProductTypesEOC,
    showSellOption = false,
    showMatchmaker = false,
): EndOfContractBO => {
    return {
        contractDescription: {
            vehicleBrand: 'VOLKSWAGEN',
            vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
            contractNumber: '440123456789',
            registrationNumber: 'EX19ABC',
            productType,
        },
        contractNumber: '440123456789',
        productType,
        financeBrand: 'vw',
        vehicleBrand: 'VOLKSWAGEN',
        registrationNumber: 'EX19ABC',
        finalPaymentAmount: 12345.67,
        contractEndDate: '2023-12-31T00:00:00Z',
        firstName: 'John',
        lastName: 'Smith',
        emailAddress: 'john.smith@gmail.com',
        mobileNumber: '07890123456',
        homePhoneNumber: '01296123456',
        workPhoneNumber: '01908123456',
        showSellOption: showSellOption,
        inArrears: false,
        minValidCurrentMileage: 1000,
        maxValidCurrentMileage: 999999,
        contractedMileage: 20000,
        showMatchmaker: showMatchmaker,
    };
};

export const EndOfContractSolutionsWithoutSellOptionAndShowMatchmaker: EndOfContractBO = buildEndOfContract(
    'solutions',
);

export const EndOfContractSolutionsWithSellOptionAndShowMatchmaker: EndOfContractBO = buildEndOfContract(
    'solutions',
    true,
    true,
);

export const EndOfContractSolutionsWithSellOption: EndOfContractBO = buildEndOfContract('solutions', true);

export const EndOfContractSolutionsWithShowMatchmaker: EndOfContractBO = buildEndOfContract(
    'solutions',
    false,
    true,
);

export const EndOfContractContractHire: EndOfContractBO = buildEndOfContract('contractHire');
