import { TFunction } from 'i18next';
import { buildCallbackAvailabilityString, upperCaseFirstLetter } from 'utils';
import { SummaryItem, Views } from '../types';
import { AvailableTimes, ContactDetailsFormValues, ContactDetailsSelections } from './types';

export const buildInitialValues = (selections: ContactDetailsSelections | undefined): ContactDetailsFormValues => {
    if (selections === undefined) {
        return {
            phoneNumber: '',
            earliestAvailableTime: AvailableTimes.noPreferredEarliest,
            latestAvailableTime: AvailableTimes.noPreferredLatest,
            availableDays: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
            },
        };
    }

    const {
        phoneNumber,
        earliestAvailableTime,
        latestAvailableTime,
        availableDays: { monday, tuesday, wednesday, thursday, friday, saturday },
    } = selections;

    return {
        phoneNumber,
        earliestAvailableTime,
        latestAvailableTime,
        availableDays: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
        },
    };
};

export const buildSelections = (
    t: TFunction,
    { phoneNumber, earliestAvailableTime, latestAvailableTime, availableDays }: ContactDetailsFormValues,
): ContactDetailsSelections => {
    const earliestAvailableTimeAsString = t(`availableTimes.${earliestAvailableTime}`);
    const latestAvailableTimeAsString = t(`availableTimes.${latestAvailableTime}`);

    const { monday, tuesday, wednesday, thursday, friday, saturday } = availableDays;

    const availableDaysAsString =
        Object.entries(availableDays)
            .filter(([_, value]) => !!value)
            .map(([key, _]) => t(`availableDays.${key}.label`))
            .join(', ') || upperCaseFirstLetter(t('availableDays.anyDay'));

    return {
        phoneNumber,
        earliestAvailableTime,
        earliestAvailableTimeAsString,
        latestAvailableTime,
        latestAvailableTimeAsString,
        availableDays: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
        },
        availableDaysAsString,
    };
};

export const buildSummaryItem = (
    t: TFunction,
    { phoneNumber, earliestAvailableTime, latestAvailableTime, availableDays }: ContactDetailsFormValues,
): SummaryItem => {
    const callbackAvailability = buildCallbackAvailabilityString(
        t,
        earliestAvailableTime as AvailableTimes,
        latestAvailableTime as AvailableTimes,
        Object.entries(availableDays),
    );

    return {
        view: Views.contactDetails,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', { phoneNumber, callbackAvailability }),
    };
};
