import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Paragraph, Table } from '@vwfs-bronson/bronson-react';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { DocumentBO, DocumentListBO, DocumentItemBO, formatAsDate, getDocumentEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from 'utils';
import { documentCentrePagePath } from 'components/navigation/paths';
import { withNoConnectionHandler } from '../integration-wrapper';
import { fetchDocumentList } from './DocumentListSlice';
import { selectDocumentListForSpecific } from './DocumentListSelector';

const manuallyTriggerBlobDownload = ({ fileName, contentBase64 }: DocumentBO): void => {
    const blob = new Blob([Buffer.from(contentBase64, 'base64')], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

const DocumentListUi: React.FC<{ encryptedContractId: string; documentList: DocumentListBO | undefined }> = ({
    encryptedContractId,
    documentList,
}) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const { t } = useTranslation('document-list');
    const { onAction: onDownloadSuccessAction } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onDownloadErrorAction } = useAnalyticsActionTracker('onPostboxDownloadError');

    if (documentList === undefined) {
        return null;
    }

    const { documents } = documentList;

    if (documents.length === 0) {
        return (
            <Paragraph className={'u-text-italic u-mb-none'} testId={'noDocumentsParagraph'}>
                {t('paragraphs.noDocumentsForContract')}
            </Paragraph>
        );
    }

    const buildOnClick = (encryptedDocumentId: string, description: string): (() => Promise<void>) => {
        return (): Promise<void> => {
            setIsDownloading(true);
            return CpDataApi.get<DocumentBO>(getDocumentEndpoint(encryptedContractId, encryptedDocumentId))
                .then(({ data }): void => {
                    manuallyTriggerBlobDownload(data);
                    setIsDownloading(false);
                    onDownloadSuccessAction(description);
                })
                .catch((error): void => {
                    setShowErrorModal(true);
                    setIsDownloading(false);
                    const errorT = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error);
                    const errorTracking = {
                        errorCode: errorT?.code || 'UNKNOWN',
                        errorMessage: errorT?.message || 'Unknown technical reason.',
                        errorCausingUrl: documentCentrePagePath(),
                    };
                    onDownloadErrorAction(description, errorTracking);
                });
        };
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
    };

    return (
        <Fragment>
            <Table className={'u-mt-small u-mb-none'} testId={'documentsTable'} noScroll>
                <Table.Thead testId={'tableHead'}>
                    <Table.Tr>
                        <Table.Th className={'u-hide@xs is-first-child'}>{t('tableHeadings.date')}</Table.Th>
                        <Table.Th className={'u-hide@xs'}>{t('tableHeadings.description')}</Table.Th>
                        <Table.Th className={'u-hide@xs is-last-child'}>{t('tableHeadings.download')}</Table.Th>
                        <Table.Th className={'u-hide@xs-up is-first-child is-last-child'}>
                            <span className={'u-block u-font-size-fs-2'}>{t('tableHeadings.date')}</span>
                            <span className={'u-block'}>{t('tableHeadings.description')}</span>
                        </Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody testId={'tableBody'}>
                    {documents.map(
                        ({ encryptedDocumentId, date, description }: DocumentItemBO, index: number): React.ReactNode => {
                            const downloadButton = (
                                <Button
                                    type={'button'}
                                    icon={'semantic-download'}
                                    title={t('buttons.download')}
                                    className={'c-table__btn'}
                                    testId={'downloadButton'}
                                    disabled={isDownloading}
                                    small
                                    secondary
                                    onClick={buildOnClick(encryptedDocumentId, description)}
                                />
                            );

                            return (
                                <Table.Tr key={index} data-testid={`document-${index + 1}`}>
                                    <Table.Td className={'u-hide@xs is-first-child'}>{formatAsDate(date)}</Table.Td>
                                    <Table.Td className={'u-hide@xs'}>{description}</Table.Td>
                                    <Table.Td className={'u-hide@xs is-last-child'}>{downloadButton}</Table.Td>
                                    <Table.Td className={'u-hide@xs-up is-first-child is-last-child'}>
                                        <span className={'u-block u-font-size-fs-2'}>{formatAsDate(date)}</span>
                                        <span className={'u-block'}>{description}</span>
                                        <span className={'u-block'}>{downloadButton}</span>
                                    </Table.Td>
                                </Table.Tr>
                            );
                        },
                    )}
                </Table.Tbody>
            </Table>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.text')}</Paragraph>
            </Modal>
        </Fragment>
    );
};

const SpinnerElseDocumentListUi: React.FC<{
    isLoading: boolean;
    encryptedContractId: string;
    documentList: DocumentListBO | undefined;
}> = ({ isLoading, encryptedContractId, documentList }) => {
    const { t } = useTranslation('document-list');

    return (
        <Fragment>
            {isLoading ? (
                <Fragment>
                    <Spinner center={true} />
                    <Paragraph
                        className={'u-text-center u-text-italic u-mt-xsmall u-mb-none'}
                        testId={'isLoadingParagraph'}
                    >
                        {t('paragraphs.isLoading')}
                    </Paragraph>
                </Fragment>
            ) : (
                <DocumentListUi encryptedContractId={encryptedContractId} documentList={documentList} />
            )}
        </Fragment>
    );
};

const DocumentListWithHandlers = withNoConnectionHandler(SpinnerElseDocumentListUi);

export const DocumentList: React.FC<{ encryptedContractId: string; contractNumber: string }> = ({
    encryptedContractId,
    contractNumber,
}) => {
    const { data: documentList, isLoading, loadingError } = useGetContractBasedApiData(
        contractNumber,
        fetchDocumentList,
        selectDocumentListForSpecific,
        encryptedContractId,
    );

    return (
        <DocumentListWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            documentList={documentList}
        />
    );
};
