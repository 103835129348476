import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-8/common-utilities';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    useAnalyticsPageViewTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    getRequestArrangementToPayEndpoint,
    IncomeAndExpenditure,
    MethodsOfPayment,
    nowCPDate,
    parseCPDate,
    parseCPDateFromInput,
    RequestArrangementToPay,
    Situation,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import 'extensions';
import { textAsHtml, textWithComponents, useBankDetails, buildLinkToPhoneNumber } from 'utils';
import { View } from 'components/view/View';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { RadioButtonProps, ValidatedRadioButtons } from 'components/validated-radio-buttons/ValidatedRadioButtons';
import { ValidatedSelect, ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';
import { BankDetailsForDirectDebit } from 'components/bank-details-for-direct-debit/BankDetailsForDirectDebit';
import { BankDetailsForBankTransfer } from 'components/bank-details-for-bank-transfer/BankDetailsForBankTransfer';
import { AlternativeNotification } from '../alternative-notification';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';
import { payFullArrearsWithinNMonths } from '../helpers';
import { Views } from '../types';
import {
    dateOfFirstPaymentDisabled,
    dateOfFirstPaymentIsMandatory,
    enteredAdditionalAmount,
    firstAdditionalAmount,
    initialValues,
    maxValidDate,
    minValidDate,
    remainingAdditionalAmount,
    showSinglePaymentBreakdownItem,
    valueAsNumber,
} from './helpers';
import { ArrangementToPayFormValues, NumbersOfMonths } from './types';
import { validationSchema } from './ArrangementToPayValidation';

type RequestAdditionalHelpArrangementToPayProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    contractNumber: string;
    registrationNumber: string;
    changeBankAccountInProgress: boolean;
    changePaymentDateInProgress: boolean;
    sortCode: string;
    accountNumber: string;
    lastBilledPaymentDate: CPDate | undefined;
    nextScheduledPaymentDate: CPDate | undefined;
    totalArrears: number;
    situation: Situation;
    incomeAndExpenditure: IncomeAndExpenditure | undefined;
    onBack: (previousView: Extract<Views, Views.situation | Views.incomeAndExpenditure>) => void;
};

export const ArrangementToPayView: React.FC<RequestAdditionalHelpArrangementToPayProps> = ({
    encryptedContractId,
    contractDescription,
    contractNumber,
    registrationNumber,
    changeBankAccountInProgress,
    changePaymentDateInProgress,
    sortCode,
    accountNumber,
    lastBilledPaymentDate,
    nextScheduledPaymentDate,
    totalArrears,
    situation,
    incomeAndExpenditure,
    onBack,
}) => {
    const { t } = useTranslation('request-additional-help-arrangement-to-pay-view');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const history = useHistory();
    const bankDetails = useBankDetails();
    const { onAction: onRequestAdditionalHelpSubmitSuccessAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitSuccess');
    const { onAction: onRequestAdditionalHelpSubmitFailedAction } = useAnalyticsActionTracker('onRequestAdditionalHelpSubmitFailed');
    const { onAction: onRequestAdditionalHelpBackAction } = useAnalyticsActionTracker('onRequestAdditionalHelpBack');
    useAnalyticsPageViewTracker('requestAdditionalHelpArrangementToPay');

    const pound = '£';

    const today = nowCPDate().toMoment().startOf('day');
    const inBillingPeriod = parseCPDate(lastBilledPaymentDate).toMoment().isSameOrAfter(today, 'day');
    const noRegularPayments = !nextScheduledPaymentDate;

    const directDebitDisabled = changeBankAccountInProgress || changePaymentDateInProgress || noRegularPayments;

    const numberOfMonthsSelectItems: ValidatedSelectItem[] = Object.entries(NumbersOfMonths)
        .slice(0, situation.relatedToCovid19 ? 12 : 3)
        .map(([, value]) => {
            return {
                label: t(`numberOfMonths.${value}`),
                value,
            };
        });

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    const notAvailableSuffix = directDebitDisabled ? t('methodOfPayment.directDebit.notAvailable') : '';

    const methodOfPaymentRadioButtons: RadioButtonProps[] = [
        {
            label: t('methodOfPayment.directDebit.label'),
            tooltip: textAsHtml(`${t('methodOfPayment.directDebit.tooltip')}${notAvailableSuffix}`),
            value: 'directDebit',
            disabled: directDebitDisabled,
        },
        {
            label: t('methodOfPayment.cardPayment.label'),
            tooltip: t('methodOfPayment.cardPayment.tooltip'),
            value: 'cardPayment',
        },
        {
            label: t('methodOfPayment.bankTransfer.label'),
            tooltip: t('methodOfPayment.bankTransfer.tooltip'),
            value: 'bankTransfer',
        },
    ];

    const payFullArrearsWithinNMonthsCached = !!payFullArrearsWithinNMonths(situation);

    const paymentBreakdownItemText = (count: number, additionalAmount: number): string => {
        return t('paymentBreakdown.item', {
            count,
            additionalAmount,
        });
    };

    const singlePaymentBreakdownItemText = (numberOfMonths: NumbersOfMonths): string => {
        return paymentBreakdownItemText(
            valueAsNumber(numberOfMonths),
            firstAdditionalAmount(numberOfMonths, totalArrears) ?? 0,
        );
    };

    const firstPaymentBreakdownItemText = (numberOfMonths: NumbersOfMonths): string => {
        return paymentBreakdownItemText(1, firstAdditionalAmount(numberOfMonths, totalArrears) ?? 0);
    };

    const remainingPaymentBreakdownItemText = (numberOfMonths: NumbersOfMonths): string => {
        return paymentBreakdownItemText(
            valueAsNumber(numberOfMonths) - 1,
            remainingAdditionalAmount(numberOfMonths, totalArrears) ?? 0,
        );
    };

    const resetDateOfFirstPayment = ({ setFieldValue, setFieldTouched }: FormikProps<ArrangementToPayFormValues>): void => {
        setFieldValue('dateOfFirstPayment', '', false);
        setFieldTouched('dateOfFirstPayment', false, false);
    };

    const onMethodOfPaymentChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<ArrangementToPayFormValues>,
    ): void => {
        if (newValue === 'directDebit') {
            resetDateOfFirstPayment(formik);
        }
    };

    const commonFragment = (): string => {
        return t('paragraphs.informationCommon', { totalArrears });
    };

    const mayContactYouFragment = (): string => {
        if (payFullArrearsWithinNMonthsCached) {
            return '';
        }

        return t('paragraphs.informationMayContactYou');
    };

    const specificFragment = (): string => {
        let key: string;
        let options: {} | undefined = undefined;
        if (changeBankAccountInProgress && changePaymentDateInProgress) {
            key = 'ChangeBankAccountAndPaymentDateInProgress';
        } else if (changeBankAccountInProgress) {
            key = 'ChangeBankAccountOnlyInProgress';
        } else if (changePaymentDateInProgress) {
            key = 'ChangePaymentDateOnlyInProgress';
        } else if (inBillingPeriod) {
            if (noRegularPayments) {
                key = 'InBillingPeriodNoRegularPayments';
            } else {
                key = 'InBillingPeriod';
                options = {
                    nextScheduledPaymentDate,
                };
            }
        } else {
            if (noRegularPayments) {
                key = 'NormalNoRegularPayments';
            } else {
                key = 'Normal';
                options = {
                    nextScheduledPaymentDate,
                };
            }
        }

        return t(`paragraphs.information${key}`, options);
    };

    const paragraphInformationContent = `${commonFragment()} ${mayContactYouFragment()} ${specificFragment()}`;

    const notificationActionText = (methodOfPayment: string): React.ReactNode => {
        let text: React.ReactNode;
        if (methodOfPayment === 'directDebit') {
            text = (
                <Fragment>
                    {textWithComponents(t, 'notifications.actionDirectDebit')}
                    <BankDetailsForDirectDebit
                        className={'c-description-list--notification u-mb-none'}
                        sortCode={sortCode}
                        accountNumber={accountNumber}
                    />
                </Fragment>
            );
        } else if (methodOfPayment === 'cardPayment') {
            text = textWithComponents(t, 'notifications.actionCardPayment', { linkToCollectionsPhoneNumber });
        } else if (methodOfPayment === 'bankTransfer') {
            text = (
                <Fragment>
                    {textWithComponents(t, 'notifications.actionBankTransfer.header')}
                    <BankDetailsForBankTransfer
                        className={'c-description-list--notification u-mb-small'}
                        {...bankDetails}
                        referenceNumber={contractNumber}
                    />
                    {textWithComponents(t, 'notifications.actionBankTransfer.footer')}
                </Fragment>
            );
        } else {
            text = t('unsupported.methodOfPayment', { methodOfPayment });
        }

        return text;
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
    };

    const onBackClick = (): void => {
        onRequestAdditionalHelpBackAction();
        onBack(
            payFullArrearsWithinNMonthsCached
                ? Views.situation
                : Views.incomeAndExpenditure,
        );
    };

    const buildRequestArrangementToPay = ({
        numberOfMonths,
        additionalAmount,
        methodOfPayment,
        dateOfFirstPayment,
    }: ArrangementToPayFormValues): RequestArrangementToPay => {
        const dateOfFirstPaymentAsCPDate =
            methodOfPayment === 'directDebit'
                ? nextScheduledPaymentDate ?? ''
                : parseCPDateFromInput(dateOfFirstPayment).toMoment().toUTC().toCPDate();
        const dateOfRemainingPaymentAsCPDate =
            !payFullArrearsWithinNMonthsCached || numberOfMonths === NumbersOfMonths.one
                ? undefined
                : parseCPDate(dateOfFirstPaymentAsCPDate).toMoment().utc().add(1, 'month').toCPDate();
        const numberOfMonthsForRequest = numberOfMonths ? valueAsNumber(numberOfMonths as NumbersOfMonths) : undefined;

        return {
            registrationNumber,
            totalArrears,
            methodOfPayment: methodOfPayment as MethodsOfPayment,
            dateOfFirstPayment: dateOfFirstPaymentAsCPDate,
            dateOfRemainingPayment: dateOfRemainingPaymentAsCPDate,
            frequency: 'monthly',
            months: numberOfMonthsForRequest,
            firstAdditionalAmount: firstAdditionalAmount(numberOfMonths as NumbersOfMonths, totalArrears),
            remainingAdditionalAmount: remainingAdditionalAmount(numberOfMonths as NumbersOfMonths, totalArrears),
            enteredAdditionalAmount: enteredAdditionalAmount(additionalAmount),
            incomeAndExpenditure,
            situation,
        };
    };

    return (
        <View testId={'arrangementToPayView'}>
            <SectionHeading level={2} testId={'viewHeader'}>{t('subHeading')}</SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'informationContent'}>{textAsHtml(paragraphInformationContent)}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, payFullArrearsWithinNMonthsCached, totalArrears)}
                onSubmit={(values: ArrangementToPayFormValues): Promise<void> => {
                    const requestArrangementToPay: RequestArrangementToPay = buildRequestArrangementToPay(values);
                    setIsSubmitting(true);
                    return CpDataApi.put<void>(getRequestArrangementToPayEndpoint(encryptedContractId), requestArrangementToPay)
                        .then(() => {
                            setShowSuccessModal(true);
                            setIsSubmitting(false);
                            onRequestAdditionalHelpSubmitSuccessAction();
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setIsSubmitting(false);
                            onRequestAdditionalHelpSubmitFailedAction();
                        });
                }}
                data-testid={'requestAdditionalHelpForm'}
            >
                {(formik: FormikProps<ArrangementToPayFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-arrangement-to-pay-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            {payFullArrearsWithinNMonthsCached ? (
                                <Fieldset.Row>
                                    <ValidatedSelect
                                        className={'select-number-of-months'}
                                        label={t('numberOfMonths.label')}
                                        tooltip={t('numberOfMonths.tooltip')}
                                        placeholder={t('numberOfMonths.placeholder')}
                                        selectItems={numberOfMonthsSelectItems}
                                        name={'numberOfMonths'}
                                        testId={'numberOfMonths'}
                                        isMandatory
                                    />
                                    <Paragraph className={'u-text-muted u-mt-xsmall u-mb-none'}>
                                        <span className={'u-block'}>{t('paymentBreakdown.label')}</span>
                                        {!!formik.values.numberOfMonths ? (
                                            <Fragment>
                                                {showSinglePaymentBreakdownItem(
                                                    formik.values.numberOfMonths as NumbersOfMonths,
                                                    totalArrears,
                                                ) ? (
                                                    <span className={'u-block'}>
                                                        {singlePaymentBreakdownItemText(
                                                            formik.values.numberOfMonths as NumbersOfMonths,
                                                        )}
                                                    </span>
                                                ) : (
                                                    <Fragment>
                                                        <span className={'u-block'}>
                                                            {firstPaymentBreakdownItemText(
                                                                formik.values.numberOfMonths as NumbersOfMonths,
                                                            )}
                                                        </span>
                                                        <span className={'u-block'}>
                                                            {remainingPaymentBreakdownItemText(
                                                                formik.values.numberOfMonths as NumbersOfMonths,
                                                            )}
                                                        </span>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span className={'u-block'}>{t('paymentBreakdown.noMonths')}</span>
                                        )}
                                    </Paragraph>
                                </Fieldset.Row>
                            ) : (
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [6],
                                            delimiter: '',
                                        }}
                                        className={'input-money'}
                                        label={t('additionalAmount.label')}
                                        tooltip={t('additionalAmount.tooltip')}
                                        name={'additionalAmount'}
                                        testId={'additionalAmount'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                    />
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ValidatedRadioButtons
                                    className={'u-indent u-mb-xsmall'}
                                    label={t('methodOfPayment.label')}
                                    radioButtons={methodOfPaymentRadioButtons}
                                    name={'methodOfPayment'}
                                    testId={'methodOfPayment'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onMethodOfPaymentChange(e, formik);
                                    }}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [2, 2, 4],
                                        delimiter: '/',
                                    }}
                                    className={'input-date'}
                                    label={t('dateOfFirstPayment.label')}
                                    tooltip={t('dateOfFirstPayment.tooltip', { minValidDate, maxValidDate })}
                                    placeholder={t('dateOfFirstPayment.placeholder')}
                                    name={'dateOfFirstPayment'}
                                    testId={'dateOfFirstPayment'}
                                    isMandatory={dateOfFirstPaymentIsMandatory(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                    disabled={dateOfFirstPaymentDisabled(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                />
                            </Fieldset.Row>
                            {!!formik.values.methodOfPayment && (
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        testId={'actionNotification'}
                                        text={' '}
                                    >
                                        {notificationActionText(formik.values.methodOfPayment as MethodsOfPayment)}
                                    </Notification>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'backButton'}
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        testId={'requestButton'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.dashboard')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'successModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{textWithComponents(t, 'errorModal.text', { linkToCollectionsPhoneNumber })}</Paragraph>
            </Modal>
        </View>
    );
};
