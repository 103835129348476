import React, { Fragment } from 'react';
import { DescriptionList } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { Nbsp } from 'components/nbsp';

export type DescriptionListHorizontalItemsProps = {
    label: React.ReactNode;
    tooltip?: React.ReactNode;
    value: React.ReactNode;
    uppercaseValue?: boolean;
    testId?: string;
};

export type DescriptionListHorizontalProps = {
    className?: string;
    items: DescriptionListHorizontalItemsProps[];
};

export const DescriptionListHorizontal: React.FC<DescriptionListHorizontalProps> = ({ className, items }) => {
    return (
        <DescriptionList className={className} horizontal>
            {items.map(({ label, tooltip, value, uppercaseValue, testId }, index) => {
                const term = (
                    <Fragment>
                        {label}
                        {tooltip && <InfoIcon text={tooltip} />}
                    </Fragment>
                );
                const detail = value || <Nbsp />;
                return (
                    <DescriptionTermAndDetail
                        key={index}
                        className={className}
                        testId={testId}
                        term={term}
                        detail={detail}
                        detailClassName={uppercaseValue ? 'u-text-uppercase' : undefined}
                    />
                );
            })}
        </DescriptionList>
    );
};
