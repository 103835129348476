import { CPDate } from '@cp-shared-8/common-utilities';
import { nowCPDate, parseCPDate } from '@cp-uk/common';

export const isTodayInDateRange = (startDate: CPDate | undefined, endDate: CPDate | undefined): boolean => {
    const todayMoment = nowCPDate().toMoment().startOf('day');
    const startDateMoment = parseCPDate(startDate).toMoment();
    const endDateMoment = parseCPDate(endDate).toMoment();

    return (
        startDateMoment.isValid() &&
        endDateMoment.isValid() &&
        startDateMoment.isSameOrBefore(todayMoment, 'day') &&
        endDateMoment.isSameOrAfter(todayMoment, 'day')
    );
};
