import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { CauseOfArrears, ContractDescriptionBO, Situation } from '@cp-uk/common';
import { textWithComponents } from 'utils';
import { View } from 'components/view/View';
import { linkToPrivacyPolicyPage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { RadioButtonProps, ValidatedRadioButtonGroup } from 'components/validated-radio-button-group/ValidatedRadioButtonGroup';
import { ValidatedTextarea } from 'components/validated-textarea/ValidatedTextarea';
import { AlternativeNotification } from '../alternative-notification';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';
import { Views } from '../types';
import { validationSchema } from './SituationValidation';
import { Buttons, CauseOfArrearsFormValues, SituationViewFormValues } from './types';
import {
    buttonsToBoolean,
    buttonsToBooleanOrUndefined,
    parseSituation,
    showContributeTowardsArrears,
    showEndAgreement,
    showPayFullArrearsWithin12Months,
    showPayFullArrearsWithin30Days,
    showPayFullArrearsWithin3Months,
} from './helpers';
import { payFullArrearsWithinNMonths } from '../helpers';

export type RequestAdditionalHelpSituationProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number;
    situation: Situation;
    onContinue: (nextStep: Exclude<Views, Views.situation>, situation: Situation) => void;
};

export const SituationView: React.FC<RequestAdditionalHelpSituationProps> = ({
    totalArrears,
    contractDescription,
    situation,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-situation-view');
    const history = useHistory();
    useAnalyticsPageViewTracker('requestAdditionalHelpSituation', !!situation);

    const noAndYesRadioButtons: RadioButtonProps[] = [
        { label: t(`noOrYesRadioButtons.${Buttons.no}`), value: Buttons.no },
        { label: t(`noOrYesRadioButtons.${Buttons.yes}`), value: Buttons.yes },
    ];

    const initialValues = parseSituation(situation);

    const resetVulnerableCustomerDetail = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<SituationViewFormValues>): void => {
        setFieldValue('vulnerableCustomerDetail', '', false);
        setFieldTouched('vulnerableCustomerDetail', false, false);
    };

    const resetEndAgreement = ({ setFieldValue, setFieldTouched }: FormikProps<SituationViewFormValues>): void => {
        setFieldValue('endAgreement', '', false);
        setFieldTouched('endAgreement', false, false);
    };

    const resetPayFullArrearsWithin30Days = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<SituationViewFormValues>): void => {
        setFieldValue('payFullArrearsWithin30Days', '', false);
        setFieldTouched('payFullArrearsWithin30Days', false, false);
    };

    const resetContributeTowardsArrears = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<SituationViewFormValues>): void => {
        setFieldValue('contributeTowardsArrears', '', false);
        setFieldTouched('contributeTowardsArrears', false, false);
    };

    const resetPayFullArrearsWithinNMonths = ({
        setFieldValue,
        setFieldTouched,
    }: FormikProps<SituationViewFormValues>): void => {
        setFieldValue('payFullArrearsWithin3Months', '', false);
        setFieldTouched('payFullArrearsWithin3Months', false, false);

        setFieldValue('payFullArrearsWithin12Months', '', false);
        setFieldTouched('payFullArrearsWithin12Months', false, false);
    };

    const onRelatedToCovid19Change = (formik: FormikProps<SituationViewFormValues>): void => {
        resetPayFullArrearsWithinNMonths(formik);
    };

    const onVulnerableCustomerChange = (formik: FormikProps<SituationViewFormValues>): void => {
        resetVulnerableCustomerDetail(formik);
    };

    const onContinueToPayRentalsChange = (formik: FormikProps<SituationViewFormValues>): void => {
        resetEndAgreement(formik);
        resetPayFullArrearsWithin30Days(formik);
        resetContributeTowardsArrears(formik);
        resetPayFullArrearsWithinNMonths(formik);
    };

    const onEndAgreementChange = (formik: FormikProps<SituationViewFormValues>): void => {
        resetContributeTowardsArrears(formik);
        resetPayFullArrearsWithinNMonths(formik);
    };

    const onPayFullArrearsWithin30DaysChange = (formik: FormikProps<SituationViewFormValues>): void => {
        resetContributeTowardsArrears(formik);
        resetPayFullArrearsWithinNMonths(formik);
    };

    const onContributeTowardsArrearsChange = (formik: FormikProps<SituationViewFormValues>): void => {
        resetPayFullArrearsWithinNMonths(formik);
    };

    const buildCauseOfArrearsForCallback = (
        causeOfArrears: CauseOfArrearsFormValues,
        causeOfArrearsMoreDetail: string,
    ): CauseOfArrears => {
        return {
            reducedIncome: causeOfArrears.reducedIncome,
            unemployment: causeOfArrears.unemployment,
            healthCondition: causeOfArrears.healthCondition,
            oversightOrMismanagementOfFinances: causeOfArrears.oversightOrMismanagementOfFinances,
            behindWithOtherCreditors: causeOfArrears.behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy: causeOfArrears.inAnIvaOrFilingForBankruptcy,
            other: causeOfArrears.other,
            moreDetail: causeOfArrearsMoreDetail,
        };
    };

    const buildSituationForCallback = (values: SituationViewFormValues): Situation => {
        return {
            causeOfArrears: buildCauseOfArrearsForCallback(values.causeOfArrears, values.causeOfArrearsMoreDetail),
            consentInformationProvided: values.consentInformationProvided,
            relatedToCovid19: buttonsToBoolean(values.relatedToCovid19),
            vulnerableCustomer: buttonsToBoolean(values.vulnerableCustomer),
            vulnerableCustomerDetail: values.vulnerableCustomerDetail,
            continueToPayRentals: buttonsToBoolean(values.continueToPayRentals),
            endAgreement: buttonsToBooleanOrUndefined(values.endAgreement),
            payFullArrearsWithin30Days: buttonsToBooleanOrUndefined(values.payFullArrearsWithin30Days),
            contributeTowardsArrears: buttonsToBooleanOrUndefined(values.contributeTowardsArrears),
            payFullArrearsWithin3Months: buttonsToBooleanOrUndefined(values.payFullArrearsWithin3Months),
            payFullArrearsWithin12Months: buttonsToBooleanOrUndefined(values.payFullArrearsWithin12Months),
        };
    };

    return (
        <View testId={'situationView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values): void => {
                    const situationForCallback = buildSituationForCallback(values);

                    if (situationForCallback.contributeTowardsArrears === false) {
                        onContinue(Views.breathingSpace, situationForCallback);
                    } else if (
                        situationForCallback.contributeTowardsArrears === true &&
                        payFullArrearsWithinNMonths(situationForCallback) === false
                    ) {
                        onContinue(Views.incomeAndExpenditure, situationForCallback);
                    } else if (
                        situationForCallback.contributeTowardsArrears === true &&
                        payFullArrearsWithinNMonths(situationForCallback) === true
                    ) {
                        onContinue(Views.arrangementToPay, situationForCallback);
                    } else if (situationForCallback.payFullArrearsWithin30Days === true) {
                        onContinue(Views.promiseToPay, situationForCallback);
                    } else if (situationForCallback.endAgreement === true) {
                        onContinue(Views.endAgreement, situationForCallback);
                    } else {
                        // NOTE: Leave this in as testing is done for each of the five other "action" views:
                        // eslint-disable-next-line no-alert
                        alert('Unexpected state for onSubmit() handler!');
                    }
                }}
            >
                {(formik: FormikProps<SituationViewFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-situation-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup
                                    className={'u-indent'}
                                    label={t('causeOfArrears.label')}
                                    secondaryLabel={t('causeOfArrears.secondaryLabel')}
                                    name={'causeOfArrears'}
                                    testId={'causeOfArrears'}
                                    isMandatory={true}
                                >
                                    {Object.keys(formik.values.causeOfArrears).map((key) => (
                                        <ValidatedCheckboxGroup.Checkbox
                                            key={key}
                                            className={'u-mb-xsmall'}
                                            label={t(`causeOfArrears.${key}.label`)}
                                            tooltip={t(`causeOfArrears.${key}.tooltip`)}
                                            groupName={'causeOfArrears'}
                                            name={`causeOfArrears.${key}`}
                                            testId={`causeOfArrears.${key}`}
                                        />
                                    ))}
                                </ValidatedCheckboxGroup>
                                <ValidatedTextarea
                                    className={'u-indent'}
                                    label={t('causeOfArrearsMoreDetail.label')}
                                    tooltip={t('causeOfArrearsMoreDetail.tooltip')}
                                    name={'causeOfArrearsMoreDetail'}
                                    testId={'causeOfArrearsMoreDetail'}
                                    isMandatory={formik.values.causeOfArrears.other}
                                    rows={4}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={t('consentInformationProvided.label')}
                                    name={'consentInformationProvided'}
                                    testId={'consentInformationProvided'}
                                    isMandatory={true}
                                />
                                <Paragraph className={'u-text-muted u-indent u-mt-xsmall u-mb-none'}>
                                    {textWithComponents(t, 'consentInformationProvided.description', { linkToPrivacyPolicyPage })}
                                </Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    label={t('relatedToCovid19.label')}
                                    tooltip={t('relatedToCovid19.tooltip')}
                                    radioButtons={noAndYesRadioButtons}
                                    name={'relatedToCovid19'}
                                    testId={'relatedToCovid19'}
                                    isMandatory={true}
                                    handleChange={(): void => onRelatedToCovid19Change(formik)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    className={'u-mb-xsmall'}
                                    label={t('vulnerableCustomer.label')}
                                    tooltip={t('vulnerableCustomer.tooltip')}
                                    radioButtons={noAndYesRadioButtons}
                                    name={'vulnerableCustomer'}
                                    testId={'vulnerableCustomer'}
                                    isMandatory={true}
                                    handleChange={(): void => onVulnerableCustomerChange(formik)}
                                />
                                <ValidatedTextarea
                                    className={'u-indent'}
                                    label={t('vulnerableCustomerDetail.label')}
                                    name={'vulnerableCustomerDetail'}
                                    testId={'vulnerableCustomerDetail'}
                                    isMandatory={formik.values.vulnerableCustomer === Buttons.yes}
                                    disabled={formik.values.vulnerableCustomer !== Buttons.yes}
                                    rows={4}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtonGroup
                                    label={t('continueToPayRentals.label')}
                                    tooltip={t('continueToPayRentals.tooltip')}
                                    radioButtons={noAndYesRadioButtons}
                                    name={'continueToPayRentals'}
                                    testId={'continueToPayRentals'}
                                    isMandatory={true}
                                    handleChange={(): void => onContinueToPayRentalsChange(formik)}
                                />
                            </Fieldset.Row>
                            {showEndAgreement(formik.values.continueToPayRentals) && (
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        label={t('endAgreement.label')}
                                        tooltip={t('endAgreement.tooltip')}
                                        radioButtons={noAndYesRadioButtons}
                                        name={'endAgreement'}
                                        testId={'endAgreement'}
                                        isMandatory={true}
                                        handleChange={(): void => onEndAgreementChange(formik)}
                                    />
                                </Fieldset.Row>
                            )}
                            {showPayFullArrearsWithin30Days(formik.values.continueToPayRentals) && (
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        label={t('payFullArrearsWithin30Days.label')}
                                        tooltip={t('payFullArrearsWithin30Days.tooltip')}
                                        radioButtons={noAndYesRadioButtons}
                                        name={'payFullArrearsWithin30Days'}
                                        testId={'payFullArrearsWithin30Days'}
                                        isMandatory={true}
                                        handleChange={(): void => onPayFullArrearsWithin30DaysChange(formik)}
                                    />
                                </Fieldset.Row>
                            )}
                            {showContributeTowardsArrears(
                                formik.values.endAgreement,
                                formik.values.payFullArrearsWithin30Days,
                            ) && (
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        label={t('contributeTowardsArrears.label')}
                                        tooltip={t('contributeTowardsArrears.tooltip')}
                                        radioButtons={noAndYesRadioButtons}
                                        name={'contributeTowardsArrears'}
                                        testId={'contributeTowardsArrears'}
                                        isMandatory={true}
                                        handleChange={(): void => onContributeTowardsArrearsChange(formik)}
                                    />
                                </Fieldset.Row>
                            )}
                            {showPayFullArrearsWithin3Months(
                                formik.values.relatedToCovid19,
                                formik.values.contributeTowardsArrears,
                            ) && (
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        label={t('payFullArrearsWithin3Months.label')}
                                        tooltip={t('payFullArrearsWithin3Months.tooltip')}
                                        radioButtons={noAndYesRadioButtons}
                                        name={'payFullArrearsWithin3Months'}
                                        testId={'payFullArrearsWithin3Months'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                            )}
                            {showPayFullArrearsWithin12Months(
                                formik.values.relatedToCovid19,
                                formik.values.contributeTowardsArrears,
                            ) && (
                                <Fieldset.Row>
                                    <ValidatedRadioButtonGroup
                                        label={t('payFullArrearsWithin12Months.label')}
                                        tooltip={t('payFullArrearsWithin12Months.tooltip')}
                                        radioButtons={noAndYesRadioButtons}
                                        name={'payFullArrearsWithin12Months'}
                                        testId={'payFullArrearsWithin12Months'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'returnButton'}
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
