import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { GreetingBO, getGreetingEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<GreetingBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'greeting',
    fetchCallback() {
        return CpDataApi.get<GreetingBO>(getGreetingEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchGreeting = fetchData;
