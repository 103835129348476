import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { StartMatchmaker } from 'components/start-matchmaker/StartMatchmaker';

export const StartMatchmakerPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.StartMatchmaker);
    const { t } = useTranslation('page-titles');
    const { encryptedContractId } = useParams<{ encryptedContractId: string }>();

    return (
        <ContentSection className={'o-content-section--compact uk-start-matchmaker-page'} pageWrapSize={'small'}>
            <PageHeader title={t('start-matchmaker')} />
            <StartMatchmaker encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
