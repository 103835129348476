import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isInputNumberInRange, isInputNumberInteger } from 'utils';
import { AvailableDaysFormValues, AvailableTimes, RefinanceMonths, SolutionsRefinanceViewFormValues } from './types';

export const minValidDepositAmount = 0;
export const maxValidDepositAmount = 99999;
export const minValidMonthlyBudget = 50;
export const maxValidMonthlyBudget = 10000;

const refinanceMonthsValidValues: string[] = [
    RefinanceMonths.twelve,
    RefinanceMonths.twentyFour,
    RefinanceMonths.thirtySix,
    RefinanceMonths.fortyEight,
];

const earliestAvailableTimeValidValues: string[] = [
    AvailableTimes.noPreferredEarliest,
    AvailableTimes.nineAm,
    AvailableTimes.tenAm,
    AvailableTimes.elevenAm,
    AvailableTimes.twelvePm,
    AvailableTimes.onePm,
    AvailableTimes.twoPm,
    AvailableTimes.threePm,
    AvailableTimes.fourPm,
    AvailableTimes.fivePm,
];

const latestAvailableTimeValidValues: string[] = [
    AvailableTimes.tenAm,
    AvailableTimes.elevenAm,
    AvailableTimes.twelvePm,
    AvailableTimes.onePm,
    AvailableTimes.twoPm,
    AvailableTimes.threePm,
    AvailableTimes.fourPm,
    AvailableTimes.fivePm,
    AvailableTimes.sixPm,
    AvailableTimes.noPreferredLatest,
];

export const availableTimesToNumber = (refinanceMonths: string): number => {
    return Number(refinanceMonths.replace('H', ''));
};

export const validationSchema = (
    t: TFunction,
    saturdayClosingTime: AvailableTimes,
): Yup.ObjectSchema<SolutionsRefinanceViewFormValues> => {
    return Yup.object()
        .shape<SolutionsRefinanceViewFormValues>({
            confirmImportantThings: Yup.boolean()
                .required(t('confirmImportantThings.validation.required'))
                .oneOf([true], t('confirmImportantThings.validation.mustConfirm')),
            phoneNumber: Yup.string().required(t('phoneNumber.validation.required')),
            refinanceMonths: Yup.string()
                .required(t('refinanceMonths.validation.required'))
                .oneOf(refinanceMonthsValidValues, t('refinanceMonths.validation.required')),
            depositAmount: Yup.string()
                .required(t('depositAmount.validation.required'))
                .test('integer', t('depositAmount.validation.integer'), (value) => isInputNumberInteger(value))
                .test(
                    'range',
                    t('depositAmount.validation.range', { minValidDepositAmount, maxValidDepositAmount }),
                    (value) => isInputNumberInRange(value, minValidDepositAmount, maxValidDepositAmount),
                ),
            monthlyBudget: Yup.string()
                .required(t('monthlyBudget.validation.required'))
                .test('integer', t('monthlyBudget.validation.integer'), (value) => isInputNumberInteger(value))
                .test(
                    'range',
                    t('monthlyBudget.validation.range', { minValidMonthlyBudget, maxValidMonthlyBudget }),
                    (value) => isInputNumberInRange(value, minValidMonthlyBudget, maxValidMonthlyBudget),
                ),
            earliestAvailableTime: Yup.string()
                .required(t('earliestAvailableTime.validation.required'))
                .oneOf(earliestAvailableTimeValidValues, t('earliestAvailableTime.validation.valid')),
            latestAvailableTime: Yup.string()
                .required(t('latestAvailableTime.validation.required'))
                .oneOf(latestAvailableTimeValidValues, t('latestAvailableTime.validation.valid'))
                .when('earliestAvailableTime', (earliestAvailableTime: string, schema: Yup.StringSchema<string>) => {
                    if (!earliestAvailableTime || earliestAvailableTime === AvailableTimes.noPreferredEarliest) {
                        return schema;
                    }
                    return schema
                        .test('matchesEarliest', t('latestAvailableTime.validation.matchesEarliest'), (value) => {
                            return (
                                !value ||
                                value === 'noPreferredLatest' ||
                                availableTimesToNumber(value) !== availableTimesToNumber(earliestAvailableTime)
                            );
                        })
                        .test('beforeEarliest', t('latestAvailableTime.validation.beforeEarliest'), (value) => {
                            return (
                                !value ||
                                value === 'noPreferredLatest' ||
                                availableTimesToNumber(value) > availableTimesToNumber(earliestAvailableTime)
                            );
                        });
                }),
            availableDays: Yup.object()
                .required()
                .shape<AvailableDaysFormValues>({
                    monday: Yup.boolean(),
                    tuesday: Yup.boolean(),
                    wednesday: Yup.boolean(),
                    thursday: Yup.boolean(),
                    friday: Yup.boolean(),
                    saturday: Yup.boolean(),
                })
                .when('earliestAvailableTime', (earliestAvailableTime: string, schema: Yup.StringSchema<string>) => {
                    if (earliestAvailableTime === AvailableTimes.noPreferredEarliest) {
                        return schema;
                    }
                    return schema.test(
                        'outsideSaturdayOpening',
                        t('availableDays.validation.outsideSaturdayOpening'),
                        (value) => {
                            const days = (value as unknown) as AvailableDaysFormValues;
                            const onlySaturday =
                                days.saturday &&
                                !(days.monday || days.tuesday || days.wednesday || days.thursday || days.friday);
                            const hoursAfterSaturdayOpening =
                                availableTimesToNumber(earliestAvailableTime) >=
                                availableTimesToNumber(saturdayClosingTime);
                            return !(onlySaturday && hoursAfterSaturdayOpening);
                        },
                    );
                }),
        })
        .required();
};
