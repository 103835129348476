import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { InsuranceFormValues } from './types';

export const validationSchema = (
    t: TFunction,
    validMonthAndYearValues: string[],
): Yup.ObjectSchema<InsuranceFormValues> => {
    return Yup.object()
        .shape<InsuranceFormValues>({
            renewalMonthAndYear: Yup.string()
                .required(t('renewalMonthAndYear.validation.required'))
                .oneOf(validMonthAndYearValues, t('renewalMonthAndYear.validation.required')),
            consent: Yup.boolean()
                .required(t('consent.validation.required'))
                .oneOf([true], t('consent.validation.mustConfirm')),
        })
        .required();
};
