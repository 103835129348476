import * as moment from 'moment-timezone';
import { CPDate } from '@cp-shared-8/common-utilities';
import { isoDateTimeFormat, UTC_TIMEZONE } from '@cp-uk/common';

declare module 'moment-timezone' {
    interface Moment {
        toUTC(): moment.Moment;
        toCPDate(): CPDate;
    }
}

moment.fn.toUTC = function (): moment.Moment {
    return this.tz(UTC_TIMEZONE, true);
};
moment.fn.toCPDate = function (): CPDate {
    return this.format(isoDateTimeFormat);
};
