export enum Views {
    solutionsStart = 'solutionsStart',
    solutionsExchange = 'solutionsExchange',
    solutionsKeep = 'solutionsKeep',
    solutionsRefinance = 'solutionsRefinance',
    solutionsHandBack = 'solutionsHandBack',
    solutionsSell = 'solutionsSell',
    contractHireStart = 'contractHireStart',
    contractHireNew = 'contractHireNew',
    contractHireCallBack = 'contractHireCallBack',
    contractHireExtend = 'contractHireExtend',
    contractHireExtension = 'contractHireExtension',
    contractHireReturn = 'contractHireReturn',
}

export type CallingViews = Extract<
    Views,
    Views.contractHireNew | Views.contractHireExtend | Views.contractHireExtension | Views.contractHireReturn
>;
