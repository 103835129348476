import { ProductCategories } from '../apis';

export const isProductCategory = (
    productCategory: ProductCategories | string | undefined,
    requiredProductCategories: ProductCategories | ProductCategories[],
): boolean => {
    if (Array.isArray(requiredProductCategories)) {
        return requiredProductCategories.some(
            (requiredProductCategory: ProductCategories) => productCategory === requiredProductCategory,
        );
    }

    return productCategory === requiredProductCategories;
};
