import { RefObject, useEffect, useRef, useState } from 'react';

const elementYPosition = (element: HTMLElement): number => {
    let loopElement: HTMLElement | undefined = element;
    let yPosition = 0;
    do {
        yPosition += loopElement.offsetTop;
        loopElement = loopElement.offsetParent as HTMLElement;
    } while (loopElement);
    return yPosition;
};

const scrollToElement = (element: HTMLElement): void => {
    const yPosition = elementYPosition(element);
    window.scrollTo(0, yPosition);
};

export const useScrollTo = (currentView?: string, initialView?: string): RefObject<HTMLElement> => {
    const scrollToRef = useRef<HTMLElement>(null);
    const [previousView, setPreviousView] = useState<string | undefined>(initialView);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined = undefined;

        if (scrollToRef && scrollToRef.current && currentView !== previousView) {
            const element: HTMLElement = scrollToRef.current;
            timeout = setTimeout(() => {
                scrollToElement(element);

                setPreviousView(currentView);
            }, 250);
        }

        return (): void => {
            if (timeout !== undefined) {
                clearTimeout(timeout);
            }
        };
    }, [scrollToRef, currentView, previousView]);

    return scrollToRef;
};
