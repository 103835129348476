import React from 'react';
import { useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { SimplePageError } from 'components/simple-page-error/SimplePageError';

export const NotFoundPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.NotFound);
    useAnalyticsErrorPageTracker('notFoundError');

    return <SimplePageError namespace={'not-found'} />;
};
