import React from 'react';
import { Heading } from '@vwfs-bronson/bronson-react';

export type PageHeaderProps = {
    title: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
    return (
        <Heading level={1} testId={'pageHeader'}>
            {title}
        </Heading>
    );
};
