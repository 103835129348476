import { useEffect } from 'react';
import {
    AnalyticsAction,
    AnalyticsPageView,
    getAnalyticsActionEndpoint,
    getAnalyticsPageViewEndpoint,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const deliberatelyDoNothing = (): void => {};

export const useAnalyticsPageViewTracker = (trackingId: string, dataLoaded = true, additionalValue?: string): void => {
    useEffect(() => {
        if (dataLoaded) {
            const pageView: AnalyticsPageView = {
                trackingId,
                additionalValue,
            };

            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('[useAnalyticsPageViewTracker] Payload...', pageView);
            } else {
                CpDataApi.post<void>(getAnalyticsPageViewEndpoint(), pageView).finally(deliberatelyDoNothing);
            }
        }
    }, [dataLoaded, trackingId, additionalValue]);
};

export type AnalyticsActionTracker = {
    onAction: (additionalValue?: string, secondAdditionalValue?: string) => void;
};

export const useAnalyticsActionTracker = (actionId: string): AnalyticsActionTracker => {
    const onAction = (additionalValue?: string, secondAdditionalValue?: string): void => {
        const analyticsAction: AnalyticsAction = {
            actionId,
            additionalValue,
            secondAdditionalValue,
        };

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('[useAnalyticsActionTracker] Payload...', analyticsAction);
        } else {
            CpDataApi.post<void>(getAnalyticsActionEndpoint(), analyticsAction).finally(deliberatelyDoNothing);
        }
    };

    return {
        onAction,
    };
};
