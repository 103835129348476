import { formatAsCurrency, MethodsOfPayment, nowCPDate } from '@cp-uk/common';
import { ArrangementToPayFormValues, NumbersOfMonths } from './types';

const moneyDecimalPlaces = 2;

const todayMoment = nowCPDate().toMoment().startOf('day');
export const minValidDate = todayMoment.clone().add(1, 'day');
export const maxValidDate = todayMoment.clone().add(1, 'month');

export const initialValues: ArrangementToPayFormValues = {
    numberOfMonths: '',
    additionalAmount: '',
    methodOfPayment: '',
    dateOfFirstPayment: '',
};

const round = (value: number, decimalPlaces: number): number => {
    return Number(`${Math.round(Number(`${value}e${decimalPlaces}`))}e-${decimalPlaces}`);
};

export const valueAsNumber = (numberOfMonths: NumbersOfMonths): number => {
    return Number(numberOfMonths.replace('M', ''));
};

export const dateOfFirstPaymentIsMandatory = (methodOfPayment: MethodsOfPayment): boolean => {
    return methodOfPayment === 'cardPayment' || methodOfPayment === 'bankTransfer';
};

export const dateOfFirstPaymentDisabled = (methodOfPayment: MethodsOfPayment): boolean => {
    return !dateOfFirstPaymentIsMandatory(methodOfPayment);
};

export const remainingAdditionalAmount = (numberOfMonths: NumbersOfMonths, totalArrears: number): number | undefined => {
    if (!numberOfMonths) {
        return undefined;
    }

    if (numberOfMonths === NumbersOfMonths.one) {
        return 0;
    }

    return round(totalArrears / valueAsNumber(numberOfMonths), moneyDecimalPlaces);
};

export const firstAdditionalAmount = (numberOfMonths: NumbersOfMonths, totalArrears: number): number | undefined => {
    if (!numberOfMonths) {
        return undefined;
    }

    if (numberOfMonths === NumbersOfMonths.one) {
        return totalArrears;
    }

    return (
        totalArrears -
        (valueAsNumber(numberOfMonths) - 1) * (remainingAdditionalAmount(numberOfMonths, totalArrears) ?? 0)
    );
};

export const enteredAdditionalAmount = (additionalAmount: string): number | undefined => {
    if (!additionalAmount) {
        return undefined;
    }

    return Number(additionalAmount);
};

export const amountsAreEqual = (firstAmount: number, secondAmount: number): boolean => {
    return formatAsCurrency(firstAmount) === formatAsCurrency(secondAmount);
};

export const showSinglePaymentBreakdownItem = (numberOfMonths: NumbersOfMonths, totalArrears: number): boolean => {
    return (
        numberOfMonths === NumbersOfMonths.one ||
        amountsAreEqual(
            firstAdditionalAmount(numberOfMonths, totalArrears) ?? 0,
            remainingAdditionalAmount(numberOfMonths, totalArrears) ?? 0,
        )
    );
};
