import React from 'react';
import { LegalFooter as LegalFooterShared } from '@cp-shared-8/frontend-ui';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { LegalFooterDto } from '@cp-uk/common';
import { textAsHtml } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchLegalFooter } from './LegalFooterSlice';
import { selectLegalFooter } from './LegalFooterSelector';

const LegalFooterUi: React.FC<{ legalFooter: LegalFooterDto | undefined }> = ({ legalFooter }) => {
    if (legalFooter === undefined) {
        return null;
    }

    const { copyrightText, legalText, links, footnotes } = legalFooter;

    return (
        <LegalFooterShared
            copyrightText={copyrightText}
            legalText={textAsHtml(legalText)}
            links={links}
            footnotes={footnotes}
        />
    );
};

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useCmsContent(fetchLegalFooter, selectLegalFooter);

    return <LegalFooterWithHandlers isLoading={isLoading} hasError={!!loadingError} legalFooter={legalFooter} />;
};
