import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionHeading, Button, ButtonContainer, Paragraph } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO } from '@cp-uk/common';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { linkToDocumentCentrePage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { View } from 'components/view/View';
import { analyticsDiscriminator } from '../constants';

export const RightOfWithdrawalView: React.FC<{
    contractDescription: ContractDescriptionBO;
}> = ({ contractDescription }) => {
    const { t } = useTranslation('early-settlement-right-of-withdrawal-view');
    const history = useHistory();

    const linkToRightOfWithdrawalPhoneNumber = buildLinkToPhoneNumber(t, 'rightOfWithdrawal');

    // TODO: Analytics...
    // useAnalyticsPageViewTracker('earlySettlementRightOfWithdrawal', true, analyticsDiscriminator);
    console.log(
        `[RightOfWithdrawalView] useAnalyticsPageViewTracker('earlySettlementRightOfWithdrawal', true, ${analyticsDiscriminator})...`,
    );

    return (
        <View testId={'rightOfWithdrawalView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'stillEarlyDaysTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.stillEarlyDays.title')}
            </Paragraph>
            <Paragraph testId={'stillEarlyDaysDetailsParagraph'}>
                {textWithComponents(t, 'paragraphs.stillEarlyDays.details', { linkToRightOfWithdrawalPhoneNumber })}
            </Paragraph>
            <Paragraph testId={'feelingCuriousTitleParagraph'} className={'u-text-bold u-mt'}>
                {t('paragraphs.feelingCurious.title')}
            </Paragraph>
            <Paragraph testId={'feelingCuriousDetailsParagraph'}>
                {textWithComponents(t, 'paragraphs.feelingCurious.details', { linkToDocumentCentrePage })}
            </Paragraph>
            <ButtonContainer nav>
                <Button
                    testId={'returnButton'}
                    type={'button'}
                    onClick={(): void => history.push(dashboardPagePath())}
                    secondary
                >
                    {t('translation:editableSectionNav.return')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
