import { getValueFromEnumKey } from 'utils';
import {
    ConsiderEndingProductCategories,
    ConsiderEndingProducts,
    ContinueOptions,
    ReasonCategories,
    Reasons,
} from './types';

export const reasonValueFromName = (nameInKebabCase: string | undefined): Reasons => {
    const value = getValueFromEnumKey(Reasons, nameInKebabCase);

    return ((value ?? '') as unknown) as Reasons;
};

export const reasonCategory = (reason: Reasons): ReasonCategories | undefined => {
    switch (reason) {
        case Reasons.makeAPayment:
        case Reasons.insolvencyBankruptcy:
        case Reasons.specialCircumstances:
        case Reasons.optionsToEndMyAgreement:
        case Reasons.voluntaryTermination:
        case Reasons.repossession:
            return ReasonCategories.other;
        case Reasons.financialDifficulty:
            return ReasonCategories.financialDifficulty;
        default:
            return undefined;
    }
};

export const considerEndingProductCategory = (
    considerEndingProduct: ConsiderEndingProducts,
): ConsiderEndingProductCategories | undefined => {
    switch (considerEndingProduct) {
        case ConsiderEndingProducts.solutions:
        case ConsiderEndingProducts.hirePurchase:
        case ConsiderEndingProducts.leasePurchase:
            return ConsiderEndingProductCategories.purchase;
        case ConsiderEndingProducts.contractHire:
            return ConsiderEndingProductCategories.hire;
        case ConsiderEndingProducts.financeLease:
            return ConsiderEndingProductCategories.lease;
        default:
            return undefined;
    }
};

export const showOtherFields = (reason: Reasons): boolean => {
    return reasonCategory(reason) === ReasonCategories.other;
};

export const showFinancialDifficultyFields = (reason: Reasons): boolean => {
    return reasonCategory(reason) === ReasonCategories.financialDifficulty;
};

export const showAnyFields = (reason: Reasons): boolean => {
    return showOtherFields(reason) || showFinancialDifficultyFields(reason);
};

export const showConsiderEndingProductFields = (continueOption: ContinueOptions): boolean => {
    return continueOption === ContinueOptions.comeOutOfAgreement;
};

export const showConsiderEndingProductPurchaseFields = (considerEndingProduct: ConsiderEndingProducts): boolean => {
    return considerEndingProductCategory(considerEndingProduct) === ConsiderEndingProductCategories.purchase;
};

export const showConsiderEndingProductHireFields = (considerEndingProduct: ConsiderEndingProducts): boolean => {
    return considerEndingProductCategory(considerEndingProduct) === ConsiderEndingProductCategories.hire;
};

export const showConsiderEndingProductLeaseFields = (considerEndingProduct: ConsiderEndingProducts): boolean => {
    return considerEndingProductCategory(considerEndingProduct) === ConsiderEndingProductCategories.lease;
};
