import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ContractHireStartViewFormValues } from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<ContractHireStartViewFormValues> => {
    const optionValidValues = ['new', 'extend', 'return'];

    return Yup.object()
        .shape<ContractHireStartViewFormValues>({
            option: Yup.string()
                .default('')
                .required(t('options.validation.required'))
                .oneOf(optionValidValues, t('options.validation.required')),
        })
        .required();
};
