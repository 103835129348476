import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { FinancialSupportAndWellbeingContent } from 'components/financial-support-and-wellbeing/FinancialSupportAndWellbeingContent';

export const FinancialSupportAndWellbeingContentPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.FinancialSupportAndWellbeing);
    const { t } = useTranslation('page-titles');

    const { contentId } = useParams<{ contentId: string }>();

    return (
        <ContentSection
            className={'o-content-section--compact uk-financial-support-and-wellbeing-content-page'}
            pageWrapSize={'small'}
        >
            <PageHeader title={t('financial-support-and-wellbeing')} />
            <FinancialSupportAndWellbeingContent contentId={contentId} />
        </ContentSection>
    );
};
