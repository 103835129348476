import { SimplePageHeroTeaserDto, getCookiePolicyPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'cookiePolicyPageHeroTeaser',
    contentEndpoint: getCookiePolicyPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchCookiePolicyPageHeroTeaser = fetchContent;
