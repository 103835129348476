import * as Yup from 'yup';
import { TFunction } from 'i18next';

// NOTE For compatibility with "old tech stack", these following have been "borrowed" from vuelidate's 'email' and 'numeric' validators
const emailAddressRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
const phoneNumberRegex = /^(|0[1-9][0-9]{8,9})$/;

export const stripPhoneNumber = (phoneNumber: string | null | undefined): string => {
    return phoneNumber ? phoneNumber.replace(/ /g, '') : '';
};

const isEmailAddressValid = (emailAddress: string | null | undefined): boolean => {
    return (emailAddress?.trim()?.length ?? 0) <= 200 && emailAddressRegex.test(emailAddress ?? '');
};

const isPhoneNumberInternational = (phoneNumber: string | null | undefined): boolean => {
    return !!phoneNumber && (phoneNumber.startsWith('+') || phoneNumber.startsWith('00'));
};

const spacesCount = (phoneNumber: string | null | undefined): number => {
    return (phoneNumber?.match(/ /g) || []).length;
};

const isPhoneNumberValid = (phoneNumber: string | null | undefined): boolean => {
    return (phoneNumber?.trim()?.length ?? 0) <= 20 && phoneNumberRegex.test(stripPhoneNumber(phoneNumber)) && spacesCount(phoneNumber) <= 3;
};

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        emailAddress: Yup.string()
            .required(t('emailAddress.validation.required'))
            .test('format', t('emailAddress.validation.format'), (emailAddress) => isEmailAddressValid(emailAddress)),
        mobileNumber: Yup.string()
            .required(t('mobileNumber.validation.required'))
            .test('notInternational', t('phoneNumbers.validation.notInternational'),
                (phoneNumber) => !isPhoneNumberInternational(phoneNumber),
            )
            .test('format', t('phoneNumbers.validation.format'), (phoneNumber) => isPhoneNumberValid(phoneNumber)),
        homePhoneNumber: Yup.string()
            .optional()
            .test('notInternational', t('phoneNumbers.validation.notInternational'),
                (phoneNumber) => !isPhoneNumberInternational(phoneNumber),
            )
            .test('format', t('phoneNumbers.validation.format'), (phoneNumber) => isPhoneNumberValid(phoneNumber)),
        workPhoneNumber: Yup.string()
            .optional()
            .test('notInternational', t('phoneNumbers.validation.notInternational'),
                (phoneNumber) => !isPhoneNumberInternational(phoneNumber),
            )
            .test('format', t('phoneNumbers.validation.format'), (phoneNumber) => isPhoneNumberValid(phoneNumber)),
    });
};
