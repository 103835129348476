import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Heading } from '@vwfs-bronson/bronson-react';
import { DataOverview } from 'components/data-overview/DataOverview';

export type ChangeDataProps = {
    currentView?: string;
    className?: string;
    heading: string;
    isSubmitting: boolean;
    onClose: () => void;
    testId?: string;
};

export const ChangeData: React.FC<ChangeDataProps> = ({
    currentView,
    className,
    heading,
    isSubmitting,
    onClose,
    testId,
    children,
}) => {
    const { t } = useTranslation('translation');

    const classNameList = classNames('c-change-data', className).trim();

    return (
        <DataOverview noBorder currentView={currentView} className={classNameList} testId={testId}>
            <header className={'c-change-data__heading u-clearfix'} data-testid={'heading'}>
                <Heading className={'u-float-left'} level={4}>
                    {heading}
                </Heading>
                <Button
                    className={'u-float-right c-btn--compact'}
                    type={'button'}
                    icon={'semantic-close'}
                    title={t('editableSectionNav.close')}
                    disabled={isSubmitting}
                    onClick={onClose}
                    testId={'closeButton'}
                    secondary
                />
            </header>
            <section className={'c-change-data__body'} data-testid={'body'}>
                {children}
            </section>
        </DataOverview>
    );
};
