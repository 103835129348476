import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { nowCPDate } from '@cp-uk/common';
import {
    isInputDateSameDayOrAfter,
    isInputDateSameDayOrBefore,
    isInputDateValid,
    isInputRegistrationNumberValid,
} from 'utils';
import { RegistrationFormValues } from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<RegistrationFormValues> => {
    const todayMoment = nowCPDate().toMoment().startOf('day');
    const minValidDateOfBirthMoment = todayMoment.clone().subtract(100, 'years');
    const maxValidDateOfBirthMoment = todayMoment.clone().subtract(18, 'years');

    return Yup.object()
        .shape<RegistrationFormValues>({
            firstName: Yup.string()
                .required(t('firstName.validation.required'))
                .max(60, t('firstName.validation.maxLength')),
            surname: Yup.string()
                .required(t('surname.validation.required'))
                .max(60, t('surname.validation.maxLength')),
            dateOfBirth: Yup.string()
                .required(t('dateOfBirth.validation.required'))
                .test('format', t('dateOfBirth.validation.format'),
                    (dateOfBirth) => isInputDateValid(dateOfBirth),
                )
                .test('tooOld', t('dateOfBirth.validation.onOrAfterMinValid'),
                    (dateOfBirth) => isInputDateSameDayOrAfter(dateOfBirth, minValidDateOfBirthMoment),
                )
                .test('tooYoung', t('dateOfBirth.validation.onOrBeforeMaxValid'),
                    (dateOfBirth) => isInputDateSameDayOrBefore(dateOfBirth, maxValidDateOfBirthMoment),
                ),
            vehicleRegistrationNumber: Yup.string()
                .required(t('vehicleRegistrationNumber.validation.required'))
                .test('format', t('vehicleRegistrationNumber.validation.format'),
                    (vehicleRegistrationNumber) => isInputRegistrationNumberValid(vehicleRegistrationNumber),
                ),
            lastFourDigitsOfBankAccount: Yup.string()
                .required(t('lastFourDigitsOfBankAccount.validation.required'))
                .matches(RegExp('^[0-9]{4}$'), t('lastFourDigitsOfBankAccount.validation.format')),
            acceptPrivacyPolicy: Yup.bool()
                .required(t('acceptPrivacyPolicy.validation.required'))
                .oneOf([true], t('acceptPrivacyPolicy.validation.mustAccept')),
            acceptTermsAndConditions: Yup.bool()
                .required(t('acceptTermsAndConditions.validation.required'))
                .oneOf([true], t('acceptTermsAndConditions.validation.mustAccept')),
            confirmPrivateAgreement: Yup.bool()
                .required(t('confirmPrivateAgreement.validation.required'))
                .oneOf([true], t('confirmPrivateAgreement.validation.mustConfirm')),
        })
        .required();
};
