import { TFunction } from 'i18next';
import { SummaryItem, Views } from '../types';
import { Options, OptionsAvailableFormValues, OptionsAvailableSelections } from './types';

export const buildInitialValues = ({ option, consent }: OptionsAvailableSelections): OptionsAvailableFormValues => {
    return {
        option,
        consent,
    };
};

export const buildSelections = (
    t: TFunction,
    { option, consent }: OptionsAvailableFormValues,
): OptionsAvailableSelections => {
    const optionAsString = t(`options.${option}.title`);

    return {
        option: option as Options,
        optionAsString,
        consent,
    };
};

export const buildSummaryItem = (t: TFunction, option: Options): SummaryItem => {
    return {
        view: Views.optionsAvailable,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', { option }),
    };
};
