import React from 'react';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { RealexModalPropsWithContext } from './types';

export const PaymentStaleModal: React.FC<RealexModalPropsWithContext> = ({
    shown,
    onClose,
    onConfirm,
    context,
}) => {
    const { t } = useTranslation('realex-payments');

    return (
        <Modal
            shown={shown}
            testId={'paymentStaleModal'}
            status={'warning'}
            title={t(`paymentStaleModal.${context}.title`)}
            closeAny={false}
            hideCloseButton={true}
            buttonConfirmText={t('translation:editableSectionNav.dashboard')}
            buttonConfirmType={'button'}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>{t(`paymentStaleModal.${context}.contents`)}</Paragraph>
        </Modal>
    );
};
