import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import { Registration } from 'components/registration';
import { Chatbot, ChatbotPage } from 'components/chatbot/Chatbot';

export const RegisterPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.Register);

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.Register} />
            <Registration />
            <Chatbot page={ChatbotPage.Register} />
        </Fragment>
    );
};
