import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Buttons, CauseOfArrearsFormValues } from './types';
import {
    showContributeTowardsArrears,
    showEndAgreement,
    showPayFullArrearsWithin12Months,
    showPayFullArrearsWithin30Days,
    showPayFullArrearsWithin3Months,
} from './helpers';

const noOrYes: string[] = [Buttons.no, Buttons.yes];

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        causeOfArrears: Yup.object()
            .required()
            .test('atLeastOneSelected', t('causeOfArrears.validation.atLeastOneSelected'), (value): boolean => {
                const causeOfArrears = value as CauseOfArrearsFormValues;
                return (
                    !!causeOfArrears &&
                    (causeOfArrears.reducedIncome ||
                        causeOfArrears.unemployment ||
                        causeOfArrears.healthCondition ||
                        causeOfArrears.oversightOrMismanagementOfFinances ||
                        causeOfArrears.behindWithOtherCreditors ||
                        causeOfArrears.inAnIvaOrFilingForBankruptcy ||
                        causeOfArrears.other)
                );
            }),
        causeOfArrearsMoreDetail: Yup.string()
            .optional()
            .max(500, t('causeOfArrearsMoreDetail.validation.maxLength'))
            .when('causeOfArrears.other', {
                is: true,
                then: Yup.string()
                    .required(t('causeOfArrearsMoreDetail.validation.required'))
                    .max(500, t('causeOfArrearsMoreDetail.validation.maxLength')),
            }),
        consentInformationProvided: Yup.bool()
            .required()
            .oneOf([true], t('consentInformationProvided.validation.required')),
        relatedToCovid19: Yup.string()
            .required(t('noOrYesRadioButtons.validation.required'))
            .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
        vulnerableCustomer: Yup.string()
            .required(t('noOrYesRadioButtons.validation.required'))
            .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
        vulnerableCustomerDetail: Yup.string()
            .optional()
            .when('vulnerableCustomer', {
                is: Buttons.yes,
                then: Yup.string()
                    .max(500, t('vulnerableCustomerDetail.validation.maxLength'))
                    .required(t('vulnerableCustomerDetail.validation.required')),
            }),
        continueToPayRentals: Yup.string()
            .required(t('noOrYesRadioButtons.validation.required'))
            .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
        endAgreement: Yup.string()
            .optional()
            .when('continueToPayRentals', {
                is: (continueToPayRentals): boolean => showEndAgreement(continueToPayRentals),
                then: Yup.string()
                    .required(t('noOrYesRadioButtons.validation.required'))
                    .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            }),
        payFullArrearsWithin30Days: Yup.string()
            .optional()
            .when('continueToPayRentals', {
                is: (continueToPayRentals): boolean => showPayFullArrearsWithin30Days(continueToPayRentals),
                then: Yup.string()
                    .required(t('noOrYesRadioButtons.validation.required'))
                    .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            }),
        contributeTowardsArrears: Yup.string()
            .optional()
            .when(['endAgreement', 'payFullArrearsWithin30Days'], {
                is: (endAgreement, payFullArrearsWithin30Days): boolean =>
                    showContributeTowardsArrears(endAgreement, payFullArrearsWithin30Days),
                then: Yup.string()
                    .required(t('noOrYesRadioButtons.validation.required'))
                    .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            }),
        payFullArrearsWithin3Months: Yup.string()
            .optional()
            .when(['relatedToCovid19', 'contributeTowardsArrears'], {
                is: (relatedToCovid19, contributeTowardsArrears): boolean =>
                    showPayFullArrearsWithin3Months(relatedToCovid19, contributeTowardsArrears),
                then: Yup.string()
                    .required(t('noOrYesRadioButtons.validation.required'))
                    .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            }),
        payFullArrearsWithin12Months: Yup.string()
            .optional()
            .when(['relatedToCovid19', 'contributeTowardsArrears'], {
                is: (relatedToCovid19, contributeTowardsArrears): boolean =>
                    showPayFullArrearsWithin12Months(relatedToCovid19, contributeTowardsArrears),
                then: Yup.string()
                    .required(t('noOrYesRadioButtons.validation.required'))
                    .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            }),
    });
};
