import React from 'react';
import { ContentSection, MediaTeaser, PageWrap } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { LandingPageMarketingCardDto } from '@cp-uk/common';
import { appendBuildTimestamp, openInNewWindow, textAsHtml} from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchLandingPageMarketingCards } from './LandingPageMarketingCardsSlice';
import { selectLandingPageMarketingCards } from './LandingPageMarketingCardsSelector';

const MarketingCards: React.FC<{ landingPageMarketingCards: Array<LandingPageMarketingCardDto> | undefined }> = ({
    landingPageMarketingCards,
}) => {
    const { onAction: onClickMarketingCardAction } = useAnalyticsActionTracker('onClickMarketingCard');

    if (!landingPageMarketingCards) {
        return null;
    }

    const buildOnClick = (marketingTitle: string | undefined, buttonUrl: string | undefined): (() => void) => {
        return (): void => {
            onClickMarketingCardAction(marketingTitle);
            openInNewWindow(buttonUrl);
        };
    };

    return (
        <ContentSection>
            {landingPageMarketingCards.map(
                ({ imageUrl, pretitle, title, text, buttonLabel, buttonUrl, marketingTitle }, index) => (
                    <PageWrap size={'medium'} key={index}>
                        <MediaTeaser
                            pretitle={pretitle}
                            title={title}
                            imgProps={{ src: appendBuildTimestamp(imageUrl), alt: title }}
                            buttonLabel={buttonLabel}
                            buttonProps={{ type: 'button', testId: 'marketingCardButton' }}
                            onButtonClick={buildOnClick(marketingTitle, buttonUrl)}
                            reversed={index % 2 === 1}
                            testId={`landingPageMediaTeaser${index + 1}`}
                        >
                            {textAsHtml(text || '')}
                        </MediaTeaser>
                    </PageWrap>
                ),
            )}
        </ContentSection>
    );
};

export const LandingPageMarketingCards: React.FC = () => {
    const { cmsContent: landingPageMarketingCards, isLoading, loadingError } = useCmsContent(
        fetchLandingPageMarketingCards,
        selectLandingPageMarketingCards,
    );

    const MarketingCardsWithWrappers = withLoadingAndNoConnectionHandler(MarketingCards);

    return (
        <MarketingCardsWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            landingPageMarketingCards={landingPageMarketingCards}
        />
    );
};
