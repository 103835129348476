import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionHeading, Fieldset, Button, ButtonContainer, Form } from '@vwfs-bronson/bronson-react';
import { useGetContractBasedUpdateData } from '@cp-shared-8/frontend-integration';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    GenerateViewBO,
    getEarlyTerminationEndpoint,
    EarlyTerminationBO,
    GenerateEarlyTerminationRequest,
    GenerateEarlyTerminationError,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { buildLinkToPhoneNumber, parseErrorResponse, textWithComponents, useFinanceCompanyDetails } from 'utils';
import { linkToDocumentCentrePage, linkToMyProfilePage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedCheckboxGroup } from 'components/validated-checkbox-group/ValidatedCheckboxGroup';
import { View } from 'components/view/View';
import { updateEarlyTermination } from '../EarlyTerminationSlice';
import { selectEarlyTerminationForSpecific } from '../EarlyTerminationSelector';
import { analyticsDiscriminator } from '../constants';
import { validationSchema } from './GenerateViewValidation';
import { DispatchByFormValues, GenerateViewFormValues } from './types';

export const GenerateView: React.FC<{
    contractDescription: ContractDescriptionBO;
    generateView: GenerateViewBO | undefined;
    onError: (code: GenerateEarlyTerminationError | undefined) => void;
}> = ({ contractDescription, generateView, onError }) => {
    const { t } = useTranslation('early-termination-generate-view');
    const { phoneNumber } = useFinanceCompanyDetails(
        generateView?.productType,
        generateView?.financeBrand,
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const history = useHistory();
    const { updateData } = useGetContractBasedUpdateData(
        generateView?.encryptedContractId ?? '!',
        updateEarlyTermination,
        selectEarlyTerminationForSpecific,
    );

    const { onAction: onEarlyTerminationGenerateQuoteAction } = useAnalyticsActionTracker(
        'onEarlySettlementGenerateQuote',
    );
    useAnalyticsPageViewTracker('earlySettlementGenerate', !!generateView, analyticsDiscriminator);

    if (generateView === undefined) {
        return null;
    }
    const {
        encryptedContractId,
        emailAddress,
        postalAddress,
        otherChangeInProgress,
        addressChangeInProgress,
        addressChangeByPhone,
    } = generateView;

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const initialDispatchByEmail = (): boolean => !!emailAddress && !otherChangeInProgress;
    const initialDispatchByPost = (): boolean => {
        return (!emailAddress || otherChangeInProgress) && !!postalAddress && !addressChangeInProgress;
    };

    const dispatchByEmailDisabled = (): boolean => {
        return isSubmitting || !emailAddress || otherChangeInProgress || !postalAddress || addressChangeInProgress;
    };
    const dispatchByPostDisabled = (): boolean => {
        return isSubmitting || !emailAddress || otherChangeInProgress || !postalAddress || addressChangeInProgress;
    };
    const generateQuoteDisabled = (): boolean => {
        return (
            isSubmitting || ((!emailAddress || otherChangeInProgress) && (!postalAddress || addressChangeInProgress))
        );
    };

    const dispatchByEmailHelpText = (): React.ReactNode => {
        let keyFragment;
        if (!!emailAddress) {
            if (otherChangeInProgress) {
                keyFragment = 'changeInProgress';
            } else {
                keyFragment = 'detailsPresent';
            }
        } else {
            keyFragment = 'detailsMissing';
        }

        return textWithComponents(t, `dispatchBy.email.${keyFragment}`, { linkToMyProfilePage }, { emailAddress });
    };
    const dispatchByPostHelpText = (): React.ReactNode => {
        let keyFragment;
        if (!!postalAddress) {
            if (addressChangeInProgress) {
                keyFragment = 'changeInProgress';
            } else if (addressChangeByPhone) {
                keyFragment = 'detailsPresentPhone';
            } else {
                keyFragment = 'detailsPresent';
            }
        } else {
            if (addressChangeByPhone) {
                keyFragment = 'detailsMissingPhone';
            } else {
                keyFragment = 'detailsMissing';
            }
        }

        return textWithComponents(
            t,
            `dispatchBy.post.${keyFragment}`,
            { linkToMyProfilePage, linkToPhoneNumber },
            { postalAddress, phoneNumber },
        );
    };

    const initialValues: GenerateViewFormValues = {
        dispatchBy: {
            email: initialDispatchByEmail(),
            post: initialDispatchByPost(),
        },
    };

    const getEarlyTerminationGenerateDescription = ({ email, post }: DispatchByFormValues): string => {
        const words: string[] = [];
        if (email) {
            words.push('email');
        }
        if (post) {
            words.push('post');
        }

        return `Request quote (by ${words.join(' and ')})`;
    };

    return (
        <View testId={'generateView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                testId={'informationNotification'}
            >
                {textWithComponents(t, 'notifications.information.text', { linkToDocumentCentrePage })}
            </Notification>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={({ dispatchBy }): Promise<void> => {
                    const generateEarlyTerminationRequest: GenerateEarlyTerminationRequest = {
                        dispatchByEmail: dispatchBy.email,
                        dispatchByPost: dispatchBy.post,
                    };
                    setIsSubmitting(true);
                    onEarlyTerminationGenerateQuoteAction(
                        analyticsDiscriminator,
                        getEarlyTerminationGenerateDescription(dispatchBy),
                    );
                    return CpDataApi.post<EarlyTerminationBO>(
                        getEarlyTerminationEndpoint(encryptedContractId),
                        generateEarlyTerminationRequest,
                    )
                        .then(({ data }) => {
                            // Note: By updating the data in Redux, the view will be changed automatically to Expiry
                            updateData(data);
                            setIsSubmitting(false);
                        })
                        .catch((error) => {
                            setIsSubmitting(false);
                            onError(parseErrorResponse<GenerateEarlyTerminationError>(error)?.code);
                        });
                }}
            >
                {(formik): React.ReactNode => (
                    <Form className={'uk-early-termination-generate-view-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedCheckboxGroup name="dispatchBy" label="" testId={'dispatchByCheckboxes'}>
                                    <ValidatedCheckboxGroup.Checkbox
                                        label={t('dispatchBy.email.label')}
                                        name={'dispatchBy.email'}
                                        groupName={'dispatchBy'}
                                        disabled={dispatchByEmailDisabled()}
                                        helpText={dispatchByEmailHelpText()}
                                        testId={'dispatchByCheckboxes.email'}
                                    />
                                    <ValidatedCheckboxGroup.Checkbox
                                        label={t('dispatchBy.post.label')}
                                        name={'dispatchBy.post'}
                                        groupName={'dispatchBy'}
                                        disabled={dispatchByPostDisabled()}
                                        helpText={dispatchByPostHelpText()}
                                        testId={'dispatchByCheckboxes.post'}
                                    />
                                </ValidatedCheckboxGroup>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        testId={'returnButton'}
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button
                                        testId={'generateQuoteButton'}
                                        type={'button'}
                                        disabled={generateQuoteDisabled()}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.generateQuote')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
