import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { SolutionsStartViewFormValues } from './types';

export const validationSchema = (
    t: TFunction,
    showSellOption: boolean,
): Yup.ObjectSchema<SolutionsStartViewFormValues> => {
    const optionValidValues = ['exchange', 'keep', 'handBack'];
    if (showSellOption) {
        optionValidValues.push('sell');
    }

    return Yup.object()
        .shape<SolutionsStartViewFormValues>({
            option: Yup.string()
                .default('')
                .required(t('option.validation.required'))
                .oneOf(optionValidValues, t('option.validation.required')),
        })
        .required();
};
