import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { buildLinkToPhoneNumber, textWithComponents, useWebsiteFinanceCompanyDetails } from 'utils';

export type AlternativeNotificationProps = {
    className?: string;
    testId?: string;
};

export const AlternativeNotification: React.FC<AlternativeNotificationProps> = ({ className, testId }) => {
    const { t } = useTranslation('document-centre');
    const { phoneNumber, openingHours } = useWebsiteFinanceCompanyDetails('purchase');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    return (
        <Notification
            className={className}
            status={NotificationStatus.info}
            testId={testId}
        >
            {textWithComponents(
                t,
                'notifications.alternative.text',
                { linkToPhoneNumber },
                { phoneNumber, openingHours },
            )}
        </Notification>
    );
};
