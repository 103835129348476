import React from 'react';
import i18next, { TFunction } from 'i18next';
import { Tag } from '@vwfs-bronson/bronson-react';
import { FinanceBrands } from '@cp-uk/common';
import { currentBrand } from 'config';

export const carOrVehicleText = (financeBrand: FinanceBrands): string => {
    const carOrVehicleKey: string = financeBrand === 'vwcv' ? 'vehicle' : 'car';

    return i18next.t(`expand.carOrVehicles.${carOrVehicleKey}`);
};

export const currentBrandAsFinanceBrands = (): FinanceBrands => {
    return currentBrand === 'vwfs' ? 'vw' : (currentBrand as FinanceBrands);
};

export const retailerDescriptionText = (financeBrand: FinanceBrands): string => {
    let retailerDescriptionKey: string;
    switch (financeBrand) {
        case 'audi':
        case 'porsche':
            retailerDescriptionKey = 'centre';
            break;
        case 'bentley':
        case 'lamborghini':
            retailerDescriptionKey = 'dealer';
            break;
        case 'vwcv':
            retailerDescriptionKey = 'vanCentre';
            break;
        case 'seat':
        case 'skoda':
        case 'vw':
            retailerDescriptionKey = 'retailer';
            break;
        case 'man':
        case 'vwg':
            retailerDescriptionKey = 'unknown';
            break;
    }

    return i18next.t(`expand.retailerDescriptions.${retailerDescriptionKey}`);
};

export const valueOrErrorTag = (t: TFunction, value: string | undefined): string | React.ReactNode => {
    return value || <Tag error>{t('missing.value')}</Tag>;
};
