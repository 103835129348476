import { toTitleCase } from './to-title-case';

const changeSkodaAccent = (value: string): string => {
    const firstCharacter = value.substring(0, 1) === 'S' ? 'Š' : 'š';
    const remainingCharacters = value.substring(1);
    return `${firstCharacter}${remainingCharacters}`;
};

export const fixSkodaAccent = (value: string): string => {
    if (value.toUpperCase() === 'SKODA') {
        return changeSkodaAccent(value);
    }

    return value;
};

export const toBrandCase = (value: string): string => {
    const valueUpperCased = value.toUpperCase();

    switch (valueUpperCased) {
        case 'CUPRA':
        case 'MAN':
        case 'SEAT':
        case 'BMW':
        case 'DS':
        case 'MG':
            return valueUpperCased;
        case 'SKODA':
            return toTitleCase(changeSkodaAccent(value));
        default:
            return toTitleCase(value);
    }
};
