import React, { Fragment } from 'react';
import { ContractDescriptionBO, EnquiryViewSettlementBO, formatAsDate, formatAsCurrency } from '@cp-uk/common';
import { buildLinkToPhoneNumber, textAsHtml, textWithComponents, useFinanceCompanyDetails } from 'utils';
import {
    SectionHeading,
    Paragraph,
    Button,
    ButtonContainer,
    Layout,
    DescriptionList,
    ProductTable,
} from '@vwfs-bronson/bronson-react';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { View } from 'components/view/View';
import { useHistory } from 'react-router-dom';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { dashboardPagePath } from 'components/navigation/paths';
import {
    InfoIcon,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { analyticsDiscriminator } from '../constants';

export const EnquiryView: React.FC<{
    contractDescription: ContractDescriptionBO;
    enquiryView: EnquiryViewSettlementBO | undefined;
    onContinue: () => void;
}> = ({ contractDescription, enquiryView, onContinue }) => {
    const { t } = useTranslation('early-settlement-enquiry-view');
    const history = useHistory();
    const { phoneNumber, openingHours } = useFinanceCompanyDetails(enquiryView?.productType, enquiryView?.financeBrand);
    const { onAction: onEarlySettlementEnquiryPrintAction } = useAnalyticsActionTracker(
        'onEarlySettlementEnquiryPrint',
    );
    useAnalyticsPageViewTracker('earlySettlementEnquiry', !!enquiryView, analyticsDiscriminator);

    if (enquiryView === undefined) {
        return null;
    }

    const {
        rightOfWithdrawal,
        quoteCreationDate,
        quoteExpiryDate,
        outstandingBalance,
        interestRebateAmount,
        arrears,
        settlementAmount,
    } = enquiryView;

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const onPrintClick: () => void = () => {
        onEarlySettlementEnquiryPrintAction(analyticsDiscriminator);
        window.print();
    };

    const titleWithTooltip = (title: string, tooltip: string): React.ReactNode => {
        return (
            <Fragment>
                {title}
                <InfoIcon text={tooltip} />
            </Fragment>
        );
    };

    const productTableSummary: React.ReactNode = (
        <DescriptionList split>
            <DescriptionTermAndDetail
                testId={'settlementAmount'}
                term={titleWithTooltip(t('settlementAmount.title'), t('settlementAmount.tooltip'))}
                termClassName={'c-product-table__summary-term'}
                detail={<u>{formatAsCurrency(settlementAmount)}</u>}
                detailClassName={'c-product-table__summary-detail'}
            />
        </DescriptionList>
    );

    return (
        <View testId={'enquiryView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            {rightOfWithdrawal && (
                <Notification
                    className={'u-mb'}
                    status={NotificationStatus.info}
                    testId={'rightOfWithdrawalNotification'}
                >
                    {textWithComponents(
                        t,
                        'notifications.rightOfWithdrawal.text',
                        { linkToPhoneNumber },
                        { phoneNumber, openingHours },
                    )}
                </Notification>
            )}
            <Paragraph testId={'informationParagraph1'}>{textAsHtml(t('paragraphs.information1'))}</Paragraph>
            <Layout className={`u-mb`}>
                <Layout.Item default={'5/12'} m={'1/1'}>
                    <DescriptionList testId={'enquiryDates'} split>
                        <DescriptionTermAndDetail
                            testId={'quoteCreationDate'}
                            term={t('quoteCreationDate.title')}
                            detail={formatAsDate(quoteCreationDate)}
                        />
                        <DescriptionTermAndDetail
                            testId={'quoteExpiryDate'}
                            term={titleWithTooltip(t('quoteExpiryDate.title'), t('quoteExpiryDate.tooltip'))}
                            detail={formatAsDate(quoteExpiryDate)}
                        />
                    </DescriptionList>
                </Layout.Item>
                <Layout.Item default={'7/12'} m={'1/1'}>
                    <ProductTable className={'enquiry-details'} summary={productTableSummary}>
                        <ProductTable.Section>
                            <DescriptionList split>
                                <DescriptionTermAndDetail
                                    testId={'outstandingBalance'}
                                    term={titleWithTooltip(t('outstandingBalance.title'), t('outstandingBalance.tooltip'))}
                                    detail={formatAsCurrency(outstandingBalance)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'interestRebateAmount'}
                                    term={titleWithTooltip(
                                        t('interestRebateAmount.title'),
                                        t('interestRebateAmount.tooltip'),
                                    )}
                                    detail={formatAsCurrency(interestRebateAmount)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'arrears'}
                                    term={titleWithTooltip(t('arrears.title'), t('arrears.tooltip'))}
                                    detail={formatAsCurrency(arrears)}
                                />
                            </DescriptionList>
                        </ProductTable.Section>
                    </ProductTable>
                </Layout.Item>
            </Layout>

            <Paragraph className={'u-text-muted'} testId={'informationParagraph2'}>
                {t('paragraphs.information2')}
            </Paragraph>
            <ButtonContainer nav>
                <Button
                    type={'button'}
                    testId={'printButton'}
                    icon={'semantic-print'}
                    iconReversed
                    simple
                    small
                    link
                    onClick={onPrintClick}
                >
                    {t('translation:editableSectionNav.print')}
                </Button>
                <Button
                    testId={'returnButton'}
                    type={'button'}
                    onClick={(): void => history.push(dashboardPagePath())}
                    secondary
                >
                    {t('translation:editableSectionNav.return')}
                </Button>
                <Button testId={'continueButton'} type={'button'} onClick={onContinue}>
                    {t('translation:editableSectionNav.continue')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
