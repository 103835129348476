import React, { Fragment, useContext, useState } from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ChangePaymentDateRequest, DayOfPaymentSectionBO, parseCPDate, PaymentDateChangeables } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ContractDetailsContext } from '../../ContractDetailsContext';
import { ChangePaymentDate } from './change-payment-date/ChangePaymentDate';
import { NotificationContentProps } from '../types';

export const DayOfPaymentSection: React.FC<{ dayOfPaymentSection: DayOfPaymentSectionBO | undefined }> = ({
    dayOfPaymentSection: propsDayOfPaymentSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [failureNotificationContentProps, setFailureNotificationContentProps] = useState<NotificationContentProps | undefined>(undefined);
    const { newDayOfPaymentSection, setNewDayOfPaymentSection } = useContext(ContractDetailsContext);
    const { t, f } = useTranslationWithFormatting('day-of-payment-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker(
        'financialDetailsEditDueDateSectionSubmitFailed',
    );
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSuccess');

    if (propsDayOfPaymentSection === undefined) {
        return null;
    }

    const title = t('title');

    const dayOfPaymentSection = newDayOfPaymentSection ?? propsDayOfPaymentSection;

    const { paymentDateChangeable, monthlyDueDay, changePaymentDate } = dayOfPaymentSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        setFailureNotificationContentProps(undefined);
        onChangeStartAction(title);
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction(title);
    };
    const onChangeFailure = (failureNotificationContentProps: NotificationContentProps): void => {
        setFailureNotificationContentProps(failureNotificationContentProps);
        setIsChanging(false);
        onChangeFailureAction(title);
    };
    const onChangeSuccess = (changePaymentDateRequest: ChangePaymentDateRequest): void => {
        const newDayOfPaymentSection: DayOfPaymentSectionBO = {
            paymentDateChangeable: 'inProgress' as Exclude<PaymentDateChangeables, 'ok'>,
            monthlyDueDay: parseCPDate(changePaymentDateRequest.paymentDate).toMoment().date(),
            changePaymentDate: undefined,
        };
        setNewDayOfPaymentSection(newDayOfPaymentSection);
        setIsChanging(false);
        onChangeSuccessAction(title);
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (monthlyDueDay !== undefined) {
        descriptionListItems.push({
            label: t('monthlyDueDay.label'),
            tooltip: t('monthlyDueDay.tooltip'),
            value: f(monthlyDueDay, TranslationFormat.NUMBER),
            testId: 'monthlyDueDay',
        });
    }

    return (
        <Fragment>
            <Hr className={'u-mb-none'} />
            {isChanging ? (
                <ChangePaymentDate
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changePaymentDate={changePaymentDate}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={title}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${paymentDateChangeable}`)}
                    buttonDisabled={paymentDateChangeable !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'dayOfPaymentSection'}
                >
                    {paymentDateChangeable === 'inProgress' && (
                        <Notification
                            className={'u-mb'}
                            status={NotificationStatus.warning}
                            text={t('inProgressNotification.text')}
                            testId={'inProgressNotification'}
                        />
                    )}
                    <DescriptionListHorizontal items={descriptionListItems} />
                    {failureNotificationContentProps && (
                        <Notification
                            className={'u-mt'}
                            status={NotificationStatus.warning}
                            headline={failureNotificationContentProps.headline}
                            text={failureNotificationContentProps.text}
                            testId={'failureNotification'}
                        >
                            {failureNotificationContentProps.children}
                        </Notification>
                    )}
                </DataOverview>
            )}
        </Fragment>
    );
};
