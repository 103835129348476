export enum ButtonsNY {
    no = 'no',
    yes = 'yes',
}

export enum ButtonsDA {
    disagree = 'disagree',
    agree = 'agree',
}

export type ContractHireExtensionViewFormValues = {
    alreadyHaveNewAgreement: string;
    newAgreementFinanceBrand: string;
    newAgreementProposalNumber: string;
    extensionMonths: string;
    currentMileage: string;
    canAffordWithCurrentLevelsOfIncomeAndExpenditure: string;
    incomeNotExpectedToChange: string;
    expenditureNotExpectedToChange: string;
    changeInCircumstance: string;
};

export enum NewAgreementFinanceBrands {
    audi = 'audi',
    bentley = 'bentley',
    lamborghini = 'lamborghini',
    porsche = 'porsche',
    seat = 'seat',
    skoda = 'skoda',
    vw = 'vw',
    vwcv = 'vwcv',
}

export enum ExtensionMonths {
    one = '1M',
    two = '2M',
    three = '3M',
    four = '4M',
    five = '5M',
    six = '6M',
    seven = '7M',
    eight = '8M',
    nine = '9M',
    ten = '10M',
    eleven = '11M',
    twelve = '12M',
}

export enum ChangeInCircumstances {
    adverseHealth = 'adverseHealth',
    recentLossOfEmployment = 'recentLossOfEmployment',
    relationshipBreakdownDivorce = 'relationshipBreakdownDivorce',
    changeInLivingArrangementNoFixedAbode = 'changeInLivingArrangementNoFixedAbode',
    noLongerWorking = 'noLongerWorking',
    risingCostOfLiving = 'risingCostOfLiving',
    other = 'other',
}
