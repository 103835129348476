import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Paragraph } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { currentBrand } from 'config';
import { buildLinkToEmailAddress, textWithComponents } from 'utils';
import { ChangeData } from 'components/change-data/ChangeData';

export const ChangeName: React.FC<{
    onChangeCancel: () => void;
    onChangeDownload: (documentType: string) => void;
}> = ({ onChangeCancel, onChangeDownload }) => {
    const { t } = useTranslation('change-name');

    const onDownloadFormClick = (): void => {
        onChangeDownload(t('buttons.downloadForm.type'));
    };

    const linkToRetailCustomerServicesEmailAddress = buildLinkToEmailAddress(t, 'retailCustomerServices');

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-name'}
                heading={t('heading')}
                isSubmitting={false}
                onClose={onChangeCancel}
                testId={'changeName'}
            >
                <Paragraph className={'u-mb-small'} testId={'informationParagraph'}>
                    {textWithComponents(t, 'informationParagraph.content', {
                        linkToRetailCustomerServicesEmailAddress,
                    })}
                </Paragraph>
                <Paragraph className={'u-text-italic'} testId={'addressParagraph'}>
                    {t('postalAddress.customerServices')}
                    <br />
                    {t('postalAddress.companyName')}
                    <br />
                    {t('postalAddress.addressLine1')}
                    <br />
                    {t('postalAddress.addressLine2')}
                    <br />
                    {t('postalAddress.addressLine3')}
                    <br />
                    {t('postalAddress.addressLine4')}
                    <br />
                    {t('postalAddress.postCode')}
                </Paragraph>
                <Paragraph className={'u-text-bold u-mb-small'} testId={'certifiedDocumentsTitleParagraph'}>
                    {t('certifiedDocumentsTitleParagraph.content')}
                </Paragraph>
                <Paragraph testId={'certifiedDocumentsDetails1Paragraph'}>
                    {t('certifiedDocumentsDetails1Paragraph.content')}
                </Paragraph>
                <Paragraph>
                    <Button
                        element={'a'}
                        link
                        small
                        icon={'semantic-forward'}
                        iconReversed
                        href={t('buttons.documentCertificationService.url')}
                        target={'_BLANK'}
                        testId={'documentCertificationServiceLink'}
                    >
                        {t('buttons.documentCertificationService.label')}
                    </Button>
                </Paragraph>
                <Paragraph testId={'certifiedDocumentsDetails2Paragraph'}>
                    {t('certifiedDocumentsDetails2Paragraph.content')}
                </Paragraph>
                <ButtonContainer nav>
                    <Button
                        type={'button'}
                        onClick={onChangeCancel}
                        testId={'backButton'}
                        secondary
                    >
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button
                        element={'a'}
                        href={t('buttons.downloadForm.url', { brand: currentBrand })}
                        target={'_BLANK'}
                        download
                        testId={'downloadFormLink'}
                        onClick={onDownloadFormClick}
                    >
                        {t('buttons.downloadForm.label')}
                    </Button>
                </ButtonContainer>
            </ChangeData>
        </Suspense>
    );
};
