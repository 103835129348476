import React from 'react';
import { isProductType, ServiceSummarySectionBO } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';

export const SummarySection: React.FC<{ summarySection: ServiceSummarySectionBO | undefined }> = ({
    summarySection,
}) => {
    const { t, f } = useTranslationWithFormatting('service-summary-section');

    if (summarySection === undefined) {
        return null;
    }

    const {
        productType,
        serviceLevel,
        totalServiceEntitlement,
        remainingServiceEntitlement,
        contractStartDate,
        contractEndDate,
        paymentInterval,
    } = summarySection;

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (isProductType(productType, 'fixedCostMaintenance') && serviceLevel !== undefined) {
        descriptionListItems.push({
            label: t('serviceLevel.label'),
            value: t(`serviceLevel.values.${serviceLevel}`),
            testId: 'serviceLevel',
        });
    }
    if (isProductType(productType, 'fixedPriceServicing') && remainingServiceEntitlement !== undefined) {
        descriptionListItems.push(
            {
                label: t('totalServiceEntitlement.label'),
                value: f(totalServiceEntitlement, TranslationFormat.NUMBER),
                testId: 'totalServiceEntitlement',
            },
            {
                label: t('remainingServiceEntitlement.label'),
                value: f(remainingServiceEntitlement, TranslationFormat.NUMBER),
                testId: 'remainingServiceEntitlement',
            },
        );
    }
    if (contractStartDate !== undefined) {
        descriptionListItems.push({
            label: t('contractStartDate.label'),
            value: f(contractStartDate, TranslationFormat.DATE),
            testId: 'contractStartDate',
        });
    }
    if (contractEndDate !== undefined) {
        descriptionListItems.push({
            label: t('contractEndDate.label'),
            value: f(contractEndDate, TranslationFormat.DATE),
            testId: 'contractEndDate',
        });
    }
    if (remainingServiceEntitlement === undefined && paymentInterval !== undefined) {
        descriptionListItems.push({
            label: t('paymentInterval.label'),
            value: t(`paymentInterval.values.${paymentInterval}`),
            testId: 'paymentInterval',
        });
    }

    if (descriptionListItems.length === 0) {
        return null;
    }

    return (
        <DataOverview noBorder testId={'summarySection'}>
            <DescriptionListHorizontal items={descriptionListItems} />
        </DataOverview>
    );
};
