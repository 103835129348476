import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '@vwfs-bronson/bronson-react';
import { DescriptionTermAndDetail } from '../description-term-and-detail';

export interface BankDetailsForBankTransferProps {
    className?: string;
    bank: string;
    accountName: string;
    sortCode: string;
    accountNumber: string;
    referenceNumber: string;
}

export const BankDetailsForBankTransfer: React.FC<BankDetailsForBankTransferProps> = ({
    className,
    bank,
    accountName,
    sortCode,
    accountNumber,
    referenceNumber,
}) => {
    const { t } = useTranslation();

    const classNameList = classNames('c-description-list--compact', className).trim();

    return (
        <DescriptionList
            className={classNameList}
            horizontal
            shortTerms
            testId={'bankDetailsForBankTransfer'}
        >
            <DescriptionTermAndDetail
                testId={'bankForBankTransfer'}
                term={t('bankDetails.bank')}
                detail={bank}
            />
            <DescriptionTermAndDetail
                testId={'accountNameForBankTransfer'}
                term={t('bankDetails.accountName')}
                detail={accountName}
            />
            <DescriptionTermAndDetail
                testId={'sortCodeForBankTransfer'}
                term={t('bankDetails.sortCode')}
                detail={sortCode}
            />
            <DescriptionTermAndDetail
                testId={'accountNumberForBankTransfer'}
                term={t('bankDetails.accountNumber')}
                detail={accountNumber}
            />
            <DescriptionTermAndDetail
                testId={'referenceNumberForBankTransfer'}
                term={t('bankDetails.referenceNumber')}
                detail={referenceNumber}
            />
        </DescriptionList>
    );
};
