import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HeroTeaser } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker, useAuthentication } from '@cp-shared-8/frontend-ui';
import { LandingPageHeroTeaserDto } from '@cp-uk/common';
import { appendBuildTimestamp, buildLoginOptions, selectAlternativeOrNormal } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchLandingPageHeroTeaser } from './LandingPageHeroTeaserSlice';
import { selectLandingPageHeroTeaser } from './LandingPageHeroTeaserSelector';

type ButtonProps = Parameters<typeof Button>[0];

export const LandingPageHeroTeaserUi: React.FC<{ landingPageHeroTeaser: LandingPageHeroTeaserDto | undefined }> = ({
    landingPageHeroTeaser,
}) => {
    const { i18n } = useTranslation();
    const { isAuthenticated, login } = useAuthentication();
    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', !!landingPageHeroTeaser, !isAuthenticated);

    if (landingPageHeroTeaser === undefined) {
        return null;
    }

    const { normal, alternative, startDate, endDate } = landingPageHeroTeaser;
    const alternativeOrNormal = selectAlternativeOrNormal(normal, alternative, startDate, endDate);
    const { title, subTitle, text, imageUrl, buttonText, isInverted } = alternativeOrNormal;

    const currentLanguage = i18n.languages[0];

    const onLoginRegisterClick = (): Promise<void> => {
        onLoginAction();
        return login(buildLoginOptions(currentLanguage));
    };

    return (
        <HeroTeaser
            imgProps={{ src: appendBuildTimestamp(imageUrl) }}
            title={title}
            sub={subTitle}
            buttonText={buttonText}
            buttonProps={{ 'data-testid': 'loginRegisterButton' } as ButtonProps}
            onButtonClick={onLoginRegisterClick}
            inverted={isInverted}
            testId={'landingPageHeroTeaser'}
        >
            {text}
        </HeroTeaser>
    );
};

const LandingPageHeroTeaserWithWrappers = withLoadingAndNoConnectionHandler(LandingPageHeroTeaserUi);

export const LandingPageHeroTeaser: React.FC = () => {
    const { cmsContent: landingPageHeroTeaser, isLoading, loadingError } = useCmsContent(
        fetchLandingPageHeroTeaser,
        selectLandingPageHeroTeaser,
    );

    return (
        <LandingPageHeroTeaserWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            landingPageHeroTeaser={landingPageHeroTeaser}
        />
    );
};
