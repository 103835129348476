import React, { FormEventHandler, Fragment, ReactNode } from 'react';
import { FormField, RadioButton, Fieldset } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { hasError, ValidationErrorMessage } from '../errors';
import { HelpText } from '../help-text/HelpText';
import { InfoIcon } from '@cp-shared-8/frontend-ui';

export type RadioButtonProps = {
    label: string;
    value: readonly string[] | string | number;
    tooltip?: string | ReactNode;
    helpText?: string | ReactNode;
    disabled?: boolean;
};

type ValidatedRadioButtonsProps = {
    radioButtons: RadioButtonProps[];
    name: string;
    label?: string;
    tooltip?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    testId?: string;
    helpText?: string | React.ReactNode;
    handleChange?: FormEventHandler<HTMLInputElement>;
    className?: string;
    isMandatory?: boolean;
};

export const ValidatedRadioButtons: React.FC<ValidatedRadioButtonsProps> = ({
    radioButtons,
    name,
    tooltip,
    defaultChecked,
    label,
    disabled,
    testId,
    helpText,
    handleChange,
    className,
    isMandatory = false,
}) => {
    const [field, meta] = useField(name);

    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <FormField
            type="other"
            testId={`${testId}FormField`}
            errorMessage={errorMessageElseHelpText}
            labelText={label}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            {radioButtons.map(({ label, value, tooltip, helpText, disabled: innerDisabled }, index: number) => {
                const innerTestId = testId ? `${testId}.${value}` : undefined;
                const innerMeta = { ...meta, error: undefined };

                return (
                    <Fieldset.Row className={className} key={index}>
                        <RadioButton
                            {...field}
                            testId={innerTestId}
                            name={name}
                            value={value}
                            error={!(innerDisabled || disabled) && hasError(meta)}
                            disabled={disabled || innerDisabled}
                            defaultChecked={defaultChecked || value === field.value}
                        >
                            <div>
                                <span data-testid={'radioButtonLabel'}>{label}</span>
                                {helpText && (
                                    <HelpText meta={innerMeta} testId={innerTestId}>
                                        {helpText}
                                    </HelpText>
                                )}
                                {tooltip && <InfoIcon text={tooltip} />}
                            </div>
                        </RadioButton>
                    </Fieldset.Row>
                );
            })}
        </FormField>
    );
};
