import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { CookieDescriptionCMS } from '@cp-shared-8/apis';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { CookieTable, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { CookiePolicyCookieDto, CookiePolicyDto } from '@cp-uk/common';
import { expandBrandName, textWithComponents } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { fetchCookiePolicy } from './CookiePolicySlice';
import { selectCookiePolicy } from './CookiePolicySelector';

const linkToNetworkAdvertisingInitiativeWebsite = (
    <SimpleLink
        href={'https://www.networkadvertising.org/'}
        target="_BLANK"
        testId={'networkAdvertisingInitiativeWebsiteLink'}
    />
);
const linkToAllAboutCookiesWebsite = (
    <SimpleLink href={'https://www.allaboutcookies.org/'} target="_BLANK" testId={'allAboutCookiesWebsiteLink'} />
);
const linkToAdobeExperienceCloudPrivacy = (
    <SimpleLink
        href={'https://www.adobe.com/uk/privacy/experience-cloud.html'}
        target="_BLANK"
        testId={'adobeExperienceCloudPrivacyLink'}
    />
);
const linkToNetworkAdvertisingInitiativeOptOut = (
    <SimpleLink
        href={'https://optout.networkadvertising.org/'}
        target="_BLANK"
        testId={'networkAdvertisingInitiativeOptOutLink'}
    />
);

const convertCookiesType = (cookies: CookiePolicyCookieDto[]): CookieDescriptionCMS[] => {
    return cookies.map(
        ({
            type,
            name,
            description,
            expirationDuration,
            creationTime,
        }: CookiePolicyCookieDto): CookieDescriptionCMS => ({
            type,
            name,
            description,
            expirationDuration,
            creationTime,
        }),
    );
};

const CookiePolicyUi: React.FC<{ cookiePolicy: CookiePolicyDto | undefined }> = ({ cookiePolicy }) => {
    const { t } = useTranslation('cookie-policy');
    useAnalyticsPageViewTracker('cookiePolicy', !!cookiePolicy);

    if (cookiePolicy === undefined) {
        return null;
    }

    const { content, cookies } = cookiePolicy;

    const columnTitles: CookieDescriptionCMS = {
        type: t('columns.type'),
        name: t('columns.name'),
        description: t('columns.description'),
        expirationDuration: t('columns.expirationDuration'),
        creationTime: t('columns.creationTime'),
    };

    // NOTE: We CANNOT use the textWithComponents() function for the descriptions within the cookies array (as they
    // contain HTML) to be consistent because the CookiesTable component only allows us to pass a string and uses the
    // equivalent of the textAsHtml() function internally (and all the links are external).
    return (
        <ContentSection className={'uk-cookie-policy'} testId={'cookiePolicyContent'}>
            <PageWrap size={'medium'}>
                {textWithComponents(undefined, expandBrandName(content), {
                    linkToNetworkAdvertisingInitiativeWebsite,
                    linkToAllAboutCookiesWebsite,
                    linkToAdobeExperienceCloudPrivacy,
                    linkToNetworkAdvertisingInitiativeOptOut,
                })}
                <CookieTable
                    columnTitles={columnTitles}
                    cookies={convertCookiesType(cookies)}
                    data-testid={'cookiePolicyTable'}
                />
            </PageWrap>
        </ContentSection>
    );
};

const CookiePolicyWithHandlers = withLoadingAndNoConnectionHandler(CookiePolicyUi);

export const CookiePolicy: React.FC = () => {
    const { cmsContent: cookiePolicy, isLoading, loadingError } = useCmsContent(fetchCookiePolicy, selectCookiePolicy);

    return <CookiePolicyWithHandlers isLoading={isLoading} hasError={!!loadingError} cookiePolicy={cookiePolicy} />;
};
