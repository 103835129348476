import { getOtherFinanceBrandsEndpoint, OtherFinanceBrandsBO } from '@cp-uk/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<OtherFinanceBrandsBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'otherFinanceBrands',
    fetchCallback() {
        return CpDataApi.get<OtherFinanceBrandsBO>(getOtherFinanceBrandsEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchOtherFinanceBrands = fetchData;
