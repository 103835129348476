import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection, PageWrap, Paragraph } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import {
    getLicenseKey,
    groupLicensesByType,
    ThirdPartyLicensesTable,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { ThirdPartyLicencesDto, ThirdPartyLicenceDto } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchThirdPartyLicences } from './ThirdPartyLicencesSlice';
import { selectThirdPartyLicences } from './ThirdPartyLicencesSelector';

const ThirdPartyLicencesUi: React.FC<{ thirdPartyLicences: ThirdPartyLicencesDto | undefined }> = ({
    thirdPartyLicences,
}) => {
    const { t } = useTranslation('third-party-licences');
    useAnalyticsPageViewTracker('thirdPartyLicenses', !!thirdPartyLicences);

    if (thirdPartyLicences === undefined) {
        return null;
    }

    const { licences } = thirdPartyLicences;

    const groupedLicences: [string, ThirdPartyLicenceDto[]][] = Object.entries(groupLicensesByType(licences));
    const sortedLicences: [
        string,
        ThirdPartyLicenceDto[],
    ][] = groupedLicences.sort(([licenceTypeA, _licencesA], [licenceTypeB, _licencesB]) =>
        licenceTypeA.localeCompare(licenceTypeB),
    );

    const columnHeadings = [t('columns.name'), t('columns.version'), t('columns.copyright')];
    const viewLicenceLabel = t('labels.viewLicence');

    return (
        <ContentSection className={'uk-third-party-licences'} testId={'thirdPartyLicencesContent'}>
            <PageWrap size={'medium'}>
                <Paragraph className={'u-text-center u-text-bold u-mb-small'} testId={'introductionParagraph'}>
                    {t('paragraphs.introduction')}
                </Paragraph>
                <section className={'u-text-center'} data-testid={'licencesNav'}>
                    {sortedLicences.map(([licenceType], index) => {
                        const key = getLicenseKey(licenceType);

                        const headline = t(`licences.${key}.headline`);

                        return (
                            <a
                                key={key}
                                className={index > 0 ? 'u-ml-small' : ''}
                                href={`#${key}`}
                                data-testid={`${key}Link`}
                            >
                                {headline}
                            </a>
                        );
                    })}
                </section>
                {sortedLicences.map(([licenceType, licences]) => {
                    const key = getLicenseKey(licenceType);

                    const headline = t(`licences.${key}.headline`);
                    const description = t(`licences.${key}.description`);
                    const website = t(`licences.${key}.website`);

                    const textAboveTable = <span id={key}>{headline}</span>;
                    const link = { label: headline, url: website };

                    return (
                        <ThirdPartyLicensesTable
                            key={key}
                            textAboveTable={textAboveTable}
                            columnHeadings={columnHeadings}
                            rows={licences}
                            viewLicenseLabel={viewLicenceLabel}
                            link={link}
                            description={description}
                        />
                    );
                })}
            </PageWrap>
        </ContentSection>
    );
};

const ThirdPartyLicencesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicencesUi);

export const ThirdPartyLicences: React.FC = () => {
    const { cmsContent: thirdPartyLicences, isLoading, loadingError } = useCmsContent(
        fetchThirdPartyLicences,
        selectThirdPartyLicences,
    );

    return (
        <ThirdPartyLicencesWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            thirdPartyLicences={thirdPartyLicences}
        />
    );
};
