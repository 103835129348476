import React from 'react';
import { FieldMetaProps } from 'formik';
import classNames from 'classnames';
import { Paragraph } from '@vwfs-bronson/bronson-react';
import { hasError } from '../errors';

export interface HelpTextProps {
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: FieldMetaProps<any>;
    testId?: string;
}

export const HelpText: React.FC<HelpTextProps> = ({ className, meta, testId, children }) => {
    if (!children) {
        return null;
    }

    const classNameList = classNames('c-help-text', className).trim();

    const hideHelpStyle = {
        display: 'none',
    };

    const hideHelpText = hasError(meta);

    return (
        <Paragraph
            className={classNameList}
            aria-hidden={hideHelpText}
            style={hideHelpText ? hideHelpStyle : undefined}
            testId={testId ? `${testId}HelpText` : undefined}
        >
            {children}
        </Paragraph>
    );
};
