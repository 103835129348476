import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Button, InfoIcon } from '@vwfs-bronson/bronson-react';
import { useScrollTo } from 'utils';

export type DataOverviewProps = {
    noBorder?: boolean;
    separator?: boolean;
    currentView?: string;
    className?: string;
    title?: string | React.ReactNode;
    buttonLabel?: string | React.ReactNode;
    buttonTooltip?: string | React.ReactNode;
    buttonDisabled?: boolean;
    buttonTestId?: string;
    onButtonClick?: () => void;
    testId?: string;
};

export const DataOverview: React.FC<DataOverviewProps> = ({
    noBorder,
    separator,
    className,
    currentView,
    title,
    buttonLabel,
    buttonTooltip,
    buttonDisabled,
    buttonTestId,
    onButtonClick,
    testId,
    children,
}) => {
    const classNameList = classNames(
        'c-data-overview',
        {
            'c-data-overview--no-border': noBorder,
            'c-data-overview--separator': separator,
        },
        className,
    ).trim();

    const scrollToRef = useScrollTo(currentView);

    const renderIfButtonTooltip = (): ReactNode | null => {
        if (buttonTooltip) {
            return <InfoIcon icon={'semantic-info'}>{buttonTooltip}</InfoIcon>;
        }
        return null;
    };

    const renderIfButtonLabel = (): ReactNode | null => {
        if (buttonLabel) {
            return (
                <div className={'c-data-overview__button'}>
                    {renderIfButtonTooltip()}
                    <Button
                        className={'c-btn--compact'}
                        type={'button'}
                        disabled={buttonDisabled}
                        onClick={onButtonClick}
                        testId={buttonTestId || 'changeButton'}
                        secondary
                    >
                        {buttonLabel}
                    </Button>
                </div>
            );
        }
        return null;
    };

    const renderIfTitle = (): ReactNode | null => {
        if (title) {
            return (
                <header className={'c-data-overview__header u-mb-small'}>
                    <h4 className="c-data-overview__title">{title}</h4>
                    {renderIfButtonLabel()}
                </header>
            );
        }
        return null;
    };

    const renderIfChildren = (): ReactNode | null => {
        if (children) {
            return <div className="c-data-overview__body">{children}</div>;
        }
        return null;
    };

    return (
        <article ref={scrollToRef} className={classNameList} data-testid={testId}>
            {renderIfTitle()}
            {renderIfChildren()}
        </article>
    );
};
