import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO, ProductTypesEOC } from '@cp-uk/common';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { contractCategory, textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { dashboardPagePath, earlySettlementPagePath } from 'components/navigation/paths';
import { cleanUrlForAnalytics } from './helpers';

export type SolutionsSellViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    vehicleBrand: string;
    onBack: () => void;
};

export const SolutionsSellView: React.FC<SolutionsSellViewProps> = ({
    encryptedContractId,
    contractDescription,
    vehicleBrand,
    onBack,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const { t } = useTranslation(['end-of-contract-solutions-sell-view', 'end-of-contract']);
    const history = useHistory();
    const inspectionContactUrl = t(`callToActionUrls.inspection.contact`);

    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractPcpSellBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractPcpSellDashboard');
    const { onAction: onInspectAction } = useAnalyticsActionTracker('onEndOfContractPcpSellInspect');
    useAnalyticsPageViewTracker('endOfContractPcpSell');

    const linkToManheimPhoneNumber = buildLinkToPhoneNumber(t, 'manheim');

    const linkToEarlySettlementPage = (
        <Link to={earlySettlementPagePath(encryptedContractId)} data-testid={'earlySettlementPageLink'} />
    );

    const onContactInspectionClick = (): void => {
        onInspectAction(cleanUrlForAnalytics(inspectionContactUrl));
    };

    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    return (
        <View testId={'solutionsSellView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-text-bold'} testId={'sellTitleParagraph'}>
                {t('paragraphs.sell.title')}
            </Paragraph>
            <Paragraph testId={'sellDetailParagraph'}>{t('paragraphs.sell.detail')}</Paragraph>
            <Paragraph className={'u-text-bold'} testId={'settlementTitleParagraph'}>
                {t('paragraphs.settlement.title')}
            </Paragraph>
            <Paragraph testId={'settlementDetailParagraph'}>
                {textWithComponents(t, 'paragraphs.settlement.detail', { linkToEarlySettlementPage })}
            </Paragraph>
            <Paragraph className={'u-text-bold'} testId={'valuationTitleParagraph'}>
                {t('paragraphs.valuation.title')}
            </Paragraph>
            <Paragraph testId={'valuationDetailParagraph'}>
                {textWithComponents(t, 'paragraphs.valuation.detail')}
            </Paragraph>
            <Paragraph className={'u-text-bold'} testId={'inspectionTitleParagraph'}>
                {t('paragraphs.inspection.title')}
            </Paragraph>
            <Paragraph testId={'inspectionDetailParagraph'}>{t('paragraphs.inspection.detail')}</Paragraph>
            <Paragraph>
                <Button
                    element={'a'}
                    testId={'inspectionLink'}
                    secondary
                    href={inspectionContactUrl}
                    onClick={onContactInspectionClick}
                    target={'_BLANK'}
                >
                    {t('buttons.inspection.label')}
                </Button>
            </Paragraph>
            <Paragraph className={'u-text-bold'} testId={'pleaseNoteTitleParagraph'}>
                {t('paragraphs.pleaseNote.title')}
            </Paragraph>
            <Paragraph testId={'pleaseNoteDetailParagraph1'}>{t('paragraphs.pleaseNote.detail1')}</Paragraph>
            <Paragraph testId={'pleaseNoteDetailParagraph2'}>
                {textWithComponents(t, 'paragraphs.pleaseNote.detail2')}
            </Paragraph>
            <Paragraph testId={'pleaseNoteDetailParagraph3'}>
                {textWithComponents(t, 'paragraphs.pleaseNote.detail3', { linkToManheimPhoneNumber })}
            </Paragraph>
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
