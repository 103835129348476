import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';

export type RequestAdditionalHelpWrapperProps = {
    className?: string;
};

export const AlternativeNotification: React.FC<RequestAdditionalHelpWrapperProps> = ({ className }) => {
    const { t } = useTranslation('request-additional-help-v2');
    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections');

    return (
        <Notification className={className} status={NotificationStatus.info} testId={'alternativeNotification'}>
            {textWithComponents(t, 'notifications.alternative.text', { linkToCollectionsPhoneNumber })}
        </Notification>
    );
};
