import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textWithComponents } from 'utils';
import { linkToMyProfilePage } from 'components/link-to-pages';

const spanForEmailAddress = <span className={'u-text-bold'} data-testid={'emailAddressSpan'} />;

export enum ContactDetailsNotificationMode {
    MissingContactDetails = 'missingContactDetails',
    MissingEmailAddress = 'missingEmailAddress',
    MissingPhoneNumbers = 'missingPhoneNumbers',
    CheckContactDetails = 'checkContactDetails',
}

export const getContactDetailsNotificationMode = (
    emailAddress: string | undefined,
    mobileNumber: string | undefined,
    homePhoneNumber: string | undefined,
    workPhoneNumber: string | undefined,
): ContactDetailsNotificationMode => {
    const hasEmailAddress = !!emailAddress;
    const hasPhoneNumbers = !!mobileNumber || !!homePhoneNumber || !!workPhoneNumber;

    let mode: ContactDetailsNotificationMode;
    if (!hasEmailAddress && !hasPhoneNumbers) {
        mode = ContactDetailsNotificationMode.MissingContactDetails;
    } else if (!hasEmailAddress && hasPhoneNumbers) {
        mode = ContactDetailsNotificationMode.MissingEmailAddress;
    } else if (hasEmailAddress && !hasPhoneNumbers) {
        mode = ContactDetailsNotificationMode.MissingPhoneNumbers;
    } else {
        mode = ContactDetailsNotificationMode.CheckContactDetails;
    }

    return mode;
};

export type ContactDetailsNotificationProps = {
    className?: string;
    mode: ContactDetailsNotificationMode;
    emailAddress?: string;
    phoneNumbersListedLaterInJourney?: boolean;
};

export const ContactDetailsNotification: React.FC<ContactDetailsNotificationProps> = ({
    className,
    mode,
    emailAddress,
    phoneNumbersListedLaterInJourney = false,
}) => {
    const { t } = useTranslation('contact-details-notification');

    const keyFragment = phoneNumbersListedLaterInJourney ? 'willBeListedLaterInJourney' : 'areListedBelowForSelection';
    const phoneNumbersLocation = t(`notifications.phoneNumbersLocations.${keyFragment}`);

    return (
        <Notification
            className={className}
            status={
                mode === ContactDetailsNotificationMode.CheckContactDetails
                    ? NotificationStatus.info
                    : NotificationStatus.warning
            }
            headline={t(`notifications.${mode}.headline`)}
            testId={
                mode === ContactDetailsNotificationMode.CheckContactDetails
                    ? 'checkContactDetailsNotification'
                    : 'missingContactDetailsNotification'
            }
        >
            {textWithComponents(
                t,
                `notifications.${mode}.text`,
                { spanForEmailAddress, linkToMyProfilePage },
                { emailAddress, phoneNumbersLocation },
            )}
        </Notification>
    );
};
