import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { OutstandingPayments } from 'components/outstanding-payments/OutstandingPayments';

export const OutstandingPaymentsPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.OutstandingPayments);
    const { t } = useTranslation('page-titles');
    const { encryptedContractId } = useParams<{ encryptedContractId: string }>();

    return (
        <ContentSection className={'o-content-section--compact uk-outstanding-payments-page'} pageWrapSize={'small'}>
            <PageHeader title={t('outstanding-payments')} />
            <OutstandingPayments encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
