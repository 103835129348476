import { TFunction } from 'i18next';
import { CPDate } from '@cp-shared-8/common-utilities';
import { nowCPDate, parseCPDate } from '@cp-uk/common';

export const getThisContractOnlyFragment = (
    t: TFunction,
    showThisContractOnlyNotification: boolean,
    showWhichPaymentOrEffectiveDateNotification: boolean,
): string => {
    if (!showThisContractOnlyNotification) {
        return '';
    }

    let fragment: string;
    if (showWhichPaymentOrEffectiveDateNotification) {
        fragment = t(`informationNotification.thisContractOnly.followedFragment`);
    } else {
        fragment = t(`informationNotification.thisContractOnly.soleFragment`);
    }

    return fragment;
};

export const getWhichPaymentOrEffectiveDateFragment = (
    t: TFunction,
    key: 'whichPaymentDate' | 'whichEffectiveDate',
    showWhichPaymentOrEffectiveDateNotification: boolean,
    lastBilledPaymentDate: CPDate | undefined,
    nextScheduledPaymentDate: CPDate,
): string => {
    if (!showWhichPaymentOrEffectiveDateNotification) {
        return '';
    }

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const lastBilledPaymentDateMoment = parseCPDate(lastBilledPaymentDate).toMoment();

    let fragment: string;
    if (lastBilledPaymentDateMoment.isAfter(todayMoment, 'day')) {
        fragment = t(`informationNotification.${key}.inFutureFragment`, {
            lastBilledPaymentDate,
            nextScheduledPaymentDate,
        });
    } else if (lastBilledPaymentDateMoment.isSame(todayMoment, 'day')) {
        fragment = t(`informationNotification.${key}.todayFragment`, {
            nextScheduledPaymentDate,
        });
    } else if (lastBilledPaymentDateMoment.isBefore(todayMoment, 'day')) {
        fragment = t(`informationNotification.${key}.inPastFragment`, {
            lastBilledPaymentDate,
            nextScheduledPaymentDate,
        });
    } else {
        fragment = '';
    }

    return fragment;
};

export const getInformationNotificationText = (
    t: TFunction,
    key: 'whichPaymentDate' | 'whichEffectiveDate',
    showThisContractOnlyNotification: boolean,
    showWhichPaymentOrEffectiveDateNotification: boolean,
    lastBilledPaymentDate: CPDate | undefined,
    nextScheduledPaymentDate: CPDate,
) => {
    const thisContractOnlyFragment = getThisContractOnlyFragment(
        t,
        showThisContractOnlyNotification,
        showWhichPaymentOrEffectiveDateNotification,
    );
    const whichPaymentOrEffectiveDateFragment = getWhichPaymentOrEffectiveDateFragment(
        t,
        key,
        showWhichPaymentOrEffectiveDateNotification,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
    );
    return `${thisContractOnlyFragment} ${whichPaymentOrEffectiveDateFragment}`.trim();
};
