import { SimplePageHeroTeaserDto, getUseOurMatchmakerPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'useOurMatchmakerPageHeroTeaser',
    contentEndpoint: getUseOurMatchmakerPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchUseOurMatchmakerPageHeroTeaser = fetchContent;
