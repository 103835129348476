import { TFunction } from 'i18next';
import { SummaryItem, Views } from '../types';
import { AdditionalInformationFormValues, AdditionalInformationSelections, Buttons } from './types';

const booleanToButtons = (value: boolean): Buttons => {
    return value ? Buttons.yes : Buttons.no;
};

const buttonsToBoolean = (value: string): boolean => {
    return value === Buttons.yes;
};

export const buildInitialValues = (
    selections: AdditionalInformationSelections | undefined,
): AdditionalInformationFormValues => {
    if (selections === undefined) {
        return {
            financialCircumstances: {
                reducedIncome: false,
                unemployment: false,
                healthCondition: false,
                oversightOrMismanagementOfFinances: false,
                behindWithOtherCreditors: false,
                inAnIvaOrFilingForBankruptcy: false,
                other: false,
            },
            financialCircumstancesMoreDetail: '',
            additionalSupport: '',
            additionalSupportDetail: '',
            upToDateWithPriorityBills: '',
        };
    }

    const {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesMoreDetail,
        additionalSupport,
        additionalSupportDetail,
        upToDateWithPriorityBills,
    } = selections;

    return {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesMoreDetail,
        additionalSupport: booleanToButtons(additionalSupport),
        additionalSupportDetail,
        upToDateWithPriorityBills: booleanToButtons(upToDateWithPriorityBills),
    };
};

export const buildSelections = (
    t: TFunction,
    {
        financialCircumstances,
        financialCircumstancesMoreDetail,
        additionalSupport,
        additionalSupportDetail,
        upToDateWithPriorityBills,
    }: AdditionalInformationFormValues,
): AdditionalInformationSelections => {
    const {
        reducedIncome,
        unemployment,
        healthCondition,
        oversightOrMismanagementOfFinances,
        behindWithOtherCreditors,
        inAnIvaOrFilingForBankruptcy,
        other,
    } = financialCircumstances;

    const financialCircumstancesAsString = Object.entries(financialCircumstances)
        .filter(([_, value]) => !!value)
        .map(([key, _]) => t(`financialCircumstances.${key}.label`))
        .join(', ');

    return {
        financialCircumstances: {
            reducedIncome,
            unemployment,
            healthCondition,
            oversightOrMismanagementOfFinances,
            behindWithOtherCreditors,
            inAnIvaOrFilingForBankruptcy,
            other,
        },
        financialCircumstancesAsString,
        financialCircumstancesMoreDetail,
        additionalSupport: buttonsToBoolean(additionalSupport),
        additionalSupportDetail,
        upToDateWithPriorityBills: buttonsToBoolean(upToDateWithPriorityBills),
    };
};

export const buildSummaryItem = (t: TFunction): SummaryItem => {
    return {
        view: Views.additionalInformation,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content'),
    };
};

export const financialCircumstancesMoreDetailIsMandatory = (financialCircumstancesOther: boolean): boolean => {
    return financialCircumstancesOther;
};

export const additionalSupportDetailIsMandatory = (additionalSupport: Buttons): boolean => {
    return additionalSupport === Buttons.yes;
};

export const additionalSupportDetailDisabled = (additionalSupport: Buttons): boolean => {
    return additionalSupport !== Buttons.yes;
};
