import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { MethodsOfPayment } from '@cp-uk/common';
import {
    isInputDateSameDayOrAfter,
    isInputDateSameDayOrBefore,
    isInputDateValid,
    isInputNumberInRange,
    isInputNumberInteger,
} from 'utils';
import { ArrangementToPayFormValues } from './types';
import { dateOfFirstPaymentIsMandatory, maxValidDate, minValidDate } from './helpers';

export const validationSchema = (
    t: TFunction,
    payFullArrearsWithinNMonths: boolean,
    totalArrears: number,
): Yup.ObjectSchema<ArrangementToPayFormValues> => {
    const minValidAmount = 1;
    const maxValidAmount = Math.trunc(totalArrears);
    const numberOfMonthsValidValues = ['1M', '2M', '3M', '4M', '5M', '6M', '7M', '8M', '9M', '10M', '11M', '12M'];
    const methodOfPaymentValidValues = ['directDebit', 'cardPayment', 'bankTransfer'];

    return Yup.object()
        .shape<ArrangementToPayFormValues>({
            numberOfMonths: Yup.string()
                .default('')
                .defined()
                .when('_', (_: unknown, schema: Yup.StringSchema<string>) => {
                    if (payFullArrearsWithinNMonths) {
                        return schema
                            .required(t('numberOfMonths.validation.required'))
                            .oneOf(numberOfMonthsValidValues, t('numberOfMonths.validation.required'));
                    } else {
                        return schema.length(0, t('numberOfMonths.validation.mustBeBlank'));
                    }
                }),
            additionalAmount: Yup.string()
                .default('')
                .defined()
                .when('_', (_: unknown, schema: Yup.StringSchema<string>) => {
                    if (payFullArrearsWithinNMonths) {
                        return schema.length(0, t('additionalAmount.validation.mustBeBlank'));
                    } else {
                        return schema
                            .required(t('additionalAmount.validation.required'))
                            .test('integer', t('additionalAmount.validation.integer'), (additionalAmount) =>
                                isInputNumberInteger(additionalAmount),
                            )
                            .test(
                                'range',
                                t('additionalAmount.validation.range', { minValidAmount, maxValidAmount }),
                                (additionalAmount) =>
                                    isInputNumberInRange(additionalAmount, minValidAmount, maxValidAmount),
                            );
                    }
                }),
            methodOfPayment: Yup.string()
                .default('')
                .required(t('methodOfPayment.validation.required'))
                .oneOf(methodOfPaymentValidValues, t('methodOfPayment.validation.required')),
            dateOfFirstPayment: Yup.string()
                .default('')
                .defined()
                .when('methodOfPayment', (methodOfPayment: string, schema: Yup.StringSchema<string>) => {
                    if (dateOfFirstPaymentIsMandatory(methodOfPayment as MethodsOfPayment)) {
                        return schema
                            .required(t('dateOfFirstPayment.validation.required'))
                            .test('format', t('dateOfFirstPayment.validation.format'), (dateOfFirstPayment) =>
                                isInputDateValid(dateOfFirstPayment),
                            )
                            .test(
                                'minValidDate',
                                t('dateOfFirstPayment.validation.minValidDate', { minValidDate }),
                                (dateOfFirstPayment) => isInputDateSameDayOrAfter(dateOfFirstPayment, minValidDate),
                            )
                            .test(
                                'maxValidDate',
                                t('dateOfFirstPayment.validation.maxValidDate', { maxValidDate }),
                                (dateOfFirstPayment) => isInputDateSameDayOrBefore(dateOfFirstPayment, maxValidDate),
                            );
                    } else {
                        return schema.length(0, t('dateOfFirstPayment.validation.mustBeBlank'));
                    }
                }),
        })
        .required();
};
