import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { RequestContact, RequestContactProps } from 'components/request-contact/RequestContact';
import { requestContactPagePath } from 'components/navigation/paths';

export const RequestContactPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.RequestContact);
    const { state } = useLocation<RequestContactProps>();
    const { t } = useTranslation('page-titles');
    const { initialReason: initialReasonParam } = useParams<RequestContactProps>();

    if (!!initialReasonParam) {
        return (
            <Redirect
                to={{
                    pathname: requestContactPagePath(''),
                    state: { initialReason: initialReasonParam },
                }}
            />
        );
    }

    const initialReasonState = (state || {}).initialReason;

    return (
        <ContentSection className={'o-content-section--compact uk-request-contact-page'} pageWrapSize={'small'}>
            <PageHeader title={t('request-contact')} />
            <RequestContact initialReason={initialReasonState} />
        </ContentSection>
    );
};
