import { MethodsOfPayment, nowCPDate } from '@cp-uk/common';
import { PromiseToPayFormValues } from './types';

const todayMoment = nowCPDate().toMoment().startOf('day');
export const minValidDate = todayMoment.clone().add(1, 'day');
export const maxValidDate = todayMoment.clone().add(30, 'day');

export const initialValues: PromiseToPayFormValues = {
    methodOfPayment: '',
    dateOfPayment: '',
};

export const dateOfPaymentIsMandatory = (methodOfPayment: MethodsOfPayment): boolean => {
    return methodOfPayment === 'cardPayment' || methodOfPayment === 'bankTransfer';
};

export const dateOfPaymentDisabled = (methodOfPayment: MethodsOfPayment): boolean => {
    return !dateOfPaymentIsMandatory(methodOfPayment);
};
