import { InsuranceBO } from '@cp-uk/common';

const defaultInsuranceResponse: InsuranceBO = {
    contractDescription: {
        vehicleBrand: 'VOLKSWAGEN',
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        contractNumber: '440123456789',
        registrationNumber: 'EX19ABC',
        productType: 'solutions',
    },
    emailAddress: 'someone@somedomain.com',
    firstName: 'John',
    surname: 'Smith',
    insuranceBrand: 'vw',
    registrationNumber: 'EX19ABC',
    showLetUsKnowOption: true,
};

export const InsuranceResponseWithLetUsKnowOption: InsuranceBO = {
    ...defaultInsuranceResponse,
};

export const InsuranceResponseWithoutLetUsKnowOption: InsuranceBO = {
    ...defaultInsuranceResponse,
    insuranceBrand: 'porsche',
    showLetUsKnowOption: false,
};

export const InsuranceResponseWithoutValidEmail: InsuranceBO = {
    ...defaultInsuranceResponse,
    emailAddress: '',
};

export const InsuranceResponseWithoutLetUsKnowOptionOrValidEmail: InsuranceBO = {
    ...defaultInsuranceResponse,
    insuranceBrand: 'porsche',
    emailAddress: '',
    showLetUsKnowOption: false,
};
