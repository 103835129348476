import { DisagreeOrAgree, RequestAgreementExtension } from '@cp-uk/common';
import { ButtonsDA, ButtonsNY } from './types';

export const buttonsToBoolean = (buttons: string): boolean => {
    return buttons === ButtonsNY.yes;
};

export const buttonsToDisagreeOrAgree = (buttons: string): DisagreeOrAgree => {
    return buttons === ButtonsDA.agree ? 'agree' : 'disagree';
};

export const extensionMonthsToNumber = (extensionMonths: string): number => {
    return Number(extensionMonths.replace('M', ''));
};

export const newAgreementFinanceBrandIsMandatory = (alreadyHaveNewAgreement: string): boolean => {
    return alreadyHaveNewAgreement === ButtonsNY.yes;
};

export const newAgreementFinanceBrandDisabled = (alreadyHaveNewAgreement: string): boolean => {
    return alreadyHaveNewAgreement !== ButtonsNY.yes;
};

export const newAgreementProposalNumberDisabled = (alreadyHaveNewAgreement: string): boolean => {
    return alreadyHaveNewAgreement !== ButtonsNY.yes;
};

export const show7To12MonthsParagraph = (extensionMonths: string): boolean => {
    const extensionMonthsAsNumber = extensionMonthsToNumber(extensionMonths);
    return extensionMonthsAsNumber >= 7 && extensionMonthsAsNumber <= 12;
};

export const changeInCircumstanceIsMandatory = (
    canAffordWithCurrentLevelsOfIncomeAndExpenditure: string,
    incomeNotExpectedToChange: string,
    expenditureNotExpectedToChange: string,
): boolean => {
    return (
        canAffordWithCurrentLevelsOfIncomeAndExpenditure === ButtonsDA.disagree ||
        incomeNotExpectedToChange === ButtonsDA.disagree ||
        expenditureNotExpectedToChange === ButtonsDA.disagree
    );
};

export const changeInCircumstanceDisabled = (
    canAffordWithCurrentLevelsOfIncomeAndExpenditure: string,
    incomeNotExpectedToChange: string,
    expenditureNotExpectedToChange: string,
): boolean => {
    return (
        canAffordWithCurrentLevelsOfIncomeAndExpenditure !== ButtonsDA.disagree &&
        incomeNotExpectedToChange !== ButtonsDA.disagree &&
        expenditureNotExpectedToChange !== ButtonsDA.disagree
    );
};

export const getFormNameForAnalytics = ({
    inArrears,
    canAffordWithCurrentLevelsOfIncomeAndExpenditure,
    incomeNotExpectedToChange,
    expenditureNotExpectedToChange,
    extensionMonths,
}: RequestAgreementExtension): string => {
    if (inArrears) {
        return 'Arrears';
    } else if (
        canAffordWithCurrentLevelsOfIncomeAndExpenditure === 'disagree' ||
        incomeNotExpectedToChange === 'disagree' ||
        expenditureNotExpectedToChange === 'disagree'
    ) {
        return 'Financial Difficulty';
    } else if (extensionMonths >= 7) {
        return '7 - 12 Months';
    } else {
        return 'RPA';
    }
};
