export interface UseLocalStorageResponse {
    getLocalValue: (key: string) => string | undefined;
    setLocalValue: (key: string, value: string) => void;
}

export const useLocalStorage = (): UseLocalStorageResponse => {
    const getLocalValue = (key: string): string | undefined => {
        // Wrapping local storage getter in a try catch to prevent errors and exceptions for unsupported devices
        try {
            return localStorage?.getItem(key) ?? undefined;
        } catch {
            return undefined;
        }
    };

    const setLocalValue = (key: string, value: string): void => {
        // Wrapping local storage setter in a try catch to prevent errors and exceptions for unsupported devices
        try {
            localStorage?.setItem(key, value);
        } catch {}
    };

    return { getLocalValue, setLocalValue };
};
