import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

export const NoConnectionNotification: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Notification
            status={NotificationStatus.error}
            headline={t('error.noConnection.title')}
            text={t('error.noConnection.text')}
            testId={'noConnectionNotification'}
        />
    );
};

