import { TFunction } from 'i18next';
import { Schema, ValidateOptions, ValidationError } from 'yup';
import { Moment } from 'moment-timezone';
import { parseCPDateFromInput } from '@cp-uk/common';

export const mockedT: TFunction = (key: string, _tOptions?: unknown): string => key;

export const validateAndCaptureError = async (
    schema: Schema<object | undefined>,
    value: unknown,
    options?: ValidateOptions,
): Promise<ValidationError> => {
    return schema.validate(value, options).catch((error) => error);
};

export const pathAndMessage = (error: ValidationError): string => {
    const { path, message } = error;

    return `${path} - ${message}`;
};

export const pathsAndMessages = (error: ValidationError): string[] => {
    const { inner } = error;

    return inner.map((ve) => pathAndMessage(ve));
};

export type InputValue = string | null | undefined;

export const isInputDateValid = (inputDate: InputValue): boolean => {
    return parseCPDateFromInput(inputDate).isValid();
};

export const isInputDateSameDayOrBefore = (inputDate: InputValue, comparisonDateMoment: Moment): boolean => {
    return parseCPDateFromInput(inputDate).toMoment().isSameOrBefore(comparisonDateMoment, 'day');
};

export const isInputDateSameDay = (inputDate: InputValue, comparisonDateMoment: Moment): boolean => {
    return parseCPDateFromInput(inputDate).toMoment().isSame(comparisonDateMoment, 'day');
};

export const isInputDateSameDayOrAfter = (inputDate: InputValue, comparisonDateMoment: Moment): boolean => {
    return parseCPDateFromInput(inputDate).toMoment().isSameOrAfter(comparisonDateMoment, 'day');
};

export const isInputNumberInteger = (inputNumber: InputValue): boolean => {
    return !inputNumber || /^-?(0|[1-9][0-9]*)$/.test(inputNumber);
};

export const isInputNumberInRange = (
    inputNumber: InputValue,
    minValidNumber: number,
    maxValidNumber: number,
): boolean => {
    const valueAsNumber = Number(inputNumber);
    return (
        !inputNumber ||
        (!Number.isNaN(valueAsNumber) && valueAsNumber >= minValidNumber && valueAsNumber <= maxValidNumber)
    );
};

export const isInputRegistrationNumberValid = (inputRegistrationNumber: InputValue): boolean => {
    if (!inputRegistrationNumber) {
        return true;
    }
    const registrationNumberValue = inputRegistrationNumber.trim();
    // Do not accept any special characters besides letters, numbers and spaces
    if (!/^[a-z0-9 ]*$/i.test(registrationNumberValue)) {
        return false;
    }
    // Do not accept any consecutive spaces
    if (/ + /i.test(registrationNumberValue)) {
        return false;
    }
    const registrationNumberWithoutSpaces = registrationNumberValue.replace(/ /g, '');
    return !(registrationNumberWithoutSpaces.length < 2 || registrationNumberWithoutSpaces.length > 7);
};
