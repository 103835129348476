import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sha1 from 'sha1';
import { Notification } from '@vwfs-bronson/bronson-react';
import { isTodayInDateRange, useLocalStorage } from 'utils';

type NotificationStatus = Parameters<typeof Notification>[0]['status'];

export const LAST_DISMISSED_KEY = 'lastDismissed';

export const BannerNotification: React.FC = () => {
    const { t } = useTranslation('banner-notification');
    const { getLocalValue, setLocalValue } = useLocalStorage();
    const [visible, setVisible] = useState(true);

    const status = t('status', '');
    const headline = t('headline', '');
    const text = t('text', '');
    const startDate = t('startDate', '');
    const endDate = t('endDate', '');
    const className = t('className', '');

    if (!status && !headline && !text && !startDate && !endDate) {
        return null;
    }

    if (!isTodayInDateRange(startDate, endDate)) {
        return null;
    }

    const message = `${status}|${headline}|${text}|${startDate}|${endDate}`;
    const hash = sha1(message);
    const lastDismissed = getLocalValue(LAST_DISMISSED_KEY);

    const isNotificationAlreadyDismissed = !!lastDismissed && lastDismissed === hash;

    if (isNotificationAlreadyDismissed) {
        return null;
    }

    const onButtonClick = (): void => {
        setLocalValue(LAST_DISMISSED_KEY, hash);
        setVisible(false);
    };

    return (
        <Notification
            className={className}
            visible={visible}
            status={status as NotificationStatus}
            showCloseButton
            onCloseClick={onButtonClick}
            title={headline}
            text={text}
            testId={'bannerNotification'}
        />
    );
};
