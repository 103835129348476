// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/validated-checkbox/ValidatedCheckbox.tsx
// (there weren't enough/working properties on the original)

import React, { FocusEventHandler, FormEventHandler, Fragment } from 'react';
import { useField } from 'formik';
import { Checkbox, FormField } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { hasError, ValidationErrorMessage } from 'components/errors';
import { HelpText } from 'components/help-text/HelpText';

export type ValidatedCheckboxProps = {
    name: string;
    label: string | React.ReactNode;
    helpText?: string | React.ReactNode;
    className?: string;
    testId?: string;
    id?: string;
    tooltip?: string;
    checked?: boolean;
    handleChange?: FormEventHandler<HTMLInputElement>;
    disabled?: boolean;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const ValidatedCheckbox: React.FC<ValidatedCheckboxProps> = ({
    name,
    label,
    helpText,
    className,
    testId,
    id,
    tooltip,
    checked,
    handleChange,
    disabled,
    isMandatory = false,
    withHtmlValidationWarning = false,
    onFocus,
    ...otherProps
}) => {
    const [field, meta] = useField({ name, type: 'checkbox' });

    const notion = isMandatory ? <span className="c-form-field__notion">* </span> : null;
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    return (
        <FormField type="checkbox" testId={`${testId}FormField`} id={id} onChange={handleChange}>
            <Checkbox
                className={className}
                checked={checked}
                disabled={disabled}
                error={hasError(meta)}
                testId={testId}
                onFocus={onFocus}
                {...field}
                {...otherProps}
            >
                {label}
                {notion}
                {infoIcon}
                {errorMessageElseHelpText}
            </Checkbox>
        </FormField>
    );
};
