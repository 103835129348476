import { EarlyTerminationExistingBO, EarlyTerminationGenerateBO } from '@cp-uk/common';
import { ContractDescriptionBO } from '@cp-uk/common/src';

const contractDescription: ContractDescriptionBO = {
    vehicleBrand: 'VOLKSWAGEN',
    vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
    contractNumber: '440123456789',
    registrationNumber: 'EX19ABC',
    productType: 'contractHire',
};

export const EarlyTerminationGenerate: EarlyTerminationGenerateBO = {
    contractDescription,
    state: 'generate',
    generateView: {
        encryptedContractId: 'encrypted0000440123456789',
        emailAddress: 'john.smith@gmail.com',
        postalAddress: 'Flat 4, Cromwell House, 123 High Street, Anytown, Anycounty, EG1 2AB',
        otherChangeInProgress: false,
        addressChangeInProgress: false,
        addressChangeByPhone: false,
        productType: 'contractHire',
        financeBrand: 'vw',
    },
    enquiryView: undefined,
    completeView: undefined,
};

export const EarlyTerminationExisting: EarlyTerminationExistingBO = {
    contractDescription,
    state: 'existing',
    generateView: undefined,
    enquiryView: {
        rightOfWithdrawal: false,
        productType: 'contractHire',
        financeBrand: 'vw',
        quoteCreationDate: '2020-03-01T00:00:00Z',
        quoteExpiryDate: '2020-03-31T00:00:00Z',
        monthsRemaining: 4,
        enquiryDetailsMode: 'old',
        outstandingBalance: 4557.12,
        interestRebateAmount: 2050.68,
        creditForEarlyReturn: 0,
        terminationRental: 2506.44,
        vat: 0,
        arrears: 3797.6,
        terminationAmount: 6304.04,
    },
    completeView: {
        productType: 'contractHire',
        financeBrand: 'vw',
        contractNumber: '440123456789',
        terminationAmount: 6304.04,
        quoteExpiryDate: '2020-03-31T00:00:00Z',
    },
};

export const EarlyTerminationExistingNew: EarlyTerminationExistingBO = {
    ...EarlyTerminationExisting,
    enquiryView: {
        ...EarlyTerminationExisting.enquiryView,
        enquiryDetailsMode: 'new',
        outstandingBalance: 3797.6,
        interestRebateAmount: 875.57,
        creditForEarlyReturn: 833.33,
        terminationRental: 2088.7,
        vat: 417.74,
    },
};
