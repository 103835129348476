import React, { Fragment } from 'react';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { ContractDetailsBO } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchContractDetails } from './ContractDetailsSlice';
import { selectContractDetailsForSpecific } from './ContractDetailsSelector';
import { ContractDetailsProvider } from './ContractDetailsProvider';
import { FinancialDetails } from './financial-details/FinancialDetails';
import { ServiceDetails } from './service-details/ServiceDetails';
import { VehicleDetails } from './vehicle-details/VehicleDetails';
import { BundledProducts } from './bundled-products/BundledProducts';

const ContractDetailsUi: React.FC<{ contractNumber: string; contractDetails: ContractDetailsBO | undefined }> = ({
    contractNumber,
    contractDetails,
}) => {
    if (contractDetails === undefined) {
        return null;
    }

    const { financialDetails, serviceDetails, vehicleDetails, bundledProducts } = contractDetails;

    return (
        <Fragment>
            <ContractDetailsProvider contractNumber={contractNumber} contractDetails={contractDetails}>
                <FinancialDetails financialDetails={financialDetails} />
                <ServiceDetails serviceDetails={serviceDetails} />
                <VehicleDetails vehicleDetails={vehicleDetails} />
                <BundledProducts bundledProducts={bundledProducts} />
            </ContractDetailsProvider>
        </Fragment>
    );
};

const ContractDetailsWithHandlers = withLoadingAndNoConnectionHandler(ContractDetailsUi);

export const ContractDetails: React.FC<{ encryptedContractId: string; contractNumber: string }> = ({
    encryptedContractId,
    contractNumber,
}) => {
    const { data: contractDetails, isLoading, loadingError } = useGetContractBasedApiData(
        contractNumber,
        fetchContractDetails,
        selectContractDetailsForSpecific,
        encryptedContractId,
    );

    return (
        <ContractDetailsWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            contractNumber={contractNumber}
            contractDetails={contractDetails}
        />
    );
};
