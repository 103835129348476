import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { AddressSectionBO, ChangeAddressRequest } from '@cp-uk/common';
import { upperCaseFirstLetterOfEachWord, joinTruthyValues } from 'utils';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ChangeAddress } from './change-address/ChangeAddress';
import { MyProfileContext } from '../MyProfileContext';
import { valueOrErrorTag } from 'components/helpers';

const cityUpperCaseExceptions: string[] = ['and', 'by', 'in', 'le', 'of', 'on', 'over', 'the', 'upon', 'with'];

const countyUpperCaseExceptions: string[] = ['and', 'of'];

const getFullStreet = (
    flatPoBox: string | undefined,
    propertyName: string | undefined,
    houseNumber: string | undefined,
    street: string | undefined,
    district: string | undefined,
): string => {
    return joinTruthyValues(
        ', ',
        flatPoBox,
        joinTruthyValues(' ', upperCaseFirstLetterOfEachWord(propertyName), houseNumber, street),
        district,
    );
};

const getFullCity = (
    city: string | undefined,
    county: string | undefined,
    postCode: string | undefined,
    country: string | undefined,
): string => {
    return joinTruthyValues(
        ', ',
        upperCaseFirstLetterOfEachWord(city, cityUpperCaseExceptions),
        upperCaseFirstLetterOfEachWord(county, countyUpperCaseExceptions),
        postCode?.toUpperCase(),
        country,
    );
};

export const AddressSection: React.FC<{ addressSection: AddressSectionBO }> = ({
    addressSection: propsAddressSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const { newAddressSection, setNewAddressSection } = useContext(MyProfileContext);
    const { t } = useTranslation('address-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker('onEditProfileAddressSubmitFailed');
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('onEditProfileAddressSuccess');

    const addressSection = newAddressSection ?? propsAddressSection;

    const {
        changeState,
        careOf,
        flatPoBox,
        propertyName,
        houseNumber,
        street,
        district,
        city,
        county,
        postCode,
        country,
        changeAddress,
    } = addressSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        onChangeStartAction();
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction('Address');
    };
    const onChangeFailure = (): void => {
        // NOTE: Failure notification is displayed within the change control and should NOT close here
        onChangeFailureAction();
    };
    const onChangeSuccess = (changeAddressRequest: ChangeAddressRequest): void => {
        const newAddressSection: AddressSectionBO = {
            changeState: 'inProgress',
            careOf: changeAddressRequest.careOf,
            flatPoBox: changeAddressRequest.flatPoBox,
            propertyName: changeAddressRequest.propertyName,
            houseNumber: changeAddressRequest.houseNumber,
            street: changeAddressRequest.street,
            district: changeAddressRequest.district,
            city: changeAddressRequest.city,
            county: undefined,
            postCode: changeAddressRequest.postCode,
            country: changeAddressRequest.country,
            changeAddress: undefined,
        };
        setNewAddressSection(newAddressSection);
        setIsChanging(false);
        onChangeSuccessAction();
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (careOf) {
        descriptionListItems.push({
            label: t('careOf.label'),
            value: careOf,
            testId: 'careOf',
        });
    }
    descriptionListItems.push(
        {
            label: t('street.label'),
            value: getFullStreet(flatPoBox, propertyName, houseNumber, street, district),
            testId: 'street',
        },
        {
            label: t('city.label'),
            value: valueOrErrorTag(t, getFullCity(city, county, postCode, country)),
            testId: 'city',
        },
    );

    return (
        <Fragment>
            {isChanging ? (
                <ChangeAddress
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changeAddress={changeAddress}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={t('title')}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${changeState}`)}
                    buttonDisabled={changeState !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'addressSection'}
                >
                    {changeState === 'inProgress' && (
                        <Notification
                            className={'u-mb'}
                            status={NotificationStatus.warning}
                            headline={t('inProgressNotification.headline')}
                            text={t('inProgressNotification.text')}
                            testId={'inProgressNotification'}
                        />
                    )}
                    <DescriptionListHorizontal items={descriptionListItems} />
                </DataOverview>
            )}
        </Fragment>
    );
};
