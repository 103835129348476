import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { GreetingBO } from '@cp-uk/common';
import { fetchGreeting } from './GreetingSlice';
import { selectGreeting } from './GreetingSelector';

const getGreetingName = (greeting: GreetingBO | undefined): string => {
    return greeting?.firstName ?? '';
};

const GreetingUi: React.FC<{ greeting: GreetingBO | undefined; isLoading: boolean }> = ({ greeting, isLoading }) => {
    const { t } = useTranslation('greeting');

    const welcome = t('welcome');
    let fullGreetingText: string;
    if (isLoading) {
        fullGreetingText = `${welcome}...`;
    } else {
        const greetingName = getGreetingName(greeting);
        fullGreetingText = greetingName ? `${welcome}, ${greetingName}` : welcome;
    }

    return (
        <Suspense fallback={<Spinner center />}>
            <Heading level={2} testId={'greeting'}>
                {fullGreetingText}
            </Heading>
        </Suspense>
    );
};

export const Greeting: React.FC = () => {
    const { data: greeting, isLoading } = useGetSimpleApiData(
        fetchGreeting,
        getSimpleDataSelector(selectGreeting),
    );

    return <GreetingUi greeting={greeting} isLoading={isLoading} />;
};
