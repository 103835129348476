export enum Buttons {
    no = 'no',
    yes = 'yes',
}

export type SituationViewFormValues = {
    causeOfArrears: CauseOfArrearsFormValues;
    // moved outside causeOfArrears to created validated group of checkboxes
    causeOfArrearsMoreDetail: string;
    consentInformationProvided: boolean;
    relatedToCovid19: string;
    vulnerableCustomer: string;
    vulnerableCustomerDetail: string;
    continueToPayRentals: string;
    endAgreement: string;
    payFullArrearsWithin30Days: string;
    contributeTowardsArrears: string;
    payFullArrearsWithin3Months: string;
    payFullArrearsWithin12Months: string;
};

export type CauseOfArrearsFormValues = {
    reducedIncome: boolean;
    unemployment: boolean;
    healthCondition: boolean;
    oversightOrMismanagementOfFinances: boolean;
    behindWithOtherCreditors: boolean;
    inAnIvaOrFilingForBankruptcy: boolean;
    other: boolean;
};
