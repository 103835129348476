import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const countryFixedValue = 'GB';

export const postCodeRegex = /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/;
export const countryRegex = RegExp(`^${countryFixedValue}$`);

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        careOf: Yup.string()
            .optional()
            .max(50, t('careOf.validation.maxLength')),
        flatPoBox: Yup.string()
            .optional()
            .max(50, t('flatPoBox.validation.maxLength')),
        propertyName: Yup.string()
            .optional()
            .max(50, t('propertyName.validation.maxLength')),
        houseNumber: Yup.string()
            .optional()
            .max(50, t('houseNumber.validation.maxLength')),
        street: Yup.string()
            .optional()
            .max(50, t('street.validation.maxLength')),
        district: Yup.string()
            .optional()
            .max(50, t('district.validation.maxLength')),
        city: Yup.string()
            .required(t('city.validation.required'))
            .max(50, t('city.validation.maxLength')),
        postCode: Yup.string()
            .required(t('postCode.validation.required'))
            .matches(postCodeRegex, t('postCode.validation.format')),
        country: Yup.string()
            .required(t('country.validation.required'))
            .matches(countryRegex, t('country.validation.mustBeGB')),
    });
};
