import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { CPDate } from '@cp-shared-8/common-utilities';
import { nowCPDate, parseCPDate } from '@cp-uk/common';
import { isInputDateSameDay, isInputDateSameDayOrAfter, isInputDateSameDayOrBefore, isInputDateValid } from 'utils';

export const validationSchema = (
    t: TFunction,
    lastBilledPaymentDate: CPDate | undefined,
    nextScheduledPaymentDate: CPDate,
    minValidPaymentDate: CPDate,
    maxValidPaymentDate: CPDate,
) => {
    const todayMoment = nowCPDate().toMoment().startOf('day');
    const lastBilledPaymentDateMoment = parseCPDate(lastBilledPaymentDate).toMoment();
    const nextScheduledPaymentDateMoment = parseCPDate(nextScheduledPaymentDate).toMoment();
    const minValidPaymentDateMoment = parseCPDate(minValidPaymentDate).toMoment();
    const maxValidPaymentDateMoment = parseCPDate(maxValidPaymentDate).toMoment();

    const keyFragment: string =
        !!lastBilledPaymentDate && lastBilledPaymentDateMoment.isSameOrAfter(todayMoment, 'day')
            ? 'Subsequent'
            : 'Next';
    const subsequentOrNext: string = t(`paymentDate.validation.scheduled${keyFragment}`);

    const onOrAfterMinValidOptions = { minValidPaymentDate };
    const onOrAfterMaxValidOptions = { maxValidPaymentDate };
    const notNextScheduledOptions = { subsequentOrNext, currentPaymentDate: nextScheduledPaymentDate };

    return Yup.object().shape({
        paymentDate: Yup.string()
            .required(t('paymentDate.validation.required'))
            .test('format', t('paymentDate.validation.format'),
                (paymentDate) => isInputDateValid(paymentDate),
            )
            .test('onOrAfterMinValid', t('paymentDate.validation.onOrAfterMinValid', onOrAfterMinValidOptions),
                (paymentDate) => isInputDateSameDayOrAfter(paymentDate, minValidPaymentDateMoment),
            )
            .test('onOrBeforeMaxValid', t('paymentDate.validation.onOrBeforeMaxValid', onOrAfterMaxValidOptions),
                (paymentDate) => isInputDateSameDayOrBefore(paymentDate, maxValidPaymentDateMoment),
            )
            .test('notNextScheduled', t('paymentDate.validation.notNextScheduled', notNextScheduledOptions),
                (paymentDate) => !isInputDateSameDay(paymentDate, nextScheduledPaymentDateMoment),
            ),
        lastDayOfMonth: Yup.boolean()
            .required(t('lastDayOfMonth.validation.required')),
    });
};
