import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Paragraph } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { OtherFinanceBrandsBO, WebsiteFinanceBrands } from '@cp-uk/common';
import { currentBrand } from '../../config';
import { withLoadingHandler } from '../integration-wrapper';
import { fetchOtherFinanceBrands } from './OtherFinanceBrandsCardSlice';
import { selectOtherFinanceBrands } from './OtherFinanceBrandsCardSelector';
import { SimpleLink } from '../simple-link/SimpleLink';
import { dashboardPagePath } from '../navigation/paths';

export const OtherFinanceBrandsCardUi: React.FC<{ otherFinanceBrands: OtherFinanceBrandsBO | undefined }> = ({
    otherFinanceBrands,
}) => {
    const { t } = useTranslation('other-finance-brands-card');

    if (otherFinanceBrands === undefined) {
        return null;
    }

    const { websiteFinanceBrands } = otherFinanceBrands;

    if (websiteFinanceBrands.length === 0) {
        return null;
    }

    return (
        <Card element={'article'} className={'u-text-left u-mb'} testId={'otherFinanceBrandsCard'}>
            <Paragraph className={'u-text-bold u-mb-xsmall'} testId={'titleParagraph'}>
                {t('title')}
            </Paragraph>
            {websiteFinanceBrands.map(
                (websiteFinanceBrand: WebsiteFinanceBrands): React.ReactNode => {
                    const origin = window.location.origin.replace(currentBrand, websiteFinanceBrand);
                    const href = `${origin}${dashboardPagePath()}`;
                    return (
                        <span key={websiteFinanceBrand} className={'u-block'}>
                            <SimpleLink href={href} testId={`${websiteFinanceBrand}Link`}>
                                {t(`companyNames.${websiteFinanceBrand}`)}
                            </SimpleLink>
                        </span>
                    );
                },
            )}
        </Card>
    );
};

const OtherFinanceBrandsCardWithHandlers = withLoadingHandler(OtherFinanceBrandsCardUi);

export const OtherFinanceBrandsCard: React.FC = () => {
    const { data: otherFinanceBrands, isLoading } = useGetSimpleApiData(
        fetchOtherFinanceBrands,
        getSimpleDataSelector(selectOtherFinanceBrands),
    );

    return <OtherFinanceBrandsCardWithHandlers isLoading={isLoading} otherFinanceBrands={otherFinanceBrands} />;
};
