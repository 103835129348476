export const currentBuild = {
    version: process.env.REACT_APP_WEBSITE_BUILD_VERSION || '1.0.0',
    dateTime: process.env.REACT_APP_WEBSITE_BUILD_DATETIME || '2000-01-01T00:00:00Z',
    timestamp: process.env.REACT_APP_WEBSITE_BUILD_TIMESTAMP || '20000101_0000',
};
export const currentBrand = process.env.REACT_APP_WEBSITE_BRAND || 'brand';
export const currentBrandName = process.env.REACT_APP_WEBSITE_BRAND_NAME || 'Brand Financial Services';
export const currentBronson = {
    baseUrl: process.env.REACT_APP_WEBSITE_BRONSON_BASE_URL,
    theme: process.env.REACT_APP_WEBSITE_BRONSON_THEME,
    modifier: process.env.REACT_APP_WEBSITE_BRONSON_MODIFIER,
    version: process.env.REACT_APP_WEBSITE_BRONSON_VERSION,
    faviconPath: process.env.REACT_APP_WEBSITE_BRONSON_FAVICON_PATH,
    logoExt: process.env.REACT_APP_WEBSITE_BRONSON_LOGO_EXT,
};
export const integrationApiBaseUrl = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
export const integrationApiBaseUrlLocal = process.env.REACT_APP_API_BASE_URL_LOCAL;
export const apiGatewayConsumerName = 'vwfs-du-cpuk';
export const authIdpHint = process.env.REACT_APP_AUTH_IDP_HINT;
export const chatbotApiKey = process.env.REACT_APP_CHATBOT_API_KEY;
export const featureToggles = {
    financialSupportAndWellbeing: {
        customerSupport:
            process.env.REACT_APP_FEATURE_TOGGLES_FINANCIAL_SUPPORT_AND_WELLBEING_CUSTOMER_SUPPORT === 'true',
        freeSupportAndIndependentAdvice:
            process.env.REACT_APP_FEATURE_TOGGLES_FINANCIAL_SUPPORT_AND_WELLBEING_FREE_SUPPORT_AND_INDEPENDENT_ADVICE === 'true',
        theRisingCostOfLiving:
            process.env.REACT_APP_FEATURE_TOGGLES_FINANCIAL_SUPPORT_AND_WELLBEING_THE_RISING_COST_OF_LIVING === 'true',
        optionsAvailableToYou:
            process.env.REACT_APP_FEATURE_TOGGLES_FINANCIAL_SUPPORT_AND_WELLBEING_OPTIONS_AVAILABLE_TO_YOU === 'true',
        moneyWorries:
            process.env.REACT_APP_FEATURE_TOGGLES_FINANCIAL_SUPPORT_AND_WELLBEING_MONEY_WORRIES === 'true',
    },
};
