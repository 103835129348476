export function getEndOfContractEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/end-of-contract/${encryptedContractId}`;
}

export function getRequestRefinanceQuoteEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-refinance-quote/${encryptedContractId}`;
}

export function getRequestAgreementExtensionEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-agreement-extension/${encryptedContractId}`;
}

export function getRequestCallBackEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-call-back/${encryptedContractId}`;
}
