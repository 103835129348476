export interface ReceivingIncomeFormValues {
    none: boolean;
    wage: boolean;
    benefits: boolean;
    govtContrib: boolean;
}

export interface ConsiderEndingProductPurchaseFormValues {
    sell: boolean;
    partExchange: boolean;
    voluntaryTermination: boolean;
}

export interface ConsiderEndingProductHireFormValues {
    earlyTermination: boolean;
}

export interface ConsiderEndingProductLeaseFormValues {
    leaseSettlement: boolean;
}

export interface RequestContactFormValues {
    [key: string]:
        | boolean
        | number
        | string
        | ReceivingIncomeFormValues
        | ConsiderEndingProductPurchaseFormValues
        | ConsiderEndingProductHireFormValues
        | ConsiderEndingProductLeaseFormValues;
    vulnerableCustomer: boolean;
    phoneNumber: string;
    reason: string;
    message: string;
    causeFinancialDifficulty: string;
    receivingIncome: ReceivingIncomeFormValues;
    behindWithOther: boolean;
    behindWithOtherDetail: string;
    knowWhenReturnNormal: boolean;
    knowWhenReturnNormalDuration: string;
    notMaintainMonthlyPayments: boolean;
    notMaintainMonthlyPaymentsDetail: boolean;
    notMaintainMonthlyPaymentsDuration: string;
    continueOption: string;
    additionalSupport: boolean;
    additionalSupportDetail: string;
    consentInformationProvided: boolean;
    incomeSalaryWages: string;
    incomeBenefitsOther: string;
    incomeTotal: string;
    expenditureVolkswagenFinance: string;
    expenditureRentMortgage: string;
    expenditureWaterGasElectric: string;
    expenditureCouncilTax: string;
    expenditureChildCareMaintenance: string;
    expenditureLandlineInternetTv: string;
    expenditureMobiles: string;
    expenditureCarInsuranceTax: string;
    expenditureFuelTravelCosts: string;
    expenditureHousekeeping: string;
    expenditureOther: string;
    expenditureOtherCreditors: string;
    expenditureTotal: string;
    disposableIncomeTotal: string;
    considerEndingProduct: string;
    considerEndingProductPurchase: ConsiderEndingProductPurchaseFormValues;
    considerEndingProductHire: ConsiderEndingProductHireFormValues;
    considerEndingProductLease: ConsiderEndingProductLeaseFormValues;
}

export enum ReasonCategories {
    other = 'O',
    financialDifficulty = 'FD',
}

export enum Reasons {
    makeAPayment = 'MAP',
    financialDifficulty = 'FD',
    insolvencyBankruptcy = 'IB',
    specialCircumstances = 'SC',
    optionsToEndMyAgreement = 'OTEMA',
    voluntaryTermination = 'VT',
    repossession = 'R',
}

export enum Durations {
    oneMonth = '1M',
    twoMonths = '2M',
    threeMonths = '3M',
    fourMonths = '4M',
    fiveMonths = '5M',
    sixMonths = '6M',
    sevenMonths = '7M',
    eightMonths = '8M',
    nineMonths = '9M',
    tenMonths = '10M',
    elevenMonths = '11M',
    twelveMonths = '12M',
}

export enum ContinueOptions {
    continuePaying = 'CP',
    comeOutOfAgreement = 'COOA',
}

export enum ConsiderEndingProductCategories {
    purchase = 'P',
    hire = 'H',
    lease = 'L',
}

export enum ConsiderEndingProducts {
    solutions = 'S',
    hirePurchase = 'HP',
    leasePurchase = 'LP',
    contractHire = 'CH',
    financeLease = 'FL',
}
