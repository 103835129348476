import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import { CookiePolicy } from 'components/cookie-policy/CookiePolicy';

export const CookiePolicyPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.CookiePolicy);

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.CookiePolicy} />
            <CookiePolicy />
        </Fragment>
    );
};
