import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ChangeContactDetailsRequest, ContactDetailsSectionBO } from '@cp-uk/common';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ChangeContactDetails } from './change-contact-details/ChangeContactDetails';
import { MyProfileContext } from '../MyProfileContext';
import { valueOrErrorTag } from 'components/helpers';

export const ContactDetailsSection: React.FC<{ contactDetailsSection: ContactDetailsSectionBO }> = ({
    contactDetailsSection: propsContactDetailsSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const { newContactDetailsSection, setNewContactDetailsSection } = useContext(MyProfileContext);
    const { t } = useTranslation('contact-details-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onAction: onChangeFailureAction } = useAnalyticsActionTracker('onEditProfileContactSubmitFailed');
    const { onAction: onChangeSuccessAction } = useAnalyticsActionTracker('onEditProfileContactSuccess');

    const contactDetailsSection = newContactDetailsSection ?? propsContactDetailsSection;

    const {
        changeState,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
        changeContactDetails,
    } = contactDetailsSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        onChangeStartAction();
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction('Contact');
    };
    const onChangeFailure = (): void => {
        // NOTE: Failure notification is displayed within the change control and should NOT close here
        onChangeFailureAction();
    };
    const onChangeSuccess = (changeContactDetailsRequest: ChangeContactDetailsRequest): void => {
        const newContactDetailsSection: ContactDetailsSectionBO = {
            changeState: 'inProgress',
            emailAddress: changeContactDetailsRequest.emailAddress,
            mobileNumber: changeContactDetailsRequest.mobileNumber,
            homePhoneNumber: changeContactDetailsRequest.homePhoneNumber,
            workPhoneNumber: changeContactDetailsRequest.workPhoneNumber,
            changeContactDetails: undefined,
        };
        setNewContactDetailsSection(newContactDetailsSection);
        setIsChanging(false);
        onChangeSuccessAction();
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('emailAddress.label'),
            value: valueOrErrorTag(t, emailAddress?.toLowerCase()),
            testId: 'emailAddress',
        },
    ];
    if (homePhoneNumber) {
        descriptionListItems.push({
            label: t('homePhoneNumber.label'),
            value: homePhoneNumber,
            testId: 'homePhoneNumber',
        });
    }
    if (mobileNumber) {
        descriptionListItems.push({
            label: t('mobileNumber.label'),
            value: mobileNumber,
            testId: 'mobileNumber',
        });
    }
    if (workPhoneNumber) {
        descriptionListItems.push({
            label: t('workPhoneNumber.label'),
            value: workPhoneNumber,
            testId: 'workPhoneNumber',
        });
    }

    return (
        <Fragment>
            {isChanging ? (
                <ChangeContactDetails
                    onChangeCancel={onChangeCancel}
                    onChangeFailure={onChangeFailure}
                    onChangeSuccess={onChangeSuccess}
                    changeContactDetails={changeContactDetails}
                />
            ) : (
                <DataOverview
                    noBorder
                    title={t('title')}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonTooltip={t(`buttonTooltips.${changeState}`)}
                    buttonDisabled={changeState !== 'ok'}
                    onButtonClick={onChangeStart}
                    testId={'contactDetailsSection'}
                >
                    <Notification
                        className={'u-mb'}
                        status={NotificationStatus.info}
                        headline={t('informationNotification.headline')}
                        text={t('informationNotification.text')}
                    />
                    {changeState === 'inProgress' && (
                        <Notification
                            className={'u-mb'}
                            status={NotificationStatus.warning}
                            headline={t('inProgressNotification.headline')}
                            text={t('inProgressNotification.text')}
                            testId={'inProgressNotification'}
                        />
                    )}
                    <DescriptionListHorizontal items={descriptionListItems} />
                </DataOverview>
            )}
        </Fragment>
    );
};
