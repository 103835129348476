import { currentBuild } from '../config';

export const appendBuildTimestamp = (url: string | undefined): string | undefined => {
    if (!url) {
        return url;
    }

    const separator = url.includes('?') ? '&' : '?';

    return `${url}${separator}v=${currentBuild.timestamp}`;
};
