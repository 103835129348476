import { useTranslation } from 'react-i18next';

export const useBankDetails = (): {
    bank: string;
    accountName: string;
    sortCode: string;
    accountNumber: string;
} => {
    const { t } = useTranslation('bank-details');

    const bank = t('bank');
    const accountName = t('accountName');
    const sortCode = t('sortCode');
    const accountNumber = t('accountNumber');

    return {
        bank,
        accountName,
        sortCode,
        accountNumber,
    };
};
