import axios from 'axios';
import { currentBrand, integrationApiBaseUrl } from 'config';

export const CpDataApi = axios.create({
    baseURL: integrationApiBaseUrl,
});

export const CpContentApi = axios.create({
    baseURL: '/contents',
    headers: {
        'x-cp-brand': currentBrand,
    },
});
