import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { ContractsOverviewBO, getContractsOverviewEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContractsOverviewBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'contractsOverview',
    fetchCallback() {
        return CpDataApi.get<ContractsOverviewBO>(getContractsOverviewEndpoint()).then(({ data }) => data);
    },
});

export default reducer;
export const fetchContractsOverview = fetchData;
