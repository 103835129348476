import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@vwfs-bronson/bronson-react';
import { openInNewWindow, textAsHtml } from 'utils';

export type NavigationTileProps = {
    imageUrl?: string;
    title: string;
    text: string;
    buttonText: string;
    buttonAriaLabel?: string;
    buttonUrl: string;
    testId: string;
    onAction: (additionalValue?: string, secondAdditionalValue?: string) => void;
};

export const NavigationTile: React.FC<NavigationTileProps> = ({
    imageUrl,
    title,
    text,
    buttonText,
    buttonAriaLabel,
    buttonUrl,
    testId,
    onAction,
}) => {
    const history = useHistory();

    const onForwardClick = (): void => {
        onAction(testId, buttonUrl);
        if (buttonUrl?.startsWith('/')) {
            history.push(buttonUrl);
        } else {
            openInNewWindow(buttonUrl);
        }
    };

    const footerContent = (
        <div className={'o-button-container o-button-container--left'}>
            <Button
                className={'c-btn--compact'}
                type={'button'}
                icon={'semantic-forward'}
                iconReversed
                secondary
                testId={'forwardButton'}
                onClick={onForwardClick}
                ariaLabel={buttonAriaLabel || undefined}
            >
                {buttonText}
            </Button>
        </div>
    );

    return (
        <Card
            element={'article'}
            className={'c-navigation-tile u-text-left'}
            imageSrc={imageUrl}
            title={title}
            footer
            footerContent={footerContent}
            testId={testId}
        >
            {textAsHtml(text)}
        </Card>
    );
};
