import { CauseOfArrears, IncomeAndExpenditure, nowCPDate, RequestAdditionalHelpBO, Situation } from '@cp-uk/common';
import { ContractDescriptionSolutions } from '../contract-description/ExampleData';

const todayMoment = nowCPDate().toMoment().startOf('day');

export const fixedDates = (requestAdditionalHelp: RequestAdditionalHelpBO): RequestAdditionalHelpBO => {
    return {
        ...requestAdditionalHelp,
        lastBilledPaymentDate: '2020-04-30T00:00:00Z',
        nextScheduledPaymentDate: '2020-05-31T00:00:00Z',
    };
};

export const RequestAdditionalHelpTypical: RequestAdditionalHelpBO = {
    firstName: 'John',
    lastName: 'Smith',
    emailAddress: 'john.smith@gmail.com',
    mobileNumber: '07890123456',
    homePhoneNumber: '01525123456',
    workPhoneNumber: '01908123456',
    contractDescription: ContractDescriptionSolutions,
    contractNumber: '440123456789',
    registrationNumber: 'EX19ABC',
    productCategory: 'purchase',
    changeBankAccountInProgress: false,
    changePaymentDateInProgress: false,
    sortCode: '*2-*4-*6',
    accountNumber: '*****678',
    lastBilledPaymentDate: todayMoment.clone().subtract(1, 'weeks').toISOString(),
    nextScheduledPaymentDate: todayMoment.clone().add(3, 'weeks').toISOString(),
    totalArrears: 1234.56,
    arrearsStatus: 'info',
};

export const RequestAdditionalHelpWithoutEmail: RequestAdditionalHelpBO = {
    ...RequestAdditionalHelpTypical,
    emailAddress: undefined,
};

export const CauseOfArrearsTypical: CauseOfArrears = {
    reducedIncome: true,
    unemployment: true,
    healthCondition: true,
    oversightOrMismanagementOfFinances: false,
    behindWithOtherCreditors: true,
    inAnIvaOrFilingForBankruptcy: false,
    other: false,
    moreDetail: 'some-more-details',
};

export const SituationTypical: Situation = {
    causeOfArrears: CauseOfArrearsTypical,
    consentInformationProvided: true,
    relatedToCovid19: true,
    vulnerableCustomer: false,
    vulnerableCustomerDetail: undefined,
    continueToPayRentals: false,
    endAgreement: true,
    contributeTowardsArrears: undefined,
    payFullArrearsWithin3Months: undefined,
    payFullArrearsWithin12Months: undefined,
    payFullArrearsWithin30Days: undefined,
};

export const IncomeAndExpenditureTypical: IncomeAndExpenditure = {
    income: {
        salaryWages: 90,
        benefitsOther: 10,
    },
    expenditure: {
        volkswagenFinance: 1,
        rentMortgage: 2,
        waterGasElectric: 3,
        councilTax: 4,
        childCareMaintenance: 5,
        landlineInternetTv: 6,
        mobiles: 7,
        carInsuranceTax: 8,
        fuelTravelCosts: 9,
        housekeeping: 10,
        other: 11,
        otherCreditors: 12,
    },
};
