import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { VehicleDetailsBO } from '@cp-uk/common';
import { SummarySection } from './summary-section/SummarySection';
import { RegistrationSection } from './registration-section/RegistrationSection';

export const VehicleDetails: React.FC<{ vehicleDetails: VehicleDetailsBO | undefined }> = ({ vehicleDetails }) => {
    const { t } = useTranslation('vehicle-details');

    if (vehicleDetails === undefined) {
        return null;
    }

    const { summarySection, registrationSection } = vehicleDetails;

    return (
        <Accordion.Item title={t('title')} testId={'vehicleDetails'}>
            <Suspense fallback={<Spinner center />}>
                <SummarySection summarySection={summarySection} />
                <RegistrationSection registrationSection={registrationSection} />
            </Suspense>
        </Accordion.Item>
    );
};
