import { StartMatchmakerBO, StartMatchmakerResponse } from '@cp-uk/common';

export const StartMatchmakerTypical: StartMatchmakerBO = {
    encryptedContractId: 'encrypted0000440123456789',
    productType: 'solutions',
    financeBrand: 'vw',
    emailAddress: 'sample@domain.com',
    title: 'Mr',
    firstName: 'John',
    lastName: 'Smith',
    mobileNumber: '07890123456',
    homePhoneNumber: '01296123456',
    workPhoneNumber: undefined,
    contractDescription: {
        vehicleBrand: 'VOLKSWAGEN',
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        contractNumber: '440123456789',
        registrationNumber: 'EX19ABC',
        productType: 'solutions',
    },
    address: {
        careOf: 'Her Majesty',
        flatPoBox: "Queen's Suite",
        propertyName: 'Buckingham Palace',
        houseNumber: '1',
        street: 'The Mall',
        district: 'Central',
        city: 'LONDON',
        county: 'Noneshire',
        postCode: 'SW1A 1AA',
        country: 'GB',
    },
    equityAmount: 500,
    valuationAverage: 12000,
    settlementEstimate: 11500,
    dealerCode: 'dealer-code',
    dealerName: 'dealer-name',
    isConsideredCompleted: false,
    creationDateTime: undefined,
};

export const StartMatchmakerCompleted: StartMatchmakerBO = {
    ...StartMatchmakerTypical,
    isConsideredCompleted: true,
    creationDateTime: '2023-01-01T12:34:56Z',
};

export const StartMatchmakerWithMissingContactDetails: StartMatchmakerBO = {
    ...StartMatchmakerTypical,
    emailAddress: undefined,
    homePhoneNumber: undefined,
    mobileNumber: undefined,
};

export const StartMatchmakerWithMissingEmailAddress: StartMatchmakerBO = {
    ...StartMatchmakerTypical,
    emailAddress: undefined,
};

export const StartMatchmakerWithMissingPhoneNumbers: StartMatchmakerBO = {
    ...StartMatchmakerTypical,
    homePhoneNumber: undefined,
    mobileNumber: undefined,
};

export const StartMatchmakerResponseTypical: StartMatchmakerResponse = {
    redirectUrl:
        'https://dev.drmmuk.vwfs.io/vw?transactionId=11111111-1111-1111-1111-111111111111&token=22222222-2222-2222-2222-222222222222',
};
