import { LoginOptions, LogoutOptions } from '@cp-shared-8/frontend-ui';
import { authIdpHint } from 'config';
import { dashboardPagePath, landingPagePath } from '../components/navigation/paths';

export const buildLoginOptions = (locale: string): LoginOptions => {
    return {
        redirectUri: window.location.origin + dashboardPagePath(),
        locale,
        idpHint: authIdpHint,
        prompt: 'login',
    };
};

export const buildLogoutOptions = (): LogoutOptions => {
    return {
        redirectUri: window.location.origin + landingPagePath(),
    };
};
