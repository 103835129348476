import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Draft, produce } from 'immer';
import {
    BankAccountSectionBO,
    ContractDetailsBO,
    DayOfPaymentSectionBO,
    MileageSectionBO,
    RegistrationSectionBO,
} from '@cp-uk/common';
import { ContractDetailsContext, ContractDetailsContextValues } from './ContractDetailsContext';
import { updateContractDetails } from './ContractDetailsSlice';

interface NewSectionsValues {
    newBankAccountSection: BankAccountSectionBO | undefined;
    newDayOfPaymentSection: DayOfPaymentSectionBO | undefined;
    newMileageSection: MileageSectionBO | undefined;
    newRegistrationSection: RegistrationSectionBO | undefined;
}

const updateBankAccountSection = (
    draftContractDetails: Draft<ContractDetailsBO>,
    newBankAccountSection: BankAccountSectionBO | undefined,
): void => {
    if (newBankAccountSection === undefined) {
        return;
    }

    if (draftContractDetails.financialDetails !== undefined) {
        draftContractDetails.financialDetails.bankAccountSection = newBankAccountSection;
    }
    if (draftContractDetails.serviceDetails !== undefined) {
        draftContractDetails.serviceDetails.bankAccountSection = newBankAccountSection;
    }
};

const updateDayOfPaymentSection = (
    draftContractDetails: Draft<ContractDetailsBO>,
    newDayOfPaymentSection: DayOfPaymentSectionBO | undefined,
): void => {
    if (newDayOfPaymentSection === undefined) {
        return;
    }

    if (draftContractDetails.financialDetails !== undefined) {
        draftContractDetails.financialDetails.dayOfPaymentSection = newDayOfPaymentSection;
    }
    if (draftContractDetails.serviceDetails !== undefined) {
        draftContractDetails.serviceDetails.dayOfPaymentSection = newDayOfPaymentSection;
    }
};

const updateMileageSection = (
    draftContractDetails: Draft<ContractDetailsBO>,
    newMileageSection: MileageSectionBO | undefined,
): void => {
    if (newMileageSection === undefined) {
        return;
    }

    if (draftContractDetails.financialDetails !== undefined) {
        draftContractDetails.financialDetails.mileageSection = newMileageSection;
    }
    if (draftContractDetails.serviceDetails !== undefined) {
        draftContractDetails.serviceDetails.mileageSection = newMileageSection;
    }
};

const updateRegistrationSection = (
    draftContractDetails: Draft<ContractDetailsBO>,
    newRegistrationSection: RegistrationSectionBO | undefined,
): void => {
    if (newRegistrationSection === undefined) {
        return;
    }

    if (draftContractDetails.vehicleDetails !== undefined) {
        draftContractDetails.vehicleDetails.registrationSection = newRegistrationSection;
    }
};

const updateContractDetailsWithNewSections = (
    existingContractDetails: ContractDetailsBO,
    newSectionsValues: NewSectionsValues,
): ContractDetailsBO | undefined => {
    const {
        newBankAccountSection,
        newDayOfPaymentSection,
        newMileageSection,
        newRegistrationSection,
    } = newSectionsValues;
    if (
        newBankAccountSection === undefined &&
        newDayOfPaymentSection === undefined &&
        newMileageSection === undefined &&
        newRegistrationSection === undefined
    ) {
        return undefined;
    }

    const updatedContractDetails = produce(
        existingContractDetails,
        (draftContractDetails: Draft<ContractDetailsBO>) => {
            updateBankAccountSection(draftContractDetails, newBankAccountSection);
            updateDayOfPaymentSection(draftContractDetails, newDayOfPaymentSection);
            updateMileageSection(draftContractDetails, newMileageSection);
            updateRegistrationSection(draftContractDetails, newRegistrationSection);
        },
    );

    return updatedContractDetails;
};

export const ContractDetailsProvider: React.FC<{ contractNumber: string; contractDetails: ContractDetailsBO }> = ({
    contractNumber,
    contractDetails: existingContractDetails,
    children,
}) => {
    const dispatch = useDispatch();
    const [newBankAccountSection, setNewBankAccountSection] = useState<BankAccountSectionBO | undefined>(undefined);
    const [newDayOfPaymentSection, setNewDayOfPaymentSection] = useState<DayOfPaymentSectionBO | undefined>(undefined);
    const [newMileageSection, setNewMileageSection] = useState<MileageSectionBO | undefined>(undefined);
    const [newRegistrationSection, setNewRegistrationSection] = useState<RegistrationSectionBO | undefined>(undefined);

    const newSectionsRef = useRef<NewSectionsValues>({
        newBankAccountSection: undefined,
        newDayOfPaymentSection: undefined,
        newMileageSection: undefined,
        newRegistrationSection: undefined,
    });

    useEffect(() => {
        newSectionsRef.current = {
            newBankAccountSection,
            newDayOfPaymentSection,
            newMileageSection,
            newRegistrationSection,
        };
    }, [newBankAccountSection, newDayOfPaymentSection, newMileageSection, newRegistrationSection]);

    useEffect(() => {
        return (): void => {
            const updatedContractDetails = updateContractDetailsWithNewSections(
                existingContractDetails,
                newSectionsRef.current,
            );
            if (updatedContractDetails !== undefined) {
                dispatch(updateContractDetails(contractNumber, updatedContractDetails));
            }
        };
    }, [dispatch, contractNumber, existingContractDetails]);

    const value: ContractDetailsContextValues = {
        contractNumber,
        newBankAccountSection,
        setNewBankAccountSection,
        newDayOfPaymentSection,
        setNewDayOfPaymentSection,
        newMileageSection,
        setNewMileageSection,
        newRegistrationSection,
        setNewRegistrationSection,
    };

    return <ContractDetailsContext.Provider value={value}>{children}</ContractDetailsContext.Provider>;
};
