import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ContractHireCallBackViewFormValues } from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<ContractHireCallBackViewFormValues> => {
    return Yup.object()
        .shape<ContractHireCallBackViewFormValues>({
            phoneNumber: Yup.string().required(t('phoneNumber.validation.required')),
        })
        .required();
};
