import { CPDateInterface, formatCpDate } from '@cp-shared-8/common-utilities';

export const displayDateFormat = 'DD MMM YYYY';
export const displayTimeFormat = 'h:mm a';
export const inputDateFormat = 'DD/MM/YYYY';
export const isoDateFormat = 'YYYY-MM-DD';
export const isoDateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
export const isoTimestampFormat = 'YYYYMMDDHHmmss';

export const nowCPDate = (): CPDateInterface => {
    return parseCPDate();
};

export const parseCPDate = formatCpDate;

export const parseCPDateFromInput = (value: string | undefined | null): CPDateInterface => {
    return parseCPDate(value, inputDateFormat);
};
