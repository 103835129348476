import { IconFooterDto, getIconFooterEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<IconFooterDto>({
    contentName: 'iconFooter',
    contentEndpoint: getIconFooterEndpoint,
});

export default reducer;
export const fetchIconFooter = fetchContent;
