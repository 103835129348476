import React, { Suspense, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, Spinner } from '@cp-shared-8/frontend-ui';
import {
    ChangeMarketingPreferencesBO,
    ChangeMarketingPreferencesRequest,
    getChangeMarketingPreferencesEndpoint,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { linkToPrivacyPolicyPage } from 'components/link-to-pages';
import { ChangeData } from 'components/change-data/ChangeData';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { MyProfileContext } from '../../MyProfileContext';
import { validationSchema } from './ChangeMarketingPreferencesValidation';

export const ChangeMarketingPreferences: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: () => void;
    onChangeSuccess: (changeMarketingPreferencesRequest: ChangeMarketingPreferencesRequest) => void;
    changeMarketingPreferences: ChangeMarketingPreferencesBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changeMarketingPreferences }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showFailureNotification, setShowFailureNotification] = useState<boolean>(false);
    const { newAddressSection, newContactDetailsSection } = useContext(MyProfileContext);
    const { t } = useTranslation('change-marketing-preferences');

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    if (changeMarketingPreferences === undefined) {
        return null;
    }

    const {
        emailAddressMissing: propsEmailAddressMissing,
        phoneNumbersMissing: propsPhoneNumbersMissing,
        postalAddressMissing: propsPostalAddressMissing,
        otherChangeInProgress: propsOtherChangeInProgress,
        addressChangeInProgress: propsAddressChangeInProgress,
        addressChangeByPhone,
    } = changeMarketingPreferences;

    const emailAddressMissing = newContactDetailsSection ? false : propsEmailAddressMissing;
    const phoneNumbersMissing = newContactDetailsSection ? false : propsPhoneNumbersMissing;
    const postalAddressMissing = newAddressSection ? false : propsPostalAddressMissing;
    const otherChangeInProgress = newContactDetailsSection ? true : propsOtherChangeInProgress;
    const addressChangeInProgress = newAddressSection ? true : propsAddressChangeInProgress;

    const marketingPreferenceFields: string[] = ['email', 'telephone', 'simpleMessageService', 'post'];

    const onClose = (): void => {
        onChangeCancel();
    };

    const onCancel = (): void => {
        onChangeCancel();
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-marketing-preferences'}
                heading={t('title')}
                isSubmitting={isSubmitting}
                onClose={onClose}
                testId={'changeMarketingPreferences'}
            >
                <Notification
                    className={'u-mb'}
                    status={NotificationStatus.info}
                    testId={'introductionNotification'}
                >
                    {textWithComponents(t, 'introductionNotification.text', { linkToPrivacyPolicyPage })}
                </Notification>
                <Formik
                    initialValues={{
                        email: changeMarketingPreferences.initialEmail,
                        telephone: changeMarketingPreferences.initialTelephone,
                        simpleMessageService: changeMarketingPreferences.initialSimpleMessageService,
                        post: changeMarketingPreferences.initialPost,
                    }}
                    validationSchema={validationSchema(
                        t,
                        emailAddressMissing,
                        phoneNumbersMissing,
                        postalAddressMissing,
                        otherChangeInProgress,
                        addressChangeInProgress,
                        addressChangeByPhone,
                    )}
                    onSubmit={({ email, telephone, simpleMessageService, post }): Promise<void> => {
                        const changeMarketingPreferences: ChangeMarketingPreferencesRequest = {
                            email,
                            telephone,
                            simpleMessageService,
                            post,
                        };
                        setShowFailureNotification(false);
                        setIsSubmitting(true);
                        return CpDataApi.put<void>(getChangeMarketingPreferencesEndpoint(), changeMarketingPreferences)
                            .then(() => {
                                setIsSubmitting(false);
                                onChangeSuccess(changeMarketingPreferences);
                            })
                            .catch(() => {
                                setIsSubmitting(false);
                                setShowFailureNotification(true);
                                onChangeFailure();
                            });
                    }}
                >
                    {(formik): React.ReactNode => (
                        <Form className={'uk-change-marketing-preferences-form'} onSubmit={preventSubmit}>
                            <Fieldset>
                                {marketingPreferenceFields.map(
                                    (field: string): React.ReactNode => (
                                        <Fieldset.Row key={field}>
                                            <ValidatedCheckbox
                                                label={t(`${field}.label`)}
                                                name={field}
                                                testId={field}
                                                isMandatory={false}
                                            />
                                        </Fieldset.Row>
                                    ),
                                )}
                                {showFailureNotification && (
                                    <Fieldset.Row>
                                        <Notification
                                            status={NotificationStatus.error}
                                            testId={'failureNotification'}
                                        >
                                            {textWithComponents(t, 'failureNotification.text', {
                                                linkToAvartoPhoneNumber,
                                            })}
                                        </Notification>
                                    </Fieldset.Row>
                                )}
                                <Fieldset.Row>
                                    <ButtonContainer nav>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={onCancel}
                                            testId={'cancelButton'}
                                            secondary
                                        >
                                            {t('translation:editableSectionNav.cancel')}
                                        </Button>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={formik.submitForm}
                                            testId={'confirmChangeButton'}
                                        >
                                            {t('translation:editableSectionNav.confirmChange')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </ChangeData>
        </Suspense>
    );
};
