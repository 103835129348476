import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { RequestAdditionalHelpBO } from '@cp-uk/common';
import { useScrollTo } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { View } from 'components/view/View';
import { fetchRequestAdditionalHelp } from '../request-additional-help/RequestAdditionalHelpSlice';
import { selectRequestAdditionalHelpForSpecific } from '../request-additional-help/RequestAdditionalHelpSelector';
import { OptionsAvailableView } from './options-available-view/OptionsAvailableView';
import { OptionsAvailableNextViews, OptionsAvailableSelections } from './options-available-view/types';
import { PayInOneGoView } from './pay-in-one-go-view/PayInOneGoView';
import { PayInOneGoSelections } from './pay-in-one-go-view/types';
import { AdditionalInformationView } from './additional-information-view/AdditionalInformationView';
import { AdditionalInformationSelections } from './additional-information-view/types';
import { ContactDetailsView } from './contact-details-view/ContactDetailsView';
import { ContactDetailsSelections } from './contact-details-view/types';
import { getInitialOptionFromProp } from './helpers';
import { SummaryItem, Views } from './types';
import { SummaryAndRequestView } from './summary-and-request-view/SummaryAndRequestView';

const TemporaryView: React.FC<{ testId: string; subHeading: string; onClick: () => void }> = ({
    testId,
    subHeading,
    onClick,
}) => {
    return (
        <View testId={testId}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {`TODO: "${subHeading}" view...`}
            </SectionHeading>
            <Paragraph className={'u-text-alert'}>TODO: Content, etc here...</Paragraph>
            <Button type={'button'} testId={'backButton'} onClick={onClick} secondary>
                Back
            </Button>
        </View>
    );
};

export type RequestAdditionalHelpProps = {
    encryptedContractId: string;
    initialOption: string | undefined;
};

const RequestAdditionalHelpUi: React.FC<
    RequestAdditionalHelpProps & { requestAdditionalHelp: RequestAdditionalHelpBO | undefined }
> = ({ encryptedContractId, initialOption, requestAdditionalHelp }) => {
    const initialOptionsAvailableSelections: OptionsAvailableSelections = {
        option: getInitialOptionFromProp(initialOption),
        optionAsString: '',
        consent: false,
    };

    const { t } = useTranslation('request-additional-help-v2');
    const [currentView, setCurrentView] = useState<Views>(Views.optionsAvailable);
    const [currentSummaryItems, setCurrentSummaryItems] = useState<SummaryItem[]>([]);
    const [currentOptionTitle, setCurrentOptionTitle] = useState<string>('');
    const [currentOptionsAvailableSelections, setCurrentOptionsAvailableSelections] = useState<
        OptionsAvailableSelections
    >(initialOptionsAvailableSelections);
    const [currentPayInOneGoSelections, setCurrentPayInOneGoSelections] = useState<PayInOneGoSelections | undefined>(
        undefined,
    );
    const [currentAdditionalInformationSelections, setCurrentAdditionalInformationSelections] = useState<
        AdditionalInformationSelections | undefined
    >(undefined);
    const [currentContactDetailsSelections, setCurrentContactDetailsSelections] = useState<
        ContactDetailsSelections | undefined
    >(undefined);
    const scrollToRef = useScrollTo(currentView, Views.optionsAvailable);

    if (!requestAdditionalHelp) {
        return null;
    }

    const {
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
        contractDescription,
        totalArrears,
        arrearsStatus,
        changeBankAccountInProgress,
        changePaymentDateInProgress,
        sortCode,
        accountNumber,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
    } = requestAdditionalHelp;

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const defaultRegistrationNumber = t('unknown');
    const defaultSortCode = '**-**-**';
    const defaultAccountNumber = '********';

    if (!contractDescription.registrationNumber) {
        contractDescription.registrationNumber = defaultRegistrationNumber;
    }

    const addSummaryItem = (current: SummaryItem[], summaryItem: SummaryItem): SummaryItem[] => {
        return [...current, summaryItem];
    };

    const removeSummaryItem = (current: SummaryItem[], view: Views): SummaryItem[] => {
        return current.filter((summaryItem) => summaryItem.view !== view);
    };

    const onOptionsAvailableContinue = (
        nextView: OptionsAvailableNextViews,
        optionTitle: string,
        selections: OptionsAvailableSelections,
        summaryItem: SummaryItem,
    ): void => {
        setCurrentView(nextView);
        setCurrentOptionTitle(optionTitle);
        setCurrentOptionsAvailableSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
        if (nextView !== Views.payInOneGo && !!currentPayInOneGoSelections) {
            setCurrentPayInOneGoSelections(undefined);
            setCurrentAdditionalInformationSelections(undefined);
            setCurrentContactDetailsSelections(undefined);
        }
    };

    const onPayInOneGoBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onPayInOneGoContinue = (selections: PayInOneGoSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentPayInOneGoSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onPayInInstalmentsBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onBreathingSpaceBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onOtherOptionsBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onAdditionalInformationBack = (): void => {
        setCurrentView(Views.payInOneGo);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.payInOneGo));
    };

    const onAdditionalInformationContinue = (
        selections: AdditionalInformationSelections,
        summaryItem: SummaryItem,
    ): void => {
        setCurrentView(Views.contactDetails);
        setCurrentAdditionalInformationSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onContactDetailsBack = (): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.additionalInformation));
    };

    const onContactDetailsContinue = (selections: ContactDetailsSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.summaryAndRequest);
        setCurrentContactDetailsSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onSummaryAndRequestBack = (): void => {
        setCurrentView(Views.contactDetails);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.contactDetails));
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {currentView === Views.optionsAvailable && (
                <ContactDetailsNotification
                    className={'u-mb'}
                    mode={contactDetailsNotificationMode}
                    emailAddress={emailAddress}
                    phoneNumbersListedLaterInJourney
                />
            )}
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.optionsAvailable && (
                    <OptionsAvailableView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        currentSelections={currentOptionsAvailableSelections}
                        onContinue={onOptionsAvailableContinue}
                    />
                )}
                {currentView === Views.payInOneGo && (
                    <PayInOneGoView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        arrearsStatus={arrearsStatus}
                        changeBankAccountInProgress={changeBankAccountInProgress}
                        changePaymentDateInProgress={changePaymentDateInProgress}
                        sortCode={sortCode || defaultSortCode}
                        accountNumber={accountNumber || defaultAccountNumber}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentPayInOneGoSelections}
                        onBack={onPayInOneGoBack}
                        onContinue={onPayInOneGoContinue}
                    />
                )}
                {currentView === Views.payInInstalments && (
                    <TemporaryView
                        testId={'payInInstalmentsView'}
                        subHeading={'Pay in instalments'}
                        onClick={onPayInInstalmentsBack}
                    />
                )}
                {currentView === Views.breathingSpace && (
                    <TemporaryView
                        testId={'breathingSpaceView'}
                        subHeading={'Breathing space'}
                        onClick={onBreathingSpaceBack}
                    />
                )}
                {currentView === Views.otherOptions && (
                    <TemporaryView
                        testId={'otherOptionsView'}
                        subHeading={'Other options'}
                        onClick={onOtherOptionsBack}
                    />
                )}
                {currentView === Views.additionalInformation && (
                    <AdditionalInformationView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentAdditionalInformationSelections}
                        onBack={onAdditionalInformationBack}
                        onContinue={onAdditionalInformationContinue}
                    />
                )}
                {currentView === Views.contactDetails && (
                    <ContactDetailsView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        mobileNumber={mobileNumber}
                        homePhoneNumber={homePhoneNumber}
                        workPhoneNumber={workPhoneNumber}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentContactDetailsSelections}
                        onBack={onContactDetailsBack}
                        onContinue={onContactDetailsContinue}
                    />
                )}
                {currentView === Views.summaryAndRequest && (
                    <SummaryAndRequestView
                        encryptedContractId={encryptedContractId}
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress ?? ''}
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        currentOptionTitle={currentOptionTitle}
                        currentOptionsAvailableSelections={currentOptionsAvailableSelections}
                        currentPayInOneGoSelections={currentPayInOneGoSelections}
                        currentAdditionalInformationSelections={currentAdditionalInformationSelections}
                        currentContactDetailsSelections={currentContactDetailsSelections}
                        currentSummaryItems={currentSummaryItems}
                        onBack={onSummaryAndRequestBack}
                    />
                )}
            </section>
        </Suspense>
    );
};

const RequestAdditionalHelpWithHandlers = withLoadingAndNoConnectionHandler(RequestAdditionalHelpUi);

export const RequestAdditionalHelp: React.FC<RequestAdditionalHelpProps> = ({ encryptedContractId, initialOption }) => {
    const { data: requestAdditionalHelp, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchRequestAdditionalHelp,
        selectRequestAdditionalHelpForSpecific,
        encryptedContractId,
        true,
    );

    return (
        <RequestAdditionalHelpWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            initialOption={initialOption}
            requestAdditionalHelp={requestAdditionalHelp}
        />
    );
};
