import { FinancialSupportAndWellbeingTile } from './FinancialSupportAndWellbeing';
import { IndependentOrganisation } from './FinancialSupportAndWellbeingContent';
import { FinancialSupportAndWellbeingBO, FinancialSupportAndWellbeingContractBO, ProductTypes } from '@cp-uk/common';

export const FinancialSupportAndWellbeingTilesTypical: FinancialSupportAndWellbeingTile[] = [
    {
        key: 'customerSupport',
        imageUrl: 'customerSupportImageUrl',
        title: 'customerSupportTitle',
        text: 'customerSupportText',
        buttonText: 'customerSupportButtonText',
        buttonAriaLabel: 'customerSupportButtonAriaLabel',
        buttonUrl: 'customerSupportButtonUrl',
    },
    {
        key: 'toggledOffTile',
        imageUrl: 'toggledOffTileImageUrl',
        title: 'toggledOffTileTitle',
        text: 'toggledOffTileText',
        buttonText: 'toggledOffTileButtonText',
        buttonAriaLabel: 'toggledOffTileButtonAriaLabel',
        buttonUrl: 'toggledOffTileButtonUrl',
    },
];

export const FinancialSupportAndWellbeingTileIndividual: FinancialSupportAndWellbeingTile = {
    key: 'customerSupport',
    imageUrl: '/contents/common/financial-support-and-wellbeing-customer-support-tile.jpg',
    title: 'Customer Support',
    text:
        'Sometimes we know it is just easier to talk to someone, and we are here for you if you need to. There are various options to get in touch.',
    buttonText: 'Find out more',
    buttonAriaLabel: 'Find out more about Customer Support',
    buttonUrl: '/financial-support-and-wellbeing-content/customer-support',
};

export const ExampleIndependentOrganisations: IndependentOrganisation[] = [
    {
        key: 'exampleIndependentOrganisation',
        name: 'Example Independent Organisation',
        description: 'Example Independent Organisation description.',
        website: 'https://www.exampleIndependentOrganisation.org.uk',
        phoneNumbers: [
            {
                key: 'key1',
                number: '0800<nbsp/>123<nbsp/>4567',
                suffix: 'Suffix1',
                freephone: true,
            },
            {
                key: 'key2',
                number: '0800<nbsp/>123<nbsp/>6789',
                suffix: 'Suffix2',
                freephone: true,
            },
        ],
    },
    {
        key: 'exampleFilteredIndependentOrganisation',
        name: 'Example Filtered Independent Organisation',
        description: 'Example Filtered Independent Organisation description.',
        website: 'https://exampleFilteredIndependentOrganisation.org',
        phoneNumbers: [
            {
                key: 'key',
                number: '0808<nbsp/>123<nbsp/>4000',
                suffix: undefined,
                freephone: false,
            },
        ],
    },
];

const buildFinancialSupportAndWellbeingFcmContract = (
    vehicleRegistrationNumber: string,
): FinancialSupportAndWellbeingContractBO => {
    return {
        encryptedContractId: 'encrypted0000440123456789',
        contractNumber: '440123456789',
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        productType: 'fixedCostMaintenance',
        financeBrand: 'vw',
        vehicleBrand: 'VOLKSWAGEN',
        vehicleRegistrationNumber,
        remainingServiceEntitlement: undefined,
    };
};

const buildFinancialSupportAndWellbeingFpsContract = (
    vehicleRegistrationNumber: string,
    remainingServiceEntitlement: number,
): FinancialSupportAndWellbeingContractBO => {
    return {
        encryptedContractId: 'encrypted0000440123456789',
        contractNumber: '440123456789',
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        productType: 'fixedPriceServicing',
        financeBrand: 'vw',
        vehicleBrand: 'VOLKSWAGEN',
        vehicleRegistrationNumber,
        remainingServiceEntitlement,
    };
};

const buildFinancialSupportAndWellbeingFinanceContract = (
    contractNumber: string,
    productType: ProductTypes,
): FinancialSupportAndWellbeingContractBO => {
    return {
        encryptedContractId: `encrypted0000${contractNumber}`,
        contractNumber,
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        productType,
        financeBrand: 'vw',
        vehicleBrand: 'VOLKSWAGEN',
        vehicleRegistrationNumber: 'AB12CDE',
        remainingServiceEntitlement: undefined,
    };
};

export const FinancialSupportAndWellbeingWithNoContracts: FinancialSupportAndWellbeingBO = {
    contracts: [],
};
export const FinancialSupportAndWellbeingWithOneFcmContract = {
    contracts: [buildFinancialSupportAndWellbeingFcmContract('AB12CDE')],
};

export const FinancialSupportAndWellbeingWithOneFpsContract = {
    contracts: [buildFinancialSupportAndWellbeingFpsContract('AB12CDE', 1)],
};

export const FinancialSupportAndWellbeingWithMultipleFcmContracts = {
    contracts: [
        buildFinancialSupportAndWellbeingFcmContract('AB12CDE'),
        buildFinancialSupportAndWellbeingFcmContract('AB13CDE'),
    ],
};

export const FinancialSupportAndWellbeingWithMultipleFpsContracts = {
    contracts: [
        buildFinancialSupportAndWellbeingFpsContract('AB12CDE', 1),
        buildFinancialSupportAndWellbeingFpsContract('AB13CDE', 2),
    ],
};

export const FinancialSupportAndWellbeingWithOneFcmAndOneFpsContract = {
    contracts: [
        buildFinancialSupportAndWellbeingFpsContract('AB12CDE', 1),
        buildFinancialSupportAndWellbeingFcmContract('AB13CDE'),
    ],
};

export const FinancialSupportAndWellbeingWithOnePcpContract = {
    contracts: [buildFinancialSupportAndWellbeingFinanceContract('440123456789', 'solutions')],
};

export const FinancialSupportAndWellbeingWithOneRchContract = {
    contracts: [buildFinancialSupportAndWellbeingFinanceContract('440123456790', 'contractHire')],
};

export const FinancialSupportAndWellbeingWithOnePcpAndOneRchContract = {
    contracts: [
        buildFinancialSupportAndWellbeingFinanceContract('440123456789', 'solutions'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456790', 'contractHire'),
    ],
};


export const FinancialSupportAndWellbeingWithMultiplePcpAndRchContracts = {
    contracts: [
        buildFinancialSupportAndWellbeingFinanceContract('440123456781', 'solutions'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456782', 'solutions'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456783', 'solutions'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456784', 'contractHire'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456785', 'contractHire'),
        buildFinancialSupportAndWellbeingFinanceContract('440123456786', 'contractHire'),
    ],
};
