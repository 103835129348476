import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { ErrorOrEarlySettlementBO, getEarlySettlementEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { isSuccessOrBadGateway } from 'utils';

const { reducer, fetchData, updateData } = createGetContractBasedDataFetchSlice<
    ErrorOrEarlySettlementBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'earlySettlement',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<ErrorOrEarlySettlementBO>(getEarlySettlementEndpoint(encryptedContractId), {
            validateStatus: isSuccessOrBadGateway,
        }).then(({ data }) => data);
    },
});

export default reducer;
export const fetchEarlySettlement = fetchData;
export const updateEarlySettlement = updateData;
