export interface ArrangementToPayFormValues {
    numberOfMonths: string;
    additionalAmount: string;
    methodOfPayment: string;
    dateOfFirstPayment: string;
}

export enum NumbersOfMonths {
    one = '1M',
    two = '2M',
    three = '3M',
    four = '4M',
    five = '5M',
    six = '6M',
    seven = '7M',
    eight = '8M',
    nine = '9M',
    ten = '10M',
    eleven = '11M',
    twelve = '12M',
}
