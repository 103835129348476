import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { DocumentCentre } from 'components/document-centre/DocumentCentre';

export const DocumentCentrePage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.DocumentCentre);
    const { t } = useTranslation('page-titles');

    return (
        <ContentSection className={'o-content-section--compact uk-document-centre-page'} pageWrapSize={'small'}>
            <PageHeader title={t('document-centre')} />
            <DocumentCentre />
        </ContentSection>
    );
};
