import moment from 'moment-timezone';
import numeral, { locale as setNumeralLocale } from 'numeral';

import 'moment/locale/en-gb';
import 'numeral/locales/en-gb';

import { CPDate } from '@cp-shared-8/common-utilities';
import { displayDateFormat, displayTimeFormat, parseCPDate } from '../utils';

// Check out https://momentjs.com/timezone/
export const UK_TIMEZONE = 'Europe/London';
export const UTC_TIMEZONE = 'Etc/UTC';

moment.tz.setDefault(UK_TIMEZONE);

const locale = 'en-gb';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatNoSeparators = '0';
const ordinalFormat = '0o';
const currencyFormat = '0,0.00';

export function changeLocale(locale: 'en-gb'): void {
    moment.locale(locale);
    setNumeralLocale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `£${numeral(value).format(currencyFormat)}` : '';
}

export function formatAsDate(date?: CPDate, format: string = displayDateFormat): string {
    return parseCPDate(date).format(format);
}

export function formatAsTime(date?: CPDate, format: string = displayTimeFormat): string {
    return parseCPDate(date).format(format);
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsOrdinal(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(ordinalFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}
