import { SimpleContentDto } from '@cp-uk/common';
import { expandBrandName, expandWebsiteOrigin, expandCarOrVehicle, expandDealerOrRetailer } from './expand-text';

export const expandSimpleContent = (simpleContent: SimpleContentDto): SimpleContentDto => {
    const { content } = simpleContent;

    let expandedContent = expandBrandName(content);
    expandedContent = expandDealerOrRetailer(expandedContent);
    expandedContent = expandCarOrVehicle(expandedContent);
    expandedContent = expandWebsiteOrigin(expandedContent);

    return {
        content: expandedContent,
    };
};
