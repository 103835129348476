import React from 'react';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { getRequestBreathingSpaceEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { buildEndpointMockDescription } from 'utils';

export const endpointMockDescription: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = buildEndpointMockDescription(
    'Request Breathing Space Response',
    getRequestBreathingSpaceEndpoint('encrypted0000440123456789'),
    'put',
    'Success (No Content)',
    204,
);

export const BreathingSpaceResponseMockWrapper = (storyFn: () => React.ReactNode): JSX.Element => {
    setupMockResponses(CpDataApi, [endpointMockDescription]);

    return <div>{storyFn()}</div>;
};
