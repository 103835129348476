import { CauseOfArrears, Situation } from '@cp-uk/common';
import { Buttons, CauseOfArrearsFormValues, SituationViewFormValues } from './types';

export const buttonsToBoolean = (buttons: string): boolean => {
    return buttons === Buttons.yes;
};

export const booleanToButtons = (value: boolean | undefined): string => {
    if (value === undefined) {
        return '';
    }
    return value ? Buttons.yes : Buttons.no;
};

export const buttonsToBooleanOrUndefined = (buttons: string): boolean | undefined => {
    if (!buttons) {
        return undefined;
    }

    return buttonsToBoolean(buttons);
};

const parseCauseOfArrears = (causeOfArrears: CauseOfArrears): CauseOfArrearsFormValues => {
    return {
        reducedIncome: causeOfArrears.reducedIncome,
        unemployment: causeOfArrears.unemployment,
        healthCondition: causeOfArrears.healthCondition,
        oversightOrMismanagementOfFinances: causeOfArrears.oversightOrMismanagementOfFinances,
        behindWithOtherCreditors: causeOfArrears.behindWithOtherCreditors,
        inAnIvaOrFilingForBankruptcy: causeOfArrears.inAnIvaOrFilingForBankruptcy,
        other: causeOfArrears.other,
    };
};

export const parseSituation = (situation: Situation): SituationViewFormValues => {
    return {
        causeOfArrears: parseCauseOfArrears(situation.causeOfArrears),
        // moved outside causeOfArrears for this form (to create validated group of checkboxes)
        causeOfArrearsMoreDetail: situation.causeOfArrears.moreDetail ?? '',
        consentInformationProvided: situation.consentInformationProvided,
        relatedToCovid19: booleanToButtons(situation.relatedToCovid19),
        vulnerableCustomer: booleanToButtons(situation.vulnerableCustomer),
        vulnerableCustomerDetail: situation.vulnerableCustomerDetail ?? '',
        continueToPayRentals: booleanToButtons(situation.continueToPayRentals),
        endAgreement: booleanToButtons(situation.endAgreement),
        payFullArrearsWithin30Days: booleanToButtons(situation.payFullArrearsWithin30Days),
        contributeTowardsArrears: booleanToButtons(situation.contributeTowardsArrears),
        payFullArrearsWithin3Months: booleanToButtons(situation.payFullArrearsWithin3Months),
        payFullArrearsWithin12Months: booleanToButtons(situation.payFullArrearsWithin12Months),
    };
};

export const showEndAgreement = (continueToPayRentals: string): boolean => {
    return continueToPayRentals === Buttons.no;
};

export const showPayFullArrearsWithin30Days = (continueToPayRentals: string): boolean => {
    return continueToPayRentals === Buttons.yes;
};

export const showContributeTowardsArrears = (endAgreement: string, payFullArrearsWithin30Days: string): boolean => {
    return endAgreement === Buttons.no || payFullArrearsWithin30Days === Buttons.no;
};

export const showPayFullArrearsWithin3Months = (relatedToCovid19: string, contributeTowardsArrears: string): boolean => {
    return relatedToCovid19 === Buttons.no && contributeTowardsArrears === Buttons.yes;
};

export const showPayFullArrearsWithin12Months = (relatedToCovid19: string, contributeTowardsArrears: string): boolean => {
    return relatedToCovid19 === Buttons.yes && contributeTowardsArrears === Buttons.yes;
};
