import React, { Suspense, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { preventSubmit, Spinner } from '@cp-shared-8/frontend-ui';
import { ChangeMileageBO, ChangeMileageRequest, getChangeMileageEndpoint } from '@cp-uk/common';
import { buildLinkToPhoneNumber, textWithComponents, useFinanceCompanyDetails } from 'utils';
import { CpDataApi } from 'cp-xhr';
import { ChangeData } from 'components/change-data/ChangeData';
import {
    EmailAddressNotification,
    EmailAddressNotificationMode,
    getEmailAddressNotificationMode,
} from 'components/notifications/email-address/EmailAddressNotification';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { validationSchema } from './ChangeMileageValidation';
import { ChangeMileageFormValues } from './types';

const normalUseThreshold = 200000;

export const ChangeMileage: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: () => void;
    onChangeSuccess: (changeMileageRequest: ChangeMileageRequest) => void;
    changeMileage: ChangeMileageBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changeMileage }) => {
    const { phoneNumber, openingHours } = useFinanceCompanyDetails(
        changeMileage?.productType,
        changeMileage?.financeBrand,
    );
    const [showCurrentMileageLowerThanExpectedModal, setShowCurrentMileageLowerThanExpectedModal] = useState<boolean>(
        false,
    );
    const [showCurrentMileageHigherThanExpectedModal, setShowCurrentMileageHigherThanExpectedModal] = useState<boolean>(
        false,
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { t } = useTranslation('change-mileage');

    if (changeMileage === undefined) {
        return null;
    }

    const {
        encryptedContractId,
        firstName,
        lastName,
        emailAddress,
        minValidCurrentMileage,
        maxValidCurrentMileage,
        latestRecordedMileage,
    } = changeMileage;

    const onClose = (): void => {
        onChangeCancel();
    };

    const buildChangeData = (children: React.ReactNode): JSX.Element => {
        return (
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-mileage'}
                heading={t('heading')}
                isSubmitting={false}
                onClose={onClose}
                testId={'changeMileage'}
            >
                {children}
            </ChangeData>
        );
    };

    const requestDescription = t('notifications.emailAddress.requestDescription');
    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const emailAddressNotificationMode = getEmailAddressNotificationMode(emailAddress);
    if (emailAddressNotificationMode !== EmailAddressNotificationMode.CheckEmailAddress) {
        return buildChangeData(
            <EmailAddressNotification mode={emailAddressNotificationMode} requestDescription={requestDescription} />,
        );
    }

    const initialValues: ChangeMileageFormValues = {
        currentMileage: '',
    };
    const currentMileageTooltip = (): string => {
        const keyFragment = latestRecordedMileage < normalUseThreshold ? 'normal' : 'exceeds';

        return t(`currentMileage.tooltip.${keyFragment}`, { latestRecordedMileage, normalUseThreshold });
    };

    const onCancel = (): void => {
        onChangeCancel();
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onIgnoreClose = (): void => {};

    const onCurrentMileageLowerThanExpectedConfirm = (): void => {
        setShowCurrentMileageLowerThanExpectedModal(false);
    };

    const onCurrentMileageHigherThanExpectedConfirm = (): void => {
        setShowCurrentMileageHigherThanExpectedModal(false);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {buildChangeData(
                <Fragment>
                    <EmailAddressNotification
                        className={'u-mb'}
                        mode={emailAddressNotificationMode}
                        requestDescription={requestDescription}
                        emailAddress={emailAddress}
                    />
                    <Paragraph testId={'detail1Paragraph'}>{t('paragraphs.detail1')}</Paragraph>
                    <Paragraph testId={'detail2Paragraph'}>{t('paragraphs.detail2')}</Paragraph>
                    <Paragraph testId={'detail3Paragraph'}>{t('paragraphs.detail3')}</Paragraph>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema(t, minValidCurrentMileage, maxValidCurrentMileage)}
                        onSubmit={({currentMileage}: ChangeMileageFormValues): Promise<void> => {
                            const currentMileageAsNumber = Number(currentMileage);
                            if (currentMileageAsNumber < latestRecordedMileage) {
                                setShowCurrentMileageLowerThanExpectedModal(true);
                                return Promise.resolve();
                            } else if (currentMileageAsNumber > normalUseThreshold) {
                                setShowCurrentMileageHigherThanExpectedModal(true);
                                return Promise.resolve();
                            } else {
                                const changeMileageRequest: ChangeMileageRequest = {
                                    firstName,
                                    lastName,
                                    emailAddress,
                                    currentMileage: currentMileageAsNumber,
                                };
                                setIsSubmitting(true);
                                return CpDataApi.post<void>(getChangeMileageEndpoint(encryptedContractId), changeMileageRequest)
                                    .then(() => {
                                        setIsSubmitting(false);
                                        onChangeSuccess(changeMileageRequest);
                                    })
                                    .catch(() => {
                                        setIsSubmitting(false);
                                        onChangeFailure();
                                    });
                            }
                        }}
                    >
                        {(formik: FormikProps<ChangeMileageFormValues>): React.ReactNode => (
                            <Form className={'uk-change-mileage-form'} onSubmit={preventSubmit}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInputWithCleave
                                            cleaveOptions={{
                                                numericOnly: true,
                                                blocks: [6],
                                            }}
                                            className={'input-short'}
                                            label={t('currentMileage.label')}
                                            tooltip={currentMileageTooltip()}
                                            name={'currentMileage'}
                                            testId={'currentMileage'}
                                            isMandatory={true}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer nav>
                                            <Button
                                                type={'button'}
                                                disabled={isSubmitting}
                                                onClick={onCancel}
                                                secondary
                                                testId={'cancelButton'}
                                            >
                                                {t('translation:editableSectionNav.cancel')}
                                            </Button>
                                            <Button
                                                type={'button'}
                                                disabled={isSubmitting}
                                                onClick={formik.submitForm}
                                                testId={'confirmChangeButton'}
                                            >
                                                {t('translation:editableSectionNav.confirmChange')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                    <Modal
                        title={t('currentMileageLowerThanExpectedModal.title')}
                        shown={showCurrentMileageLowerThanExpectedModal}
                        status={'warning'}
                        closeAny={false}
                        hideCloseButton={true}
                        buttonConfirmText={t('translation:editableSectionNav.close')}
                        buttonConfirmType={'button'}
                        onClose={onIgnoreClose}
                        onConfirm={onCurrentMileageLowerThanExpectedConfirm}
                        testId={'currentMileageLowerThanExpectedModal'}
                    >
                        <Paragraph testId={'statementParagraph'}>
                            {textWithComponents(
                                t,
                                'currentMileageLowerThanExpectedModal.statement',
                                {linkToPhoneNumber},
                                {latestRecordedMileage, phoneNumber, openingHours},
                            )}
                        </Paragraph>
                    </Modal>
                    <Modal
                        title={t('currentMileageHigherThanExpectedModal.title')}
                        shown={showCurrentMileageHigherThanExpectedModal}
                        status={'warning'}
                        closeAny={false}
                        hideCloseButton={true}
                        buttonConfirmText={t('translation:editableSectionNav.close')}
                        buttonConfirmType={'button'}
                        onClose={onIgnoreClose}
                        onConfirm={onCurrentMileageHigherThanExpectedConfirm}
                        testId={'currentMileageHigherThanExpectedModal'}
                    >
                        <Paragraph testId={'statementParagraph'}>
                            {textWithComponents(
                                t,
                                'currentMileageHigherThanExpectedModal.statement',
                                {linkToPhoneNumber},
                                {normalUseThreshold, phoneNumber, openingHours},
                            )}
                        </Paragraph>
                    </Modal>
                </Fragment>,
            )}
        </Suspense>
    );
};
