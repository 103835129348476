import React, { Fragment } from 'react';
import { FinalPaymentAmountSectionBO } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { Hr } from '@vwfs-bronson/bronson-react';

export const FinalPaymentAmountSection: React.FC<{
    finalPaymentAmountSection: FinalPaymentAmountSectionBO | undefined;
}> = ({ finalPaymentAmountSection }) => {
    const { t, f } = useTranslationWithFormatting('final-payment-amount-section');

    if (finalPaymentAmountSection === undefined) {
        return null;
    }

    const { finalPaymentAmount } = finalPaymentAmountSection;

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [];
    if (finalPaymentAmount !== undefined) {
        descriptionListItems.push({
            label: t('finalPaymentAmount.label'),
            tooltip: t('finalPaymentAmount.tooltip'),
            value: f(finalPaymentAmount, TranslationFormat.CURRENCY),
            testId: 'finalPaymentAmount',
        });
    }

    if (descriptionListItems.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <Hr className={'u-mb-none'} />
            <DataOverview noBorder testId={'finalPaymentAmountSection'}>
                <DescriptionListHorizontal items={descriptionListItems} />
            </DataOverview>
        </Fragment>
    );
};
