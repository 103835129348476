import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { buildLinkToPhoneNumber, textWithComponents, useWebsiteFinanceCompanyDetails } from 'utils';

export type FeatureNotAvailableNotificationProps = {
    className?: string;
    testId?: string;
};

export const FeatureNotAvailableNotification: React.FC<FeatureNotAvailableNotificationProps> = ({
    className,
    testId,
}) => {
    const { t } = useTranslation('feature-not-available-notification');
    const { phoneNumber, openingHours } = useWebsiteFinanceCompanyDetails('purchase');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    return (
        <Notification
            className={className}
            status={NotificationStatus.warning}
            testId={testId}
        >
            {textWithComponents(
                t,
                'notifications.featureNotAvailable.text',
                { linkToPhoneNumber },
                { phoneNumber, openingHours },
            )}
        </Notification>
    );
};
