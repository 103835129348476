import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { ContractDetailsBO, getContractDetailsEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = createGetContractBasedDataFetchSlice<
    ContractDetailsBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'contractDetails',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<ContractDetailsBO>(getContractDetailsEndpoint(encryptedContractId)).then(
            ({ data }) => data,
        );
    },
});

export default reducer;
export const fetchContractDetails = fetchData;
export const updateContractDetails = updateData;
