import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textWithComponents } from 'utils';
import { linkToMyProfilePage } from 'components/link-to-pages';

const spanForEmailAddress = <span className={'u-text-bold'} data-testid={'emailAddressSpan'} />;

export enum EmailAddressNotificationMode {
    MissingEmailAddress = 'missingEmailAddress',
    CheckEmailAddress = 'checkEmailAddress',
}

export const getEmailAddressNotificationMode = (emailAddress: string | undefined): EmailAddressNotificationMode => {
    const hasEmailAddress = !!emailAddress;

    return !hasEmailAddress
        ? EmailAddressNotificationMode.MissingEmailAddress
        : EmailAddressNotificationMode.CheckEmailAddress;
};

export type EmailAddressNotificationProps = {
    className?: string;
    mode: EmailAddressNotificationMode;
    requestDescription?: string;
    emailAddress?: string;
};

export const EmailAddressNotification: React.FC<EmailAddressNotificationProps> = ({
    className,
    mode,
    requestDescription,
    emailAddress,
}) => {
    const { t } = useTranslation('email-address-notification');

    const keyFragment = !!requestDescription ? 'withRequestDescription' : 'withoutRequestDescription';

    return (
        <Notification
            className={className}
            status={
                mode === EmailAddressNotificationMode.CheckEmailAddress
                    ? NotificationStatus.info
                    : NotificationStatus.warning
            }
            headline={t(`notifications.${mode}.headline`)}
            testId={
                mode === EmailAddressNotificationMode.CheckEmailAddress
                    ? 'checkEmailAddressNotification'
                    : 'missingEmailAddressNotification'
            }
        >
            {textWithComponents(
                t,
                `notifications.${mode}.${keyFragment}`,
                { spanForEmailAddress, linkToMyProfilePage },
                { requestDescription, emailAddress },
            )}
        </Notification>
    );
};
