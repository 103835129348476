import React from 'react';
import { Redirect } from 'react-router-dom';
import { createAuthenticationComponent, createAuthorization, createDecoratedRoute, Spinner } from '@cp-shared-8/frontend-ui';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { landingPagePath, loginErrorPagePath, registerPagePath } from 'components/navigation/paths';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const {
    useAuthorizationData: useAuthorization,
    useAuthorizationFlow,
    Authorization: UserRegistryAuthorization,
    AuthorizationProvider,
} = createAuthorization({
    displayName: 'UserRegistryAuthorization',
    authorizationDataProvider: async () => {
        const { data: userRegistryStatus } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
        return { isAuthorized: userRegistryStatus.isRegistered };
    },
    onMissingAuthorization: <Redirect to={registerPagePath()} />,
    onLoading: <Spinner center />,
    onError: <Redirect to={loginErrorPagePath()} />,
});

const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistryAuthorization);

export type AuthorizationHookResult<T = {}> = ({ isAuthorized: true } & T) | ({ isAuthorized: false } & Partial<T>);

export { useAuthorization, useAuthorizationFlow, AuthenticatedRoute, AuthorizedRoute, AuthorizationProvider };
