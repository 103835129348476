import { Error, isError } from '@cp-shared-8/common-utilities';
import { toCamelCase } from './to-camel-case';

export const processErrorCode = <TAppErrorCode extends string>(code: TAppErrorCode | undefined): string => {
    const supportedCodes: string[] = ['NOT_FOUND', 'CONFLICT', 'UNPROCESSABLE_ENTITY', 'LOCKED', 'TOO_EARLY'];

    return supportedCodes.includes(code ?? '') ? toCamelCase(code ?? '') : 'defaultError';
};

export const getInitialErrorNotificationKey = <
    TAppErrorCode extends string,
    TBusinessObject extends unknown | Error<TAppErrorCode>
>(
    value: TBusinessObject | undefined,
): string | undefined => {
    return !!value && isError<TAppErrorCode>(value) ? processErrorCode<TAppErrorCode>(value.code) : undefined;
};
