import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isError } from '@cp-shared-8/common-utilities';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Notification, NotificationStatus, Spinner, withAutoScroll } from '@cp-shared-8/frontend-ui';
import { EarlySettlementBO, ErrorOrEarlySettlementBO, GenerateEarlySettlementError } from '@cp-uk/common';
import {
    getInitialErrorNotificationKey,
    processErrorCode,
    textWithComponents,
    useWebsiteFinanceCompanyDetails,
    useScrollTo,
    buildLinkToPhoneNumber,
} from 'utils';
import { selectEarlySettlementForSpecific } from './EarlySettlementSelector';
import { fetchEarlySettlement } from './EarlySettlementSlice';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RightOfWithdrawalView } from './right-of-withdrawal-view/RightOfWithdrawalView';
import { GenerateView } from './generate-view/GenerateView';
import { EnquiryView } from './enquiry-view/EnquiryView';
import { CompleteView } from './complete-view/CompleteView';

const NotificationWithAutoScroll = withAutoScroll(Notification);

enum Views {
    rightOfWithdrawal = 'rightOfWithdrawal',
    generate = 'generate',
    enquiry = 'enquiry',
    complete = 'complete',
}

export const EarlySettlementUi: React.FC<{ errorOrEarlySettlement: ErrorOrEarlySettlementBO | undefined }> = ({
    errorOrEarlySettlement,
}) => {
    const earlySettlement =
        !!errorOrEarlySettlement && !isError<GenerateEarlySettlementError>(errorOrEarlySettlement)
            ? (errorOrEarlySettlement as EarlySettlementBO)
            : undefined;

    const initialErrorNotificationKey = getInitialErrorNotificationKey<
        GenerateEarlySettlementError,
        ErrorOrEarlySettlementBO
    >(errorOrEarlySettlement);

    const initialView =
        earlySettlement?.state === 'rightOfWithdrawal'
            ? Views.rightOfWithdrawal
            : earlySettlement?.state === 'generate'
                ? Views.generate
                : Views.enquiry;

    const [currentView, setCurrentView] = useState<Views>(initialView);
    const [errorNotificationKey, setErrorNotificationKey] = useState<string | undefined>(initialErrorNotificationKey);
    const scrollToRef = useScrollTo(currentView, initialView);
    const { phoneNumber, openingHours } = useWebsiteFinanceCompanyDetails('purchase');
    const { t } = useTranslation('early-settlement');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    if (errorNotificationKey) {
        return (
            <NotificationWithAutoScroll
                status={NotificationStatus.warning}
                testId={'errorNotification'}
            >
                {textWithComponents(
                    t,
                    `notifications.${errorNotificationKey}.text`,
                    { linkToPhoneNumber },
                    { phoneNumber, openingHours },
                )}
            </NotificationWithAutoScroll>
        );
    }

    if (earlySettlement === undefined) {
        return null;
    }

    const { contractDescription, generateView, enquiryView, completeView } = earlySettlement;

    const onGenerateError = (code: GenerateEarlySettlementError | undefined): void => {
        setErrorNotificationKey(processErrorCode(code));
    };
    const onEnquiryContinue = (): void => {
        setCurrentView(Views.complete);
    };
    const onCompleteReturn = (): void => {
        setCurrentView(Views.enquiry);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.rightOfWithdrawal && (
                    <RightOfWithdrawalView
                        contractDescription={contractDescription}
                    />
                )}
                {currentView === Views.generate && (
                    <GenerateView
                        contractDescription={contractDescription}
                        generateView={generateView}
                        onError={onGenerateError}
                    />
                )}
                {currentView === Views.enquiry && (
                    <EnquiryView
                        contractDescription={contractDescription}
                        enquiryView={enquiryView}
                        onContinue={onEnquiryContinue}
                    />
                )}
                {currentView === Views.complete && (
                    <CompleteView
                        contractDescription={contractDescription}
                        completeView={completeView}
                        onReturn={onCompleteReturn}
                    />
                )}
            </section>
        </Suspense>
    );
};

const EarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(EarlySettlementUi);

export const EarlySettlement: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: errorOrEarlySettlement, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchEarlySettlement,
        selectEarlySettlementForSpecific,
        encryptedContractId,
    );

    return (
        <EarlySettlementWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            errorOrEarlySettlement={errorOrEarlySettlement}
        />
    );
};
