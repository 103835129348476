import React, { Fragment, Suspense } from 'react';
import { Accordion, Card } from '@vwfs-bronson/bronson-react';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ContractBO, FinanceBrandsDrive, FinanceBrandsMM, InsuranceBrands, ProductTypes } from '@cp-uk/common';
import { contractCategory, fixSkodaAccent } from 'utils';
import { ArrearsNotification } from './ArrearsNotification';
import { ContractActions } from './ContractActions';
import { ContractSummary } from './ContractSummary';
import { LicensePlate } from './LicensePlate';
import { ContractDetails } from '../contract-details/ContractDetails';
import { DriveMarketingCard } from './DriveMarketingCard';
import { MatchmakerMarketingCard } from './MatchmakerMarketingCard';
import { InsuranceMarketingCard } from './InsuranceMarketingCard';
import { RemediationNotification, RemediationTypes } from './RemediationNotification';

export const contractTitle = (
    vehicleBrand: string | undefined,
    vehicleDescription: string | undefined,
): React.ReactNode => (
    <span data-testid={'contractTitle'}>
        {vehicleBrand && <span data-testid={'vehicleBrand'}>{fixSkodaAccent(vehicleBrand)}</span>}
        {vehicleBrand && vehicleDescription && ' '}
        {vehicleDescription && <span data-testid={'vehicleDescription'}>{vehicleDescription}</span>}
    </span>
);

export const contractSubtitle = (productType: ProductTypes, contractNumber: string): React.ReactNode => (
    <span data-testid={'contractSubtitle'}>
        <span data-testid={'contractCategory'}>{contractCategory(productType)}</span>{' '}
        <span data-testid={'contractNumber'}>{contractNumber}</span>
    </span>
);

export const ContractOverview: React.FC<{ contract: ContractBO; isFirst: boolean }> = ({ contract, isFirst }) => {
    const { onAction: onOpenContractAccordionAction } = useAnalyticsActionTracker('onOpenContractAccordion');

    const {
        isActive,
        encryptedContractId,
        contractNumber,
        productType,
        financeBrand,
        insuranceBrand,
        vehicleBrand,
        vehicleDescription,
        vehicleRegistrationNumber,
        arrearsStatus,
        totalArrears,
        earlySettlementEnabled,
        earlyTerminationEnabled,
        endOfContractEnabled,
        showDrive,
        showMatchmaker,
        showInsurance,
        showBorrowersInFinancialDifficultyNotification,
        showContractHireTerminationNotification,
        showJulySetOfFourRemediationsNotification,
    } = contract;

    const onChange = ([firstItem]: string[]): void => {
        if (!!firstItem) {
            onOpenContractAccordionAction(firstItem);
        }
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <Card
                element={'section'}
                className={`c-card--body-pt-none u-mb ${!isActive ? 'c-card--inactive' : ''}`}
                title={contractTitle(vehicleBrand, vehicleDescription)}
                subtitle={contractSubtitle(productType, contractNumber)}
                contentShort={<LicensePlate vehicleRegistrationNumber={vehicleRegistrationNumber} isContentShort />}
                contentLarge={
                    <Fragment>
                        <ContractActions
                            encryptedContractId={encryptedContractId}
                            isActive={isActive}
                            productType={productType}
                            financeBrand={financeBrand}
                            earlySettlementEnabled={earlySettlementEnabled}
                            earlyTerminationEnabled={earlyTerminationEnabled}
                            endOfContractEnabled={endOfContractEnabled}
                        />
                        <Accordion onChange={onChange}>
                            <ContractDetails
                                encryptedContractId={encryptedContractId}
                                contractNumber={contractNumber}
                            />
                        </Accordion>
                        {showDrive && (
                            <DriveMarketingCard
                                financeBrand={financeBrand as FinanceBrandsDrive}
                            />
                        )}
                        {showMatchmaker && (
                            <MatchmakerMarketingCard
                                encryptedContractId={encryptedContractId}
                                financeBrand={financeBrand as FinanceBrandsMM}
                            />
                        )}
                        {showInsurance && (
                            <InsuranceMarketingCard
                                insuranceBrand={insuranceBrand as InsuranceBrands}
                            />
                        )}
                    </Fragment>
                }
                testId={`contract-${contractNumber}`}
                expandableTestId={'expandableButton'}
                expandable
                defaultExpanded={isFirst}
            >
                <ContractSummary contract={contract} />
                <ArrearsNotification
                    className={'u-mb-small'}
                    arrearsStatus={arrearsStatus}
                    totalArrears={totalArrears}
                    encryptedContractId={encryptedContractId}
                />
                <RemediationNotification
                    className={'u-mb-small'}
                    showNotification={showBorrowersInFinancialDifficultyNotification}
                    remediationType={RemediationTypes.BorrowersInFinancialDifficulty}
                    contractNumber={contractNumber}
                />
                <RemediationNotification
                    className={'u-mb-small'}
                    showNotification={showContractHireTerminationNotification}
                    remediationType={RemediationTypes.ContractHireTermination}
                    contractNumber={contractNumber}
                />
                <RemediationNotification
                    className={'u-mb-small'}
                    showNotification={showJulySetOfFourRemediationsNotification}
                    remediationType={RemediationTypes.JulySetOfFour}
                    contractNumber={contractNumber}
                />
            </Card>
        </Suspense>
    );
};
