import { SimplePageHeroTeaserDto, getPrivacyPolicyPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'privacyPolicyPageHeroTeaser',
    contentEndpoint: getPrivacyPolicyPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchPrivacyPolicyPageHeroTeaser = fetchContent;
