import {
    BankAccountSectionBO,
    ContractDetailsBO,
    DayOfPaymentSectionBO,
    MileageSectionBO,
    RegistrationSectionBO,
    ServiceDetailsBO,
    VehicleDetailsBO,
} from '@cp-uk/common';

const buildBankAccountSection = (isServiceProduct: boolean): BankAccountSectionBO => {
    return {
        bankAccountChangeable: 'ok',
        sortCode: '*2-*4-*6',
        accountNumber: '*****678',
        changeBankAccount: {
            encryptedContractId: isServiceProduct ? 'encrypted0000440123456790' : 'encrypted0000440123456789',
            contractNumber: '440123456789',
            showThisContractOnlyNotification: true,
            lastBilledPaymentDate: '2021-03-01T00:00:00Z',
            nextScheduledPaymentDate: '2021-04-01T00:00:00Z',
            productType: isServiceProduct ? 'fixedPriceServicing' : 'solutions',
            financeBrand: 'vw',
        },
    };
};

const buildDayOfPaymentSection = (isServiceProduct: boolean): DayOfPaymentSectionBO => {
    return {
        paymentDateChangeable: 'ok',
        monthlyDueDay: 17,
        changePaymentDate: {
            encryptedContractId: isServiceProduct ? 'encrypted0000440123456790' : 'encrypted0000440123456789',
            showThisContractOnlyNotification: true,
            lastBilledPaymentDate: '2021-03-01T00:00:00Z',
            nextScheduledPaymentDate: '2021-04-01T00:00:00Z',
            minValidPaymentDate: '2021-03-15T00:00:00Z',
            maxValidPaymentDate: '2021-04-15T00:00:00Z',
            productType: isServiceProduct ? 'fixedPriceServicing' : 'solutions',
            financeBrand: 'vw',
        },
    };
};

const buildMileageSection = (isServiceProduct: boolean): MileageSectionBO => {
    return {
        mileageChangeable: 'ok',
        startingMileage: 1000,
        latestRecordedMileage: 5000,
        expectedMileageToDate: 15000,
        contractedMileage: 20000,
        changeMileage: {
            encryptedContractId: isServiceProduct ? 'encrypted0000440123456790' : 'encrypted0000440123456789',
            firstName: 'John',
            lastName: 'Smith',
            emailAddress: 'sample@domain.com',
            minValidCurrentMileage: 1000,
            maxValidCurrentMileage: 999999,
            latestRecordedMileage: 5000,
            productType: isServiceProduct ? 'fixedPriceServicing' : 'solutions',
            financeBrand: 'vw',
        },
    };
};

const buildRegistrationSection = (isServiceProduct: boolean): RegistrationSectionBO => {
    return {
        registrationChangeable: 'ok',
        vehicleRegistrationNumber: 'EX19ABC',
        changeRegistrationNumber: {
            encryptedContractId: isServiceProduct ? 'encrypted0000440123456790' : 'encrypted0000440123456789',
            productCategory: isServiceProduct ? 'service' : 'purchase',
            registrationNumber: 'EX19ABC',
            firstName: 'John',
            lastName: 'Smith',
            emailAddress: 'sample@domain.com',
        },
    };
};

export const ContractDetailsFinanceProduct: ContractDetailsBO = {
    financialDetails: {
        summarySection: {
            contractStartDate: '2018-11-02T00:00:00Z',
            contractEndDate: '2022-12-02T00:00:00Z',
            term: 48,
        },
        bankAccountSection: buildBankAccountSection(false),
        dayOfPaymentSection: buildDayOfPaymentSection(false),
        finalPaymentAmountSection: undefined,
        mileageSection: buildMileageSection(false),
    },
    serviceDetails: undefined,
    vehicleDetails: {
        summarySection: {
            vehicleBrand: 'VOLKSWAGEN',
            vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
            vehicleIdentificationNumber: 'TTTTTT9TTTT999999',
        },
        registrationSection: buildRegistrationSection(false),
    },
    bundledProducts: undefined,
};

export const ContractDetailsFinanceProductWithBundledProducts: ContractDetailsBO = {
    ...ContractDetailsFinanceProduct,
    bundledProducts: {
        fixedCostMaintenanceSection: {
            serviceLevel: 'serviceMaintenanceAndTyres',
        },
    },
};

const serviceDetails: ServiceDetailsBO = {
    summarySection: {
        productType: 'fixedPriceServicing',
        serviceLevel: 'serviceMaintenanceAndTyres',
        totalServiceEntitlement: undefined,
        remainingServiceEntitlement: undefined,
        contractStartDate: '2018-11-02T00:00:00Z',
        contractEndDate: '2022-12-02T00:00:00Z',
        paymentInterval: 'monthly',
    },
    bankAccountSection: {
        bankAccountChangeable: 'contractEnded',
        sortCode: '*2-*4-*6',
        accountNumber: '*****678',
        changeBankAccount: undefined,
    },
    dayOfPaymentSection: {
        paymentDateChangeable: 'contractEnded',
        monthlyDueDay: 17,
        changePaymentDate: undefined,
    },
    mileageSection: {
        mileageChangeable: 'contractEnded',
        startingMileage: 1000,
        latestRecordedMileage: 5000,
        expectedMileageToDate: 15000,
        contractedMileage: 20000,
        changeMileage: undefined,
    },
};

const vehicleDetails: VehicleDetailsBO = {
    summarySection: {
        vehicleBrand: 'VOLKSWAGEN',
        vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
        vehicleIdentificationNumber: 'TTTTTT9TTTT999999',
    },
    registrationSection: {
        registrationChangeable: 'notAllowed',
        vehicleRegistrationNumber: 'EX19ABC',
        changeRegistrationNumber: undefined,
    },
};

export const ContractDetailsServiceProduct: ContractDetailsBO = {
    financialDetails: undefined,
    serviceDetails,
    vehicleDetails,
    bundledProducts: undefined,
};

export const ContractDetailsServiceProductEditable: ContractDetailsBO = {
    ...ContractDetailsServiceProduct,
    serviceDetails: {
        ...serviceDetails,
        bankAccountSection: buildBankAccountSection(true),
        dayOfPaymentSection: buildDayOfPaymentSection(true),
        mileageSection: buildMileageSection(true),
    },
    vehicleDetails: {
        ...vehicleDetails,
        registrationSection: buildRegistrationSection(true),
    },
};
