import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import {
    FinancialSupportAndWellbeingBO,
    getFinancialSupportAndWellbeingEndpoint,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<
    FinancialSupportAndWellbeingBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'financialSupportAndWellbeing',
    fetchCallback() {
        return CpDataApi.get<FinancialSupportAndWellbeingBO>(getFinancialSupportAndWellbeingEndpoint()).then(
            ({ data }) => data,
        );
    },
});

export default reducer;
export const fetchFinancialSupportAndWellbeing = fetchData;
