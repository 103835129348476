import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Paragraph, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { InsuranceBrands } from '@cp-uk/common';
import {
    appendBuildTimestamp,
    openInNewWindow,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useInsuranceFinanceCompanyDetails,
} from 'utils';
import { ContractMarketingCard } from './ContractMarketingCard';

export const InsuranceMarketingCard: React.FC<{ insuranceBrand: InsuranceBrands }> = ({ insuranceBrand }) => {
    const { t } = useTranslation('insurance-marketing-card');
    const shortCompanyName = useInsuranceFinanceCompanyDetails(insuranceBrand);

    const { onAction: onFindOutMoreAction } = useAnalyticsActionTracker('insuranceMarketingCardForward');
    useAnalyticsPageViewTracker('insuranceMarketingCard', true, insuranceBrand);

    const title = t('title', { shortCompanyName });
    const buttonUrl = t(`buttonUrls.${insuranceBrand}`);
    const buttonTestId = 'findOutMoreButton';

    const onFindOutMoreClick = (): void => {
        onFindOutMoreAction(buttonTestId, insuranceBrand);
        openInNewWindow(buttonUrl);
    };

    return (
        <ContractMarketingCard
            title={title}
            buttonLabel={t('translation:editableSectionNav.findOutMore')}
            buttonTestId={buttonTestId}
            onButtonClick={onFindOutMoreClick}
            testId={'insuranceMarketingCard'}
        >
            <Layout>
                <Layout.Item default={'2/3'} m={'1/2'} s={'1/1'}>
                    <Paragraph className={'u-mb-none'}>{t('text', { shortCompanyName })}</Paragraph>
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/2'} s={'1/1'}>
                    <ResponsiveImage
                        src={appendBuildTimestamp(t('imageUrl', { brand: insuranceBrand }))}
                        alt={title}
                        testId={'insuranceImage'}
                    />
                </Layout.Item>
            </Layout>
        </ContractMarketingCard>
    );
};
