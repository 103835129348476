import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { EarlyTermination } from 'components/early-termination/EarlyTermination';

export const EarlyTerminationPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.EarlyTermination);
    const { t } = useTranslation('page-titles');
    const { encryptedContractId } = useParams<{ encryptedContractId: string }>();

    return (
        <ContentSection className={'o-content-section--compact uk-early-termination-page'} pageWrapSize={'small'}>
            <PageHeader title={t('early-termination')} />
            <EarlyTermination encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
