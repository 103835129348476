import { Expenditure, formatAsNumber, Income, IncomeAndExpenditure, numberFormatNoSeparators } from '@cp-uk/common';
import {
    DisposableIncomeFormValues,
    ExpenditureFormValues,
    IncomeAndExpenditureFormValues,
    IncomeFormValues,
} from './types';

export const incomeTotalled = (income: Income | IncomeFormValues): number => {
    const { salaryWages, benefitsOther } = income;

    return Number(salaryWages) + Number(benefitsOther);
};

export const expenditureTotalled = (expenditure: Expenditure | ExpenditureFormValues): number => {
    const {
        volkswagenFinance,
        rentMortgage,
        waterGasElectric,
        councilTax,
        childCareMaintenance,
        landlineInternetTv,
        mobiles,
        carInsuranceTax,
        fuelTravelCosts,
        housekeeping,
        other,
        otherCreditors,
    } = expenditure;

    return (
        Number(volkswagenFinance) +
        Number(rentMortgage) +
        Number(waterGasElectric) +
        Number(councilTax) +
        Number(childCareMaintenance) +
        Number(landlineInternetTv) +
        Number(mobiles) +
        Number(carInsuranceTax) +
        Number(fuelTravelCosts) +
        Number(housekeeping) +
        Number(other) +
        Number(otherCreditors)
    );
};

const parseIncome = (income: Income): IncomeFormValues => {
    const { salaryWages, benefitsOther } = income;

    return {
        salaryWages: formatAsNumber(salaryWages, numberFormatNoSeparators),
        benefitsOther: formatAsNumber(benefitsOther, numberFormatNoSeparators),
        total: formatAsNumber(incomeTotalled(income), numberFormatNoSeparators),
    };
};

const parseExpenditure = (expenditure: Expenditure): ExpenditureFormValues => {
    const {
        volkswagenFinance,
        rentMortgage,
        waterGasElectric,
        councilTax,
        childCareMaintenance,
        landlineInternetTv,
        mobiles,
        carInsuranceTax,
        fuelTravelCosts,
        housekeeping,
        other,
        otherCreditors,
    } = expenditure;

    return {
        volkswagenFinance: formatAsNumber(volkswagenFinance, numberFormatNoSeparators),
        rentMortgage: formatAsNumber(rentMortgage, numberFormatNoSeparators),
        waterGasElectric: formatAsNumber(waterGasElectric, numberFormatNoSeparators),
        councilTax: formatAsNumber(councilTax, numberFormatNoSeparators),
        childCareMaintenance: formatAsNumber(childCareMaintenance, numberFormatNoSeparators),
        landlineInternetTv: formatAsNumber(landlineInternetTv, numberFormatNoSeparators),
        mobiles: formatAsNumber(mobiles, numberFormatNoSeparators),
        carInsuranceTax: formatAsNumber(carInsuranceTax, numberFormatNoSeparators),
        fuelTravelCosts: formatAsNumber(fuelTravelCosts, numberFormatNoSeparators),
        housekeeping: formatAsNumber(housekeeping, numberFormatNoSeparators),
        other: formatAsNumber(other, numberFormatNoSeparators),
        otherCreditors: formatAsNumber(otherCreditors, numberFormatNoSeparators),
        total: formatAsNumber(expenditureTotalled(expenditure), numberFormatNoSeparators),
    };
};

const parseDisposableIncome = (incomeAndExpenditure: IncomeAndExpenditure): DisposableIncomeFormValues => {
    const { income, expenditure } = incomeAndExpenditure;

    return {
        total: formatAsNumber(incomeTotalled(income) - expenditureTotalled(expenditure), numberFormatNoSeparators),
    };
};

export const parseIncomeAndExpenditure = (
    incomeAndExpenditure: IncomeAndExpenditure,
): IncomeAndExpenditureFormValues => {
    const { income, expenditure } = incomeAndExpenditure;

    return {
        income: parseIncome(income),
        expenditure: parseExpenditure(expenditure),
        disposableIncome: parseDisposableIncome(incomeAndExpenditure),
    };
};
