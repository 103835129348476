import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { BundledProductsBO } from '@cp-uk/common';
import { FixedCostMaintenanceSection } from './fixed-cost-maintenance-section/FixedCostMaintenanceSection';

export const BundledProducts: React.FC<{ bundledProducts: BundledProductsBO | undefined }> = ({ bundledProducts }) => {
    const { t } = useTranslation('bundled-products');

    if (bundledProducts === undefined) {
        return null;
    }

    const { fixedCostMaintenanceSection } = bundledProducts;

    return (
        <Accordion.Item title={t('title')} testId={'bundledProducts'}>
            <Suspense fallback={<Spinner center />}>
                <FixedCostMaintenanceSection fixedCostMaintenanceSection={fixedCostMaintenanceSection} />
            </Suspense>
        </Accordion.Item>
    );
};
