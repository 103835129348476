import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { EarlySettlement } from 'components/early-settlement/EarlySettlement';

export const EarlySettlementPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.EarlySettlement);
    const { t } = useTranslation('page-titles');
    const { encryptedContractId } = useParams<{ encryptedContractId: string }>();

    return (
        <ContentSection className={'o-content-section--compact uk-early-settlement-page'} pageWrapSize={'small'}>
            <PageHeader title={t('early-settlement')} />
            <EarlySettlement encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
