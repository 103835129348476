import { SimplePageHeroTeaserDto, getModernSlaveryStatementPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'modernSlaveryStatementPageHeroTeaser',
    contentEndpoint: getModernSlaveryStatementPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchModernSlaveryStatementPageHeroTeaser = fetchContent;
