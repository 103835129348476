import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { SimplePageError } from 'components/simple-page-error/SimplePageError';

export const SessionTimeoutPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.SessionTimeout);
    useAnalyticsPageViewTracker('onAutoLogout');

    return <SimplePageError namespace={'session-timeout'} />;
};
