import React, { Suspense } from 'react';
import { Card, Hr } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { Spinner, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { MyProfileBO } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { fetchMyProfile } from './MyProfileSlice';
import { selectMyProfile } from './MyProfileSelector';
import { MyProfileProvider } from './MyProfileProvider';
import { IdentificationSection } from './identification-section/IdentificationSection';
import { AddressSection } from './address-section/AddressSection';
import { ContactDetailsSection } from './contact-details-section/ContactDetailsSection';
import { MarketingPreferencesSection } from './marketing-preferences-section/MarketingPreferencesSection';

export const MyProfileUi: React.FC<{ myProfile: MyProfileBO | undefined }> = ({ myProfile }) => {
    useAnalyticsPageViewTracker('profile', !!myProfile);

    if (!myProfile) {
        return null;
    }

    const { identificationSection, addressSection, contactDetailsSection, marketingPreferencesSection } = myProfile;

    return (
        <Suspense fallback={<Spinner center />}>
            <Card
                element={'section'}
                className={'uk-my-profile c-card--body-pv-none u-text-left'}
                testId={'myProfile'}
            >
                <MyProfileProvider myProfile={myProfile}>
                    <IdentificationSection identificationSection={identificationSection} />
                    <Hr className={'u-mb-none'} />
                    <AddressSection addressSection={addressSection} />
                    <Hr className={'u-mb-none'} />
                    <ContactDetailsSection contactDetailsSection={contactDetailsSection} />
                    <Hr className={'u-mb-none'} />
                    <MarketingPreferencesSection marketingPreferencesSection={marketingPreferencesSection} />
                </MyProfileProvider>
            </Card>
        </Suspense>
    );
};

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data: myProfile, isLoading, loadingError } = useGetSimpleApiData(
        fetchMyProfile,
        getSimpleDataSelector(selectMyProfile),
    );

    return <MyProfileWithHandlers isLoading={isLoading} hasError={!!loadingError} myProfile={myProfile} />;
};
