import { SimplePageHeroTeaserDto, getLegalNoticePageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'legalNoticePageHeroTeaser',
    contentEndpoint: getLegalNoticePageHeroTeaserEndpoint,
});

export default reducer;
export const fetchLegalNoticePageHeroTeaser = fetchContent;
