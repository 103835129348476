import React, { FormEventHandler, Fragment } from 'react';
import { FormField, RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { hasError, ValidationErrorMessage } from '../errors';
import { HelpText } from '../help-text/HelpText';
import { InfoIcon } from '@cp-shared-8/frontend-ui';

export type RadioButtonProps = {
    label: string;
    value: readonly string[] | string | number;
};

type ValidatedRadioButtonGroupProps = {
    radioButtons: RadioButtonProps[];
    name: string;
    label?: string;
    tooltip?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    testId?: string;
    helpText?: string | React.ReactNode;
    handleChange?: FormEventHandler<HTMLInputElement>;
    className?: string;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
};

export const ValidatedRadioButtonGroup: React.FC<ValidatedRadioButtonGroupProps> = ({
    radioButtons,
    name,
    tooltip,
    defaultChecked,
    label,
    disabled,
    testId,
    helpText,
    handleChange,
    className,
    isMandatory = false,
    withHtmlValidationWarning = false,
}) => {
    const [field, meta] = useField(name);

    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    return (
        <FormField
            type="other"
            testId={`${testId}FormField`}
            errorMessage={errorMessageElseHelpText}
            labelText={label}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            <RadioButtonGroup className={className} {...field}>
                {radioButtons.map(({ label, value }, index: number) => (
                    <RadioButton
                        key={index}
                        testId={`${testId}.${value}`}
                        name={name}
                        value={value}
                        error={hasError(meta)}
                        disabled={disabled}
                        defaultChecked={defaultChecked || value === field.value}
                    >
                        <div>
                            <span data-testid={'radioButtonLabel'}>{label}</span>
                        </div>
                    </RadioButton>
                ))}
            </RadioButtonGroup>
        </FormField>
    );
};
