import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit, Spinner } from '@cp-shared-8/frontend-ui';
import {
    ChangePaymentDateBO,
    ChangePaymentDateRequest,
    formatAsDate,
    getChangePaymentDateEndpoint,
    parseCPDate,
    parseCPDateFromInput,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import 'extensions';
import { buildLinkToPhoneNumber, textAsHtml, textWithComponents, useFinanceCompanyDetails } from 'utils';
import { ChangeData } from 'components/change-data/ChangeData';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { validationSchema } from './ChangePaymentDateValidation';
import { getInformationNotificationText } from '../../helpers';
import { NotificationContentProps } from '../../types';

export const ChangePaymentDate: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: (failureNotificationContentProps: NotificationContentProps) => void;
    onChangeSuccess: (changePaymentDateRequest: ChangePaymentDateRequest) => void;
    changePaymentDate: ChangePaymentDateBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changePaymentDate }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [changePaymentDateRequest, setChangePaymentDateRequest] = useState<ChangePaymentDateRequest | undefined>(
        undefined,
    );
    const [lastDayOfMonthDisabled, setLastDayOfMonthDisabled] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { phoneNumber } = useFinanceCompanyDetails(changePaymentDate?.productType, changePaymentDate?.financeBrand);
    const { t } = useTranslation('change-payment-date');

    if (changePaymentDate === undefined) {
        return null;
    }

    const {
        encryptedContractId,
        showThisContractOnlyNotification,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
        minValidPaymentDate,
        maxValidPaymentDate,
    } = changePaymentDate;

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');
    const showWhichPaymentDateNotification = !!lastBilledPaymentDate;
    const showInformationNotification = showThisContractOnlyNotification || showWhichPaymentDateNotification;

    const informationNotificationText = getInformationNotificationText(
        t,
        'whichPaymentDate',
        showThisContractOnlyNotification,
        showWhichPaymentDateNotification,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
    );

    const onPaymentDateChange = (
        { target: { value } }: React.ChangeEvent<HTMLInputElement>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ): void => {
        const paymentDateMoment = parseCPDateFromInput(value).toMoment();
        if (!paymentDateMoment.isValid()) {
            setLastDayOfMonthDisabled(true);
            setFieldValue('lastDayOfMonth', false);
            return;
        }

        const parsedDayOfMonth = paymentDateMoment.date();
        const daysInMonth = paymentDateMoment.daysInMonth();

        setLastDayOfMonthDisabled(parsedDayOfMonth === 31 || parsedDayOfMonth !== daysInMonth);
        setFieldValue('lastDayOfMonth', parsedDayOfMonth === 31);
    };

    const areTwoPaymentsInSameCalendarMonth = (paymentDate: string): boolean => {
        const lastBilledPaymentDateMoment = parseCPDate(lastBilledPaymentDate).toMoment();
        const paymentDateMoment = parseCPDateFromInput(paymentDate).toMoment();

        return lastBilledPaymentDateMoment.isSame(paymentDateMoment, 'month');
    };

    const submitInternal = (): Promise<void> => {
        if (changePaymentDateRequest === undefined) {
            return Promise.resolve();
        }

        setIsSubmitting(true);
        return CpDataApi.put<void>(getChangePaymentDateEndpoint(encryptedContractId), changePaymentDateRequest)
            .then(() => {
                setIsSubmitting(false);
                onChangeSuccess(changePaymentDateRequest);
            })
            .catch(() => {
                setIsSubmitting(false);
                onChangeFailure({
                    headline: t('failureNotification.headline'),
                    children: textWithComponents(t, 'failureNotification.text', { linkToPhoneNumber }, { phoneNumber }),
                });
            });
    };

    const onClose = (): void => {
        onChangeCancel();
    };

    const onCancel = (): void => {
        onChangeCancel();
    };

    const onConfirmationClose = (): void => {
        setShowConfirmationModal(false);
    };
    const onConfirmationCancel = (): void => {
        setShowConfirmationModal(false);
    };
    const onConfirmationConfirm = (): Promise<void> => {
        setShowConfirmationModal(false);
        return submitInternal();
    };

    const minAndMaxValidPaymentDates = {
        minValidPaymentDate,
        maxValidPaymentDate,
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={'single-view'}
                className={'uk-change-payment-date'}
                heading={t('heading')}
                isSubmitting={isSubmitting}
                onClose={onClose}
                testId={'changePaymentDate'}
            >
                <Modal
                    title={t('confirmationModal.title')}
                    shown={showConfirmationModal}
                    status={'warning'}
                    closeAny={false}
                    hideCloseButton={true}
                    buttonCancelText={t('translation:editableSectionNav.cancel')}
                    buttonConfirmText={t('translation:editableSectionNav.continue')}
                    buttonConfirmType={'button'}
                    onClose={onConfirmationClose}
                    onCancel={onConfirmationCancel}
                    onConfirm={onConfirmationConfirm}
                    testId={'confirmationModal'}
                >
                    <Paragraph testId={'statementParagraph'}>
                        {t('confirmationModal.statement', {
                            lastBilledPaymentDay: formatAsDate(lastBilledPaymentDate, 'DD'),
                            newPaymentDate: changePaymentDateRequest?.paymentDate,
                        })}
                    </Paragraph>
                    <Paragraph testId={'questionParagraph'}>{t('confirmationModal.question')}</Paragraph>
                </Modal>
                {showInformationNotification && (
                    <Notification
                        className={'u-mb'}
                        status={NotificationStatus.info}
                        testId={'informationNotification'}
                    >
                        {textAsHtml(informationNotificationText)}
                    </Notification>
                )}
                <Formik
                    initialValues={{
                        paymentDate: '',
                        lastDayOfMonth: false,
                    }}
                    validationSchema={validationSchema(
                        t,
                        lastBilledPaymentDate,
                        nextScheduledPaymentDate,
                        minValidPaymentDate,
                        maxValidPaymentDate,
                    )}
                    onSubmit={({ paymentDate, lastDayOfMonth }): Promise<void> => {
                        setChangePaymentDateRequest({
                            paymentDate: parseCPDateFromInput(paymentDate).toMoment().toUTC().toCPDate(),
                            lastDayOfMonth,
                        });
                        if (areTwoPaymentsInSameCalendarMonth(paymentDate)) {
                            setShowConfirmationModal(true);
                            return Promise.resolve();
                        } else {
                            return submitInternal();
                        }
                    }}
                >
                    {(formik): React.ReactNode => (
                        <Form className={'uk-change-payment-date-form'} onSubmit={preventSubmit}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [2, 2, 4],
                                            delimiter: '/',
                                        }}
                                        className={'input-date'}
                                        label={t('paymentDate.label')}
                                        placeholder={t('paymentDate.placeholder')}
                                        tooltip={t('paymentDate.tooltip', minAndMaxValidPaymentDates)}
                                        helpText={t('paymentDate.helpText', minAndMaxValidPaymentDates)}
                                        name={'paymentDate'}
                                        testId={'paymentDate'}
                                        isMandatory={true}
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            onPaymentDateChange(e, formik.setFieldValue)
                                        }
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        label={t('lastDayOfMonth.label')}
                                        tooltip={t('lastDayOfMonth.tooltip')}
                                        helpText={t('lastDayOfMonth.helpText', minAndMaxValidPaymentDates)}
                                        name={'lastDayOfMonth'}
                                        testId={'lastDayOfMonth'}
                                        disabled={lastDayOfMonthDisabled}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer nav>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={onCancel}
                                            secondary
                                            testId={'cancelButton'}
                                        >
                                            {t('translation:editableSectionNav.cancel')}
                                        </Button>
                                        <Button
                                            type={'button'}
                                            disabled={isSubmitting}
                                            onClick={formik.submitForm}
                                            testId={'confirmChangeButton'}
                                        >
                                            {t('translation:editableSectionNav.confirmChange')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </ChangeData>
        </Suspense>
    );
};
