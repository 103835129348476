import React from 'react';
import { Logo as LogoBronson } from '@vwfs-bronson/bronson-react';
import { currentBrandName, currentBronson } from 'config';

type LogoSpecificLogo = Parameters<typeof LogoBronson>[0]['specificLogo'];

function getSrc(): string {
    const { baseUrl, theme, modifier, version, logoExt } = currentBronson;

    const fileFragment = (modifier) ? `-${modifier}` : '';
    const extFragment = (logoExt) ? logoExt : 'svg';

    return `${baseUrl}/${theme}/v/${version}/img/logo${fileFragment}.${extFragment}`;
}

function getSpecificLogo(): string | undefined {
    const { modifier } = currentBronson;

    return (modifier) ? modifier : undefined;
}

export const Logo: React.FC<{ title: string; onClick: () => void }> = ({ title, onClick }) => {
    return (
        <LogoBronson
            title={title}
            onClick={onClick}
            src={getSrc()}
            alt={currentBrandName}
            ariaLabel={title}
            specificLogo={getSpecificLogo() as LogoSpecificLogo}
            testId={'logo'}
        />
    );
};
