// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/validated-select/ValidatedSelect.tsx
// (there weren't enough/working properties on the original)

import { FormField, Select } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { FocusEventHandler, FormEventHandler, Fragment } from 'react';
import { ValidationErrorMessage } from '../errors';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { HelpText } from '../help-text/HelpText';

export type ValidatedSelectItem = {
    label: string;
    value: string;
    key?: string | number;
};

export type ValidatedSelectProps = {
    name: string;
    id?: string;
    testId?: string;
    tooltip?: string;
    selectItems: ValidatedSelectItem[];
    placeholder?: string;
    /**
     * @deprecated
     * will be changed to true default in future
     */
    disablePlaceholder?: boolean;
    label?: string;
    helpText?: string | React.ReactNode;
    className?: string;
    handleChange?: FormEventHandler<HTMLSelectElement>;
    disabled?: boolean;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    emptyByDefault?: boolean;
    onFocus?: FocusEventHandler<HTMLSelectElement>;
};

export const ValidatedSelect: React.FC<ValidatedSelectProps> = ({
    name,
    label,
    helpText,
    className,
    id,
    testId,
    tooltip,
    selectItems,
    placeholder,
    disablePlaceholder,
    handleChange,
    disabled,
    isMandatory = false,
    withHtmlValidationWarning = false,
    emptyByDefault,
    onFocus,
}) => {
    const [field, meta] = useField({ name, type: 'select' });

    const labelText = disabled ? <span className={'u-text-disabled'}>{label}</span> : label;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <FormField
            className={className}
            type="select"
            testId={`${testId}FormField`}
            labelText={labelText as string | undefined}
            errorMessage={errorMessageElseHelpText}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            <Select id={id} testId={testId} disabled={disabled} onFocus={onFocus} {...field}>
                {emptyByDefault && <Select.Item hidden />}
                {placeholder && (
                    <Select.Item value={''} disabled={disablePlaceholder ?? true}>
                        {placeholder}
                    </Select.Item>
                )}
                {selectItems.map((item) => {
                    return (
                        <Select.Item key={item.key ? item.key : item.value} value={item.value}>
                            {item.label}
                        </Select.Item>
                    );
                })}
            </Select>
        </FormField>
    );
};
