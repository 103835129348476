import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { DispatchByFormValues } from './types';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        dispatchBy: Yup.object().test('atLeastOneSelected', t('dispatchBy.validation.atLeastOneSelected'), (value) => {
            const dispatchBy = value as DispatchByFormValues;
            return !!dispatchBy && (dispatchBy.email || dispatchBy.post);
        }),
    });
};
