import { useTranslation } from 'react-i18next';
import { currentBrand, currentBrandName } from 'config';

export enum HeadTitleMetaElementsPages {
    Landing = 'landing',
    FrequentlyAskedQuestions = 'frequentlyAskedQuestions',
    LegalNotice = 'legalNotice',
    PrivacyPolicy = 'privacyPolicy',
    CookiePolicy = 'cookiePolicy',
    ModernSlaveryStatement = 'modernSlaveryStatement',
    ThirdPartyLicences = 'thirdPartyLicences',
    NotFound = 'notFound',
    LoginError = 'loginError',
    SessionTimeout = 'sessionTimeout',
    Register = 'register',
    Dashboard = 'dashboard',
    OutstandingPayments = 'outstandingPayments',
    RequestAdditionalHelp = 'requestAdditionalHelp',
    EarlySettlement = 'earlySettlement',
    EarlyTermination = 'earlyTermination',
    EndOfContract = 'endOfContract',
    MyProfile = 'myProfile',
    DocumentCentre = 'documentCentre',
    RequestContact = 'requestContact',
    StartMatchmaker = 'startMatchmaker',
    UseOurMatchmaker = 'useOurMatchmaker',
    FinancialSupportAndWellbeing = 'financialSupportAndWellbeing',
    BrandCarInsurance = 'brandCarInsurance',
}

export const useHeadTitleMetaElements = (page: HeadTitleMetaElementsPages, shortCompanyName?: string): void => {
    const { t } = useTranslation('head-title-meta-elements');

    const titleFragment = t(`${page}Page.titleFragment`, { shortCompanyName });
    const metaDescriptionForBrand = t(`${page}Page.metaDescription.${currentBrand}`, '');

    const title = `${titleFragment} | ${currentBrandName}`;
    const metaDescription = metaDescriptionForBrand || `${currentBrandName} ${titleFragment.toLowerCase()}`;

    document.title = title;
    document.head.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
};
