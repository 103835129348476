import React, { Fragment, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, ContentSection, Heading, PageWrap, Paragraph } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { FrequentlyAskedQuestionsDto } from '@cp-uk/common';
import { expandBrand, expandBrandName, expandCarOrVehicle, textAsHtml } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchFrequentlyAskedQuestions } from './FrequentlyAskedQuestionsSlice';
import { selectFrequentlyAskedQuestions } from './FrequentlyAskedQuestionsSelector';

const expandQuestion = (question: string): string => {
    const expandedQuestion = expandCarOrVehicle(question);

    return expandedQuestion;
};

const expandAnswer = (answer: string): string => {
    let expandedAnswer = expandBrand(answer);
    expandedAnswer = expandBrandName(expandedAnswer);
    expandedAnswer = expandCarOrVehicle(expandedAnswer);

    return expandedAnswer;
};

const FrequentlyAskedQuestionsUi: React.FC<{ frequentlyAskedQuestions: FrequentlyAskedQuestionsDto | undefined }> = ({
    frequentlyAskedQuestions,
}) => {
    const location = useLocation();
    const previousItems = useRef<string[]>([]);

    const { onAction: onFaqQuestionAction } = useAnalyticsActionTracker('onFaqQuestion');
    useAnalyticsPageViewTracker('faq', !!frequentlyAskedQuestions);

    const { search } = location;
    const idToScrollTo = search.startsWith('?') ? search.replace('?', '') : undefined;

    useLayoutEffect(() => {
        if (frequentlyAskedQuestions === undefined || idToScrollTo === undefined) {
            return;
        }

        const element = document.getElementById(idToScrollTo);
        if (element) {
            window.scrollTo(0, element.offsetTop);
        }
    }, [frequentlyAskedQuestions, idToScrollTo]);

    if (frequentlyAskedQuestions === undefined) {
        return null;
    }

    const { heading, description, items } = frequentlyAskedQuestions;

    const onChange = (items: string[]): void => {
        if (items.length > previousItems.current.length) {
            const newItems = items.filter((item: string): boolean => !previousItems.current.includes(item));
            newItems.forEach((item: string): void => onFaqQuestionAction(item));
        }
        previousItems.current = items;
    };

    return (
        <ContentSection className={'uk-frequently-asked-questions'}>
            <PageWrap size={'medium'}>
                <section>
                    <Heading level={2} testId={'subHeading'}>
                        {heading}
                    </Heading>
                    <Paragraph testId={'introductionParagraph'}>{description}</Paragraph>
                    <Accordion multipleOpen={true} onChange={onChange} testId={'frequentlyAskedQuestionsAccordion'}>
                        {items.map(({ id, question, answer }, index) => (
                            <Accordion.Item
                                key={index}
                                id={id}
                                className={'c-frequently-asked-questions__question'}
                                contentClassName={'c-frequently-asked-questions__answer'}
                                title={expandQuestion(question)}
                                defaultOpen={id === idToScrollTo}
                                testId={`${id}AccordionItem`}
                            >
                                <Fragment>{textAsHtml(expandAnswer(answer))}</Fragment>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </section>
            </PageWrap>
        </ContentSection>
    );
};

const FrequentlyAskedQuestionsWithHandlers = withLoadingAndNoConnectionHandler(FrequentlyAskedQuestionsUi);

export const FrequentlyAskedQuestions: React.FC = () => {
    const { cmsContent: frequentlyAskedQuestions, isLoading, loadingError } = useCmsContent(
        fetchFrequentlyAskedQuestions,
        selectFrequentlyAskedQuestions,
    );

    return (
        <FrequentlyAskedQuestionsWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            frequentlyAskedQuestions={frequentlyAskedQuestions}
        />
    );
};
