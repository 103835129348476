import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { InsuranceBO, getInsuranceEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<InsuranceBO, DefaultBusinessMarketApiErrorCode>({
    dataName: 'insurance',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<InsuranceBO>(getInsuranceEndpoint(encryptedContractId)).then(({ data }) => data);
    },
});

export default reducer;
export const fetchInsurance = fetchData;
