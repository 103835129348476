import React, { Fragment } from 'react';
import { SectionHeading, Layout, ItemTeaser } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { IconFooterDto, IconFooterItemDto } from '@cp-uk/common';
import { textAsHtml } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LinkAdapter } from 'components/link-adapter';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { fetchIconFooter } from './IconFooterSlice';
import { selectIconFooter } from './IconFooterSelector';

const IconFooterUi: React.FC<{ iconFooter: IconFooterDto | undefined }> = ({ iconFooter }) => {
    const { onAction: onClickIconFooterAction } = useAnalyticsActionTracker('onClickMarketingCard');

    if (iconFooter === undefined) {
        return null;
    }

    const { heading, items, trustpilot } = iconFooter;

    const buildOnClickOrUndefined = (linkText: string, isInternal: boolean): (() => void) | undefined => {
        if (isInternal) {
            return undefined;
        }

        return (): void => {
            onClickIconFooterAction(linkText);
        };
    };

    return (
        <Fragment>
            <SectionHeading center={true} level={2} testId={'iconFooterHeading'}>
                {heading}
            </SectionHeading>
            <Layout center={true}>
                {items.map(({ key, icon, title, text, linkText, linkUrl }: IconFooterItemDto) => {
                    const isInternal = linkUrl.startsWith('/');

                    return (
                        <Layout.Item key={key} default={'1/3'} m={'1/1'}>
                            <ItemTeaser
                                icon={icon}
                                iconDescription={title}
                                headingLevel={3}
                                title={title}
                                linkLabel={linkText}
                                linkProps={{
                                    element: isInternal ? LinkAdapter : 'a',
                                    href: linkUrl,
                                    target: isInternal ? undefined : '_BLANK',
                                    onClick: buildOnClickOrUndefined(linkText, isInternal),
                                    testId: `${key}Link`,
                                }}
                                testId={`${key}ItemTeaser`}
                            >
                                {textAsHtml((text as string) || '')}
                            </ItemTeaser>
                        </Layout.Item>
                    );
                })}
            </Layout>
            <Layout center={true} className={'u-mt-none'}>
                <Layout.Item default={'1/1'}>
                    <ItemTeaser
                        className={'c-trustpilot u-text-center'}
                        headingLevel={3}
                        title={trustpilot.title}
                        testId={'trustpilotItemTeaser'}
                    >
                        <SimpleLink
                            title={trustpilot.linkTitle}
                            href={trustpilot.linkUrl}
                            target={trustpilot.linkTarget}
                            onClick={buildOnClickOrUndefined(trustpilot.linkText, false)}
                            testId={'trustpilotLink'}
                        >
                            <img
                                width={200}
                                alt={trustpilot.imageAlt}
                                src={trustpilot.imageUrl}
                                data-testid={'trustpilotImage'}
                            />
                        </SimpleLink>
                    </ItemTeaser>
                </Layout.Item>
            </Layout>
        </Fragment>
    );
};

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useCmsContent(fetchIconFooter, selectIconFooter);

    return <IconFooterWithHandlers isLoading={isLoading} hasError={!!loadingError} iconFooter={iconFooter} />;
};
