import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import { FrequentlyAskedQuestions } from 'components/frequently-asked-questions/FrequentlyAskedQuestions';

export const FrequentlyAskedQuestionsPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.FrequentlyAskedQuestions);

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.FrequentlyAskedQuestions} />
            <FrequentlyAskedQuestions />
        </Fragment>
    );
};
