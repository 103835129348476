import { ProductTypes } from '../apis';

export const SERVICE_PRODUCTS: ProductTypes[] = ['fixedCostMaintenance', 'fixedPriceServicing'];
export const FINANCE_PRODUCTS: ProductTypes[] = ['solutions', 'hirePurchase', 'leasePurchase', 'contractHire'];

export const isProductType = (
    productType: ProductTypes | string | undefined,
    requiredProductTypes: ProductTypes | ProductTypes[],
): boolean => {
    if (Array.isArray(requiredProductTypes)) {
        return requiredProductTypes.some((requiredProductType: ProductTypes) => productType === requiredProductType);
    }

    return productType === requiredProductTypes;
};
