import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, Paragraph, UnorderedList } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO, ProductTypesEOC } from '@cp-uk/common';
import {
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { contractCategory, textAsHtml } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { dashboardPagePath } from 'components/navigation/paths';

export type ContractHireExtendViewProps = {
    contractDescription: ContractDescriptionBO;
    onBack: () => void;
    onExtension: () => void;
    onCallBack: () => void;
};

export const ContractHireExtendView: React.FC<ContractHireExtendViewProps> = ({
    contractDescription,
    onBack,
    onExtension,
    onCallBack,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const { t } = useTranslation('end-of-contract-contract-hire-extend-view');
    const history = useHistory();

    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractRchExtendBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractRchExtendDashboard');
    useAnalyticsPageViewTracker('endOfContractRchExtend');

    const onExtensionClick = (): void => {
        onExtension();
    };
    const onRequestCallBackClick = (): void => {
        onCallBack();
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    return (
        <View testId={'contractHireExtendView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType) })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                testId={'semiConductorDelaysNotification'}
            >
                {textAsHtml(t('notifications.semiConductorDelays.text'))}
            </Notification>
            <Paragraph testId={'extendAgreementTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.extendAgreement.title')}
            </Paragraph>
            <Paragraph testId={'extendAgreement1Paragraph'}>{t('paragraphs.extendAgreement.detail1')}</Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'extendAgreementBenefitsList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.extendAgreement.benefits.1')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.extendAgreement.benefits.2')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.extendAgreement.benefits.3')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>
                        {t('listItems.extendAgreement.benefits.4')}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'extendAgreement2Paragraph'}>{t('paragraphs.extendAgreement.detail2')}</Paragraph>
            <Paragraph testId={'extendAgreement3Paragraph'}>{t('paragraphs.extendAgreement.detail3')}</Paragraph>
            <Paragraph testId={'extendAgreement4Paragraph'}>{t('paragraphs.extendAgreement.detail4')}</Paragraph>
            <Paragraph>
                <Button type={'button'} testId={'extensionButton'} onClick={onExtensionClick} secondary>
                    {t('buttons.extension')}
                </Button>
            </Paragraph>
            <Paragraph testId={'requestCallBackTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.requestCallBack.title')}
            </Paragraph>
            <Paragraph testId={'requestCallBackParagraph'}>{t('paragraphs.requestCallBack.detail')}</Paragraph>
            <Paragraph>
                <Button type={'button'} testId={'requestCallBackButton'} onClick={onRequestCallBackClick} secondary>
                    {t('buttons.requestCallBack')}
                </Button>
            </Paragraph>
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
