import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Moment } from 'moment-timezone';
import { MethodsOfPayment } from '@cp-uk/common';
import { isInputDateSameDayOrAfter, isInputDateSameDayOrBefore, isInputDateValid } from 'utils';
import { dateOfPaymentIsMandatory } from './helpers';
import { PayInOneGoFormValues } from './types';

export const validationSchema = (
    t: TFunction,
    minValidDate: Moment,
    maxValidDate: Moment,
): Yup.ObjectSchema<PayInOneGoFormValues> => {
    const methodOfPaymentValidValues = ['directDebit', 'cardPayment', 'bankTransfer'];

    return Yup.object()
        .shape<PayInOneGoFormValues>({
            methodOfPayment: Yup.string()
                .default('')
                .required(t('methodOfPayment.validation.required'))
                .oneOf(methodOfPaymentValidValues, t('methodOfPayment.validation.required')),
            dateOfPayment: Yup.string()
                .default('')
                .defined()
                .when('methodOfPayment', (methodOfPayment: string, schema: Yup.StringSchema<string>) => {
                    if (dateOfPaymentIsMandatory(methodOfPayment as MethodsOfPayment)) {
                        return schema
                            .required(t('dateOfPayment.validation.required'))
                            .test('format', t('dateOfPayment.validation.format'), (dateOfPayment) =>
                                isInputDateValid(dateOfPayment),
                            )
                            .test(
                                'minValidDate',
                                t('dateOfPayment.validation.minValidDate', { minValidDate }),
                                (dateOfPayment) => isInputDateSameDayOrAfter(dateOfPayment, minValidDate),
                            )
                            .test(
                                'maxValidDate',
                                t('dateOfPayment.validation.maxValidDate', { maxValidDate }),
                                (dateOfPayment) => isInputDateSameDayOrBefore(dateOfPayment, maxValidDate),
                            );
                    } else {
                        return schema.length(0, t('dateOfPayment.validation.mustBeBlank'));
                    }
                }),
        })
        .required();
};
