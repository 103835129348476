import { currentBrand } from '../config';
import i18next from 'i18next';

export const dealerOrRetailerText = (): string => {
    const keyFragment: string = currentBrand === 'seat' ? 'retailer' : 'dealer';

    return i18next.t(`expand.dealerOrRetailers.${keyFragment}`);
};

export const carOrVehicleText = (): string => {
    const keyFragment: string = currentBrand === 'vwcv' ? 'vehicle' : 'car';

    return i18next.t(`expand.carOrVehicles.${keyFragment}`);
};
