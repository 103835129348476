import { CallingViews, Views } from '../types';

export const getSectionForAnalytics = (callingView: CallingViews): string => {
    switch (callingView) {
        case Views.contractHireNew:
            return 'Find new car';
        case Views.contractHireExtend:
            return 'Extend your current agreement';
        case Views.contractHireExtension:
            return 'Apply for an extension';
        case Views.contractHireReturn:
            return 'Returning your car';
        default:
            // eslint-disable-next-line no-alert
            alert('Unexpected state for getSectionForAnalytics()!');
            return '';
    }
};
