import React from 'react';
import { CPDate } from '@cp-shared-8/common-utilities';
import { ContractSummary as ContractSummaryShared, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import { ContractBO } from '@cp-uk/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { textWithComponents } from '../../utils';
import { linkToDocumentCentrePage } from '../link-to-pages';

export const ContractSummary: React.FC<{ contract: ContractBO }> = ({ contract }) => {
    const { t, f } = useTranslationWithFormatting('contract-summary');

    const {
        isActive,
        productType,
        dateOfNextPayment,
        monthlyPaymentAmount,
        contractStartDate,
        contractEndDate,
        outstandingBalance,
        totalServiceEntitlement,
        remainingServiceEntitlement,
        showOutstandingBalanceTooltip,
    } = contract;

    const getItems = (): ContractSummaryItemProps[] => {
        const makeSummaryItem = (
            labelKey: string,
            value: CPDate | number | undefined,
            translationFormat: TranslationFormat,
            testId: string,
            useNotAvailable: boolean,
            tooltipKey: string | undefined,
        ): ContractSummaryItemProps => {
            const undefinedSubstitute = (): string => {
                return t(`substitutions.${useNotAvailable && isActive ? 'notAvailable' : 'default'}`);
            };

            return {
                label: t(`summaryItems.${labelKey}`),
                value: value === undefined ? undefinedSubstitute() : f(value, translationFormat),
                testClass: (undefined as unknown) as string,
                testId: testId,
                tooltip: tooltipKey
                    ? textWithComponents(t, `tooltips.${tooltipKey}`, { linkToDocumentCentrePage })
                    : undefined,
            };
        };

        const makeNextDueDayItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'nextDueDay',
                dateOfNextPayment,
                TranslationFormat.DATE,
                'nextDueDaySummary',
                true,
                undefined,
            );
        };
        const makeNextDueAmountItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'nextDueAmount',
                monthlyPaymentAmount,
                TranslationFormat.CURRENCY,
                'nextDueAmountSummary',
                true,
                'nextDueAmount',
            );
        };
        const makeOutstandingBalanceItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'outstandingBalance',
                outstandingBalance,
                TranslationFormat.CURRENCY,
                'outstandingBalanceSummary',
                true,
                showOutstandingBalanceTooltip ? 'outstandingBalance' : undefined,
            );
        };
        const makeEndDateItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'endDate',
                contractEndDate,
                TranslationFormat.DATE,
                'endDateSummary',
                true,
                undefined,
            );
        };
        const makeTotalServicesIncluded = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'totalServicesIncluded',
                totalServiceEntitlement,
                TranslationFormat.NUMBER,
                'totalServicesIncludedSummary',
                false,
                undefined,
            );
        };
        const makeRemainingServices = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'remainingServices',
                remainingServiceEntitlement,
                TranslationFormat.NUMBER,
                'remainingServicesSummary',
                false,
                undefined,
            );
        };
        const makeContractStartDateItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'contractStartDate',
                contractStartDate,
                TranslationFormat.DATE,
                'contractStartDateSummary',
                false,
                undefined,
            );
        };
        const makeContractEndDateItem = (): ContractSummaryItemProps => {
            return makeSummaryItem(
                'contractEndDate',
                contractEndDate,
                TranslationFormat.DATE,
                'contractEndDateSummary',
                false,
                undefined,
            );
        };

        switch (productType) {
            case 'solutions':
            case 'hirePurchase':
            case 'leasePurchase':
                return [makeNextDueDayItem(), makeNextDueAmountItem(), makeOutstandingBalanceItem()];
            case 'contractHire':
                return [makeNextDueDayItem(), makeNextDueAmountItem(), makeEndDateItem()];
            case 'fixedPriceServicing':
                return (totalServiceEntitlement || 0) > 0
                    ? [makeTotalServicesIncluded(), makeRemainingServices()]
                    : [makeContractStartDateItem(), makeContractEndDateItem()];
            case 'fixedCostMaintenance':
                return [makeContractStartDateItem(), makeContractEndDateItem()];
            default:
                return [];
        }
    };

    return <ContractSummaryShared items={getItems()} />;
};
