import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { IncomeAndExpenditure, RequestAdditionalHelpBO, Situation } from '@cp-uk/common';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { useScrollTo } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import {
    EmailAddressNotification,
    EmailAddressNotificationMode,
    getEmailAddressNotificationMode,
} from 'components/notifications/email-address/EmailAddressNotification';
import { fetchRequestAdditionalHelp } from './RequestAdditionalHelpSlice';
import { selectRequestAdditionalHelpForSpecific } from './RequestAdditionalHelpSelector';
import { SituationView } from './situation-view';
import { BreathingSpaceView } from './breathing-space-view';
import { IncomeAndExpenditureView } from './income-and-expenditure-view';
import { ArrangementToPayView } from './arrangement-to-pay-view';
import { PromiseToPayView } from './promise-to-pay-view';
import { EndAgreementView } from './end-agreement-view';
import { Views } from './types';
import { defaultIncomeAndExpenditure, defaultSituation, payFullArrearsWithinNMonths } from './helpers';

export const RequestAdditionalHelpUi: React.FC<{
    encryptedContractId: string;
    requestAdditionalHelp: RequestAdditionalHelpBO | undefined;
}> = ({ encryptedContractId, requestAdditionalHelp }) => {
    const { t } = useTranslation('request-additional-help');
    const [currentView, setCurrentView] = useState<Views>(Views.situation);
    const [situation, setSituation] = useState<Situation>(defaultSituation as Situation);
    const [incomeAndExpenditure, setIncomeAndExpenditure] = useState<IncomeAndExpenditure>(defaultIncomeAndExpenditure);
    const scrollToRef = useScrollTo(currentView, Views.situation);

    if (!requestAdditionalHelp) {
        return null;
    }

    const {
        emailAddress,
        contractDescription,
        contractNumber,
        registrationNumber,
        productCategory,
        changeBankAccountInProgress,
        changePaymentDateInProgress,
        sortCode,
        accountNumber,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
        totalArrears,
    } = requestAdditionalHelp;

    const requestDescription = t('notifications.emailAddress.requestDescription');

    const emailAddressNotificationMode = getEmailAddressNotificationMode(emailAddress);
    if (emailAddressNotificationMode !== EmailAddressNotificationMode.CheckEmailAddress) {
        return <EmailAddressNotification mode={emailAddressNotificationMode} requestDescription={requestDescription} />;
    }

    const defaultRegistrationNumber = t('unknown');
    const defaultSortCode = '**-**-**';
    const defaultAccountNumber = '********';

    if (!contractDescription.registrationNumber) {
        contractDescription.registrationNumber = defaultRegistrationNumber;
    }

    const onSituationContinue = (nextView: Exclude<Views, Views.situation>, situation: Situation): void => {
        setCurrentView(nextView);
        setSituation(situation);
    };

    const onBreathingSpaceBack = (): void => {
        setCurrentView(Views.situation);
    };

    const onIncomeAndExpenditureBack = (incomeAndExpenditure: IncomeAndExpenditure): void => {
        setCurrentView(Views.situation);
        setIncomeAndExpenditure(incomeAndExpenditure);
    };

    const onIncomeAndExpenditureContinue = (incomeAndExpenditure: IncomeAndExpenditure): void => {
        setCurrentView(Views.arrangementToPay);
        setIncomeAndExpenditure(incomeAndExpenditure);
    };

    const onAgreementToPayBack = (previousView: Extract<Views, Views.situation | Views.incomeAndExpenditure>): void => {
        setCurrentView(previousView);
    };

    const onPromiseToPayBack = (): void => {
        setCurrentView(Views.situation);
    };

    const onEndAgreementBack = (): void => {
        setCurrentView(Views.situation);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {currentView === Views.situation && (
                <EmailAddressNotification
                    className={'u-mb'}
                    mode={emailAddressNotificationMode}
                    requestDescription={requestDescription}
                    emailAddress={emailAddress}
                />
            )}
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.situation && (
                    <SituationView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        situation={situation}
                        onContinue={onSituationContinue}
                    />
                )}
                {currentView === Views.breathingSpace && (
                    <BreathingSpaceView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        registrationNumber={registrationNumber || defaultRegistrationNumber}
                        totalArrears={totalArrears}
                        situation={situation}
                        onBack={onBreathingSpaceBack}
                    />
                )}
                {currentView === Views.incomeAndExpenditure && (
                    <IncomeAndExpenditureView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        incomeAndExpenditure={incomeAndExpenditure}
                        onBack={onIncomeAndExpenditureBack}
                        onContinue={onIncomeAndExpenditureContinue}
                    />
                )}
                {currentView === Views.arrangementToPay && (
                    <ArrangementToPayView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        contractNumber={contractNumber}
                        registrationNumber={registrationNumber || defaultRegistrationNumber}
                        changeBankAccountInProgress={changeBankAccountInProgress}
                        changePaymentDateInProgress={changePaymentDateInProgress}
                        sortCode={sortCode || defaultSortCode}
                        accountNumber={accountNumber || defaultAccountNumber}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                        totalArrears={totalArrears}
                        situation={situation}
                        incomeAndExpenditure={payFullArrearsWithinNMonths(situation) ? undefined : incomeAndExpenditure}
                        onBack={onAgreementToPayBack}
                    />
                )}
                {currentView === Views.promiseToPay && (
                    <PromiseToPayView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        contractNumber={contractNumber}
                        registrationNumber={registrationNumber || defaultRegistrationNumber}
                        changeBankAccountInProgress={changeBankAccountInProgress}
                        changePaymentDateInProgress={changePaymentDateInProgress}
                        sortCode={sortCode || defaultSortCode}
                        accountNumber={accountNumber || defaultAccountNumber}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                        totalArrears={totalArrears}
                        situation={situation}
                        onBack={onPromiseToPayBack}
                    />
                )}
                {currentView === Views.endAgreement && (
                    <EndAgreementView
                        contractDescription={contractDescription}
                        productCategory={productCategory}
                        totalArrears={totalArrears}
                        onBack={onEndAgreementBack}
                    />
                )}
            </section>
        </Suspense>
    );
};

const RequestAdditionalHelpWithHandlers = withLoadingAndNoConnectionHandler(RequestAdditionalHelpUi);

export const RequestAdditionalHelp: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: requestAdditionalHelp, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchRequestAdditionalHelp,
        selectRequestAdditionalHelpForSpecific,
        encryptedContractId,
        true,
    );

    return (
        <RequestAdditionalHelpWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            requestAdditionalHelp={requestAdditionalHelp}
        />
    );
};
