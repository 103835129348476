import React from 'react';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import { Nbsp } from 'components/nbsp/Nbsp';

const pseudoParagraphWithMargin = <span className={'u-block u-mb-small'} />;
const pseudoParagraph = <span className={'u-block'} />;
const olBronsonCompact = <ol className={'c-ol c-ol--compact'} />;
const ulBronsonCompact = <ul className={'c-ul c-ul--compact'} />;
const olBronson = <ol className={'c-ol'} />;
const ulBronson = <ul className={'c-ul'} />;
const small = <small />;
const li = <li />;
const em = <em />;
const u = <u />;
const nbsp = <Nbsp />;

export const textWithComponents = (
    t: TFunction | undefined,
    keyOrDefaults: string,
    components?: Record<string, React.ReactNode>,
    tOptions?: object,
): React.ReactNode => {
    let key: string | undefined;
    let defaults: string | undefined;
    if (!!t) {
        key = keyOrDefaults;
        defaults = undefined;
    } else {
        key = undefined;
        defaults = keyOrDefaults;
    }

    return (
        <Trans
            t={t}
            i18nKey={key}
            defaults={defaults}
            tOptions={tOptions}
            components={{
                pseudoParagraphWithMargin,
                pseudoParagraph,
                olBronsonCompact,
                ulBronsonCompact,
                olBronson,
                ulBronson,
                small,
                li,
                em,
                u,
                nbsp,
                ...components,
            }}
        />
    );
};
