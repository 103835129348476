import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-8/frontend-ui';
import { AuthenticatedRoute, AuthorizationProvider, AuthorizedRoute } from 'auth';
import { useAxiosWithAuthentication, useAxiosWithCurrentLanguage } from 'cp-xhr';
import {
    brandCarInsurancePagePath,
    cookiePolicyPagePath,
    dashboardPagePath,
    documentCentrePagePath,
    earlySettlementPagePath,
    earlyTerminationPagePath,
    endOfContractPagePath,
    startMatchmakerPagePath,
    faqPagePath,
    financialSupportAndWellbeingPagePath,
    financialSupportAndWellbeingContentPagePath,
    financialSupportAndWellbeingTopicPagePath,
    landingPagePath,
    legacyCustomerPortalPath,
    legalNoticePagePath,
    loginErrorPagePath,
    useOurMatchmakerPagePath,
    modernSlaveryStatementPagePath,
    myProfilePagePath,
    notFoundPagePath,
    outstandingPaymentsPagePath,
    privacyPolicyPagePath,
    registerPagePath,
    requestAdditionalHelpPagePath,
    requestAdditionalHelpV2PagePath,
    requestContactPagePath,
    sessionTimeoutPagePath,
    thirdPartyLicencesPagePath,
} from 'components/navigation/paths';
import { MasterPage } from 'pages/MasterPage';
import { LandingPage } from 'pages/landing/LandingPage';
import { UseOurMatchmakerPage } from './pages/use-our-matchmaker/UseOurMatchmakerPage';
import { FrequentlyAskedQuestionsPage } from 'pages/frequently-asked-questions/FrequentlyAskedQuestionsPage';
import { LegalNoticePage } from 'pages/legal-notice/LegalNoticePage';
import { PrivacyPolicyPage } from 'pages/privacy-policy/PrivacyPolicyPage';
import { CookiePolicyPage } from 'pages/cookie-policy/CookiePolicyPage';
import { ModernSlaveryStatementPage } from 'pages/modern-slavery-statement/ModernSlaveryStatementPage';
import { ThirdPartyLicencesPage } from 'pages/third-party-licences/ThirdPartyLicencesPage';
import { RegisterPage } from 'pages/register/RegisterPage';
import { DashboardPage } from 'pages/dashboard/DashboardPage';
import { MyProfilePage } from 'pages/my-profile/MyProfilePage';
import { NotFoundPage } from 'pages/not-found/NotFoundPage';
import { LoginErrorPage } from 'pages/login-error/LoginErrorPage';
import { SessionTimeoutPage } from 'pages/session-timeout/SessionTimeoutPage';
import { RequestContactPage } from 'pages/request-contact/RequestContactPage';
import { history } from 'store/rootReducer';
import { OutstandingPaymentsPage } from './pages/outstanding-payments/OutstandingPaymentsPage';
import { RequestAdditionalHelpPage } from './pages/request-additional-help/RequestAdditionalHelpPage';
import { RequestAdditionalHelpPage as RequestAdditionalHelpV2Page } from './pages/request-additional-help-v2/RequestAdditionalHelpPage';
import { EarlySettlementPage } from 'pages/early-settlement/EarlySettlementPage';
import { EarlyTerminationPage } from 'pages/early-termination/EarlyTerminationPage';
import { EndOfContractPage } from 'pages/end-of-contract/EndOfContractPage';
import { StartMatchmakerPage } from 'pages/start-matchmaker/StartMatchmakerPage';
import { DocumentCentrePage } from './pages/document-centre/DocumentCentrePage';
import { FinancialSupportAndWellbeingPage } from './pages/financial-support-and-wellbeing/FinancialSupportAndWellbeingPage';
import { FinancialSupportAndWellbeingTopicPage } from './pages/financial-support-and-wellbeing-topic/FinancialSupportAndWellbeingTopicPage';
import { FinancialSupportAndWellbeingContentPage } from './pages/financial-support-and-wellbeing-content/FinancialSupportAndWellbeingContentPage';
import { BrandCarInsurancePage } from './pages/brand-car-insurance/BrandCarInsurancePage';

const AxiosConfiguration: React.FC = ({ children }) => {
    useAxiosWithCurrentLanguage();
    useAxiosWithAuthentication();
    return <>{children}</>;
};

const idleTimeInMinutes = process.env.NODE_ENV === 'development' ? 60 : 5;
const sessionTimeoutPageUri: string = window.location.origin + sessionTimeoutPagePath();

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner fullPage />}>
                <AuthenticationProvider loadingComponent={<Spinner center />}>
                    <AuthorizationProvider>
                        <AutoLogout idleTimeInMinutes={idleTimeInMinutes} redirectUri={sessionTimeoutPageUri}>
                            <AxiosConfiguration>
                                <ConnectedRouter history={history}>
                                    <LastLocationProvider>
                                        <MasterPage>
                                            <Switch>
                                                <AuthorizedRoute exact path={dashboardPagePath()}>
                                                    <DashboardPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={outstandingPaymentsPagePath()}>
                                                    <OutstandingPaymentsPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={requestAdditionalHelpPagePath()}>
                                                    <RequestAdditionalHelpPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={requestAdditionalHelpV2PagePath()}>
                                                    <RequestAdditionalHelpV2Page />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={earlySettlementPagePath()}>
                                                    <EarlySettlementPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={earlyTerminationPagePath()}>
                                                    <EarlyTerminationPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={endOfContractPagePath()}>
                                                    <EndOfContractPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={brandCarInsurancePagePath()}>
                                                    <BrandCarInsurancePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={startMatchmakerPagePath()}>
                                                    <StartMatchmakerPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={myProfilePagePath()}>
                                                    <MyProfilePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={documentCentrePagePath()}>
                                                    <DocumentCentrePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={financialSupportAndWellbeingPagePath()}>
                                                    <FinancialSupportAndWellbeingPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={financialSupportAndWellbeingTopicPagePath()}>
                                                    <FinancialSupportAndWellbeingTopicPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={financialSupportAndWellbeingContentPagePath()}>
                                                    <FinancialSupportAndWellbeingContentPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={requestContactPagePath()}>
                                                    <RequestContactPage />
                                                </AuthorizedRoute>
                                                <AuthenticatedRoute path={registerPagePath()}>
                                                    <RegisterPage />
                                                </AuthenticatedRoute>
                                                <Route exact path={landingPagePath()}>
                                                    <LandingPage />
                                                </Route>
                                                <Route path={useOurMatchmakerPagePath()}>
                                                    <UseOurMatchmakerPage />
                                                </Route>
                                                <Route path={faqPagePath()}>
                                                    <FrequentlyAskedQuestionsPage />
                                                </Route>
                                                <Route path={legalNoticePagePath()}>
                                                    <LegalNoticePage />
                                                </Route>
                                                <Route path={privacyPolicyPagePath()}>
                                                    <PrivacyPolicyPage />
                                                </Route>
                                                <Route path={cookiePolicyPagePath()}>
                                                    <CookiePolicyPage />
                                                </Route>
                                                <Route path={modernSlaveryStatementPagePath()}>
                                                    <ModernSlaveryStatementPage />
                                                </Route>
                                                <Route path={thirdPartyLicencesPagePath()}>
                                                    <ThirdPartyLicencesPage />
                                                </Route>
                                                <Route path={notFoundPagePath()}>
                                                    <NotFoundPage />
                                                </Route>
                                                <Route path={loginErrorPagePath()}>
                                                    <LoginErrorPage />
                                                </Route>
                                                <Route path={sessionTimeoutPagePath()}>
                                                    <SessionTimeoutPage />
                                                </Route>
                                                <Redirect path={legacyCustomerPortalPath()} to={landingPagePath()} />
                                                <Route path="*">
                                                    <Redirect to={notFoundPagePath()} />
                                                </Route>
                                            </Switch>
                                        </MasterPage>
                                        <ScrollToTopOnNavigation exceptions={[]} />
                                    </LastLocationProvider>
                                </ConnectedRouter>
                            </AxiosConfiguration>
                        </AutoLogout>
                    </AuthorizationProvider>
                </AuthenticationProvider>
            </Suspense>
        </div>
    );
};

export default App;
