import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { OptionsAvailableFormValues } from './types';

export const validationSchema = (t: TFunction): Yup.ObjectSchema<OptionsAvailableFormValues> => {
    const optionValidValues = ['payInOneGo', 'payInInstalments', 'breathingSpace', 'otherOptions'];

    return Yup.object()
        .shape<OptionsAvailableFormValues>({
            option: Yup.string()
                .default('')
                .required(t('option.validation.required'))
                .oneOf(optionValidValues, t('option.validation.required')),
            consent: Yup.bool()
                .required(t('consent.validation.required'))
                .oneOf([true], t('consent.validation.mustConsent')),
        })
        .required();
};
