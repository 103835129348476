import React from 'react';
import { useTranslation } from 'react-i18next';
import { FixedCostMaintenanceSectionBO } from '@cp-uk/common';
import { valueOrErrorTag } from 'components/helpers';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';

export const FixedCostMaintenanceSection: React.FC<{
    fixedCostMaintenanceSection: FixedCostMaintenanceSectionBO | undefined;
}> = ({ fixedCostMaintenanceSection }) => {
    const { t } = useTranslation('fixed-cost-maintenance-section');

    if (fixedCostMaintenanceSection === undefined) {
        return null;
    }

    const { serviceLevel } = fixedCostMaintenanceSection;

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('serviceLevel.label'),
            value: valueOrErrorTag(
                t,
                serviceLevel !== undefined ? t(`serviceLevel.values.${serviceLevel}`) : undefined,
            ),
            testId: 'serviceLevel',
        },
    ];

    return (
        <DataOverview noBorder title={t('title')} testId={'fixedCostMaintenanceSection'}>
            <DescriptionListHorizontal items={descriptionListItems} />
        </DataOverview>
    );
};
