import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ChangeMileageFormValues } from './types';
import { isInputNumberInRange, isInputNumberInteger } from 'utils';

export const validationSchema = (
    t: TFunction,
    minValidCurrentMileage: number,
    maxValidCurrentMileage: number,
): Yup.ObjectSchema<ChangeMileageFormValues> => {
    return Yup.object()
        .shape<ChangeMileageFormValues>({
            currentMileage: Yup.string()
                .required(t('currentMileage.validation.required'))
                .test(
                    'integer',
                    t('currentMileage.validation.integer', { minValidCurrentMileage, maxValidCurrentMileage }),
                    (value) => isInputNumberInteger(value),
                )
                .test(
                    'range',
                    t('currentMileage.validation.range', { minValidCurrentMileage, maxValidCurrentMileage }),
                    (value) => isInputNumberInRange(value, minValidCurrentMileage, maxValidCurrentMileage),
                ),
        })
        .required();
};
