import React, { Dispatch, SetStateAction } from 'react';
import { AddressSectionBO, ContactDetailsSectionBO, MarketingPreferencesSectionBO } from '@cp-uk/common';

export interface MyProfileContextValues {
    newAddressSection: AddressSectionBO | undefined;
    setNewAddressSection: Dispatch<SetStateAction<AddressSectionBO | undefined>>;
    newContactDetailsSection: ContactDetailsSectionBO | undefined;
    setNewContactDetailsSection: Dispatch<SetStateAction<ContactDetailsSectionBO | undefined>>;
    newMarketingPreferencesSection: MarketingPreferencesSectionBO | undefined;
    setNewMarketingPreferencesSection: Dispatch<SetStateAction<MarketingPreferencesSectionBO | undefined>>;
}

const defaultDispatch = <S>(): Dispatch<SetStateAction<S>> => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (_state): void => {};
};

export const MyProfileContext = React.createContext<MyProfileContextValues>({
    newAddressSection: undefined,
    setNewAddressSection: defaultDispatch<AddressSectionBO | undefined>(),
    newContactDetailsSection: undefined,
    setNewContactDetailsSection: defaultDispatch<ContactDetailsSectionBO | undefined>(),
    newMarketingPreferencesSection: undefined,
    setNewMarketingPreferencesSection: defaultDispatch<MarketingPreferencesSectionBO | undefined>(),
});
