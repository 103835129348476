import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { postCodeRegex } from './ChangeAddressValidation';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        postCodeToFind: Yup.string()
            .required(t('postCode.validation.required'))
            .matches(postCodeRegex, t('postCode.validation.format')),
        selectAddress: Yup.string()
            .optional(),
    });
};
