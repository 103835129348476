import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { InsuranceBrands } from '@cp-uk/common';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements, useInsuranceFinanceCompanyDetails } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { Insurance } from 'components/insurance/Insurance';
import { notFoundPagePath } from 'components/navigation/paths';

export const BrandCarInsurancePage: React.FC = () => {
    const { t } = useTranslation('page-titles');

    const { encryptedContractId, insuranceBrand } = useParams<{
        encryptedContractId: string;
        insuranceBrand: InsuranceBrands;
    }>();

    const shortCompanyName = useInsuranceFinanceCompanyDetails(insuranceBrand);

    useHeadTitleMetaElements(HeadTitleMetaElementsPages.BrandCarInsurance, shortCompanyName);

    if (!shortCompanyName) {
        return <Redirect to={notFoundPagePath()} />;
    }

    return (
        <ContentSection
            className={'o-content-section--compact uk-brand-car-insurance-page'}
            pageWrapSize={'small'}
        >
            <PageHeader title={t('brandCarInsurance', { shortCompanyName })} />
            <Insurance encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
