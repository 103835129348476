import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-8/frontend-ui';
import { currentBrand, currentBuild } from 'config';
import { authPaths, registerPagePath } from 'components/navigation/paths';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { IconFooter } from 'components/icon-footer';
import { LegalFooter } from 'components/legal-footer';
import { BannerNotification } from 'components/notifications/banner-notification/BannerNotification';

export const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();

    return (
        <AnalyticsProvider
            userGroup={isAuthenticated ? 'private' : undefined}
            isAuthenticated={isAuthenticated}
            brand={currentBrand}
            market={'UK'}
            version={currentBuild.version}
            releaseDate={currentBuild.dateTime.substring(0, 10)}
            language={'en'}
            registrationPath={registerPagePath()}
            loginPath={authPaths.loginCallback()}
        >
            <BannerNotification />
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('backToTop')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});
MasterPageComponent.displayName = 'MasterPageComponent';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();

    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};
