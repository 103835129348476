import { SimplePageHeroTeaserDto, getRegisterPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'registerPageHeroTeaser',
    contentEndpoint: getRegisterPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchRegisterPageHeroTeaser = fetchContent;
