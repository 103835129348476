// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/validated-input/ValidatedInput.tsx
// (there weren't enough/working properties on the original)

import React, { FocusEventHandler, FormEventHandler, Fragment, MouseEventHandler } from 'react';
import { useField } from 'formik';
import { Input, FormField } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { hasError, hasSuccess, ValidationErrorMessage } from 'components/errors';
import { HelpText } from 'components/help-text/HelpText';
import { withCleave } from './withCleave';

export type ValidatedInputProps = {
    name: string;
    addonText?: string | React.ReactNode;
    reversed?: boolean;
    maxLength?: number;
    label: string | React.ReactNode;
    helpText?: string | React.ReactNode;
    onLinkClick?: MouseEventHandler<HTMLInputElement>;
    type?: 'tel' | 'email' | 'password' | 'search' | 'text' | 'url'; // some theoretically possible HTML types have been remove from list, as they change the layout to a non brand compliant layout
    inputMode?: 'numeric' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'decimal' | 'none';
    testId?: string;
    tooltip?: string;
    id?: string;
    placeholder?: string;
    handleChange?: FormEventHandler<HTMLInputElement>;
    stateIcon?: boolean;
    disabled?: boolean;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const ValidatedInput: React.FC<ValidatedInputProps & React.HTMLAttributes<HTMLInputElement>> = ({
    children,
    name,
    reversed,
    addonText,
    label,
    helpText,
    onLinkClick,
    maxLength,
    type,
    className,
    testId,
    id,
    tooltip,
    inputMode,
    placeholder,
    handleChange,
    stateIcon,
    disabled,
    isMandatory = false,
    withHtmlValidationWarning = false,
    onFocus,
}) => {
    const [field, meta] = useField({ name, type: 'input' });

    const labelText = disabled ? <span className={'u-text-disabled'}>{label}</span> : label;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <FormField
            type="input"
            onClick={onLinkClick}
            labelText={labelText as string | undefined}
            testId={`${testId}FormField`}
            id={id}
            errorMessage={errorMessageElseHelpText}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            <Input
                disabled={disabled}
                reversed={reversed}
                error={!disabled && hasError(meta)}
                success={!disabled && hasSuccess(meta)}
                addonText={addonText}
                maxLength={maxLength}
                type={type}
                className={className}
                testId={testId}
                inputMode={inputMode}
                placeholder={placeholder}
                stateIcon={stateIcon}
                onFocus={onFocus}
                {...field}
            />
            {children}
        </FormField>
    );
};

export const ValidatedInputWithCleave = withCleave(ValidatedInput);
