import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isInputRegistrationNumberValid } from 'utils';
import { DataEntryFormValues } from './types';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape<DataEntryFormValues>({
        registrationNumber: Yup.string()
            .required(t('registrationNumber.validation.required'))
            .test('format', t('registrationNumber.validation.format'), (registrationNumber) =>
                isInputRegistrationNumberValid(registrationNumber),
            ),
    });
};
