import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { Greeting } from 'components/greeting/Greeting';
import { ContractsOverview } from 'components/contracts-overview/ContractsOverview';
import { DashboardMarketingCard } from 'components/dashboard-marketing-card/DashboardMarketingCard';
import { OtherFinanceBrandsCard } from 'components/other-finance-brands-card/OtherFinanceBrandsCard';

export const DashboardPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.Dashboard);
    const { t } = useTranslation('page-titles');

    return (
        <ContentSection className={'o-content-section--compact uk-dashboard-page'} pageWrapSize={'medium'}>
            <PageHeader title={t('dashboard')} />
            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <ContractsOverview />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <OtherFinanceBrandsCard />
                    <DashboardMarketingCard />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
