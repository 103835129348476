import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, Paragraph, UnorderedList } from '@vwfs-bronson/bronson-react';
import { ContractDescriptionBO, FinanceBrandsEOC, ProductTypesEOC } from '@cp-uk/common';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { dashboardPagePath } from 'components/navigation/paths';
import { carOrVehicleText, retailerDescriptionText } from 'components/helpers';
import {
    ExternalWebsitesNotification,
    ExternalWebsitesNotificationMode
} from 'components/notifications/external-websites/ExternalWebsitesNotification';
import { buildLinkToPhoneNumber, contractCategory, textWithComponents, useFinanceCompanyDetails } from 'utils';

export type SolutionsHandBackViewProps = {
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    onBack: () => void;
};

export const SolutionsHandBackView: React.FC<SolutionsHandBackViewProps> = ({
    contractDescription,
    financeBrand,
    vehicleBrand,
    onBack,
}) => {
    const productType: ProductTypesEOC = 'solutions';

    const { shortCompanyName, phoneNumber, openingHours } = useFinanceCompanyDetails(
        productType,
        financeBrand,
    );
    const { t } = useTranslation('end-of-contract-solutions-hand-back-view');
    const history = useHistory();

    const { onAction: onPersonalisedPlatesAction } = useAnalyticsActionTracker(
        'onEndOfContractPcpHandBackPersonalisedPlates',
    );
    const { onAction: onBvrlaGuidelinesAction } = useAnalyticsActionTracker(
        'onEndOfContractPcpHandBackBvrlaGuidelines',
    );
    const { onAction: onEndOfContractChargesAction } = useAnalyticsActionTracker(
        'onEndOfContractPcpHandBackEndOfContractCharges',
    );

    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractPcpHandBackBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractPcpHandBackDashboard');
    useAnalyticsPageViewTracker('endOfContractPcpHandBack');

    // TODO: Analytics...
    // const { onAction: onDvlaWebsiteAction } = useAnalyticsActionTracker('onEndOfContractPcpHandBackDvlaWebsite');
    // const { onAction: onCompleteProcessGuideAction } = useAnalyticsActionTracker('onEndOfContractPcpHandBackCompleteProcessGuide');
    const onDvlaWebsiteAction = (dvlaWebsiteUrl: string): void => {
        console.log(`[SolutionsHandBackView] onDvlaWebsiteAction(${dvlaWebsiteUrl})...`);
    };
    const onCompleteProcessGuideAction = (processGuideUrl: string): void => {
        console.log(`[SolutionsHandBackView] onCompleteProcessGuideAction(${processGuideUrl})...`);
    };

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);

    const personalisedPlatesUrl = t('links.preparingForInspection.personalisedPlates.url');
    const dvlaWebsiteUrl = t('paragraphs.updateLogbook.dvlaWebsiteUrl');
    const v5ProcessGuideForBrandUrl = t('paragraphs.updateLogbook.v5ProcessGuideForBrandUrl', { financeBrand });
    const bvrlaFairWearAndTearGuidelinesUrl = t('paragraphs.inspectionProcess.bvrlaFairWearAndTearGuidelinesUrl');
    const endOfContractChargesGuideForBrandUrl = t('paragraphs.damageOrMissing.endOfContractChargesGuideForBrandUrl', {
        financeBrand,
    });

    const onPreparingForInspectionPersonalisedPlatesClick = (): void => {
        onPersonalisedPlatesAction(personalisedPlatesUrl);
    };
    const onUpdateLogbookCompleteProcessDvlaWebsiteClick = (): void => {
        onDvlaWebsiteAction(dvlaWebsiteUrl);
    };
    const onUpdateLogbookCompleteProcessGuideClick = (): void => {
        onCompleteProcessGuideAction(v5ProcessGuideForBrandUrl);
    };
    const onInspectionProcessBvrlaGuidelinesClick = (): void => {
        onBvrlaGuidelinesAction(bvrlaFairWearAndTearGuidelinesUrl);
    };
    const onDamageOrMissingEndOfContractChargesClick = (): void => {
        onEndOfContractChargesAction(endOfContractChargesGuideForBrandUrl);
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    const linkToDvlaWebsite = (
        <SimpleLink
            href={dvlaWebsiteUrl}
            target={'_BLANK'}
            onClick={onUpdateLogbookCompleteProcessDvlaWebsiteClick}
            testId={'updateLogbookCompleteProcessDvlaWebsiteLink'}
        />
    );
    const linkToV5ProcessGuide = (
        <SimpleLink
            href={v5ProcessGuideForBrandUrl}
            target={'_BLANK'}
            onClick={onUpdateLogbookCompleteProcessGuideClick}
            testId={'updateLogbookCompleteProcessGuideLink'}
            download
        />
    );
    const linkToBvrlaFairWearAndTearGuidelines = (
        <SimpleLink
            href={bvrlaFairWearAndTearGuidelinesUrl}
            target={'_BLANK'}
            onClick={onInspectionProcessBvrlaGuidelinesClick}
            testId={'inspectionProcessBvrlaGuidelinesLink'}
        />
    );
    const linkToEndOfContractChargesGuide = (
        <SimpleLink
            href={endOfContractChargesGuideForBrandUrl}
            target={'_BLANK'}
            onClick={onDamageOrMissingEndOfContractChargesClick}
            testId={'damageOrMissingEndOfContractChargesLink'}
            download
        />
    );

    return (
        <View testId={'solutionsHandBackView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />

            <Paragraph testId={'handBackTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.handBack.title', { vehicleBrand })}
            </Paragraph>
            <Paragraph testId={'handBackNoLongerWishToKeepParagraph'}>
                {t('paragraphs.handBack.noLongerWishToKeep', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'handBackStillNeedToPayParagraph'}>{t('paragraphs.handBack.stillNeedToPay')}</Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'handBackStillNeedToPayList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.handBack.stillNeedToPay.1')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.handBack.stillNeedToPay.2')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.handBack.stillNeedToPay.3')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>
                        {t('listItems.handBack.stillNeedToPay.4')}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'handBackOptionalFinalPaymentParagraph'}>
                {t('paragraphs.handBack.optionalFinalPayment', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'handBackContactParagraph'}>
                {textWithComponents(
                    t,
                    'paragraphs.handBack.contact',
                    { linkToPhoneNumber },
                    { phoneNumber, openingHours, carOrVehicle },
                )}
            </Paragraph>

            <Paragraph testId={'updateLogbookTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.updateLogbook.title')}
            </Paragraph>
            <Paragraph testId={'updateLogbookUpdateRegisteredKeeperParagraph'}>
                {t('paragraphs.updateLogbook.updateRegisteredKeeper')}
            </Paragraph>
            <Paragraph testId={'updateLogbookCompleteProcessParagraph'}>
                {textWithComponents(t, 'paragraphs.updateLogbook.completeProcess', {
                    linkToDvlaWebsite,
                    linkToV5ProcessGuide,
                })}
            </Paragraph>
            <Paragraph testId={'updateLogbookCompleteAsSoonAsPossibleParagraph'}>
                {t('paragraphs.updateLogbook.completeAsSoonAsPossible')}
            </Paragraph>

            <Paragraph testId={'inspectionAndCollectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.inspectionAndCollection.title')}
            </Paragraph>
            <Paragraph testId={'inspectionAndCollectionHandBackParagraph'}>
                {t('paragraphs.inspectionAndCollection.handBack', { carOrVehicle })}
            </Paragraph>

            <Paragraph testId={'preparingForInspectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.preparingForInspection.title')}
            </Paragraph>
            <Paragraph testId={'inspectionAndCollectionDayOfInspectionParagraph'}>
                {t('paragraphs.preparingForInspection.dayOfInspection')}
            </Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'inspectionAndCollectionDayOfInspectionList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.preparingForInspection.dayOfInspection.1', { carOrVehicle })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.preparingForInspection.dayOfInspection.2')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.preparingForInspection.dayOfInspection.3')}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>
                        {t('listItems.preparingForInspection.dayOfInspection.4', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'inspectionAndCollectionHelpfulToKnowParagraph'}>
                {t('paragraphs.preparingForInspection.helpfulToKnow')}
            </Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'inspectionAndCollectionHelpfulToKnowList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.1', {
                            carOrVehicle,
                            shortCompanyName,
                            retailerDescription,
                        })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item2'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.2', { carOrVehicle })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item3'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.3', { carOrVehicle })}
                    </UnorderedList.Item>
                    <UnorderedList.Item data-testid={'item4'}>
                        {t('listItems.preparingForInspection.helpfulToKnow.4', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Paragraph testId={'inspectionAndCollectionPersonalisedPlatesParagraph'}>
                {t('paragraphs.preparingForInspection.personalisedPlates')}
            </Paragraph>
            <div className={'c-ul c-ul--compact'}>
                <UnorderedList testId={'inspectionAndCollectionPersonalisedPlatesList'}>
                    <UnorderedList.Item data-testid={'item1'}>
                        {t('listItems.preparingForInspection.personalisedPlates.1', { carOrVehicle })}
                    </UnorderedList.Item>
                </UnorderedList>
            </div>
            <Button
                element={'a'}
                className={'u-mb-small u-ml'}
                testId={'preparingForInspectionPersonalisedPlatesLink'}
                href={personalisedPlatesUrl}
                target={'_BLANK'}
                onClick={onPreparingForInspectionPersonalisedPlatesClick}
                icon={'semantic-arrow-right'}
                link
                simple
            >
                {t('links.preparingForInspection.personalisedPlates.label')}
            </Button>

            <Paragraph testId={'inspectionProcessTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.inspectionProcess.title', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'inspectionProcessMissingEquipmentDocumentationParagraph'}>
                {textWithComponents(t, 'paragraphs.inspectionProcess.missingEquipmentDocumentation', {
                    linkToBvrlaFairWearAndTearGuidelines,
                })}
            </Paragraph>
            <Paragraph testId={'inspectionProcessGuidelinesParagraph'}>
                {t('paragraphs.inspectionProcess.guidelines')}
            </Paragraph>
            <Paragraph testId={'inspectionProcessDamagesAndMissingItemsParagraph'}>
                {t('paragraphs.inspectionProcess.damagesAndMissingItems')}
            </Paragraph>

            <Paragraph testId={'damageOrMissingTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.damageOrMissing.title', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'damageOrMissingDamageOutsideBvrlaParagraph'}>
                {textWithComponents(
                    t,
                    'paragraphs.damageOrMissing.damageOutsideBvrla',
                    { linkToEndOfContractChargesGuide },
                    { carOrVehicle },
                )}
            </Paragraph>
            <Paragraph testId={'damageOrMissingAverageCustomersPayParagraph'}>
                {t('paragraphs.damageOrMissing.averageCustomersPay', { carOrVehicle })}
            </Paragraph>
            <ButtonContainer nav className={'u-mb-paragraph'}>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
            <ExternalWebsitesNotification mode={ExternalWebsitesNotificationMode.Plural} />
        </View>
    );
};
