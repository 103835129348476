import React, { Fragment, Suspense } from 'react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { Spinner, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { DocumentCentreBO, DocumentContractBO } from '@cp-uk/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { NoContractsNotification } from 'components/notifications/no-contracts/NoContractsNotification';
import { DocumentSafetyNotification } from 'components/notifications/document-safety/DocumentSafetyNotification';
import { selectDocumentCentre } from './DocumentCentreSelector';
import { fetchDocumentCentre } from './DocumentCentreSlice';
import { DocumentContract } from './DocumentContract';
import { AlternativeNotification } from './AlternativeNotification';
import { FeatureNotAvailableNotification } from './FeatureNotAvailableNotification';

export const DocumentCentreUi: React.FC<{ documentCentre: DocumentCentreBO | undefined }> = ({ documentCentre }) => {
    useAnalyticsPageViewTracker('postbox', !!documentCentre);

    if (documentCentre === undefined) {
        return null;
    }

    const { documentCentreEnabled, hasOtherBrandContracts, contracts } = documentCentre;

    if (!documentCentreEnabled) {
        return <FeatureNotAvailableNotification testId={'contractsNotification'} />;
    }

    return (
        <Suspense fallback={<Spinner center />}>
            {contracts.length === 0 ? (
                <NoContractsNotification
                    className={'u-mb'}
                    hasOtherBrandContracts={hasOtherBrandContracts}
                    testId={'contractsNotification'}
                />
            ) : (
                <Fragment>
                    <DocumentSafetyNotification className={'u-mb'} testId={'contractsNotification'} />
                    {contracts.map((contract: DocumentContractBO, index: number) => (
                        <DocumentContract key={index} contract={contract} isFirst={index === 0} />
                    ))}
                    <AlternativeNotification testId={'alternativeNotification'} />
                </Fragment>
            )}
        </Suspense>
    );
};

const DocumentCentreWithHandlers = withLoadingAndNoConnectionHandler(DocumentCentreUi);

export const DocumentCentre: React.FC = () => {
    const { data: documentCentre, isLoading, loadingError } = useGetSimpleApiData(
        fetchDocumentCentre,
        getSimpleDataSelector(selectDocumentCentre),
    );

    return (
        <DocumentCentreWithHandlers isLoading={isLoading} hasError={!!loadingError} documentCentre={documentCentre} />
    );
};
