import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DescriptionList } from '@vwfs-bronson/bronson-react';
import { DescriptionTermAndDetail } from '../description-term-and-detail';

export interface BankDetailsForDirectDebitProps {
    className?: string;
    sortCode: string;
    accountNumber: string;
}

export const BankDetailsForDirectDebit: React.FC<BankDetailsForDirectDebitProps> = ({
    className,
    sortCode,
    accountNumber,
}) => {
    const { t } = useTranslation();

    const classNameList = classNames('c-description-list--compact', className).trim();

    return (
        <DescriptionList
            className={classNameList}
            horizontal
            shortTerms
            testId={'bankDetailsForDirectDebit'}
        >
            <DescriptionTermAndDetail
                testId={'sortCodeForDirectDebit'}
                term={t('bankDetails.sortCode')}
                detail={sortCode}
            />
            <DescriptionTermAndDetail
                testId={'accountNumberForDirectDebit'}
                term={t('bankDetails.accountNumber')}
                detail={accountNumber}
            />
        </DescriptionList>
    );
};
