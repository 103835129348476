import React from 'react';
import { RealexModalProps } from './types';
import { useTranslation } from 'react-i18next';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { textWithComponents } from 'utils';

export const PaymentGatewayErrorModal: React.FC<RealexModalProps> = ({
    shown,
    confirmationDisabled,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation('realex-payments');

    return (
        <Modal
            shown={shown}
            testId={'paymentGatewayErrorModal'}
            status={'error'}
            title={t('paymentGatewayErrorModal.title')}
            closeAny={false}
            hideCloseButton={true}
            buttonConfirmText={t('translation:editableSectionNav.close')}
            buttonConfirmType={'button'}
            confirmationDisabled={confirmationDisabled}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>{textWithComponents(t, 'paymentGatewayErrorModal.contents')}</Paragraph>
        </Modal>
    );
};
