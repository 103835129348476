import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Heading, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import {
    preventSubmit,
    TrackingId,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import {
    CallBackContexts,
    ContractDescriptionBO,
    getRequestCallBackEndpoint,
    ProductTypesEOC,
    RequestCallBack,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { ValidatedSelect } from 'components/validated-select/ValidatedSelect';
import { dashboardPagePath } from 'components/navigation/paths';
import { buildPhoneNumberSelectItems, contractCategory, textWithComponents } from 'utils';
import { ContractHireCallBackViewFormValues } from './types';
import { validationSchema } from './ContractHireCallBackValidation';
import { CallingViews, Views } from '../types';
import { getSectionForAnalytics } from './helpers';

export type ContractHireCallBackViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    vehicleBrand: string;
    registrationNumber: string;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    mobileNumber: string | undefined;
    homePhoneNumber: string | undefined;
    workPhoneNumber: string | undefined;
    callingView: CallingViews;
    onBack: (previousStep: CallingViews) => void;
};

export const ContractHireCallBackView: React.FC<ContractHireCallBackViewProps> = ({
    encryptedContractId,
    contractDescription,
    vehicleBrand,
    registrationNumber,
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    homePhoneNumber,
    workPhoneNumber,
    callingView,
    onBack,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const [trackingId, setTrackingId] = useState<TrackingId>('endOfContractRchCallBack');
    const { t } = useTranslation('end-of-contract-contract-hire-call-back-view');
    const history = useHistory();

    const sectionForAnalytics = getSectionForAnalytics(callingView);
    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractRchCallBackBack');
    const { onAction: onRequestAction } = useAnalyticsActionTracker('onEndOfContractRchCallBackRequest');
    useAnalyticsPageViewTracker(trackingId, undefined, sectionForAnalytics);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const phoneNumberSelectItems = buildPhoneNumberSelectItems(t, mobileNumber, homePhoneNumber, workPhoneNumber);

    const initialValues: ContractHireCallBackViewFormValues = {
        phoneNumber: '',
    };

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setShowErrorModal(false);
        setTrackingId('endOfContractRchCallBack');
    };

    const onBackClick = (): void => {
        onBackAction(sectionForAnalytics);
        onBack(callingView);
    };

    return (
        <View testId={'contractHireCallBackView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), vehicleBrand })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <ContactDetailsNotification
                className={'u-mb'}
                mode={contactDetailsNotificationMode}
                emailAddress={emailAddress}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={({ phoneNumber }): Promise<void> => {
                    let context: CallBackContexts;
                    switch (callingView) {
                        case Views.contractHireNew:
                            context = 'new';
                            break;
                        case Views.contractHireExtend:
                        case Views.contractHireExtension:
                            context = 'extension';
                            break;
                        case Views.contractHireReturn:
                            context = 'return';
                            break;
                        default:
                            // NOTE: Leave this in as testing is done for each of the other "calling" views:
                            // eslint-disable-next-line no-alert
                            alert('Unexpected state for onSubmit() handler!');
                            return Promise.resolve();
                    }
                    const requestCallBack: RequestCallBack = {
                        context,
                        registrationNumber,
                        firstName,
                        lastName,
                        emailAddress: emailAddress ?? '',
                        phoneNumber,
                    };
                    onRequestAction(sectionForAnalytics);
                    setIsSubmitting(true);
                    return CpDataApi.post<void>(getRequestCallBackEndpoint(encryptedContractId), requestCallBack)
                        .then(() => {
                            setShowSuccessModal(true);
                            setTrackingId('endOfContractRchCallBackSuccess');
                            setIsSubmitting(false);
                        })
                        .catch(() => {
                            setShowErrorModal(true);
                            setTrackingId('endOfContractRchCallBackFailure');
                            setIsSubmitting(false);
                        });
                }}
            >
                {(formik: FormikProps<ContractHireCallBackViewFormValues>): React.ReactNode => (
                    <Form className={'uk-end-of-contract-contract-hire-call-back-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    className={'select-phone-number'}
                                    label={t('phoneNumber.label')}
                                    placeholder={t('phoneNumber.placeholder')}
                                    selectItems={phoneNumberSelectItems}
                                    name={'phoneNumber'}
                                    testId={'phoneNumber'}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'contactUsParagraph'} className={'u-mb-none'}>
                                    {textWithComponents(t, 'paragraphs.contactUs')}
                                </Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'backButton'}
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        testId={'requestButton'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.request')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onSuccessModalConfirm}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{t('successModal.contents')}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={false}
                buttonCloseLabel={t('translation:editableSectionNav.close')}
                buttonConfirmText={t('translation:editableSectionNav.ok')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onErrorModalConfirm}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </View>
    );
};
