import React from 'react';

export type ViewProps = {
    testId?: string;
};

export const View: React.FC<ViewProps> = ({ testId, children }) => {
    return (
        <div className={'c-view'} data-testid={testId}>
            {children}
        </div>
    );
};
