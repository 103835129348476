import { SimplePageHeroTeaserDto, getThirdPartyLicencesPageHeroTeaserEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePageHeroTeaserDto>({
    contentName: 'thirdPartyLicencesPageHeroTeaser',
    contentEndpoint: getThirdPartyLicencesPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchThirdPartyLicencesPageHeroTeaser = fetchContent;
