import React, { Fragment } from 'react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import {
    SimplePageHeroTeaser,
    SimplePageHeroTeaserPage,
} from 'components/simple-page-hero-teaser/SimplePageHeroTeaser';
import {
    SimplePageContent,
    SimplePageContentPage,
} from 'components/simple-page-content/SimplePageContent';

export const LegalNoticePage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.LegalNotice);

    return (
        <Fragment>
            <SimplePageHeroTeaser page={SimplePageHeroTeaserPage.LegalNotice} />
            <SimplePageContent page={SimplePageContentPage.LegalNotice} />
        </Fragment>
    );
};
