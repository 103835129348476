export type SolutionsRefinanceViewFormValues = {
    confirmImportantThings: boolean;
    phoneNumber: string;
    refinanceMonths: string;
    depositAmount: string;
    monthlyBudget: string;
    earliestAvailableTime: string;
    latestAvailableTime: string;
    availableDays: AvailableDaysFormValues;
};

export type AvailableDaysFormValues = {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
};

export enum RefinanceMonths {
    twelve = '12M',
    twentyFour = '24M',
    thirtySix = '36M',
    fortyEight = '48M',
}

export enum AvailableTimes {
    noPreferredEarliest = 'noPreferredEarliest',
    nineAm = '9H',
    tenAm = '10H',
    elevenAm = '11H',
    twelvePm = '12H',
    onePm = '13H',
    twoPm = '14H',
    threePm = '15H',
    fourPm = '16H',
    fivePm = '17H',
    sixPm = '18H',
    noPreferredLatest = 'noPreferredLatest',
}
