import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonContainer,
    DescriptionList,
    Heading,
    Paragraph,
    ResponsiveImage,
} from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { FinanceBrands, ProductTypes } from '@cp-uk/common';
import { appendBuildTimestamp, textWithComponents, useFinanceCompanyDetails } from 'utils';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { Well } from 'components/well/Well';
import { View } from 'components/view/View';

export type ConfirmationProps = {
    onClose: () => void;
    accountHolderName: string;
    sortCode: string;
    accountNumber: string;
    effectiveDate: CPDate;
    contractNumber: string;
    productType: ProductTypes;
    financeBrand: FinanceBrands;
};

export const Confirmation: React.FC<ConfirmationProps> = ({
    onClose,
    accountHolderName,
    sortCode,
    accountNumber,
    effectiveDate,
    contractNumber,
    productType,
    financeBrand,
}) => {
    const { companyName, phoneNumber, emailAddress } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation('change-bank-account-confirmation');

    return (
        <View testId={'confirmationView'}>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.info}
                headline={t('informationNotification.headline')}
                text={t('informationNotification.text')}
                testId={'informationNotification'}
            />
            <Paragraph testId={'requestDataParagraph'}>{t('requestData.text')}</Paragraph>
            <DescriptionList className={'c-description-list--compact u-ml u-mb-small'} horizontal shortTerms>
                <DescriptionTermAndDetail
                    testId={'accountHolderName'}
                    term={t('requestData.accountHolderName')}
                    detail={accountHolderName}
                />
                <DescriptionTermAndDetail
                    testId={'sortCode'}
                    term={t('requestData.sortCode')}
                    detail={sortCode}
                />
                <DescriptionTermAndDetail
                    testId={'accountNumber'}
                    term={t('requestData.accountNumber')}
                    detail={accountNumber}
                />
            </DescriptionList>
            <Paragraph testId={'effectiveDateParagraph'}>{t('effectiveDate.text', { effectiveDate })}</Paragraph>
            <Paragraph testId={'paymentReferenceParagraph'}>{t('paymentReference.text')}</Paragraph>
            <DescriptionList className={'c-description-list--compact u-ml u-mb-small'} horizontal shortTerms>
                <DescriptionTermAndDetail
                    testId={'originator'}
                    term={t('paymentReference.originator')}
                    detail={t('paymentReference.companyName')}
                />
                <DescriptionTermAndDetail
                    testId={'reference'}
                    term={t('paymentReference.reference')}
                    detail={contractNumber}
                />
            </DescriptionList>
            <Paragraph testId={'contactDetailsParagraph'}>{t('contactDetails.text')}</Paragraph>
            <DescriptionList className={'c-description-list--compact u-ml u-mb-small'} horizontal shortTerms>
                <DescriptionTermAndDetail
                    testId={'telephone'}
                    term={t('contactDetails.telephone')}
                    detail={textWithComponents(undefined, phoneNumber)}
                />
                <DescriptionTermAndDetail
                    testId={'postalAddress'}
                    term={t('contactDetails.postalAddress')}
                    detail={
                        <Fragment>
                            {t('contactDetails.customerServices')}
                            <br />
                            {companyName}
                            <br />
                            {t('contactDetails.addressLine1')}
                            <br />
                            {t('contactDetails.addressLine2')}
                            <br />
                            {t('contactDetails.addressLine3')}
                            <br />
                            {t('contactDetails.postCode')}
                        </Fragment>
                    }
                />
                <DescriptionTermAndDetail
                    testId={'emailAddress'}
                    term={t('contactDetails.emailAddress')}
                    detail={emailAddress}
                />
            </DescriptionList>
            <Paragraph testId={'printOrDownloadParagraph'}>{t('printOrDownload.text')}</Paragraph>
            <Well className={'u-mv'} testId={'directDebitWell'}>
                <div className={'u-clearfix'}>
                    <Heading className={'u-float-left u-mb-xsmall'} level={3} testId={'heading'}>
                        {t('directDebit.header')}
                    </Heading>
                    <ResponsiveImage
                        className={'u-float-right u-mb-xsmall'}
                        alt={t('directDebit.altText')}
                        src={appendBuildTimestamp('/contents/common/direct-debit-logo.png')}
                        style={{ width: '200px' }}
                        testId={'directDebitLogo'}
                    />
                </div>
                <ul className={'c-ul c-ul--compact u-mb-none'} data-testid={'directDebitList'}>
                    <li>{t('directDebit.listItems.1')}</li>
                    <li>{t('directDebit.listItems.2')}</li>
                    <li>
                        {t('directDebit.listItems.3')}
                        <ul className={'u-mv-none'}>
                            <li>{t('directDebit.listItems.3a')}</li>
                        </ul>
                    </li>
                    <li>{t('directDebit.listItems.4')}</li>
                </ul>
            </Well>
            <ButtonContainer nav>
                <Button type={'button'} onClick={onClose} testId={'closeButton'}>
                    {t('translation:editableSectionNav.close')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
