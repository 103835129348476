import React from 'react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { HeroImage as HeroImageShared } from '@cp-shared-8/frontend-ui';
import { appendBuildTimestamp } from "utils";
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchRegisterPageHeroTeaser } from './RegisterPageHeroTeaserSlice';
import { selectRegisterPageHeroTeaser } from './RegisterPageHeroTeaserSelector';
import { fetchFrequentlyAskedQuestionsPageHeroTeaser } from './FrequentlyAskedQuestionsPageHeroTeaserSlice';
import { selectFrequentlyAskedQuestionsPageHeroTeaser } from './FrequentlyAskedQuestionsPageHeroTeaserSelector';
import { fetchLegalNoticePageHeroTeaser } from './LegalNoticePageHeroTeaserSlice';
import { selectLegalNoticePageHeroTeaser } from './LegalNoticePageHeroTeaserSelector';
import { fetchPrivacyPolicyPageHeroTeaser } from './PrivacyPolicyPageHeroTeaserSlice';
import { selectPrivacyPolicyPageHeroTeaser } from './PrivacyPolicyPageHeroTeaserSelector';
import { fetchCookiePolicyPageHeroTeaser } from './CookiePolicyPageHeroTeaserSlice';
import { selectCookiePolicyPageHeroTeaser } from './CookiePolicyPageHeroTeaserSelector';
import { fetchModernSlaveryStatementPageHeroTeaser } from './ModernSlaveryStatementPageHeroTeaserSlice';
import { selectModernSlaveryStatementPageHeroTeaser } from './ModernSlaveryStatementPageHeroTeaserSelector';
import { fetchThirdPartyLicencesPageHeroTeaser } from './ThirdPartyLicencesPageHeroTeaserSlice';
import { selectThirdPartyLicencesPageHeroTeaser } from './ThirdPartyLicencesPageHeroTeaserSelector';
import { fetchUseOurMatchmakerPageHeroTeaser } from './UseOurMatchmakerPageHeroTeaserSlice';
import { selectUseOurMatchmakerPageHeroTeaser } from './UseOurMatchmakerPageHeroTeaserSelector';

const HeroImageWithWrappers = withLoadingAndNoConnectionHandler(HeroImageShared);

export enum SimplePageHeroTeaserPage {
    FrequentlyAskedQuestions = 'frequently-asked-questions',
    LegalNotice = 'legal-notice',
    PrivacyPolicy = 'privacy-policy',
    CookiePolicy = 'cookie-policy',
    ModernSlaveryStatement = 'modern-slavery-statement',
    ThirdPartyLicences = 'third-party-licences',
    Register = 'register',
    UseOurMatchmaker = 'use-our-matchmaker',
}

export type SimplePageHeroProps = {
    page: SimplePageHeroTeaserPage;
};

export const SimplePageHeroTeaser: React.FC<SimplePageHeroProps> = ({ page }) => {
    let fetchContent, selectContent;
    switch (page) {
        case SimplePageHeroTeaserPage.FrequentlyAskedQuestions:
            fetchContent = fetchFrequentlyAskedQuestionsPageHeroTeaser;
            selectContent = selectFrequentlyAskedQuestionsPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.LegalNotice:
            fetchContent = fetchLegalNoticePageHeroTeaser;
            selectContent = selectLegalNoticePageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.PrivacyPolicy:
            fetchContent = fetchPrivacyPolicyPageHeroTeaser;
            selectContent = selectPrivacyPolicyPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.CookiePolicy:
            fetchContent = fetchCookiePolicyPageHeroTeaser;
            selectContent = selectCookiePolicyPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.ModernSlaveryStatement:
            fetchContent = fetchModernSlaveryStatementPageHeroTeaser;
            selectContent = selectModernSlaveryStatementPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.ThirdPartyLicences:
            fetchContent = fetchThirdPartyLicencesPageHeroTeaser;
            selectContent = selectThirdPartyLicencesPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.Register:
            fetchContent = fetchRegisterPageHeroTeaser;
            selectContent = selectRegisterPageHeroTeaser;
            break;

        case SimplePageHeroTeaserPage.UseOurMatchmaker:
            fetchContent = fetchUseOurMatchmakerPageHeroTeaser;
            selectContent = selectUseOurMatchmakerPageHeroTeaser;
            break;
    }

    const { cmsContent: simplePageHeroTeaser, isLoading, loadingError } = useCmsContent(fetchContent, selectContent);

    return (
        <HeroImageWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={simplePageHeroTeaser?.title || ''}
            subTitle={simplePageHeroTeaser?.subTitle || ''}
            imageUrl={appendBuildTimestamp(simplePageHeroTeaser?.imageUrl) || ''}
            inverted={!!simplePageHeroTeaser?.isInverted}
            shallow
            testId={'simplePageHeroTeaser'}
        />
    );
};
