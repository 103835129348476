import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Heading, Paragraph} from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, FinanceBrandsEOC, ProductTypesEOC } from '@cp-uk/common';
import { carOrVehicleText, retailerDescriptionText } from 'components/helpers';
import { dashboardPagePath, startMatchmakerPagePath } from 'components/navigation/paths';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    ExternalWebsitesNotification,
    ExternalWebsitesNotificationMode,
} from 'components/notifications/external-websites/ExternalWebsitesNotification';
import { View } from 'components/view/View';
import { contractCategory, useFinanceCompanyDetails } from 'utils';

export type ContractHireNewViewProps = {
    encryptedContractId: string;
    contractDescription: ContractDescriptionBO;
    financeBrand: FinanceBrandsEOC;
    vehicleBrand: string;
    showMatchmaker: boolean;
    onBack: () => void;
    onReturning: () => void;
    onCallBack: () => void;
};

export const ContractHireNewView: React.FC<ContractHireNewViewProps> = ({
    encryptedContractId,
    contractDescription,
    financeBrand,
    vehicleBrand,
    showMatchmaker,
    onBack,
    onReturning,
    onCallBack,
}) => {
    const productType: ProductTypesEOC = 'contractHire';

    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation(['end-of-contract-contract-hire-new-view', 'end-of-contract']);
    const history = useHistory();

    const { onAction: onContactRetailerAction } = useAnalyticsActionTracker('onEndOfContractRchNewContactRetailer');
    const { onAction: onFinanceOffersAction } = useAnalyticsActionTracker('onEndOfContractRchNewFinanceOffers');
    const { onAction: onNewFromStockAction } = useAnalyticsActionTracker('onEndOfContractRchNewNewFromStock');
    const { onAction: onConfiguratorAction } = useAnalyticsActionTracker('onEndOfContractRchNewConfigurator');
    const { onAction: onBackAction } = useAnalyticsActionTracker('onEndOfContractRchNewBack');
    const { onAction: onDashboardAction } = useAnalyticsActionTracker('onEndOfContractRchNewDashboard');
    const { onAction: onStartMatchmakerAction } = useAnalyticsActionTracker('onEndOfContractRchStartMatchmaker');
    useAnalyticsPageViewTracker('endOfContractRchNew');

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);
    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);
    const financeOffersUrl = t(`callToActionUrls.${financeBrand}.financeOffers`);
    const newFromStockUrl = t(`callToActionUrls.${financeBrand}.newFromStock`);
    const configuratorUrl = t(`callToActionUrls.${financeBrand}.configurator`);

    const onContactRetailerClick = (): void => {
        onContactRetailerAction();
    };
    const onCallBackClick = (): void => {
        onCallBack();
    };
    const onStartMatchmakerClick = (): void => {
        onStartMatchmakerAction();
        history.push(startMatchmakerPagePath(encryptedContractId));
    };
    const onFinanceOffersClick = (): void => {
        onFinanceOffersAction();
    };
    const onNewFromStockClick = (): void => {
        onNewFromStockAction();
    };
    const onConfiguratorClick = (): void => {
        onConfiguratorAction();
    };
    const onBackClick = (): void => {
        onBackAction();
        onBack();
    };
    const onDashboardClick = (): void => {
        onDashboardAction();
        history.push(dashboardPagePath());
    };

    const findNewCommonFragment: React.ReactNode = (
        <Fragment>
            <Paragraph testId={'findNewTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.findNew.title', { shortCompanyName })}
            </Paragraph>
            <Paragraph testId={'findNewIntroductionParagraph1'}>
                {t(`paragraphs.findNew.introduction1.${financeBrand}`, {
                    shortCompanyName,
                    carOrVehicle,
                })}
            </Paragraph>
            <Paragraph testId={'findNewIntroductionParagraph2'}>
                {t('paragraphs.findNew.introduction2', { shortCompanyName })}
            </Paragraph>
            <Paragraph>
                <Button type={'button'} testId={'requestCallBackButton'} secondary onClick={onCallBackClick}>
                    {t('buttons.requestCallBack')}
                </Button>
            </Paragraph>
        </Fragment>
    );

    return (
        <View testId={'contractHireNewView'}>
            <Heading level={2} testId={'viewHeader'}>
                {t('subHeading', { contractCategory: contractCategory(productType), shortCompanyName })}
            </Heading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            {showMatchmaker ? (
                <Fragment>
                    {findNewCommonFragment}
                    <Paragraph testId={'findNewIntroductionParagraph3'}>
                        {t('paragraphs.findNew.introduction3')}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            type={'button'}
                            testId={'startMatchmakerLink'}
                            secondary
                            onClick={onStartMatchmakerClick}
                        >
                            {t('buttons.startMatchmaker')}
                        </Button>
                    </Paragraph>
                </Fragment>
            ) : (
                <Fragment>
                    {contactUrl && (
                        <Fragment>
                            {findNewCommonFragment}
                            <Paragraph>
                                <Button
                                    element={'a'}
                                    testId={'contactRetailerLink'}
                                    secondary
                                    href={contactUrl}
                                    target={'_BLANK'}
                                    onClick={onContactRetailerClick}
                                >
                                    {t('buttons.contactRetailer', { shortCompanyName, retailerDescription })}
                                </Button>
                            </Paragraph>
                        </Fragment>
                    )}
                </Fragment>
            )}
            {financeOffersUrl && (
                <Fragment>
                    <Paragraph testId={'financeOffersTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.financeOffers.title')}
                    </Paragraph>
                    <Paragraph testId={'financeOffersIntroductionParagraph'}>
                        {t('paragraphs.financeOffers.introduction', { carOrVehicle })}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            element={'a'}
                            testId={'financeOffersLink'}
                            secondary
                            href={financeOffersUrl}
                            target={'_BLANK'}
                            onClick={onFinanceOffersClick}
                        >
                            {t('buttons.financeOffers')}
                        </Button>
                    </Paragraph>
                </Fragment>
            )}
            {newFromStockUrl && (
                <Fragment>
                    <Paragraph testId={'newFromStockTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.newFromStock.title', { shortCompanyName })}
                    </Paragraph>
                    <Paragraph testId={'newFromStockIntroductionParagraph'}>
                        {t('paragraphs.newFromStock.introduction', { shortCompanyName, retailerDescription })}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            element={'a'}
                            testId={'newFromStockLink'}
                            secondary
                            href={newFromStockUrl}
                            target={'_BLANK'}
                            onClick={onNewFromStockClick}
                        >
                            {t('buttons.newFromStock', { shortCompanyName })}
                        </Button>
                    </Paragraph>
                </Fragment>
            )}
            {configuratorUrl && (
                <Fragment>
                    <Paragraph testId={'configuratorTitleParagraph'} className={'u-text-bold'}>
                        {t('paragraphs.configurator.title')}
                    </Paragraph>
                    <Paragraph testId={'configuratorIntroductionParagraph'}>
                        {t('paragraphs.configurator.introduction', { shortCompanyName })}
                    </Paragraph>
                    <Paragraph>
                        <Button
                            element={'a'}
                            testId={'configuratorLink'}
                            secondary
                            href={configuratorUrl}
                            target={'_BLANK'}
                            onClick={onConfiguratorClick}
                        >
                            {t('buttons.configurator', { shortCompanyName })}
                        </Button>
                    </Paragraph>
                </Fragment>
            )}
            <Paragraph testId={'inspectionTitleParagraph'} className={'u-text-bold'}>
                {t('paragraphs.inspection.title', { carOrVehicle })}
            </Paragraph>
            <Paragraph testId={'inspectionIntroductionParagraph'}>
                {t('paragraphs.inspection.introduction', { carOrVehicle })}
            </Paragraph>
            <Paragraph>
                <Button type={'button'} testId={'returningButton'} onClick={onReturning} secondary>
                    {t('buttons.returning', { vehicleBrand })}
                </Button>
            </Paragraph>
            <ExternalWebsitesNotification className={'u-mv-small'} mode={ExternalWebsitesNotificationMode.Plural} />
            <ButtonContainer nav>
                <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type={'button'} testId={'dashboardButton'} onClick={onDashboardClick}>
                    {t('translation:editableSectionNav.dashboard')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
