import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import {
    ContractDescriptionBO,
    Expenditure,
    formatAsNumber,
    Income,
    IncomeAndExpenditure,
    numberFormatNoSeparators,
} from '@cp-uk/common';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedInput } from 'components/validated-input/ValidatedInput';
import { validationSchema } from './IncomeAndExpenditureValidation';
import { AlternativeNotification } from '../alternative-notification';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph';
import { ExpenditureFormValues, IncomeAndExpenditureFormValues, IncomeFormValues } from './types';
import { expenditureTotalled, incomeTotalled, parseIncomeAndExpenditure } from './helpers';

export type IncomeAndExpenditureViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number;
    incomeAndExpenditure: IncomeAndExpenditure;
    onBack: (incomeAndExpenditure: IncomeAndExpenditure) => void;
    onContinue: (incomeAndExpenditure: IncomeAndExpenditure) => void;
};

export const IncomeAndExpenditureView: React.FC<IncomeAndExpenditureViewProps> = ({
    contractDescription,
    totalArrears,
    incomeAndExpenditure,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-income-and-expenditure-view');
    const pound = '£';

    const { onAction: onRequestAdditionalHelpIncomeAndExpenditurePrint } = useAnalyticsActionTracker(
        'onRequestAdditionalHelpIncomeAndExpenditurePrint',
    );
    const { onAction: onRequestAdditionalHelpBackAction } = useAnalyticsActionTracker('onRequestAdditionalHelpBack');
    useAnalyticsPageViewTracker('requestAdditionalHelpIncomeAndExpenditure', !!incomeAndExpenditure);

    const totalFormatted = (total: number): string => {
        return Number.isNaN(total) ? t('money.validation.total') : formatAsNumber(total, numberFormatNoSeparators);
    };

    const initialValues = parseIncomeAndExpenditure(incomeAndExpenditure);

    const onMoneyChange = (
        { target: { value: newValue, name: targetName } }: React.ChangeEvent<HTMLInputElement>,
        { values, setFieldValue }: FormikProps<IncomeAndExpenditureFormValues>,
    ): void => {
        const clonedValues: IncomeAndExpenditureFormValues = {
            income: { ...values.income },
            expenditure: { ...values.expenditure },
            disposableIncome: { ...values.disposableIncome },
        };

        const [containerName, inputName] = targetName.split('.');
        clonedValues[containerName][inputName] = newValue.replace(/[^0-9]/g, '');

        const { income, expenditure } = clonedValues;

        const incomeTotal = incomeTotalled(income);
        const expenditureTotal = expenditureTotalled(expenditure);
        const disposableIncomeTotal = incomeTotal - expenditureTotal;

        setFieldValue('income.total', totalFormatted(incomeTotal));
        setFieldValue('expenditure.total', totalFormatted(expenditureTotal));
        setFieldValue('disposableIncome.total', totalFormatted(disposableIncomeTotal));
    };

    const buildIncomeForCallback = (incomeValues: IncomeFormValues): Income => {
        const { salaryWages, benefitsOther } = incomeValues;
        return {
            salaryWages: Number(salaryWages),
            benefitsOther: Number(benefitsOther),
        };
    };

    const buildExpenditureForCallback = (expenditureValues: ExpenditureFormValues): Expenditure => {
        const {
            volkswagenFinance,
            rentMortgage,
            waterGasElectric,
            councilTax,
            childCareMaintenance,
            landlineInternetTv,
            mobiles,
            carInsuranceTax,
            fuelTravelCosts,
            housekeeping,
            other,
            otherCreditors,
        } = expenditureValues;

        return {
            volkswagenFinance: Number(volkswagenFinance),
            rentMortgage: Number(rentMortgage),
            waterGasElectric: Number(waterGasElectric),
            councilTax: Number(councilTax),
            childCareMaintenance: Number(childCareMaintenance),
            landlineInternetTv: Number(landlineInternetTv),
            mobiles: Number(mobiles),
            carInsuranceTax: Number(carInsuranceTax),
            fuelTravelCosts: Number(fuelTravelCosts),
            housekeeping: Number(housekeeping),
            other: Number(other),
            otherCreditors: Number(otherCreditors),
        };
    };

    const buildIncomeAndExpenditureForCallback = (values: IncomeAndExpenditureFormValues): IncomeAndExpenditure => {
        const { income, expenditure } = values;

        const incomeAndExpenditure = {
            income: buildIncomeForCallback(income),
            expenditure: buildExpenditureForCallback(expenditure),
        };
        return incomeAndExpenditure;
    };

    const onBackClicked = ({ values }: FormikProps<IncomeAndExpenditureFormValues>): void => {
        onRequestAdditionalHelpBackAction();
        const incomeAndExpenditureForCallback = buildIncomeAndExpenditureForCallback(values);

        onBack(incomeAndExpenditureForCallback);
    };

    const onPrintClick: () => void = () => {
        onRequestAdditionalHelpIncomeAndExpenditurePrint();
        window.print();
    };

    return (
        <View testId={'incomeAndExpenditureView'}>
            <SectionHeading level={2} testId={'viewHeader'}>{t('subHeading')}</SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph>{t('paragraphs.information')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values: IncomeAndExpenditureFormValues): void => {
                    const incomeAndExpenditureForCallback = buildIncomeAndExpenditureForCallback(values);

                    onContinue(incomeAndExpenditureForCallback);
                }}
            >
                {(formik: FormikProps<IncomeAndExpenditureFormValues>): React.ReactNode => (
                    <Form
                        className={'uk-request-additional-help-income-and-expenditure-form'}
                        onSubmit={preventSubmit}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <Paragraph>{t('incomeAndExpenditure.income.subHeading')}</Paragraph>
                                <div className={'u-indent u-mb'}>
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.income.salaryWages.label')}
                                        name={'income.salaryWages'}
                                        testId={'income.salaryWages'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.income.benefitsOther.label')}
                                        name={'income.benefitsOther'}
                                        testId={'income.benefitsOther'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.income.total.label')}
                                        name={'income.total'}
                                        testId={'income.total'}
                                        addonText={pound}
                                        reversed
                                        disabled
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph>{t('incomeAndExpenditure.expenditure.subHeading')}</Paragraph>
                                <div className={'u-indent u-mb'}>
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.volkswagenFinance.label')}
                                        name={'expenditure.volkswagenFinance'}
                                        testId={'expenditure.volkswagenFinance'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.rentMortage.label')}
                                        name={'expenditure.rentMortgage'}
                                        testId={'expenditure.rentMortgage'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.waterGasElectric.label')}
                                        name={'expenditure.waterGasElectric'}
                                        testId={'expenditure.waterGasElectric'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.councilTax.label')}
                                        name={'expenditure.councilTax'}
                                        testId={'expenditure.councilTax'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.childCareMaint.label')}
                                        name={'expenditure.childCareMaintenance'}
                                        testId={'expenditure.childCareMaintenance'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.landlineInternetTV.label')}
                                        name={'expenditure.landlineInternetTv'}
                                        testId={'expenditure.landlineInternetTv'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.mobiles.label')}
                                        name={'expenditure.mobiles'}
                                        testId={'expenditure.mobiles'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.carInsuranceTax.label')}
                                        name={'expenditure.carInsuranceTax'}
                                        testId={'expenditure.carInsuranceTax'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.fuelTravelCosts.label')}
                                        name={'expenditure.fuelTravelCosts'}
                                        testId={'expenditure.fuelTravelCosts'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.housekeeping.label')}
                                        name={'expenditure.housekeeping'}
                                        testId={'expenditure.housekeeping'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.other.label')}
                                        name={'expenditure.other'}
                                        testId={'expenditure.other'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money u-mb-xsmall'}
                                        label={t('incomeAndExpenditure.expenditure.otherCreditors.label')}
                                        name={'expenditure.otherCreditors'}
                                        testId={'expenditure.otherCreditors'}
                                        addonText={pound}
                                        reversed
                                        isMandatory
                                        handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onMoneyChange(e, formik);
                                        }}
                                    />
                                    <ValidatedInput
                                        className={'input-money'}
                                        label={t('incomeAndExpenditure.expenditure.total.label')}
                                        name={'expenditure.total'}
                                        testId={'expenditure.total'}
                                        addonText={pound}
                                        reversed
                                        disabled
                                    />
                                </div>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    className={'input-money u-mb-xsmall'}
                                    label={t('incomeAndExpenditure.disposableIncome.total.label')}
                                    name={'disposableIncome.total'}
                                    testId={'disposableIncome.total'}
                                    addonText={pound}
                                    reversed
                                    disabled
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph className={'u-text-muted'}>{t('paragraphs.printOrSave')}</Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'buttonPrint'}
                                        icon={'semantic-print'}
                                        iconReversed
                                        simple
                                        small
                                        link
                                        onClick={onPrintClick}
                                    >
                                        {t('translation:editableSectionNav.print')}
                                    </Button>
                                    <Button type={'button'} testId={'buttonBack'} onClick={() => onBackClicked(formik)} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={'buttonContinue'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
