import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { CPDate, Error } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus, preventSubmit } from '@cp-shared-8/frontend-ui';
import { ChangeBankAccountError, ChangeBankAccountRequest, getChangeBankAccountEndpoint } from '@cp-uk/common';
import { parseErrorResponse, textAsHtml } from 'utils';
import { CpDataApi } from 'cp-xhr';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { View } from 'components/view/View';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { validationSchema } from './DataEntryValidation';
import { getInformationNotificationText } from '../../../helpers';
import { NotificationContentProps } from '../../../types';

export type DataEntryProps = {
    onIsSubmitting: (newIsSubmitting: boolean) => void;
    onCancel: () => void;
    onFailure: (failureNotificationContentProps: NotificationContentProps) => void;
    onSuccess: (changeBankAccountRequest: ChangeBankAccountRequest) => void;
    encryptedContractId: string;
    showThisContractOnlyNotification: boolean;
    lastBilledPaymentDate?: CPDate;
    nextScheduledPaymentDate: CPDate;
};

export const DataEntry: React.FC<DataEntryProps> = ({
    onCancel,
    onIsSubmitting,
    onFailure,
    onSuccess,
    encryptedContractId,
    showThisContractOnlyNotification,
    lastBilledPaymentDate,
    nextScheduledPaymentDate,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { t } = useTranslation('change-bank-account-data-entry');

    const getFailureMessage = <TAppErrorCode extends string>(
        error: Error<TAppErrorCode> | undefined,
    ): string | undefined => {
        return error?.code === 'ERROR_CHANGING_WITH_MESSAGE' ? error?.message : t('failureNotification.default');
    };

    const showWhichEffectiveDateNotification = !!lastBilledPaymentDate;
    const showInformationNotification = showThisContractOnlyNotification || showWhichEffectiveDateNotification;

    const informationNotificationText = getInformationNotificationText(
        t,
        'whichEffectiveDate',
        showThisContractOnlyNotification,
        showWhichEffectiveDateNotification,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
    );

    return (
        <View testId={'dataEntryView'}>
            {showInformationNotification && (
                <Notification
                    className={'u-mb'}
                    status={NotificationStatus.info}
                    testId={'informationNotification'}
                >
                    {textAsHtml(informationNotificationText)}
                </Notification>
            )}
            <Formik
                initialValues={{
                    accountHolderName: '',
                    sortCode: '',
                    accountNumber: '',
                    accountHolderConfirmation: false,
                }}
                validationSchema={validationSchema(t)}
                onSubmit={({ accountHolderName, sortCode, accountNumber }): Promise<void> => {
                    const changeBankAccountRequest: ChangeBankAccountRequest = {
                        accountHolderName,
                        sortCode,
                        accountNumber,
                    };
                    onIsSubmitting(true);
                    setIsSubmitting(true);
                    return CpDataApi.put<void>(getChangeBankAccountEndpoint(encryptedContractId), changeBankAccountRequest)
                        .then(() => {
                            setIsSubmitting(false);
                            onIsSubmitting(false);
                            onSuccess(changeBankAccountRequest);
                        })
                        .catch((error) => {
                            setIsSubmitting(false);
                            onIsSubmitting(false);
                            onFailure({
                                headline: t('failureNotification.headline'),
                                text: getFailureMessage(parseErrorResponse<ChangeBankAccountError>(error)),
                            });
                        });
                }}
            >
                {(formik) => (
                    <Form className={'uk-change-bank-account-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        blocks: [60],
                                        delimiter: '',
                                    }}
                                    className={'input-full-name'}
                                    label={t('accountHolderName.label')}
                                    placeholder={t('accountHolderName.placeholder')}
                                    tooltip={t('accountHolderName.tooltip')}
                                    name={'accountHolderName'}
                                    testId={'accountHolderName'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        blocks: [2, 2, 2],
                                        numericOnly: true,
                                        delimiter: '-',
                                    }}
                                    className={'input-sort-code'}
                                    label={t('sortCode.label')}
                                    placeholder={t('sortCode.placeholder')}
                                    tooltip={t('sortCode.tooltip')}
                                    name={'sortCode'}
                                    testId={'sortCode'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        blocks: [8],
                                        numericOnly: true,
                                    }}
                                    className={'input-account-number'}
                                    label={t('accountNumber.label')}
                                    placeholder={t('accountNumber.placeholder')}
                                    tooltip={t('accountNumber.tooltip')}
                                    name={'accountNumber'}
                                    testId={'accountNumber'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={t('accountHolderConfirmation.label')}
                                    name={'accountHolderConfirmation'}
                                    testId={'accountHolderConfirmation'}
                                    isMandatory={true}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={onCancel}
                                        secondary
                                        testId={'cancelButton'}
                                    >
                                        {t('translation:editableSectionNav.cancel')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                        testId={'confirmChangesButton'}
                                    >
                                        {t('translation:editableSectionNav.confirmChange')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
