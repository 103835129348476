import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { DocumentContractBO } from '@cp-uk/common';
import { LicensePlate } from '../contracts-overview/LicensePlate';
import { contractSubtitle, contractTitle } from '../contracts-overview/ContractOverview';
import { DocumentList } from '../document-list/DocumentList';

export const DocumentContract: React.FC<{ contract: DocumentContractBO; isFirst: boolean }> = ({
    contract,
    isFirst,
}) => {
    const {
        encryptedContractId,
        contractNumber,
        isActive,
        productType,
        vehicleBrand,
        vehicleDescription,
        vehicleRegistrationNumber,
    } = contract;

    return (
        <Card
            element={'section'}
            className={`u-mb ${!isActive ? 'c-card--inactive' : ''}`}
            title={contractTitle(vehicleBrand, vehicleDescription)}
            subtitle={contractSubtitle(productType, contractNumber)}
            contentShort={<LicensePlate vehicleRegistrationNumber={vehicleRegistrationNumber} isContentShort />}
            contentLarge={<DocumentList encryptedContractId={encryptedContractId} contractNumber={contractNumber} />}
            testId={`contract-${contractNumber}`}
            expandableTestId={'expandableButton'}
            expandable
            defaultExpanded={isFirst}
        />
    );
};
