import React, { Suspense, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { ChangeRegistrationNumberBO, ChangeRegistrationNumberRequest } from '@cp-uk/common';
import { ChangeData } from 'components/change-data/ChangeData';
import {
    EmailAddressNotification,
    EmailAddressNotificationMode,
    getEmailAddressNotificationMode,
} from 'components/notifications/email-address/EmailAddressNotification';
import { RegisteredKeeperView } from './registered-keeper-view/RegisteredKeeperView';
import { ConfirmationOrInformationView } from './confirmation-or-information-view/ConfirmationOrInformationView';
import { DataEntryView } from './data-entry-view/DataEntryView';

enum Views {
    RegisteredKeeper = 'registered-keeper',
    ConfirmationOrInformation = 'confirmation-or-information',
    DataEntry = 'data-entry',
}

export const ChangeRegistrationNumber: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: () => void;
    onChangeSuccess: (changeRegistrationNumberRequest: ChangeRegistrationNumberRequest) => void;
    changeRegistrationNumber: ChangeRegistrationNumberBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, changeRegistrationNumber }) => {
    const [currentView, setCurrentView] = useState<Views>(Views.RegisteredKeeper);
    const [isRegisteredKeeperOfVehicle, setIsRegisteredKeeperOfVehicle] = useState<boolean | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { t } = useTranslation('change-registration-number');

    if (changeRegistrationNumber === undefined) {
        return null;
    }

    const {
        encryptedContractId,
        productCategory,
        registrationNumber,
        firstName,
        lastName,
        emailAddress,
    } = changeRegistrationNumber;

    const buildChangeData = (children: React.ReactNode): JSX.Element => {
        return (
            <ChangeData
                currentView={currentView.toString()}
                className={'uk-change-registration-number'}
                heading={t('heading')}
                isSubmitting={isSubmitting}
                onClose={onChangeCancel}
                testId={'changeRegistrationNumber'}
            >
                {children}
            </ChangeData>
        );
    };

    const requestDescription = t('notifications.emailAddress.requestDescription');
    const emailAddressNotificationMode = getEmailAddressNotificationMode(emailAddress);
    if (emailAddressNotificationMode !== EmailAddressNotificationMode.CheckEmailAddress) {
        return buildChangeData(
            <EmailAddressNotification mode={emailAddressNotificationMode} requestDescription={requestDescription} />,
        );
    }

    const onRegisteredKeeperNo = (): void => {
        setIsRegisteredKeeperOfVehicle(false);
        setCurrentView(Views.ConfirmationOrInformation);
    };
    const onRegisteredKeeperYes = (): void => {
        setIsRegisteredKeeperOfVehicle(true);
        setCurrentView(Views.ConfirmationOrInformation);
    };

    const onConfirmationOrInformationCancel = (): void => {
        onChangeCancel();
    };
    const onConfirmationOrInformationNext = (): void => {
        setCurrentView(Views.DataEntry);
    };

    const onDataEntryIsSubmitting = (newIsSubmitting: boolean): void => {
        setIsSubmitting(newIsSubmitting);
    };
    const onDataEntryBack = (): void => {
        setCurrentView(Views.ConfirmationOrInformation);
    };
    const onDataEntryFailure = (): void => {
        onChangeFailure();
    };
    const onDataEntrySuccess = (changeRegistrationNumberRequest: ChangeRegistrationNumberRequest): void => {
        onChangeSuccess(changeRegistrationNumberRequest);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {buildChangeData(
                <Fragment>
                    {currentView === Views.RegisteredKeeper && (
                        <Fragment>
                            <EmailAddressNotification
                                className={'u-mb'}
                                mode={emailAddressNotificationMode}
                                requestDescription={requestDescription}
                                emailAddress={emailAddress}
                            />
                            <RegisteredKeeperView onNo={onRegisteredKeeperNo} onYes={onRegisteredKeeperYes} />
                        </Fragment>
                    )}
                    {currentView === Views.ConfirmationOrInformation && (
                        <ConfirmationOrInformationView
                            productCategory={productCategory}
                            isRegisteredKeeperOfVehicle={isRegisteredKeeperOfVehicle ?? false}
                            onBack={onConfirmationOrInformationCancel}
                            onNext={onConfirmationOrInformationNext}
                        />
                    )}
                    {currentView === Views.DataEntry && (
                        <DataEntryView
                            encryptedContractId={encryptedContractId}
                            registrationNumber={registrationNumber}
                            firstName={firstName}
                            lastName={lastName}
                            emailAddress={emailAddress}
                            onIsSubmitting={onDataEntryIsSubmitting}
                            onBack={onDataEntryBack}
                            onFailure={onDataEntryFailure}
                            onSuccess={onDataEntrySuccess}
                        />
                    )}
                </Fragment>,
            )}
        </Suspense>
    );
};
