import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { PageHeader } from 'components/page-header/PageHeader';
import { MyProfile } from 'components/my-profile/MyProfile';

export const MyProfilePage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.MyProfile);
    const { t } = useTranslation('page-titles');

    return (
        <ContentSection className={'o-content-section--compact uk-my-profile-page'} pageWrapSize={'small'}>
            <PageHeader title={t('my-profile')} />
            <MyProfile />
        </ContentSection>
    );
};
