import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { Header, SiteNav } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker, useAuthentication } from '@cp-shared-8/frontend-ui';
import { useAuthorization } from 'auth';
import { featureToggles } from 'config';
import { Logo } from './Logo';
import {
    cookiePolicyPagePath,
    dashboardPagePath,
    documentCentrePagePath,
    faqPagePath,
    financialSupportAndWellbeingPagePath,
    landingPagePath,
    legalNoticePagePath,
    loginErrorPagePath,
    modernSlaveryStatementPagePath,
    myProfilePagePath,
    notFoundPagePath,
    privacyPolicyPagePath,
    registerPagePath,
    requestContactPagePath,
    sessionTimeoutPagePath,
    thirdPartyLicencesPagePath,
    useOurMatchmakerPagePath,
} from './paths';
import { buildLoginOptions, buildLogoutOptions } from '../../utils';

interface SiteNavItemProps {
    key: string;
    label: string;
    isActive?: boolean;
    url?: string;
    testId: string;
    onClick: (() => void | Promise<void>) | undefined;
    dropdownItems?: SiteNavDropdownItemProps[];
}

interface SiteNavDropdownItemProps {
    key: string;
    label: string;
    isActive?: boolean;
    testId: string;
    url: string;
    onClick: () => void | Promise<void>;
}

const pagesWithoutStaticHeader: string[] = [
    landingPagePath(),
    faqPagePath(),
    legalNoticePagePath(),
    privacyPolicyPagePath(),
    cookiePolicyPagePath(),
    modernSlaveryStatementPagePath(),
    thirdPartyLicencesPagePath(),
    registerPagePath(),
    notFoundPagePath(),
    loginErrorPagePath(),
    sessionTimeoutPagePath(),
    useOurMatchmakerPagePath(),
];

function buildSiteNavItem(
    t: TFunction,
    key: string,
    url: string | undefined,
    onClick: (() => Promise<void> | void) | undefined,
    dropdownItems: SiteNavDropdownItemProps[] | undefined,
): SiteNavItemProps {
    return {
        key,
        label: t(`navigation.${key}`),
        url,
        testId: `${key}Link`,
        onClick,
        dropdownItems,
    };
}

function buildSiteNavDropdownItem(
    t: TFunction,
    key: string,
    url: string,
    onClick: () => Promise<void> | void,
): SiteNavDropdownItemProps {
    return {
        key,
        label: t(`navigation.${key}`),
        testId: `${key}Link`,
        url,
        onClick,
    };
}

function buildLocalSiteNavItem(
    t: TFunction,
    key: string,
    history: History<unknown> | undefined,
    url: string | undefined,
    dropdownItems: SiteNavDropdownItemProps[] | undefined,
): SiteNavItemProps {
    return buildSiteNavItem(t, key, url, history && url ? (): void => history.push(url) : undefined, dropdownItems);
}

function buildLocalSiteNavDropdownItem(
    t: TFunction,
    key: string,
    history: History<unknown>,
    url: string,
    featureToggle = true,
): SiteNavDropdownItemProps {
    if (!featureToggle) {
        return {
            key: '',
            label: '',
            testId: '',
            url: '',
            onClick: (): void => undefined,
        };
    }
    return buildSiteNavDropdownItem(t, key, url, (): void => history.push(url));
}

function buildAuthSiteNavItem(t: TFunction, key: string, onClick: () => Promise<void>): SiteNavItemProps {
    return buildSiteNavItem(t, key, undefined, onClick, undefined);
}

function publicSiteNavItems(t: TFunction, history: History<unknown>, login: () => Promise<void>): SiteNavItemProps[] {
    return [
        buildLocalSiteNavItem(t, 'home', history, landingPagePath(), undefined),
        buildAuthSiteNavItem(t, 'login', login),
    ];
}

function privateSiteNavItems(
    t: TFunction,
    history: History<unknown>,
    isAuthorized: boolean,
    logout: () => Promise<void>,
): SiteNavItemProps[] {
    if (isAuthorized) {
        const needHelpDropdownItems = [
            buildLocalSiteNavDropdownItem(
                t,
                'financialSupportAndWellbeing',
                history,
                financialSupportAndWellbeingPagePath(),
                Object.values(featureToggles.financialSupportAndWellbeing).some((value) => value),
            ),
            buildLocalSiteNavDropdownItem(t, 'requestContact', history, requestContactPagePath('')),
            buildLocalSiteNavDropdownItem(t, 'faq', history, faqPagePath()),
        ].filter((siteNavItem) => !!siteNavItem.key);

        return [
            buildLocalSiteNavItem(t, 'dashboard', history, dashboardPagePath(), undefined),
            buildLocalSiteNavItem(t, 'myProfile', history, myProfilePagePath(), undefined),
            buildLocalSiteNavItem(t, 'documentCentre', history, documentCentrePagePath(), undefined),
            buildLocalSiteNavItem(t, 'needHelp', undefined, undefined, needHelpDropdownItems),
            buildAuthSiteNavItem(t, 'logout', logout),
        ].filter((siteNavItem) => !!siteNavItem.key);
    }

    return [
        buildLocalSiteNavItem(t, 'register', history, registerPagePath(), undefined),
        buildAuthSiteNavItem(t, 'logout', logout),
    ];
}

export const NavigationBar: React.FC = () => {
    const { t, i18n } = useTranslation('navigation');
    const history = useHistory();
    const location = useLocation();

    const { isAuthenticated, logout, login } = useAuthentication();
    const { isAuthorized } = useAuthorization();

    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');

    const locationPathname = location.pathname;
    const currentLanguage = i18n.languages[0];
    const siteNavItems = (isAuthenticated
        ? privateSiteNavItems(
              t,
              history,
              isAuthorized,
              (): Promise<void> => {
                  onLogoutAction();
                  return logout(buildLogoutOptions());
              },
          )
        : publicSiteNavItems(
              t,
              history,
              (): Promise<void> => {
                  onLoginAction();
                  return login(buildLoginOptions(currentLanguage));
              },
          )
    ).map((siteNavItem) => ({
        ...siteNavItem,
        isActive: locationPathname === siteNavItem.url,
        dropdownItems: siteNavItem.dropdownItems?.map((siteNavDropdownItem) => ({
            ...siteNavDropdownItem,
            isActive: locationPathname === siteNavDropdownItem.url,
        })),
    }));

    const firstSiteNavItem = siteNavItems[0];
    const logo = <Logo title={firstSiteNavItem.label} onClick={(): void => history.push(firstSiteNavItem.url ?? '')} />;

    const staticHeader = !pagesWithoutStaticHeader.includes(locationPathname);

    return (
        <Header logo={logo} staticHeader={staticHeader} testId={'navigationBar'}>
            <SiteNav testId={'siteNav'}>
                <SiteNav.Area>
                    {siteNavItems.map(({ key, label, isActive, testId, onClick, dropdownItems }) => (
                        <SiteNav.Item
                            key={key}
                            label={label}
                            active={isActive}
                            data-testid={testId}
                            onClick={onClick}
                            icon={!!dropdownItems}
                        >
                            {dropdownItems?.map(({ key, label, isActive, testId, onClick }) => (
                                <SiteNav.Item.DropdownItem
                                    key={key}
                                    active={isActive}
                                    data-testid={testId}
                                    onClick={onClick}
                                >
                                    {label}
                                </SiteNav.Item.DropdownItem>
                            ))}
                        </SiteNav.Item>
                    ))}
                </SiteNav.Area>
            </SiteNav>
        </Header>
    );
};
