import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ChangeRegistrationNumberRequest, getChangeRegistrationNumberEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { View } from 'components/view/View';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { DataEntryFormValues } from './types';
import { validationSchema } from './DataEntryValidation';

export type DataEntryViewProps = {
    encryptedContractId: string;
    registrationNumber: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
    onIsSubmitting: (newIsSubmitting: boolean) => void;
    onBack: () => void;
    onFailure: () => void;
    onSuccess: (changeRegistrationNumberRequest: ChangeRegistrationNumberRequest) => void;
};

export const DataEntryView: React.FC<DataEntryViewProps> = ({
    encryptedContractId,
    registrationNumber: oldRegistrationNumber,
    firstName,
    lastName,
    emailAddress,
    onIsSubmitting,
    onBack,
    onFailure,
    onSuccess,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { t } = useTranslation('change-registration-number-data-entry-view');

    const initialValues: DataEntryFormValues = { registrationNumber: '' };

    const onBackClick = (): void => {
        onBack();
    };

    return (
        <View testId={'dataEntryView'}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={({ registrationNumber: newRegistrationNumber }): Promise<void> => {
                    const changeRegistrationNumberRequest: ChangeRegistrationNumberRequest = {
                        firstName,
                        lastName,
                        emailAddress,
                        oldRegistrationNumber,
                        newRegistrationNumber,
                    };
                    onIsSubmitting(true);
                    setIsSubmitting(true);
                    return CpDataApi.post<void>(
                        getChangeRegistrationNumberEndpoint(encryptedContractId),
                        changeRegistrationNumberRequest,
                    )
                        .then((): void => {
                            setIsSubmitting(false);
                            onIsSubmitting(false);
                            onSuccess(changeRegistrationNumberRequest);
                        })
                        .catch((): void => {
                            setIsSubmitting(false);
                            onIsSubmitting(false);
                            onFailure();
                        });
                }}
            >
                {(formik: FormikProps<DataEntryFormValues>): React.ReactNode => (
                    <Form className={'uk-change-registration-number-data-entry-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        uppercase: true,
                                        blocks: [8],
                                        delimiter: '',
                                    }}
                                    className={'input-short'}
                                    label={t('registrationNumber.label')}
                                    name={'registrationNumber'}
                                    testId={'registrationNumber'}
                                    withHtmlValidationWarning
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Paragraph testId={'informationParagraph'}>{t('paragraphs.information')}</Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        type={'button'}
                                        testId={'backButton'}
                                        disabled={isSubmitting}
                                        onClick={onBackClick}
                                        secondary
                                    >
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        type={'button'}
                                        testId={'confirmChangeButton'}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                    >
                                        {t('translation:editableSectionNav.confirmChange')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
