import { useCallback, useEffect, useRef } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { TrackingId } from '@cp-shared-8/frontend-ui';
import {
    getCancelPaymentEndpoint,
    getProcessPaymentEndpoint,
    ProcessPaymentError,
    ProcessPaymentResponse,
    ProposeCommonPaymentResponse,
} from '@cp-uk/common';
import { RealexHpp, RealexOnProcessDetail } from '@cp-uk/rxp-js';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from './parse-error-response';
import { toCamelCase } from './to-camel-case';
import { RealexFailedModalStatus } from 'components/realex-payments/types';
import { parseResponseJson } from './realex-utils';

export enum RealexEventTypes {
    onProcess = 'realex-on-process-event',
    onClose = 'realex-on-close-event',
}

type PaymentContext = 'outstanding-payment' | 'early-settlement';

export const useRealex = (
    context: PaymentContext,
    paymentSuccessTrackingID: TrackingId,
    paymentErrorTrackingID: TrackingId,
    setTrackingId: (trackingId: TrackingId) => void,
    setShowPaymentSuccessfulModal: (showPaymentSuccessfulModal: boolean) => void,
    setShowPaymentFailedModal: (showPaymentFailedModal: boolean) => void,
    setPaymentFailedModalStatus: (paymentFailedModalStatus: RealexFailedModalStatus) => void,
    setPaymentFailedModalKey: (paymentFailedModalKey: string) => void,
    setIsProcessSubmitting: (isProcessSubmitting: boolean) => void,
    setIsCancelSubmitting: (isCancelSubmitting: boolean) => void,
    setAuthCode?: (authCode: string) => void,
): { setupRealex: (data: ProposeCommonPaymentResponse) => void; cancelPayment: () => Promise<void> } => {
    const isProcessSubmittingRef = useRef<boolean>(false);
    const paymentIdentifierRef = useRef<string | undefined>(undefined);
    const isCancelSubmittingRef = useRef<boolean>(false);

    const setupRealex = (data: ProposeCommonPaymentResponse): void => {
        const parsedRealexJson = JSON.parse(data.realexJson);
        paymentIdentifierRef.current = data.paymentIdentifier;

        RealexHpp.setHppUrl(data.realexUrl);
        RealexHpp.init('autoload', undefined, parsedRealexJson);
    };

    const processPaymentCallback = useCallback(
        (response: string): Promise<void> => {
            if (isProcessSubmittingRef.current || !paymentIdentifierRef.current) {
                return Promise.resolve();
            }

            const parsedResponseJson = parseResponseJson(response);

            setIsProcessSubmitting(true);
            isProcessSubmittingRef.current = true;
            return CpDataApi.post<ProcessPaymentResponse>(
                getProcessPaymentEndpoint(context, paymentIdentifierRef.current),
                parsedResponseJson,
            )
                .then(({ data }: AxiosResponse<ProcessPaymentResponse>): void => {
                    paymentIdentifierRef.current = undefined;
                    isProcessSubmittingRef.current = false;
                    setShowPaymentSuccessfulModal(true);
                    setTrackingId(paymentSuccessTrackingID);
                    setIsProcessSubmitting(false);
                    if (setAuthCode) {
                        setAuthCode(data.authCode);
                    }
                })
                .catch((error: AxiosError<void>): void => {
                    const supportedCodes: string[] = [
                        'TRANSACTION_FAILED',
                        'BANK_ERROR',
                        'REALEX_ERROR',
                        'BAD_REQUEST',
                        'ACCOUNT_SUSPENDED',
                    ];

                    const code = parseErrorResponse<ProcessPaymentError>(error)?.code;

                    const isSupportedCode = supportedCodes.includes(code ?? '');

                    paymentIdentifierRef.current = undefined;
                    isProcessSubmittingRef.current = false;
                    setPaymentFailedModalStatus(isSupportedCode ? 'error' : 'warning');
                    setPaymentFailedModalKey(isSupportedCode ? toCamelCase(code ?? '') : 'defaultError');
                    setShowPaymentFailedModal(true);
                    setTrackingId(paymentErrorTrackingID);
                    setIsProcessSubmitting(false);
                });
        },
        [
            context,
            paymentSuccessTrackingID,
            paymentErrorTrackingID,
            setIsProcessSubmitting,
            setPaymentFailedModalKey,
            setPaymentFailedModalStatus,
            setShowPaymentFailedModal,
            setShowPaymentSuccessfulModal,
            setTrackingId,
            setAuthCode,
        ],
    );

    const cancelPaymentCallback = useCallback((): Promise<void> => {
        if (isCancelSubmittingRef.current || !paymentIdentifierRef.current) {
            return Promise.resolve();
        }

        RealexHpp.close();

        setIsCancelSubmitting(true);
        isCancelSubmittingRef.current = true;
        return CpDataApi.post<void>(getCancelPaymentEndpoint(context, paymentIdentifierRef.current))
            .then((): void => {
                paymentIdentifierRef.current = undefined;
                isCancelSubmittingRef.current = false;
                setIsCancelSubmitting(false);
            })
            .catch((): void => {
                paymentIdentifierRef.current = undefined;
                isCancelSubmittingRef.current = false;
                setIsCancelSubmitting(false);
            });
    }, [context, setIsCancelSubmitting]);

    useEffect((): (() => void) => {
        const onProcess = (event: Event): Promise<void> => {
            event.preventDefault();

            const { detail: { response } } = event as CustomEvent<RealexOnProcessDetail>;

            return processPaymentCallback(response);
        };
        const onClose = (event: Event): Promise<void> => {
            event.preventDefault();

            return cancelPaymentCallback();
        };

        window.addEventListener(RealexEventTypes.onProcess, onProcess);
        window.addEventListener(RealexEventTypes.onClose, onClose);

        return (): Promise<void> => {
            window.removeEventListener(RealexEventTypes.onProcess, onProcess);
            window.removeEventListener(RealexEventTypes.onClose, onClose);

            return cancelPaymentCallback();
        };
    }, [processPaymentCallback, cancelPaymentCallback]);

    return { setupRealex, cancelPayment: cancelPaymentCallback };
};
