import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { StartMatchmakerBO, getStartMatchmakerEndpoint } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    StartMatchmakerBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'startMatchmaker',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<StartMatchmakerBO>(getStartMatchmakerEndpoint(encryptedContractId)).then(
            ({ data }) => data,
        );
    },
});

export default reducer;
export const fetchStartMatchmaker = fetchData;
