import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Layout, Paragraph, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { FinancialSupportAndWellbeingBO } from '@cp-uk/common';
import { appendBuildTimestamp, textWithComponents, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { NavigationTile } from './NavigationTile';
import { ContentText } from './types';
import {
    ExternalWebsitesNotification,
    ExternalWebsitesNotificationMode,
} from 'components/notifications/external-websites/ExternalWebsitesNotification';
import { fetchFinancialSupportAndWellbeing } from './FinancialSupportAndWellbeingSlice';
import { selectFinancialSupportAndWellbeing } from './FinancialSupportAndWellbeingSelector';
import { getContractTypeKeyFragment, getTrackingId } from './helpers';

export type FinancialSupportAndWellbeingTopicTile = {
    key: string;
    imageUrl: string;
    title: string;
    text: string;
    buttonText: string;
    buttonAriaLabel: string;
    buttonUrl: string;
    appliesIfHas: (string | undefined)[] | undefined;
    appliesIfHasNot: (string | undefined)[] | undefined;
};

export const FinancialSupportAndWellbeingTopicUi: React.FC<{
    topicId: string;
    financialSupportAndWellbeing?: FinancialSupportAndWellbeingBO | undefined;
}> = ({ topicId, financialSupportAndWellbeing }) => {
    const { t } = useTranslation([
        `financial-support-and-wellbeing-${topicId}`,
        'financial-support-and-wellbeing-topic',
    ]);
    const history = useHistory();

    const trackingId = getTrackingId('financialSupportAndWellbeingTopic', topicId);
    useAnalyticsPageViewTracker(trackingId, !!financialSupportAndWellbeing);
    const { onAction: onForwardAction } = useAnalyticsActionTracker(`${trackingId}Forward`);
    const { onAction: onBackAction } = useAnalyticsActionTracker(`${trackingId}Back`);

    if (financialSupportAndWellbeing === undefined) {
        return null;
    }

    if (!t('subHeading', '')) {
        return (
            <Paragraph testId={'unknownParagraph'}>
                {t('financial-support-and-wellbeing-topic:paragraphs.unknown', { topicId })}
            </Paragraph>
        );
    }

    const { contracts } = financialSupportAndWellbeing;

    const isRelevant = (tile: FinancialSupportAndWellbeingTopicTile): boolean => {
        if (
            tile.appliesIfHasNot &&
            tile.appliesIfHasNot.length > 0 &&
            contracts.some((contract) => tile.appliesIfHasNot?.includes(contract.productType))
        ) {
            return false;
        }

        return (
            !tile.appliesIfHas ||
            tile.appliesIfHas.length === 0 ||
            contracts.some((contract) => tile.appliesIfHas?.includes(contract.productType))
        );
    };

    const contractTypeKeyFragment = getContractTypeKeyFragment(contracts);

    const components: Record<string, React.ReactNode | undefined> = {};
    const texts = t<ContentText[]>('texts', {
        returnObjects: true,
        contractTypeKeyFragment,
    });
    texts.reduce((accumulator, { key, text }) => {
        accumulator[key] = textWithComponents(undefined, text, components);
        return accumulator;
    }, components);

    const options: Record<string, string | boolean> = { returnObjects: true };
    texts.reduce((accumulator, { key, text }) => {
        accumulator[key] = text;
        return accumulator;
    }, options);

    const tiles = t<FinancialSupportAndWellbeingTopicTile[]>('tiles', options).filter((tile) => isRelevant(tile));

    const imageUrl = appendBuildTimestamp(t('imageUrl'));
    const paragraphDefaultLayout = !!imageUrl ? '1/2' : '1/1';
    const paragraphSmallLayout = !!imageUrl ? '1/1' : undefined;

    const onBackClick = (): void => {
        const href = t('backUrl');
        onBackAction('backButton', href);
        history.push(href);
    };

    const externalWebsitesNotificationMode = t('externalWebsitesNotification') as ExternalWebsitesNotificationMode;

    return (
        <section>
            <Heading level={2} testId={'subHeading'}>
                {t('subHeading')}
            </Heading>
            <Layout equalHeight>
                {!!imageUrl && (
                    <Layout.Item default={'1/2'} s={'1/1'}>
                        <ResponsiveImage src={imageUrl} testId={'topicImage'} />
                    </Layout.Item>
                )}
                <Layout.Item default={paragraphDefaultLayout} s={paragraphSmallLayout}>
                    <Paragraph testId={'introductionParagraph'} className={'u-mb-none'}>
                        {textWithComponents(t, 'paragraphs.introduction', components)}
                    </Paragraph>
                </Layout.Item>
                {tiles.map(({ key, title, text, buttonText, buttonAriaLabel, buttonUrl }) => (
                    <Layout.Item key={key} default={'1/3'} m={'1/2'} s={'1/1'}>
                        <NavigationTile
                            title={title}
                            text={text}
                            buttonText={buttonText}
                            buttonAriaLabel={buttonAriaLabel}
                            buttonUrl={buttonUrl}
                            testId={`${key}SubTopic`}
                            onAction={onForwardAction}
                        />
                    </Layout.Item>
                ))}
            </Layout>
            <ExternalWebsitesNotification
                className={'u-mt'}
                mode={externalWebsitesNotificationMode}
            />
            <Paragraph className={'u-mt'}>
                <Button
                    className={'c-btn--compact'}
                    type={'button'}
                    icon={'semantic-back'}
                    iconReversed
                    secondary
                    testId={'backButton'}
                    onClick={onBackClick}
                >
                    {t('translation:editableSectionNav.back')}
                </Button>
            </Paragraph>
        </section>
    );
};

const FinancialSupportAndWellbeingTopicWithHandlers = withLoadingAndNoConnectionHandler(FinancialSupportAndWellbeingTopicUi);

export const FinancialSupportAndWellbeingTopic: React.FC<{ topicId: string }> = ({ topicId }) => {
    const { data: financialSupportAndWellbeing, isLoading, loadingError } = useGetSimpleApiData(
        fetchFinancialSupportAndWellbeing,
        getSimpleDataSelector(selectFinancialSupportAndWellbeing),
    );

    return (
        <FinancialSupportAndWellbeingTopicWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            topicId={topicId}
            financialSupportAndWellbeing={financialSupportAndWellbeing}
        />
    );
};
