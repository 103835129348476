import React from 'react';
import { RealexModalProps } from './types';
import { useTranslation } from 'react-i18next';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { textWithComponents } from 'utils';

export const BrowserAwarenessModal: React.FC<RealexModalProps> = ({
    shown,
    confirmationDisabled,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation('realex-payments');

    return (
        <Modal
            shown={shown}
            testId={'browserAwarenessModal'}
            status={'info'}
            title={t('browserAwarenessModal.title')}
            closeAny={false}
            hideCloseButton={false}
            buttonCloseLabel={t('translation:editableSectionNav.close')}
            buttonConfirmText={t('buttons.payNow.label')}
            buttonConfirmType={'button'}
            confirmationDisabled={confirmationDisabled}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>{textWithComponents(t, 'browserAwarenessModal.contents')}</Paragraph>
        </Modal>
    );
};
