import React from 'react';
import classNames from 'classnames';

export type DescriptionTermAndDetailProps = {
    className?: string;
    testId?: string;
    term: React.ReactNode;
    termClassName?: string;
    detail: React.ReactNode;
    detailClassName?: string;
    action?: React.ReactNode;
};

export const DescriptionTermAndDetail: React.FC<DescriptionTermAndDetailProps> = ({
    className,
    testId,
    term,
    termClassName,
    detail,
    detailClassName,
    action,
}) => {
    const groupClassNameList = classNames('c-description-list__group', className).trim();
    const termClassNameList = classNames('c-description-list__term', termClassName).trim();
    const detailClassNameList = classNames('c-description-list__detail', detailClassName).trim();

    return (
        <div className={groupClassNameList} data-testid={!!testId ? `${testId}Group` : undefined}>
            <dt className={termClassNameList} data-testid={!!testId ? `${testId}Term` : undefined}>
                {term}
            </dt>
            <dd className={detailClassNameList} data-testid={!!testId ? `${testId}Detail` : undefined}>
                {detail && <span className={'c-description-list__text'}>{detail}</span>}
                {action && <div className={'c-description-list__action'}>{action}</div>}
            </dd>
        </div>
    );
};
