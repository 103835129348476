import React from 'react';
import { RealexModalPropsWithContext } from './types';
import { useTranslation } from 'react-i18next';
import { Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { textWithComponents } from 'utils';

export const PaymentSuccessfulModal: React.FC<RealexModalPropsWithContext> = ({
    shown,
    confirmationDisabled,
    onClose,
    onConfirm,
    context,
    authCode,
}) => {
    const { t } = useTranslation('realex-payments');

    return (
        <Modal
            shown={shown}
            testId={'paymentSuccessfulModal'}
            status={'success'}
            title={t(`paymentSuccessfulModal.${context}.title`)}
            closeAny={false}
            hideCloseButton={true}
            buttonConfirmText={t('translation:editableSectionNav.close')}
            buttonConfirmType={'button'}
            confirmationDisabled={confirmationDisabled}
            onClose={onClose}
            onConfirm={onConfirm}
        >
            <Paragraph>
                {textWithComponents(t, `paymentSuccessfulModal.${context}.contents`, undefined, { authCode })}
            </Paragraph>
        </Modal>
    );
};
