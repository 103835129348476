export interface UseSessionStorageResponse {
    getSessionValue: (key: string) => string | undefined;
    setSessionValue: (key: string, value: string) => void;
}

export const useSessionStorage = (): UseSessionStorageResponse => {
    const getSessionValue = (key: string): string | undefined => {
        // Wrapping session storage getter in a try catch to prevent errors and exceptions for unsupported devices
        try {
            return sessionStorage?.getItem(key) ?? undefined;
        } catch {
            return undefined;
        }
    };

    const setSessionValue = (key: string, value: string): void => {
        // Wrapping session storage setter in a try catch to prevent errors and exceptions for unsupported devices
        try {
            sessionStorage?.setItem(key, value);
        } catch {}
    };

    return { getSessionValue: getSessionValue, setSessionValue: setSessionValue };
};
