import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ContentSection, Fieldset, Form, Heading, PageWrap, SectionHeading } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import {
    InfoCheckbox,
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    withAutoScroll,
} from '@cp-shared-8/frontend-ui';
import {
    CustomerRegistrationError,
    getRegistrationEndpoint,
    parseCPDateFromInput,
    RegistrationRequest,
    SimpleContentDto,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { useAuthorizationFlow } from 'auth';
import 'extensions';
import {
    expandSimpleContent,
    parseErrorResponse,
    textAsHtml,
    textWithComponents,
    toCamelCase,
    buildLinkToPhoneNumber,
} from 'utils';
import { linkToFaqPage, linkToPrivacyPolicyPage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import {
    fetchLegalNotice,
    fetchPrivacyPolicy,
    getLegalNoticeComponents,
    getPrivacyPolicyComponents,
} from 'components/simple-page-content';
import { selectPrivacyPolicy } from '../simple-page-content/PrivacyPolicySelector';
import { selectLegalNotice } from '../simple-page-content/LegalNoticeSelector';
import { validationSchema } from './RegistrationValidation';
import { RegistrationFormValues } from './types';
import { ValidatedCheckbox } from '../validated-checkbox/ValidatedCheckbox';
import { SimpleLink } from '../simple-link/SimpleLink';

const h5Bronson = <Heading level={5} className={'u-mb-small'} testId={'heading'} />;

const NotificationWithAutoScroll = withAutoScroll(Notification);

const RegistrationUi: React.FC<{
    privacyPolicy?: SimpleContentDto;
    legalNotice?: SimpleContentDto;
}> = ({ privacyPolicy, legalNotice }) => {
    const { t } = useTranslation('registration');
    const [lastErrorNotificationKey, setLastErrorNotificationKey] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { confirm } = useAuthorizationFlow();
    const history = useHistory();

    const { onAction: onRegistrationFailureAction } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const { onAction: onRegistrationSuccessAction } = useAnalyticsActionTracker('onConfirmIdentiyAuthSuccess');
    useAnalyticsPageViewTracker('confirmIdentity', !!privacyPolicy && !!legalNotice);

    const showErrorNotification = (code: CustomerRegistrationError | undefined): void => {
        const supportedCodes: string[] = [
            'ALREADY_REGISTERED_CUSTOMER',
            'VEHICLE_NOT_FOUND',
            'CUSTOMER_DATA_MISMATCH',
            'PLEASE_CALL',
        ];

        setLastErrorNotificationKey(supportedCodes.includes(code ?? '') ? toCamelCase(code ?? '') : 'defaultError');
    };

    if (!privacyPolicy || !legalNotice) {
        return null;
    }

    const privacyPolicyWithHeading = t('cardHeadings.privacyPolicy') + expandSimpleContent(privacyPolicy).content;
    const legalNoticeWithHeading = t('cardHeadings.legalNotice') + expandSimpleContent(legalNotice).content;

    const duringYourAgreementUrl = t('notifications.information.duringYourAgreementUrl');
    const vwfsServicePlansUrl = t('notifications.information.vwfsServicePlansUrl');

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    const linkToDuringYourAgreement = (
        <SimpleLink href={duringYourAgreementUrl} target={'_BLANK'} testId={'duringYourAgreementPageLink'} />
    );
    const linkToVwfsServicePlans = (
        <SimpleLink href={vwfsServicePlansUrl} target={'_BLANK'} testId={'vwfsServicePlansPageLink'} />
    );

    const initialValues: RegistrationFormValues = {
        firstName: '',
        surname: '',
        dateOfBirth: '',
        vehicleRegistrationNumber: '',
        lastFourDigitsOfBankAccount: '',
        acceptPrivacyPolicy: false,
        acceptTermsAndConditions: false,
        confirmPrivateAgreement: false,
    };

    return (
        <ContentSection className={'uk-registration'}>
            <PageWrap size={'xsmall'}>
                {isSubmitting && <Spinner fullPage={true} />}
                <SectionHeading level={2}>{t('subHeading')}</SectionHeading>
                <Notification
                    className={'u-mb'}
                    status={NotificationStatus.info}
                    testId={'informationNotification'}
                >
                    {textWithComponents(t, 'notifications.information.text', {
                        linkToDuringYourAgreement,
                        linkToVwfsServicePlans,
                        linkToPrivacyPolicyPage,
                    })}
                </Notification>
                {lastErrorNotificationKey && (
                    <NotificationWithAutoScroll
                        className={'u-mb'}
                        status={NotificationStatus.warning}
                        testId={'lastErrorNotification'}
                    >
                        {textWithComponents(t, `notifications.${lastErrorNotificationKey}.text`, {
                            linkToFaqPage,
                            linkToAvartoPhoneNumber,
                        })}
                    </NotificationWithAutoScroll>
                )}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t)}
                    onSubmit={(
                        { firstName, surname, dateOfBirth, vehicleRegistrationNumber, lastFourDigitsOfBankAccount },
                        { resetForm },
                    ): Promise<void> => {
                        const registrationRequest: RegistrationRequest = {
                            firstName,
                            surname,
                            dateOfBirth: parseCPDateFromInput(dateOfBirth).toMoment().toUTC().toCPDate(),
                            vehicleRegistrationNumber,
                            lastFourDigitsOfBankAccount,
                        };
                        setIsSubmitting(true);
                        return CpDataApi.post<void>(getRegistrationEndpoint(), registrationRequest)
                            .then(() => {
                                confirm({ isAuthorized: true });
                                history.push(dashboardPagePath());
                                onRegistrationSuccessAction();
                            })
                            .catch((error) => {
                                setIsSubmitting(false);
                                showErrorNotification(parseErrorResponse<CustomerRegistrationError>(error)?.code);
                                onRegistrationFailureAction();
                                resetForm({ values: initialValues });
                            });
                    }}
                    data-testid={'registrationForm'}
                >
                    {(formik: FormikProps<RegistrationFormValues>): React.ReactNode => (
                        <Form className={'uk-registration-form'} onSubmit={preventSubmit}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            blocks: [60],
                                            delimiter: '',
                                        }}
                                        className={'input-name'}
                                        label={t('firstName.label')}
                                        placeholder={t('firstName.placeholder')}
                                        helpText={t('firstName.helpText')}
                                        name={'firstName'}
                                        testId={'firstName'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            blocks: [60],
                                            delimiter: '',
                                        }}
                                        className={'input-name'}
                                        label={t('surname.label')}
                                        placeholder={t('surname.placeholder')}
                                        helpText={t('surname.helpText')}
                                        name={'surname'}
                                        testId={'surname'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [2, 2, 4],
                                            delimiter: '/',
                                        }}
                                        className={'input-date'}
                                        label={t('dateOfBirth.label')}
                                        placeholder={t('dateOfBirth.placeholder')}
                                        helpText={t('dateOfBirth.helpText')}
                                        name={'dateOfBirth'}
                                        testId={'dateOfBirth'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            blocks: [8],
                                            delimiter: '',
                                            uppercase: true,
                                        }}
                                        className={'input-short'}
                                        label={t('vehicleRegistrationNumber.label')}
                                        placeholder={t('vehicleRegistrationNumber.placeholder')}
                                        helpText={t('vehicleRegistrationNumber.helpText')}
                                        name={'vehicleRegistrationNumber'}
                                        testId={'vehicleRegistrationNumber'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInputWithCleave
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [4],
                                        }}
                                        className={'input-short'}
                                        label={t('lastFourDigitsOfBankAccount.label')}
                                        placeholder={t('lastFourDigitsOfBankAccount.placeholder')}
                                        helpText={textAsHtml(t('lastFourDigitsOfBankAccount.helpText'))}
                                        name={'lastFourDigitsOfBankAccount'}
                                        testId={'lastFourDigitsOfBankAccount'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <InfoCheckbox
                                    preLinkLabel={t('acceptPrivacyPolicy.preLabel')}
                                    linkLabel={t('acceptPrivacyPolicy.label')}
                                    afterLinkLabel={'*'} // Should really be isMandatory={true} but not supported
                                    fieldName={'acceptPrivacyPolicy'}
                                    testId={'acceptPrivacyPolicy'}
                                    testIdInfoText={'privacyPolicyInfoText'}
                                    isLinkButton
                                >
                                    {textWithComponents(undefined, privacyPolicyWithHeading, {
                                        ...getPrivacyPolicyComponents(t),
                                        h5Bronson,
                                    })}
                                </InfoCheckbox>
                                <InfoCheckbox
                                    preLinkLabel={t('acceptTermsAndConditions.preLabel')}
                                    linkLabel={t('acceptTermsAndConditions.label')}
                                    afterLinkLabel={'*'} // Should really be isMandatory={true} but not supported
                                    fieldName={'acceptTermsAndConditions'}
                                    testId={'acceptTermsAndConditions'}
                                    testIdInfoText={'termsAndConditionsInfoText'}
                                    isLinkButton
                                >
                                    {textWithComponents(undefined, legalNoticeWithHeading, {
                                        ...getLegalNoticeComponents(),
                                        h5Bronson,
                                    })}
                                </InfoCheckbox>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        className={'c-checkbox--unindent-error-message'}
                                        label={t('confirmPrivateAgreement.label')}
                                        name={'confirmPrivateAgreement'}
                                        testId={'confirmPrivateAgreement'}
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Button
                                        type={'button'}
                                        full={true}
                                        disabled={isSubmitting}
                                        onClick={formik.submitForm}
                                        testId={'complete-registration-button'}
                                    >
                                        {t('buttons.completeRegistration.label')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </PageWrap>
        </ContentSection>
    );
};

const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);

export const Registration: React.FC = () => {
    const {
        cmsContent: privacyPolicy,
        isLoading: isPrivacyPolicyLoading,
        loadingError: loadingPrivacyPolicyError,
    } = useCmsContent(fetchPrivacyPolicy, selectPrivacyPolicy);

    const {
        cmsContent: legalNotice,
        isLoading: isLegalNoticeLoading,
        loadingError: loadingLegalNoticeError,
    } = useCmsContent(fetchLegalNotice, selectLegalNotice);

    return (
        <RegistrationWithHandlers
            isLoading={isPrivacyPolicyLoading || isLegalNoticeLoading}
            hasError={!!loadingPrivacyPolicyError && !!loadingLegalNoticeError}
            privacyPolicy={privacyPolicy}
            legalNotice={legalNotice}
        />
    );
};
