import { toCamelCase } from './to-camel-case';

export const getValueFromEnumKey = (enumAsObject: object, keyInKebabCase: string | undefined): string | undefined => {
    const keyInCamelCase = toCamelCase((keyInKebabCase ?? '').replace(/-/g, '_'));

    const values = Object.entries(enumAsObject)
        .filter(([itemKey, _]): boolean => itemKey === keyInCamelCase)
        .map(([, itemValue]) => itemValue);

    // NOTE: There can ONLY be 0 or 1 values in the array -- think about enum member uniqueness!!
    return values.length > 0 ? values[0] : undefined;
};
