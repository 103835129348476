import { ContractDescriptionBO } from '@cp-uk/common';

export const ContractDescriptionSolutions: ContractDescriptionBO = {
    vehicleBrand: 'VOLKSWAGEN',
    vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
    contractNumber: '440123456789',
    registrationNumber: 'EX19ABC',
    productType: 'solutions',
};

export const ContractDescriptionContractHire: ContractDescriptionBO = {
    ...ContractDescriptionSolutions,
    contractNumber: '440123456790',
    productType: 'contractHire',
};
