import React, { Suspense, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, DescriptionList, Fieldset, Form, Paragraph } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { InsuranceBO } from '@cp-uk/common';
import { textWithComponents, useInsuranceFinanceCompanyDetails } from 'utils';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import {
    EmailAddressNotification,
    EmailAddressNotificationMode,
    getEmailAddressNotificationMode,
} from 'components/notifications/email-address/EmailAddressNotification';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { ValidatedSelect } from 'components/validated-select/ValidatedSelect';
import { buildRenewalMonthAndYearSelectItems } from './helpers';
import { fetchInsurance } from './InsuranceSlice';
import { selectInsuranceForSpecific } from './InsuranceSelector';
import { validationSchema } from './InsuranceValidation';
import { InsuranceFormValues } from './types';
import { Well } from '../well/Well';

export const InsuranceUi: React.FC<{ insurance: InsuranceBO | undefined }> = ({ insurance }) => {
    const { t } = useTranslation('insurance');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const history = useHistory();

    const shortCompanyName = useInsuranceFinanceCompanyDetails(insurance?.insuranceBrand);

    if (insurance === undefined) {
        return null;
    }

    const {
        contractDescription,
        firstName,
        surname,
        emailAddress,
        insuranceBrand,
        registrationNumber,
        showLetUsKnowOption,
    } = insurance;

    const emailAddressNotificationMode = getEmailAddressNotificationMode(emailAddress);
    if (emailAddressNotificationMode !== EmailAddressNotificationMode.CheckEmailAddress && showLetUsKnowOption) {
        return <EmailAddressNotification mode={emailAddressNotificationMode} />;
    }

    const initialValues: InsuranceFormValues = {
        renewalMonthAndYear: '',
        consent: false,
    };

    const onReturnClick = (): void => {
        //todo: analytics
        history.push(dashboardPagePath());
    };

    const onFindOutMoreClick = (): void => {
        //todo: analytics
    };

    const onUkInsurancePrivacyNoticeClick = (): void => {
        //todo analytics
    };

    const returnButton = (
        <Button type={'button'} testId={'returnButton'} disabled={isSubmitting} onClick={onReturnClick} secondary>
            {t('translation:editableSectionNav.return')}
        </Button>
    );

    const findOutMoreButton = (
        <Button
            element={'a'}
            testId={'findOutMoreButton'}
            secondary={showLetUsKnowOption}
            href={t(`buttonUrls.${insuranceBrand}`)}
            onClick={onFindOutMoreClick}
            target={'_BLANK'}
        >
            {t('translation:editableSectionNav.findOutMore')}
        </Button>
    );

    const linkToUkInsurancePrivacyNotice = (
        <SimpleLink
            href={t('ukInsurancePrivacyNoticeUrl')}
            target={'_BLANK'}
            onClick={onUkInsurancePrivacyNoticeClick}
            testId={'ukInsurancePrivacyNoticeLink'}
        />
    );

    const renewalMonthAndYearSelectItems = buildRenewalMonthAndYearSelectItems();
    const validRenewalMonthAndYears = renewalMonthAndYearSelectItems.map((selectItem) => selectItem.value);

    const getRenewalMonthAndYearText = (renewalMonthAndYear: string | undefined): string => {
        const x = renewalMonthAndYearSelectItems.find((selectItem) => selectItem.value === renewalMonthAndYear);
        if (!x) {
            return t('notSelected');
        }
        return x.label;
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <section className={'c-section--scroll-to-margin-padding'}>
                <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
                {showLetUsKnowOption && (
                    <EmailAddressNotification
                        className={'u-mb'}
                        mode={emailAddressNotificationMode}
                        emailAddress={emailAddress}
                    />
                )}
                <Paragraph className={'u-text-bold'} testId={'comprehensiveCoverYouCanRelyOnTitleParagraph'}>
                    {t('paragraphs.comprehensiveCoverYouCanRelyOn.title')}
                </Paragraph>
                <Paragraph testId={'comprehensiveCoverYouCanRelyOnDetailParagraph'}>
                    {t('paragraphs.comprehensiveCoverYouCanRelyOn.detail', { shortCompanyName })}
                </Paragraph>
                {showLetUsKnowOption ? (
                    <Fragment>
                        {findOutMoreButton}
                        <Paragraph className={'u-text-bold u-mt-large'} testId={'notReadyToGetAQuoteYetTitleParagraph'}>
                            {t('paragraphs.notReadyToGetAQuoteYet.title')}
                        </Paragraph>
                        <Paragraph testId={'notReadyToGetAQuoteYetDetailParagraph'}>
                            {textWithComponents(t, 'paragraphs.notReadyToGetAQuoteYet.detail', { shortCompanyName })}
                        </Paragraph>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema(t, validRenewalMonthAndYears)}
                            onSubmit={(): void => {
                                setIsSubmitting(true);
                                alert('toDo!');
                                setIsSubmitting(false);
                            }}
                        >
                            {(formik: FormikProps<InsuranceFormValues>): React.ReactNode => (
                                <Form className={`uk-brand-car-insurance-form`}>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ValidatedSelect
                                                className={'select-renewal-month-and-year'}
                                                label={t('renewalMonthAndYear.label')}
                                                placeholder={t('renewalMonthAndYear.placeholder')}
                                                name={'renewalMonthAndYear'}
                                                selectItems={renewalMonthAndYearSelectItems}
                                                testId={'renewalMonthAndYear'}
                                                isMandatory
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <Well testId={'additionalDataWell'}>
                                                <Paragraph className={'u-text-bold'}>
                                                    {t('additionalDataWell.label')}
                                                </Paragraph>
                                                <DescriptionList
                                                    horizontal
                                                    shortTerms
                                                    className={'c-description-list--compact'}
                                                >
                                                    <DescriptionTermAndDetail
                                                        testId={'firstName'}
                                                        term={t('additionalDataWell.firstName')}
                                                        detail={firstName}
                                                    />
                                                    <DescriptionTermAndDetail
                                                        testId={'surname'}
                                                        term={t('additionalDataWell.surname')}
                                                        detail={surname}
                                                    />
                                                    <DescriptionTermAndDetail
                                                        testId={'emailAddress'}
                                                        term={t('additionalDataWell.emailAddress')}
                                                        detail={emailAddress}
                                                    />
                                                    <DescriptionTermAndDetail
                                                        testId={'insuranceBrand'}
                                                        term={t('additionalDataWell.insuranceBrand')}
                                                        detail={shortCompanyName}
                                                    />
                                                    <DescriptionTermAndDetail
                                                        testId={'registrationNumber'}
                                                        term={t('additionalDataWell.registrationNumber')}
                                                        detail={registrationNumber}
                                                    />
                                                    <DescriptionTermAndDetail
                                                        testId={'renewalMonthAndYear'}
                                                        term={t('additionalDataWell.renewalMonthAndYear')}
                                                        detail={getRenewalMonthAndYearText(
                                                            formik.values.renewalMonthAndYear,
                                                        )}
                                                    />
                                                </DescriptionList>
                                            </Well>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ValidatedCheckbox
                                                label={textWithComponents(t, 'consent.label')}
                                                name={'consent'}
                                                testId={'consent'}
                                                isMandatory
                                                helpText={textWithComponents(t, 'consent.helpText', {
                                                    linkToUkInsurancePrivacyNotice,
                                                })}
                                                withHtmlValidationWarning
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ButtonContainer nav>
                                                {returnButton}
                                                <Button
                                                    type={'button'}
                                                    testId={'letUsKnowButton'}
                                                    disabled={isSubmitting}
                                                    onClick={formik.submitForm}
                                                >
                                                    {t('buttons.letUsKnow.label')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                            )}
                        </Formik>
                    </Fragment>
                ) : (
                    <ButtonContainer nav>
                        {returnButton}
                        {findOutMoreButton}
                    </ButtonContainer>
                )}
            </section>
        </Suspense>
    );
};

const InsuranceWithHandlers = withLoadingAndNoConnectionHandler(InsuranceUi);

export const Insurance: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: insurance, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchInsurance,
        selectInsuranceForSpecific,
        encryptedContractId,
    );

    return <InsuranceWithHandlers isLoading={isLoading} hasError={!!loadingError} insurance={insurance} />;
};
