// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/contracts/ContractActions.tsx
// (there weren't enough/working properties on the original)
import React, { MouseEventHandler } from 'react';
import { Layout, Button } from '@vwfs-bronson/bronson-react';
import { InfoIcon, Spinner } from '@cp-shared-8/frontend-ui';
import { LinkAdapter } from 'components/link-adapter';

type ButtonProps = Parameters<typeof Button>[0];

export type ContractActionItemType = {
    /**
     * The name of the icon from bronson guide
     */
    iconName: string;
    /**
     * The name of the URL of the page the link goes to
     */
    pageUrl?: string;
    /**
     * The name of contract action such as Amortization Table, Documents.
     */
    label: string;
    tooltip?: string | React.ReactNode;
    onClick?: MouseEventHandler;
    /**
     * The condition for showing a spinner instead of the Link
     */
    isLoading?: boolean;
    /**
     * To disable button (works only for buttons)
     */
    disabled?: boolean;
    /**
     * An optional test ID to be associated with the action item
     */
    testId?: string;

    iconReversed?: boolean;
};

export type ContractActionsProps = {
    contractActionItems: ContractActionItemType[];
    className?: string;
};

export const ContractActions: React.FC<ContractActionsProps> = ({ contractActionItems, className }) => {
    if (!contractActionItems) {
        return null;
    }

    return (
        <Layout className={className}>
            {contractActionItems.map((contractActionItem: ContractActionItemType, index: number) => {
                const commonProps: Partial<ButtonProps> = {
                    className: 'u-text-brand',
                    icon: contractActionItem.iconName,
                    onClick: contractActionItem.onClick,
                    testId: contractActionItem.testId,
                    link: true,
                    simple: true,
                };
                let buttonProps: ButtonProps;
                if (!!contractActionItem.pageUrl) {
                    const isInternal = contractActionItem.pageUrl.startsWith('/');

                    buttonProps = {
                        ...commonProps,
                        element: isInternal ? LinkAdapter : 'a',
                        iconReversed: contractActionItem.iconReversed,
                        href: contractActionItem.pageUrl,
                        target: isInternal ? undefined : '_BLANK',
                    };
                } else {
                    buttonProps = {
                        ...commonProps,
                        element: 'button',
                        type: 'button',
                        iconReversed: false,
                        disabled: contractActionItem.disabled,
                    };
                }
                return (
                    <Layout.Item key={index} className="u-1/3 u-1/1@s">
                        {contractActionItem.isLoading ? (
                            <Spinner small />
                        ) : (
                            <>
                                <Button {...buttonProps}>{contractActionItem.label}</Button>
                                {contractActionItem.tooltip && <InfoIcon text={contractActionItem.tooltip} />}
                            </>
                        )}
                    </Layout.Item>
                );
            })}
        </Layout>
    );
};
