import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { IdentificationSectionBO } from '@cp-uk/common';
import { upperCaseFirstLetterOfEachWord, joinTruthyValues } from 'utils';
import { DataOverview } from 'components/data-overview/DataOverview';
import { DescriptionListHorizontal, DescriptionListHorizontalItemsProps } from 'components/description-list-horizontal';
import { ChangeName } from './change-name/ChangeName';
import { valueOrErrorTag } from 'components/helpers';

const getFullName = (title: string | undefined, firstName: string | undefined, surname: string | undefined): string => {
    return joinTruthyValues(' ', upperCaseFirstLetterOfEachWord(title), firstName, surname);
};

export const IdentificationSection: React.FC<{ identificationSection: IdentificationSectionBO }> = ({
    identificationSection,
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const { t } = useTranslation('identification-section');

    const { onAction: onChangeStartAction } = useAnalyticsActionTracker('onEditProfileIdentification');
    const { onAction: onChangeCancelAction } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onAction: onChangeDownloadAction } = useAnalyticsActionTracker('onEditProfileDownload');

    const { title, firstName, surname } = identificationSection;

    const onChangeStart: () => void = () => {
        setIsChanging(true);
        onChangeStartAction();
    };
    const onChangeCancel = (): void => {
        setIsChanging(false);
        onChangeCancelAction('Identification');
    };
    const onChangeDownload = (documentType: string): void => {
        onChangeDownloadAction('Identification', documentType);
    };

    const descriptionListItems: DescriptionListHorizontalItemsProps[] = [
        {
            label: t('name.label'),
            value: valueOrErrorTag(t, getFullName(title, firstName, surname)),
            testId: 'name',
        },
    ];

    return (
        <Fragment>
            {isChanging ? (
                <ChangeName onChangeCancel={onChangeCancel} onChangeDownload={onChangeDownload} />
            ) : (
                <DataOverview
                    noBorder
                    title={t('title')}
                    buttonLabel={t('translation:editableSectionNav.change')}
                    buttonDisabled={false}
                    onButtonClick={onChangeStart}
                    testId={'identificationSection'}
                >
                    <DescriptionListHorizontal items={descriptionListItems} />
                </DataOverview>
            )}
        </Fragment>
    );
};
