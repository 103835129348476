import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { ChangeBankAccountBO, ChangeBankAccountRequest } from '@cp-uk/common';
import { ChangeData } from 'components/change-data/ChangeData';
import { DataEntry } from './data-entry/DataEntry';
import { Confirmation } from './confirmation/Confirmation';
import { NotificationContentProps } from '../../types';

enum Views {
    DataEntry = 'data-entry',
    Confirmation = 'confirmation',
}

export const ChangeBankAccount: React.FC<{
    onChangeCancel: () => void;
    onChangeFailure: (failureNotificationContentProps: NotificationContentProps) => void;
    onChangeSuccess: () => void;
    onChangeData: (changeBankAccountRequest: ChangeBankAccountRequest) => void;
    changeBankAccount: ChangeBankAccountBO | undefined;
}> = ({ onChangeCancel, onChangeFailure, onChangeSuccess, onChangeData, changeBankAccount }) => {
    const [currentView, setCurrentView] = useState<Views>(Views.DataEntry);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [changeBankAccountRequest, setChangeBankAccountRequest] = useState<ChangeBankAccountRequest>({
        accountHolderName: '',
        sortCode: '',
        accountNumber: '',
    });
    const { t } = useTranslation('change-bank-account');

    if (changeBankAccount === undefined) {
        return null;
    }

    const {
        encryptedContractId,
        contractNumber,
        showThisContractOnlyNotification,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
        productType,
        financeBrand,
    } = changeBankAccount;

    const onDataEntryIsSubmitting = (newIsSubmitting: boolean): void => {
        setIsSubmitting(newIsSubmitting);
    };
    const onDataEntryCancel = (): void => {
        onChangeCancel();
    };
    const onDataEntryFailure = (failureNotificationContentProps: NotificationContentProps): void => {
        onChangeFailure(failureNotificationContentProps);
    };
    const onDataEntrySuccess = (changeBankAccountRequest: ChangeBankAccountRequest): void => {
        setChangeBankAccountRequest(changeBankAccountRequest);
        setCurrentView(Views.Confirmation);
        onChangeData(changeBankAccountRequest);
    };

    const onConfirmationClose = (): void => {
        onChangeSuccess();
    };

    const onClose = (): void => {
        if (currentView === Views.DataEntry) {
            onDataEntryCancel();
        } else {
            onConfirmationClose();
        }
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <ChangeData
                currentView={currentView.toString()}
                className={'uk-change-bank-account'}
                heading={t('heading')}
                isSubmitting={isSubmitting}
                onClose={onClose}
                testId={'changeBankAccount'}
            >
                {currentView === Views.DataEntry && (
                    <DataEntry
                        onIsSubmitting={onDataEntryIsSubmitting}
                        onCancel={onDataEntryCancel}
                        onFailure={onDataEntryFailure}
                        onSuccess={onDataEntrySuccess}
                        encryptedContractId={encryptedContractId}
                        showThisContractOnlyNotification={showThisContractOnlyNotification}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                    />
                )}
                {currentView === Views.Confirmation && (
                    <Confirmation
                        onClose={onConfirmationClose}
                        accountHolderName={changeBankAccountRequest.accountHolderName}
                        sortCode={changeBankAccountRequest.sortCode}
                        accountNumber={changeBankAccountRequest.accountNumber}
                        effectiveDate={nextScheduledPaymentDate}
                        contractNumber={contractNumber}
                        productType={productType}
                        financeBrand={financeBrand}
                    />
                )}
            </ChangeData>
        </Suspense>
    );
};
