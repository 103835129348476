import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { ServiceDetailsBO } from '@cp-uk/common';
import { SummarySection } from './summary-section/SummarySection';
import { BankAccountSection } from '../common-details/bank-account-section/BankAccountSection';
import { DayOfPaymentSection } from '../common-details/day-of-payment-section/DayOfPaymentSection';
import { MileageSection } from '../common-details/mileage-section/MileageSection';

export const ServiceDetails: React.FC<{ serviceDetails: ServiceDetailsBO | undefined }> = ({ serviceDetails }) => {
    const { t } = useTranslation('service-details');

    if (serviceDetails === undefined) {
        return null;
    }

    const { summarySection, bankAccountSection, dayOfPaymentSection, mileageSection } = serviceDetails;

    return (
        <Accordion.Item title={t('title')} testId={'serviceDetails'}>
            <Suspense fallback={<Spinner center />}>
                <SummarySection summarySection={summarySection} />
                <BankAccountSection bankAccountSection={bankAccountSection} />
                <DayOfPaymentSection dayOfPaymentSection={dayOfPaymentSection} />
                <MileageSection mileageSection={mileageSection} />
            </Suspense>
        </Accordion.Item>
    );
};
