import { Views } from '../types';

export enum Options {
    payInOneGo = 'payInOneGo',
    payInInstalments = 'payInInstalments',
    breathingSpace = 'breathingSpace',
    otherOptions = 'otherOptions',
}

export type OptionsAvailableFormValues = {
    option: string;
    consent: boolean;
};

export type OptionsAvailableNextViews = Extract<
    Views,
    Views.payInOneGo | Views.payInInstalments | Views.breathingSpace | Views.otherOptions
>;

export type OptionsAvailableSelections = {
    option: Options;
    optionAsString: string;
    consent: boolean;
};
