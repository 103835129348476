// NOTE: This file is "borrowed" from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/errors/ValidationErrorMessage.tsx
// (this is NOT exposed by default in the @cp-shared/frontend-ui package)

import React from 'react';
import { ErrorMessage } from '@vwfs-bronson/bronson-react';
import { FieldMetaProps } from 'formik';
import { hasError } from './hasError';

export type ErrorMessageProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: FieldMetaProps<any>;
    testId?: string;
    withHtmlValidationWarning?: boolean;
};

export const ValidationErrorMessage: React.FC<ErrorMessageProps> = ({ meta, testId, withHtmlValidationWarning }) => {
    /**
     * Working with the style and not with an if statement was required to not break the tests in the markets.
     * See also hint from Bronson team
     * https://teams.microsoft.com/l/message/19:1ff395352c3542fa8477b5c53072ebdf@thread.skype/1598356613820?tenantId=e9293843-ced6-4c97-b99f-4998c3285f92&groupId=ca9aef03-550e-48ec-8b25-840a6c13b765&parentMessageId=1598356613820&teamName=Bronson-%20%2F%20UX-Support&channelName=General&createdTime=1598356613820
     */
    const hideErrorStyle = {
        display: 'none',
    };

    const hideErrorMessage = !hasError(meta);

    return (
        <ErrorMessage
            aria-hidden={hideErrorMessage}
            style={hideErrorMessage ? hideErrorStyle : undefined}
            testId={`${testId}ErrorMessage`}
        >
            {withHtmlValidationWarning ? <span dangerouslySetInnerHTML={{ __html: meta.error || '' }} /> : meta.error}
        </ErrorMessage>
    );
};
