import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Draft, produce } from 'immer';
import { AddressSectionBO, ContactDetailsSectionBO, MarketingPreferencesSectionBO, MyProfileBO } from '@cp-uk/common';
import { MyProfileContext, MyProfileContextValues } from './MyProfileContext';
import { updateMyProfile } from './MyProfileSlice';

interface NewSectionsValues {
    newAddressSection: AddressSectionBO | undefined;
    newContactDetailsSection: ContactDetailsSectionBO | undefined;
    newMarketingPreferencesSection: MarketingPreferencesSectionBO | undefined;
}

const updateAddressSection = (
    draftMyProfile: Draft<MyProfileBO>,
    newAddressSection: AddressSectionBO | undefined,
): void => {
    if (newAddressSection === undefined) {
        return;
    }

    draftMyProfile.addressSection = newAddressSection;
};

const updateContactDetailsSection = (
    draftMyProfile: Draft<MyProfileBO>,
    newContactDetailsSection: ContactDetailsSectionBO | undefined,
): void => {
    if (newContactDetailsSection === undefined) {
        return;
    }

    draftMyProfile.contactDetailsSection = newContactDetailsSection;
};

const updateMarketingPreferencesSection = (
    draftMyProfile: Draft<MyProfileBO>,
    newMarketingPreferencesSection: MarketingPreferencesSectionBO | undefined,
): void => {
    if (newMarketingPreferencesSection === undefined) {
        return;
    }

    draftMyProfile.marketingPreferencesSection = newMarketingPreferencesSection;
};

const updateMyProfileWithNewSections = (
    existingMyProfile: MyProfileBO,
    newSectionsValues: NewSectionsValues,
): MyProfileBO | undefined => {
    const { newAddressSection, newContactDetailsSection, newMarketingPreferencesSection } = newSectionsValues;
    if (
        newAddressSection === undefined &&
        newContactDetailsSection === undefined &&
        newMarketingPreferencesSection === undefined
    ) {
        return undefined;
    }

    const updatedMyProfile = produce(existingMyProfile, (draftMyProfile: Draft<MyProfileBO>) => {
        updateAddressSection(draftMyProfile, newAddressSection);
        updateContactDetailsSection(draftMyProfile, newContactDetailsSection);
        updateMarketingPreferencesSection(draftMyProfile, newMarketingPreferencesSection);
    });

    return updatedMyProfile;
};

export const MyProfileProvider: React.FC<{ myProfile: MyProfileBO }> = ({ myProfile: existingMyProfile, children }) => {
    const dispatch = useDispatch();
    const [newAddressSection, setNewAddressSection] = useState<AddressSectionBO | undefined>(undefined);
    const [newContactDetailsSection, setNewContactDetailsSection] = useState<ContactDetailsSectionBO | undefined>(
        undefined,
    );
    const [newMarketingPreferencesSection, setNewMarketingPreferencesSection] = useState<
        MarketingPreferencesSectionBO | undefined
    >(undefined);

    const newSectionsRef = useRef<NewSectionsValues>({
        newAddressSection: undefined,
        newContactDetailsSection: undefined,
        newMarketingPreferencesSection: undefined,
    });

    useEffect(() => {
        newSectionsRef.current = { newAddressSection, newContactDetailsSection, newMarketingPreferencesSection };
    }, [newAddressSection, newContactDetailsSection, newMarketingPreferencesSection]);

    useEffect(() => {
        return (): void => {
            const updatedMyProfile = updateMyProfileWithNewSections(existingMyProfile, newSectionsRef.current);
            if (updatedMyProfile !== undefined) {
                dispatch(updateMyProfile(updatedMyProfile));
            }
        };
    }, [dispatch, existingMyProfile]);

    const value: MyProfileContextValues = {
        newAddressSection,
        setNewAddressSection,
        newContactDetailsSection,
        setNewContactDetailsSection,
        newMarketingPreferencesSection,
        setNewMarketingPreferencesSection,
    };

    return <MyProfileContext.Provider value={value}>{children}</MyProfileContext.Provider>;
};
