import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isError } from '@cp-shared-8/common-utilities';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Notification, NotificationStatus, Spinner, withAutoScroll } from '@cp-shared-8/frontend-ui';
import { EarlyTerminationBO, ErrorOrEarlyTerminationBO, GenerateEarlyTerminationError } from '@cp-uk/common';
import {
    getInitialErrorNotificationKey,
    processErrorCode,
    textWithComponents,
    useWebsiteFinanceCompanyDetails,
    useScrollTo,
    buildLinkToPhoneNumber,
} from 'utils';
import { selectEarlyTerminationForSpecific } from './EarlyTerminationSelector';
import { fetchEarlyTermination } from './EarlyTerminationSlice';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { GenerateView } from './generate-view/GenerateView';
import { EnquiryView } from './enquiry-view/EnquiryView';
import { CompleteView } from './complete-view/CompleteView';

const NotificationWithAutoScroll = withAutoScroll(Notification);

enum Views {
    generate = 'generate',
    enquiry = 'enquiry',
    complete = 'complete',
}

export const EarlyTerminationUi: React.FC<{ errorOrEarlyTermination: ErrorOrEarlyTerminationBO | undefined }> = ({
    errorOrEarlyTermination,
}) => {
    const earlyTermination =
        !!errorOrEarlyTermination && !isError<GenerateEarlyTerminationError>(errorOrEarlyTermination)
            ? (errorOrEarlyTermination as EarlyTerminationBO)
            : undefined;

    const initialErrorNotificationKey = getInitialErrorNotificationKey<
        GenerateEarlyTerminationError,
        ErrorOrEarlyTerminationBO
    >(errorOrEarlyTermination);

    const initialView = earlyTermination?.state === 'generate' ? Views.generate : Views.enquiry;

    const [currentView, setCurrentView] = useState<Views>(initialView);
    const [errorNotificationKey, setErrorNotificationKey] = useState<string | undefined>(initialErrorNotificationKey);
    const scrollToRef = useScrollTo(currentView, initialView);
    const { phoneNumber, openingHours } = useWebsiteFinanceCompanyDetails('hire');
    const { t } = useTranslation('early-termination');

    const linkToPhoneNumber = buildLinkToPhoneNumber(phoneNumber, 'brand');

    if (errorNotificationKey) {
        return (
            <NotificationWithAutoScroll
                status={NotificationStatus.warning}
                testId={'errorNotification'}
            >
                {textWithComponents(
                    t,
                    `notifications.${errorNotificationKey}.text`,
                    { linkToPhoneNumber },
                    { phoneNumber, openingHours },
                )}
            </NotificationWithAutoScroll>
        );
    }

    if (earlyTermination === undefined) {
        return null;
    }

    const { contractDescription, generateView, enquiryView, completeView } = earlyTermination;

    const onGenerateError = (code: GenerateEarlyTerminationError | undefined): void => {
        setErrorNotificationKey(processErrorCode(code));
    };
    const onEnquiryContinue = (): void => {
        setCurrentView(Views.complete);
    };
    const onCompleteReturn = (): void => {
        setCurrentView(Views.enquiry);
    };

    return (
        <Suspense fallback={<Spinner center />}>
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.generate && (
                    <GenerateView
                        contractDescription={contractDescription}
                        generateView={generateView}
                        onError={onGenerateError}
                    />
                )}
                {currentView === Views.enquiry && (
                    <EnquiryView
                        contractDescription={contractDescription}
                        enquiryView={enquiryView}
                        onContinue={onEnquiryContinue}
                    />
                )}
                {currentView === Views.complete && (
                    <CompleteView
                        contractDescription={contractDescription}
                        completeView={completeView}
                        onReturn={onCompleteReturn}
                    />
                )}
            </section>
        </Suspense>
    );
};

const EarlyTerminationWithHandlers = withLoadingAndNoConnectionHandler(EarlyTerminationUi);

export const EarlyTermination: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { data: errorOrEarlyTermination, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchEarlyTermination,
        selectEarlyTerminationForSpecific,
        encryptedContractId,
    );

    return (
        <EarlyTerminationWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            errorOrEarlyTermination={errorOrEarlyTermination}
        />
    );
};
