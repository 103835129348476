import { SimpleContentDto, getModernSlaveryStatementEndpoint } from '@cp-uk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimpleContentDto>({
    contentName: 'modernSlaveryStatement',
    contentEndpoint: getModernSlaveryStatementEndpoint,
});

export default reducer;
export const fetchModernSlaveryStatement = fetchContent;
