import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeroImage as HeroImageShared } from '@cp-shared-8/frontend-ui';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { landingPagePath } from 'components/navigation/paths';

export type SimplePageErrorProps = {
    namespace: string;
};

export const SimplePageError: React.FC<SimplePageErrorProps> = ({ namespace }) => {
    const { t } = useTranslation(namespace);
    const history = useHistory();

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    const addClass = (Component: JSX.Element): JSX.Element => {
        return <Component.type {...Component.props} className={'u-text-inherit-color'} />;
    };

    return (
        <HeroImageShared
            pretitle={t('pretitle')}
            title={t('title')}
            subTitle={textWithComponents(t, 'subTitle', { linkToAvartoPhoneNumber: addClass(linkToAvartoPhoneNumber) })}
            buttonText={t('button')}
            clickHandler={() => history.push(landingPagePath())}
            shallow
        />
    );
};
