import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { getRequestAdditionalHelpEndpoint, RequestAdditionalHelpBO } from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    RequestAdditionalHelpBO,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'requestAdditionalHelp',
    fetchCallback(encryptedContractId: string) {
        return CpDataApi.get<RequestAdditionalHelpBO>(getRequestAdditionalHelpEndpoint(encryptedContractId)).then(
            ({ data }) => data,
        );
    },
});

export default reducer;
export const fetchRequestAdditionalHelp = fetchData;
