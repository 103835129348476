import { currentBrand, currentBrandName } from 'config';
import { carOrVehicleText, dealerOrRetailerText } from './brand-text';

export const expandBrand = (content: string): string => {
    return content.replace(/{{brand}}/g, currentBrand);
};

export const expandBrandName = (content: string): string => {
    return content.replace(/{{brandName}}/g, currentBrandName);
};

export const expandDealerOrRetailer = (content: string): string => {
    const dealerOrRetailer = dealerOrRetailerText();

    return content.replace(/{{dealerOrRetailer}}/g, dealerOrRetailer);
};

export const expandCarOrVehicle = (content: string): string => {
    const carOrVehicle = carOrVehicleText();

    return content.replace(/{{carOrVehicle}}/g, carOrVehicle);
};

export const expandWebsiteOrigin = (content: string): string => {
    return content.replace(/{{websiteOrigin}}/g, window.location.origin);
};
