import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { FinanceBrands, isFinanceBrand, isProductType, ProductTypes, SERVICE_PRODUCTS } from '@cp-uk/common';
import { useFinanceCompanyDetails } from 'utils';
import { earlySettlementPagePath, earlyTerminationPagePath, endOfContractPagePath } from 'components/navigation/paths';
import {
    ContractActions as ContractActionsLocal,
    ContractActionItemType,
} from 'components/contract-actions/ContractActions';
import { retailerDescriptionText } from '../helpers';

export type ContractActionsProps = {
    encryptedContractId: string;
    isActive: boolean;
    productType: ProductTypes;
    financeBrand: FinanceBrands;
    earlySettlementEnabled: boolean;
    earlyTerminationEnabled: boolean;
    endOfContractEnabled: boolean;
};

export const ContractActions: React.FC<ContractActionsProps> = ({
    encryptedContractId,
    isActive,
    productType,
    financeBrand,
    earlySettlementEnabled,
    earlyTerminationEnabled,
    endOfContractEnabled,
}) => {
    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);
    const { t } = useTranslation('contract-actions');

    const { onAction: onFindYourRetailerAction } = useAnalyticsActionTracker('onLearnMore');

    const contractActionItems: ContractActionItemType[] = [];

    const onFindYourRetailerClick = (): void => {
        onFindYourRetailerAction('Find your retailer');
    };

    if (!isProductType(productType, SERVICE_PRODUCTS)) {
        if (isActive) {
            if (isProductType(productType, 'contractHire')) {
                if (earlyTerminationEnabled) {
                    contractActionItems.push({
                        iconName: 'semantic-calculator',
                        pageUrl: earlyTerminationPagePath(encryptedContractId),
                        label: t('items.early-termination'),
                        testId: 'earlyTerminationAction',
                    });
                }
            } else {
                if (earlySettlementEnabled) {
                    contractActionItems.push({
                        iconName: 'semantic-calculator',
                        pageUrl: earlySettlementPagePath(encryptedContractId),
                        label: t('items.early-settlement'),
                        testId: 'earlySettlementAction',
                    });
                }
            }

            if (
                isProductType(productType, ['solutions', 'contractHire']) &&
                isFinanceBrand(financeBrand, ['audi', 'bentley', 'porsche', 'seat', 'skoda', 'vw', 'vwcv']) &&
                endOfContractEnabled
            ) {
                contractActionItems.push({
                    iconName: 'semantic-calendar',
                    pageUrl: endOfContractPagePath(encryptedContractId),
                    label: t('items.end-of-contract'),
                    testId: 'endOfContractAction',
                });
            }
        }
    }

    const contactUrl = t(`callToActionUrls.${financeBrand}.contact`);
    if (contactUrl) {
        const retailerDescription = retailerDescriptionText(financeBrand);

        contractActionItems.push({
            iconName: 'semantic-location',
            pageUrl: contactUrl,
            label: t('items.find-your-retailer', { shortCompanyName, retailerDescription }),
            testId: 'findYourRetailerAction',
            onClick: onFindYourRetailerClick,
        });
    }

    if (contractActionItems.length === 0) {
        return null;
    }

    return (
        <div className={'c-contract-actions u-border-h u-pv-xsmall u-mv-small'} data-testid={'contractActions'}>
            <ContractActionsLocal contractActionItems={contractActionItems} />
        </div>
    );
};
