import { Link } from 'react-router-dom';
import {
    cookiePolicyPagePath,
    dashboardPagePath,
    documentCentrePagePath,
    faqPagePath,
    financialSupportAndWellbeingTopicPagePath,
    landingPagePath,
    myProfilePagePath,
    privacyPolicyPagePath,
    requestContactPagePath,
} from './navigation/paths';
import React from 'react';

export const linkToLandingPage = <Link to={landingPagePath()} data-testid={'landingPageLink'} />;
export const linkToFaqPage = <Link to={faqPagePath()} data-testid={'faqPageLink'} />;
export const linkToPrivacyPolicyPage = <Link to={privacyPolicyPagePath()} data-testid={'privacyPolicyPageLink'} />;
export const linkToCookiePolicyPage = <Link to={cookiePolicyPagePath()} data-testid={'cookiePolicyPageLink'} />;
export const linkToDashboardPage = <Link to={dashboardPagePath()} data-testid={'dashboardPageLink'} />;
export const linkToMyProfilePage = <Link to={myProfilePagePath()} data-testid={'myProfilePageLink'} />;
export const linkToDocumentCentrePage = <Link to={documentCentrePagePath()} data-testid={'documentCentrePageLink'} />;
export const linkToRequestContactPage = <Link to={requestContactPagePath('')} data-testid={'requestContactPageLink'} />;

export const linkToRequestContactPageFinancialDifficulty = (
    <Link
        to={requestContactPagePath('financial-difficulty')}
        data-testid={'requestContactForFinancialDifficultyPageLink'}
    />
);

export const linkToFreeSupportAndIndependentAdvicePage = (
    <Link
        to={financialSupportAndWellbeingTopicPagePath('free-support-and-independent-advice')}
        data-testid={'freeSupportAndIndependentAdvicePageLink'}
    />
);
