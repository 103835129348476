import { useTranslation } from 'react-i18next';
import {
    FinanceBrands,
    InsuranceBrands,
    isFinanceBrand,
    isProductCategory,
    isProductType,
    ProductCategories,
    ProductTypes,
} from '@cp-uk/common';
import { currentBrandAsFinanceBrands } from 'components/helpers';

const getProductTypeKey = (isRetailContractHire: boolean): string => {
    return isRetailContractHire ? 'retailContractHire' : 'retailCustomerServices';
};
const getFinanceBrandKey = (financeBrand: FinanceBrands): string => {
    return financeBrand.toLowerCase();
};

export interface FinanceCompanyDetails {
    companyName: string;
    shortCompanyName: string;
    phoneNumber: string;
    emailAddress: string;
    openingHours: string;
}

const useFinanceCompanyDetailsInternal = (
    isRetailContractHire: boolean | undefined,
    financeBrand: FinanceBrands | undefined,
): FinanceCompanyDetails => {
    const { t } = useTranslation('finance-company-details');

    if (isRetailContractHire === undefined || financeBrand === undefined) {
        return {
            companyName: '',
            shortCompanyName: '',
            phoneNumber: '',
            emailAddress: '',
            openingHours: '',
        };
    }

    const productTypeKey = getProductTypeKey(isRetailContractHire);
    const financeBrandKey = getFinanceBrandKey(financeBrand);

    const companyName = t(`companyNames.${financeBrandKey}`);
    const shortCompanyName = t(`shortCompanyNames.${financeBrandKey}`);
    const phoneNumber = t(`phoneNumbers.${productTypeKey}.${financeBrandKey}`);
    const emailAddress = t(`emailAddresses.${productTypeKey}`);
    const openingHours = t(`openingHours.${productTypeKey}`);

    return {
        companyName,
        shortCompanyName,
        phoneNumber,
        emailAddress,
        openingHours,
    };
};

export const useFinanceCompanyDetails = (
    productType: ProductTypes | undefined,
    financeBrand: FinanceBrands | undefined,
): FinanceCompanyDetails => {
    return useFinanceCompanyDetailsInternal(
        productType ? isProductType(productType, 'contractHire') : undefined,
        financeBrand,
    );
};

export const useWebsiteFinanceCompanyDetails = (productCategory: ProductCategories): FinanceCompanyDetails => {
    return useFinanceCompanyDetailsInternal(isProductCategory(productCategory, 'hire'), currentBrandAsFinanceBrands());
};

export const useInsuranceFinanceCompanyDetails = (insuranceBrand: InsuranceBrands | undefined): string => {
    const { t } = useTranslation('finance-company-details');

    if (!isFinanceBrand(insuranceBrand, ['audi', 'porsche', 'seat', 'skoda', 'vw'])) {
        return '';
    }

    return t(`shortCompanyNames.${insuranceBrand}`);
};
